import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";
import WhiteSpinner from "../Modals/WhiteSpinner";
import PageNumbers from "../Subcomponents/PageNumbers";

const AddressBook = (props) => {
  const {
    targetName,
    buttonLabel,
    className,
    showInternational,
    showInternationalOnly,
    changeDeliveryAddress,
    changeDeliveryAddressBatch,
    addressFieldType,
    batchCanBeSelected,
    lookupCodes
  } = props;

  const [modal, setModal] = useState(false);

  // The contacts to display on the current page
  const [stateGabData, setStateGabData] = useState([]);

  const [storedLetter, setStoredLetter] = useState("All");

  // Number of results to display per page
  const [numPerPage, setNumPerPage] = useState(5);

  // Number of pages
  const [numOfPages, setNumOfPages] = useState(1);

  // The page to display
  const [currentPage, setCurrentPage] = useState(1);

  // The array of pages to display as selectable options
  const [pageNumbersToDisplay, setPageNumbersToDisplay] = useState([]);

  // The total number of contacts
  const [totalContacts, setTotalContacts] = useState(0);

  // The total number of domestic contacts
  const [totalDomesticContacts, setTotalDomesticContacts] = useState(0);

  // The total number of contacts after any filters (if present) are applied
  const [totalFilteredContacts, setTotalFilteredContacts] = useState(0);

  // The current value in the search box
  const [searchBox, setSearchBox] = useState("");

  // The searched text after clicking the search button
  const [searchedText, setSearchedText] = useState("");

  const [selectedGroup, setSelectedGroup] = useState(-1);

  // Object containing the selected contact
  const [selectedAddress, setSelectedAddress] = useState({});

  // Array of all selected contact objects (for batch only)
  const [selectedAddressBatch, setSelectedAddressBatch] = useState([]);

  //  The checkbox to select all contacts (for batch only)
  const [batchAllCheckbox, setBatchAllCheckbox] = useState(false);

  // The letters of the alphabet to display
  const [stateFilteredAlpha, setStateFilteredAlpha] = useState([]);

  // Array of all groups with their associated subgroups
  const [groupsWithSubgroups, setGroupsWithSubgroups] = useState([]);

  const [groupsDropdownOpen, setGroupsDropdownOpen] = useState(false);

  const [resultsDropdownOpen, setResultsDropdownOpen] = useState(false);

  // Toggle the processing bar when loading contacts
  const [toggleSpinner, setToggleSpinner] = useState(false);

  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  };

  // Runs whenever contacts need to be fetched
  useEffect(() => {
    setToggleSpinner(true);

    // Adapted from
    // https://stackoverflow.com/questions/22780430/javascript-xmlhttprequest-using-jsonp
    const jsonp = (url, callback) => {
      // Set up a callback for each JSONP call
      const callbackName = "_jsonpCallback";
      window.jsonp_abdojoIoScript = {
        [callbackName]: function (data) {
          delete window.jsonp_abdojoIoScript;
          document.body.removeChild(script);
          callback(data);
        }
      };
      // Make the JSONP call
      let script = document.createElement("script");
      script.id = "gabJsonpScript";
      script.src = url + "&_abCallback=jsonp_abdojoIoScript." + callbackName;
      document.body.appendChild(script);
    };

    jsonp(
      ((lookupCodes && lookupCodes["cfo.addressbook.jsonp"]) ||
        "https://gab.usps.com/addrBook/Secure/ABQuerySMDAction_input") +
        "?filterGroup=" +
        selectedGroup +
        "&filterLetter=" +
        storedLetter +
        "&filterText=" +
        searchedText +
        "&sortField=" +
        "&contactId=" +
        "&page=" +
        (currentPage - 1) +
        "&showInternational=" +
        showInternational +
        "&fetchSize=" +
        numPerPage +
        "&jsonInput=%7B%27method%27%3A%27getAddressBookSMDResponse%27%2C%27params%27%3A%5B%5D%2C%27id%27%3A%270%27%7D",
      (data) => {
        if (data && data.result) {
          const result = data.result;
          setTotalContacts(result.totalContactsCount || 0);
          if (
            selectedGroup === -1 &&
            storedLetter === "All" &&
            searchedText === ""
          ) {
            // Only relevant if showInternational is false
            setTotalDomesticContacts(result.resultSize || 0);
          }
          setTotalFilteredContacts(result.resultSize || 0);
          setNumOfPages(
            result.resultSize ? Math.ceil(result.resultSize / numPerPage) : 1
          );
          setGroupsWithSubgroups(result.groups);
          setStateFilteredAlpha(
            result.letters ? result.letters.split("").sort() : []
          );
          // Modify certain fields before storing and displaying the contacts
          let allDisplayedContactIds = [];
          for (let i in result.contacts) {
            result.contacts[i].address =
              result.contacts[i].addresses &&
              result.contacts[i].addresses.length > 0
                ? result.contacts[i].addresses[0]
                : {};
            result.contacts[i].contactId =
              result.contacts[i].contactIdList &&
              result.contacts[i].contactIdList.length > 0
                ? result.contacts[i].contactIdList[0]
                : "-1";
            result.contacts[i].emailAddress =
              result.contacts[i].emailAddress &&
              result.contacts[i].emailAddress.length > 0
                ? result.contacts[i].emailAddress[0]
                : "";
            result.contacts[i].phoneNbr =
              result.contacts[i].phones && result.contacts[i].phones.length > 0
                ? result.contacts[i].phones[0]
                : "";
            allDisplayedContactIds.push(result.contacts[i].contactId);
          }
          for (let i in result.contacts) {
            result.contacts[i].showThis = true;
            for (let j in allDisplayedContactIds) {
              if (result.contacts[i].contactId === allDisplayedContactIds[j]) {
                if (i !== j) {
                  // The currently iterated row
                  // is not the first occurrence of this contact;
                  // the radio button or checkbox should be hidden
                  result.contacts[i].showThis = false;
                } else if (
                  showInternationalOnly === "true" &&
                  result.contacts[i].address &&
                  result.contacts[i].address.country &&
                  result.contacts[i].address.country.countryId === "840"
                ) {
                  // If the currently iterated row is a domestic location and
                  // only international locations should be selectable, then
                  // the radio button or checkbox should be hidden
                  result.contacts[i].showThis = false;
                }
                break;
              }
            }
          }
          setStateGabData(result.contacts);
        }
        setToggleSpinner(false);
      }
    );
  }, [
    showInternational,
    searchedText,
    storedLetter,
    selectedGroup,
    numPerPage,
    currentPage,
    lookupCodes
  ]);

  // Runs whenever the selected address(es) needs to be reset
  useEffect(() => {
    setSelectedAddress({});
    // Relevant for batch only
    setBatchAllCheckbox(false);
    setSelectedAddressBatch([]);
    // Update the page numbers that should be displayed
    let pageNumbersToDisplay = [];
    // The current page
    pageNumbersToDisplay.push(currentPage);
    // Add up to 2 pages before the current page, if available
    if (currentPage - 1 >= 1) {
      pageNumbersToDisplay.unshift(currentPage - 1);
      if (currentPage - 2 >= 1) {
        pageNumbersToDisplay.unshift(currentPage - 2);
      }
    }
    // Add pages after the current page until either the total number is 5 or
    // the last page has been reached
    for (
      let i = currentPage + 1;
      pageNumbersToDisplay.length < 5 && i <= numOfPages;
      i++
    ) {
      pageNumbersToDisplay.push(i);
    }
    // If the total number of pages is still less than 5 and the first page
    // has not been included yet, prepend pages to the array
    for (
      let j = currentPage - 3;
      pageNumbersToDisplay.length < 5 && j >= 1;
      j--
    ) {
      pageNumbersToDisplay.unshift(j);
    }
    setPageNumbersToDisplay(pageNumbersToDisplay);
  }, [stateGabData, numPerPage, currentPage, numOfPages]);

  const clickGroup = (groupId) => {
    setSearchBox("");
    setSearchedText("");
    setStoredLetter("All");
    setSelectedGroup(groupId);
    setCurrentPage(1);
  };

  const searchAddresses = () => {
    setSearchedText(searchBox);
    setStoredLetter("All");
    setSelectedGroup(-1);
    setCurrentPage(1);
  };

  const filterByLetter = (item) => {
    setSearchBox("");
    setSearchedText("");
    setStoredLetter(item);
    setSelectedGroup(-1);
    setCurrentPage(1);
  };

  const changeNumPerPage = (e) => {
    setNumPerPage(Number(e.target.innerText));
    setCurrentPage(1);
  };

  const useGABAddress = (e) => {
    if (addressFieldType !== "GABBatch") {
      changeDeliveryAddress(addressFieldType)(selectedAddress);
      setSelectedAddress({});
    } else if (selectedAddressBatch.length > 0) {
      changeDeliveryAddress(addressFieldType)(selectedAddressBatch[0]);
      setSelectedAddressBatch([]);
      setBatchAllCheckbox(false);
    }
    resetFields();
    toggle(e);
  };

  const useGABAddressBatch = (e) => {
    changeDeliveryAddressBatch(selectedAddressBatch);
    setSelectedAddressBatch([]);
    setBatchAllCheckbox(false);
    resetFields();
    toggle(e);
  };

  const handleCancel = (e) => {
    if (addressFieldType !== "GABBatch") {
      setSelectedAddress({});
    } else if (selectedAddressBatch.length > 0) {
      setSelectedAddressBatch([]);
      setBatchAllCheckbox(false);
    }
    resetFields();
    toggle(e);
  };

  // When closing the modal, reset all fields except results per page
  const resetFields = () => {
    setSearchBox("");
    setSearchedText("");
    setStoredLetter("All");
    setSelectedGroup(-1);
    setCurrentPage(1);
  };

  const gabSelectedAddress = (item) => {
    setSelectedAddress(item);
  };

  const gabSelectedAddressBatch = (item) => {
    let newArray = JSON.parse(JSON.stringify(selectedAddressBatch));
    let isBeingAdded = true;
    // If the contact is already checked, uncheck it
    for (let i in newArray) {
      if (newArray[i].contactId === item.contactId) {
        newArray.splice(i, 1);
        isBeingAdded = false;
        break;
      }
    }
    // If the contact is unchecked and the maximum number of contacts
    // has not yet been reached, check the box
    if (isBeingAdded) {
      if (newArray.length < batchCanBeSelected) {
        newArray.push(item);
      }
    }
    // Update the array of selected contacts
    setSelectedAddressBatch(newArray);
  };

  const isContactChecked = (contactId) => {
    for (let i in selectedAddressBatch) {
      if (selectedAddressBatch[i].contactId === contactId) {
        return true;
      }
    }
    return false;
  };

  const checkBatchAllCheckbox = (e) => {
    const isChecked = e.currentTarget.checked;
    // If checked, select all contacts on the current page, unless doing so
    // would exceed 20 items in the shipping cart
    if (isChecked) {
      let newArray = JSON.parse(JSON.stringify(selectedAddressBatch));
      for (
        // numberOfSelectedContacts = the current number of checked contacts
        // contactsOnPageIndex = the currently iterated contact row
        let numberOfSelectedContacts = newArray.length, contactsOnPageIndex = 0;
        // The loop ends when either the number of checked contacts exceeds
        // the allowable amount or the end of the page is reached
        numberOfSelectedContacts < batchCanBeSelected &&
        contactsOnPageIndex < stateGabData.length;
        // Advance to the next contact row
        contactsOnPageIndex++
      ) {
        // Determine if the currently iterated contact row is checked
        // (this should also correctly account for possible duplicates because
        // a duplicate row will have the same contact ID as the previous row,
        // so it will already be checked (isCurrentlyChecked = true) and
        // therefore will not be added to newArray again)
        let isCurrentlyChecked = false;
        for (let i in newArray) {
          if (
            newArray[i].contactId ===
            stateGabData[contactsOnPageIndex].contactId
          ) {
            isCurrentlyChecked = true;
            break;
          }
        }
        // If the currently iterated contact row is unchecked, check it
        // and increment the number of checked contacts
        if (!isCurrentlyChecked) {
          newArray.push(stateGabData[contactsOnPageIndex]);
          numberOfSelectedContacts++;
        }
      }
      // Update the array of selected contacts
      setSelectedAddressBatch(newArray);
    } else {
      // Remove all currently selected contacts
      setSelectedAddressBatch([]);
    }
    // Update the state of the batch-all-checkbox
    setBatchAllCheckbox(isChecked);
  };

  const isSubgroupActive = (subgroups) => {
    for (let subgroup in subgroups) {
      if (selectedGroup === subgroup.groupDbId) {
        return true;
      }
    }
    return false;
  };

  const renderSearchResults = () => {
    const startingResult = 1 + (currentPage - 1) * numPerPage;
    let endingResult = startingResult + numPerPage - 1;
    if (endingResult > totalFilteredContacts) {
      endingResult = totalFilteredContacts;
    }
    return (
      <div className="col-12 col-md-8">
        <p className="ab-search-results-text d-none d-md-block">
          <strong>
            Search results {startingResult} - {endingResult} of{" "}
            {totalFilteredContacts}
          </strong>
        </p>
      </div>
    );
  };

  const renderGroupsMobile = () => {
    // Get group name to display
    let groupName = "";
    if (selectedGroup === -1) {
      // "All contacts" was selected
      groupName = "All contacts";
    } else if (groupsWithSubgroups) {
      for (let i in groupsWithSubgroups) {
        if (selectedGroup === groupsWithSubgroups[i].groupDbId) {
          // A group was selected
          groupName = groupsWithSubgroups[i].groupName;
          break;
        }
        if (groupsWithSubgroups[i].subgroups) {
          for (let j in groupsWithSubgroups[i].subgroups) {
            // A subgroup was selected
            if (
              selectedGroup === groupsWithSubgroups[i].subgroups[j].groupDbId
            ) {
              groupName = groupsWithSubgroups[i].subgroups[j].groupName;
              break;
            }
          }
        }
      }
    }

    // Display dropdown
    return (
      <div className="col-12 d-md-none form-group">
        <Dropdown
          isOpen={groupsDropdownOpen}
          toggle={() => setGroupsDropdownOpen(!groupsDropdownOpen)}
          value={selectedGroup}
          id="mobile-contact-select"
          className="dropdown-selection"
        >
          <DropdownToggle className="btn btn-default dropdown dropdown-items-wrapper">
            {groupName}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu">
            {groupsWithSubgroups.map((group) => (
              <React.Fragment key={group.groupDbId}>
                <li>
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => clickGroup(group.groupDbId)}
                  >
                    {group.groupName}
                  </DropdownItem>
                </li>
                {group.subgroups ? (
                  <React.Fragment>
                    {group.subgroups.map((subgroup) => (
                      <li key={subgroup.groupDbId}>
                        <DropdownItem
                          className="dropdown-item"
                          onClick={() => clickGroup(subgroup.groupDbId)}
                        >
                          {subgroup.groupName}
                        </DropdownItem>
                      </li>
                    ))}
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  };

  const renderGroups = () => {
    return (
      <div className="row" id="group-links-list">
        <div className="col-12 d-none d-md-block contact-groups">
          {groupsWithSubgroups.map((group) => (
            <React.Fragment key={group.groupDbId}>
              {
                // Do not display the "All Contacts" group since that is
                // handled elsewhere
                group.groupDbId !== -1 ? (
                  <div className="row">
                    <div
                      className={
                        "col-10 contact-group-name" +
                        // Highlight group if this group was selected
                        (selectedGroup === group.groupDbId ||
                        // Highlight group if a subgroup within this group
                        // was selected
                        (group.subgroups && isSubgroupActive(group.subgroups))
                          ? " active"
                          : "")
                      }
                    >
                      <button
                        type="button"
                        className="button-link"
                        onClick={() => clickGroup(group.groupDbId)}
                      >
                        {selectedGroup === group.groupDbId ||
                        (group.subgroups &&
                          isSubgroupActive(group.subgroups)) ? (
                          <span className="visuallyhidden">
                            Currently selected group
                          </span>
                        ) : null}
                        {group.groupName} ({group.groupSize})
                      </button>
                    </div>
                    {group.subgroups ? (
                      <React.Fragment>
                        {group.subgroups.map((subgroup) => (
                          <div
                            key={subgroup.groupDbId}
                            className={
                              "col-10 contact-group-name contact-sub-group" +
                              (selectedGroup === subgroup.groupDbId
                                ? " active"
                                : "")
                            }
                          >
                            <button
                              type="button"
                              className="button-link"
                              onClick={() => clickGroup(subgroup.groupDbId)}
                            >
                              {selectedGroup === subgroup.groupDbId ? (
                                <span className="visuallyhidden">
                                  Currently selected subgroup
                                </span>
                              ) : null}
                              {subgroup.groupName} ({subgroup.groupSize})
                            </button>
                          </div>
                        ))}
                      </React.Fragment>
                    ) : null}
                  </div>
                ) : null
              }
            </React.Fragment>
          ))}
        </div>{" "}
      </div>
    );
  };

  return (
    <React.Fragment>
      <button
        type="button"
        className="inline-link right-chevron button-link"
        data-toggle="modal"
        data-target="#address-book"
        data-backdrop="static"
        id={targetName}
        tabIndex="0"
        onClick={toggle}
        style={{ color: "#333366", fontWeight: "bold ", cursor: "pointer" }}
      >
        {buttonLabel}
      </button>
      <Modal isOpen={modal} id="address-book" className={className}>
        <div className="modal-content modal-container">
          <div className="modal-header">
            <button
              type="button"
              id="close"
              className="close button-link"
              data-dismiss="modal"
              tabIndex="0"
              onClick={handleCancel}
            >
              <span className="visuallyhidden">Close Modal</span>
            </button>
            <h3 className="modal-title">Address Book</h3>
          </div>
          <div className="modal-body">
            <div className="body-content">
              <div className="row">
                <div className="col-12 col-md-4">
                  <a
                    href="/addressBook"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-link left-chevron"
                  >
                    Manage My Address Book
                  </a>
                </div>
                {renderSearchResults()}
              </div>
              <div className="row">
                <div className="col-12 address-book-desktop">
                  <div className="row">
                    <div className="col-12 col-md-4 form-group groups-list">
                      <div className="row">
                        <div className="col-12">
                          <div className="input-group">
                            <input
                              type="text"
                              id="address-book-search-input"
                              className="form-control"
                              placeholder="Search Contacts"
                              onChange={(e) => setSearchBox(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  searchAddresses();
                                }
                              }}
                              value={searchBox}
                            />
                            <span className="input-group-btn">
                              <button
                                className="btn btn-default search"
                                type="button"
                                aria-label="Search Global Address Book"
                                onClick={(e) => searchAddresses()}
                              />
                            </span>
                          </div>
                        </div>
                        {renderGroupsMobile()}
                      </div>
                      <div className="row">
                        <div className="col-12 d-none d-md-block contact-group-header">
                          <p
                            className={
                              selectedGroup === -1 && searchedText === ""
                                ? "active"
                                : ""
                            }
                          >
                            <button
                              type="button"
                              className="button-link"
                              onClick={() => clickGroup(-1)}
                            >
                              {selectedGroup === -1 && searchedText === "" ? (
                                <span className="visuallyhidden">
                                  Currently selected group
                                </span>
                              ) : null}
                              All Contacts (
                              {showInternational === "true"
                                ? totalContacts
                                : totalDomesticContacts}
                              )
                            </button>
                          </p>
                        </div>
                      </div>
                      {renderGroups()}
                    </div>
                    <div className="col-12 col-md-8 form-group">
                      <div className="row">
                        <div className="col-12 modal-sort-by-letter">
                          <button
                            type="button"
                            className={
                              "header-tabs button-link" +
                              (storedLetter === "All" && searchedText === ""
                                ? " active"
                                : "")
                            }
                            onClick={() => filterByLetter("All")}
                          >
                            {storedLetter === "All" && searchedText === "" ? (
                              <span className="visuallyhidden">
                                Currently selected letter
                              </span>
                            ) : null}
                            All
                          </button>
                          {stateFilteredAlpha.map((item) => (
                            <button
                              key={item}
                              type="button"
                              className={
                                "header-tabs button-link" +
                                (storedLetter === item ? " active" : "")
                              }
                              onClick={() => filterByLetter(item)}
                            >
                              {storedLetter === item ? (
                                <span className="visuallyhidden">
                                  Currently selected letter
                                </span>
                              ) : null}
                              {item}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          {/* <p className="ab-batch-message">Select up to 20 contacts to send identical packages to multiple addresses.</p> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <table
                            className="table"
                            id="modal-address-book-table"
                          >
                            <thead>
                              <tr>
                                <th scope="col">
                                  {addressFieldType === "GABBatch" ? (
                                    <label htmlFor="batch-all-checkbox">
                                      <input
                                        type="checkbox"
                                        id="batch-all-checkbox"
                                        onChange={checkBatchAllCheckbox}
                                        checked={batchAllCheckbox}
                                      />
                                      <span className="checkbox" />
                                      <span className="visuallyhidden">
                                        Select all addresses
                                      </span>
                                    </label>
                                  ) : null}
                                </th>
                                <th scope="col">
                                  <p id="name-header">
                                    <strong>Name (Last, First)</strong>
                                  </p>
                                </th>
                                <th scope="col">
                                  <p id="company-header">
                                    <strong>Company</strong>
                                  </p>
                                </th>
                                <th scope="col">
                                  <p id="location-header">
                                    <strong>Location</strong>
                                  </p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {stateGabData.map((item, index) => {
                                return (
                                  <tr
                                    key={
                                      item.contactId +
                                      (!item.showThis ? "-" + index : "")
                                    }
                                  >
                                    <th scope="row">
                                      {item.showThis ? (
                                        <React.Fragment>
                                          {addressFieldType !== "GABBatch" ? (
                                            <label
                                              htmlFor={"radio" + item.contactId}
                                            >
                                              <input
                                                type="radio"
                                                id={"radio" + item.contactId}
                                                name="radio-btn"
                                                onChange={(e) =>
                                                  gabSelectedAddress(item)
                                                }
                                                checked={
                                                  item.contactId ===
                                                  selectedAddress.contactId
                                                }
                                              />
                                              <span className="radio" />
                                            </label>
                                          ) : (
                                            <label
                                              htmlFor={
                                                "checkbox" + item.contactId
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                id={"checkbox" + item.contactId}
                                                onChange={(e) =>
                                                  gabSelectedAddressBatch(item)
                                                }
                                                checked={isContactChecked(
                                                  item.contactId
                                                )}
                                              />
                                              <span className="checkbox" />
                                            </label>
                                          )}
                                        </React.Fragment>
                                      ) : null}
                                    </th>
                                    <td>
                                      {item.lastName}
                                      {item.lastName && item.firstName
                                        ? ", "
                                        : ""}
                                      {item.firstName}
                                    </td>
                                    <td>{item.companyName}</td>
                                    <td>
                                      <span className="d-none d-md-inline">
                                        {item.address.addressLine1}
                                        <br />
                                      </span>
                                      {item.address.city}{" "}
                                      {item.address.province}
                                      {item.address.country
                                        ? item.address.country.countryId ===
                                          "840"
                                          ? item.address.state &&
                                            item.address.state.stateCode
                                            ? item.address.state.stateCode
                                            : ""
                                          : item.address.country.countryName
                                        : ""}
                                      <span className="d-none d-md-inline">
                                        {" "}
                                        {item.address.country &&
                                        item.address.country.countryId === "840"
                                          ? item.address.zipCode
                                          : item.address.postalCode}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6 d-none d-md-inline-flex results-dropdown-container">
                          <strong className="results-label">
                            Results Per Page:
                          </strong>
                          <Dropdown
                            isOpen={resultsDropdownOpen}
                            toggle={() =>
                              setResultsDropdownOpen(!resultsDropdownOpen)
                            }
                            value={numPerPage}
                            className="dropdown-selection"
                          >
                            <DropdownToggle
                              id="results-amount-address-book"
                              className="btn btn-default dropdown dropdown-items-wrapper"
                            >
                              {numPerPage}
                            </DropdownToggle>
                            <DropdownMenu>
                              <li>
                                <DropdownItem
                                  onClick={(e) => changeNumPerPage(e)}
                                >
                                  5
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  onClick={(e) => changeNumPerPage(e)}
                                >
                                  10
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  onClick={(e) => changeNumPerPage(e)}
                                >
                                  15
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  onClick={(e) => changeNumPerPage(e)}
                                >
                                  20
                                </DropdownItem>
                              </li>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <PageNumbers
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          pageNumbersToDisplay={pageNumbersToDisplay}
                          numOfPages={numOfPages}
                        />
                      </div>
                      <div className="modal-buttons">
                        {selectedAddressBatch.length < 2 ? (
                          <div className="button-container">
                            <button
                              type="button"
                              className="btn-primary"
                              id="modal-confirm"
                              data-dismiss="modal"
                              tabIndex="0"
                              onClick={useGABAddress}
                            >
                              Use This Address
                            </button>
                          </div>
                        ) : null}
                        {addressFieldType === "GABBatch" ? (
                          <div className="button-container ab-add-to-batch-button">
                            <button
                              type="button"
                              className="btn-primary button--white"
                              data-dismiss="modal"
                              tabIndex="0"
                              onClick={useGABAddressBatch}
                            >
                              Add to Batch
                            </button>
                          </div>
                        ) : null}
                        <div className="button-container">
                          <button
                            type="button"
                            className="btn-primary button--white"
                            id="modal-cancel"
                            data-dismiss="modal"
                            tabIndex="0"
                            style={{ marginLeft: "28%" }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {toggleSpinner ? (
          <WhiteSpinner topLine="Gathering contacts..." />
        ) : (
          <React.Fragment />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default AddressBook;
