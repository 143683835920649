import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import caretRight from "../customs_forms/images/caret-right.svg";
import LoggedInNav from "./LoggedInNav";
import AesExemptionPopover from "./Popovers/AesExemptionPopover";
import ChooseAESExemptionPopover from "./Popovers/ChooseAESExemptionPopover";
import AesExemptionITNPopover from "./Popovers/AesExemptionITNPopover";
import * as analytics from "../Components/Analytics/Analytics.js";
var dataLayer = {
  event: "vpvCFO",
  element: "",
  selection: "",
  page: "cfo-step-8-export-information"
};
class AesExemptions extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.setPageErrors([]);
    this.props.toggleLoadingBar(false);
    this.flowType = this.previousState.flowType;
    this.cartInfo = this.previousState.cartInfo;
    this.packageInfo = this.previousState.packageInfo;
    this.nonDeliveryOption = this.previousState.nonDeliveryOption;
    this.packageContents = this.previousState.packageContents;
    this.commercialSender = this.previousState.commercialSender;
    this.itemEntry = this.previousState.itemEntry;
    this.officialBusiness = this.previousState.officialBusiness;
    const aesExemptions = this.previousState.aesExemptions;
    if (aesExemptions) {
      this.state = {
        ...aesExemptions
      };
    } else {
      let eelCode;
      if (this.flowType === "Canada") {
        eelCode = "NOEEI 30.36";
      } else if ((this.flowType === "military" || this.flowType === "militaryToUSA") && this.officialBusiness === "1") {
        eelCode = "NOEEI 30.39"; // value posts to "/saveAES"
      } else if (this.packageInfo.totalPackageValue <= 2500) {
        eelCode = "NOEEI 30.37(a)";
      }
      this.state = {
        eelCode: eelCode,
        aesManualEntry: "",
        aesItn: ""
      };
    }
  }

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.itemEntry
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there are no items
    window.location.replace("/index");
  };

  // componentDidMount() {
  //   axios
  //     .get(
  //       "/cfo/cfoRest/fetchCustomsInfo?cnsCartId=" + this.cartInfo.encodedCartId
  //     )
  //     .then((response) => {
  //       this.setState({
  //         hasLoaded: true
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  handleAesExemptions = (event) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    });
  };

  handleBackButton = () => {
    this.previousState.stepNumber = this.previousState.stepNumber - 1;
    this.props.stepNumberCallback(this.previousState.stepNumber);
    this.previousState.exemptionStatus = 0;
    this.props.history.push("/item-entry", {
      previousState: this.previousState
    });
    dataLayer.element = "Back";
    analytics.setDataLayerInfo(dataLayer);
  };

  displayContinueButton = () => {
    return (
      // User selected a NOEEI option from the dropdown
      (this.state.eelCode !== "unknown" &&
        this.state.eelCode !== "I have an export license") ||
      // User manually provided an AES exemption
      (this.state.eelCode === "unknown" && this.state.aesManualEntry) ||
      // User provided an export license
      (this.state.eelCode === "I have an export license" && this.state.aesItn)
    );
  };

  componentDidMount = () => {
    this.props.loginCheck();
    if (!this.previousState.exemptionStatus ||
      (this.previousState.exemptionStatus && this.previousState.exemptionStatus === 0)) {
      this.previousState.stepNumber = this.previousState.stepNumber + 1
      this.props.stepNumberCallback(this.previousState.stepNumber);
    }
    if ((this.flowType === "military" || this.flowType === "militaryToUSA") && this.officialBusiness === "1") {
      this.handleContinueButton();
    }
  };

  handleContinueButton = (event) => {
    if ((this.flowType !== "military" || this.flowType !== "militaryToUSA") && this.officialBusiness !== "1") {
      event.preventDefault();
    }
    this.previousState.exemptionStatus = 1;
    const dataToSend = this.getDataToSend();

    this.props.toggleLoadingBar(true);
    axios
      .post("/CFOApplication/saveAES", JSON.stringify(dataToSend), {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        this.previousState.aesExemptions = {
          eelCode: this.officialBusiness === "1" ? "NOEEI 30.39" : this.state.eelCode,
          aesManualEntry:
            this.state.eelCode === "unknown" ? this.state.aesManualEntry : "",
          aesItn:
            this.officialBusiness === "1" ? "NOEEI 30.39"
              : this.state.eelCode === "I have an export license"
                ? this.state.aesItn
                : ""
        };
        this.props.history.push("/summary", {
          previousState: this.previousState
        });
        dataLayer.element = "Continue";
        analytics.setDataLayerInfo(dataLayer);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  };

  getDataToSend = () => {
    return {
      aesItn: this.officialBusiness === "1" ? "NOEEI 30.39" : this.state.aesItn,
      aesManualEntry: this.state.aesManualEntry,
      eelCode: this.officialBusiness === "1" ? "NOEEI 30.39" : this.state.eelCode,
      strCnsCartId:
        this.props.location.state.previousState.cartInfo.encodedCartId,
      cnsCartId: 0
    };
  };

  render() {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                      <p className="note">* indicates a required field</p>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="row section-row">
                    <div className="col-12">
                      <h2>
                        Please confirm export information for your package.
                      </h2>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-12">
                      <p>
                        <strong className="strong">
                          An AES Exemption has been pre-selected for you based
                          on your package information.
                        </strong>{" "}
                        <br />
                        If you agree with the pre-selected option, please
                        continue. To change the selection, choose a different
                        option from the dropdown.
                      </p>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-8 col-xl-6">
                      <div className="form-group">
                        <div className="inline-label-wrapper">
                          <label className="is-required">
                            AES Export Options:
                          </label>{" "}
                          <div className="more-info">
                            <span
                              className="more-info-link modal-trigger"
                              data-toggle="modal"
                              data-target="#modal-aes"
                            >
                              <ChooseAESExemptionPopover targetName="returnChooseAESExemtionPopover" />
                              {/* <img
                                className="tooltip-icon"
                                src={info}
                                alt="More info"
                                title="NOEEI 30.37(a) Value of each class of goods is $2,500 or less, when an export license is not required. NOEEI 30.36 Shipments destined to Canada, when an export license is not required. NOEEI 30.37(h) Authorized shipments of gift parcels or humanitarian donations"
                                width="18"
                                height="18"
                              /> */}
                            </span>
                            <div
                              className="more-info-content modal fade"
                              id="modal-aes"
                            >
                              <div
                                className="modal-dialog modal-lg"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <p>
                                      An AES Exemption is a notation entered on
                                      the Customs Declaration (PS2976-A) that
                                      indicates the basis for not filing
                                      electronic export information. The most
                                      common AES exemptions are provided in the
                                      drop down list. Obtain an ITN or an AES
                                      Dropdown Citation at the following site:
                                      https://ace.cbp.dhs.gov. Any mail pieces
                                      containing any type of goods (per Schedule
                                      B Export Codes at
                                      http://www.census.gov/foreign-trade/schedules/b/
                                      valued at more that $2,500 or requiring an
                                      export license under U.S. law must have a
                                      Proof of Filling Citation (PFC) issued by
                                      the U.S. Census Bureau, subject to certain
                                      exceptions. Note: Customers who fail to
                                      electronically file their export
                                      information may be subject to civil and
                                      criminal penalties.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.officialBusiness === "1" ? (
                          // TODO: Hide this page from user
                          // Show official business aes exemption code
                          <select
                            id="eelCode"
                            className="form-control has-dependent"
                            name="eelCode"
                            value={this.state.eelCode}
                            onChange={this.handleAesExemptions}
                          >
                            <option
                              id="opt0"
                              rel=""
                              className="eelOption"
                              value="NOEEI 30.39"
                              data-dependent="0"
                            >
                              NOEEI 30.39: Exemption for Shipment to the US Armed Forces
                            </option>
                          </select>
                        ) :
                          // Otherwise, show all other aes exemption codes
                          <select
                            id="eelCode"
                            className="form-control has-dependent"
                            name="eelCode"
                            value={this.state.eelCode}
                            onChange={this.handleAesExemptions}
                          >
                            {this.flowType === "Canada" ? (
                              <option
                                id="opt0"
                                rel=""
                                className="eelOption"
                                value="NOEEI 30.36"
                                data-dependent="0"
                              >
                                NOEEI 30.36: Package ultimate destination is
                                Canada
                              </option>
                            ) : null}
                            {this.flowType === "military" ? (
                              <option
                                id="opt1"
                                rel=""
                                className="eelOption"
                                value="NOEEI 30.37(w)"
                                data-dependent="0"
                              >
                                NOEEI 30.37(w): Package ultimate destination is
                                APO, FPO, or DPO location
                              </option>
                            ) : null}
                            {this.flowType !== "Canada" ? (
                              <option
                                id="opt2"
                                rel=""
                                className="eelOption"
                                value="NOEEI 30.37(a)"
                                data-dependent="0"
                              >
                                NOEEI 30.37(a): Each type of goods in the shipment
                                is less than $2500
                              </option>
                            ) : null}
                            {this.flowType !== "Canada" ? (
                              <option
                                id="opt3"
                                rel=""
                                className="eelOption"
                                value="NOEEI 30.37(h)"
                                data-dependent="0"
                              >
                                NOEEI 30.37(h): Authorized gift or humanitarian
                                donation
                              </option>
                            ) : null}
                            <option
                              id="aesUnk"
                              className="eelOption"
                              value="unknown"
                              data-dependent="3"
                            >
                              I don't see my AES Exemption
                            </option>
                            <option
                              id="aesItnId"
                              className="eelOption"
                              value="I have an export license"
                              data-dependent="4"
                            >
                              My shipment requires an ITN
                            </option>
                          </select>}
                      </div>
                    </div>
                    {this.state.eelCode === "I have an export license" ? (
                      <div
                        className="col-lg-4 col-xl-6"
                        id="generate-aes-itn-link"
                      >
                        <p>
                          <a
                            href="https://ace.cbp.dhs.gov/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Generate an AES/ITN
                          </a>
                        </p>
                      </div>
                    ) : null}
                  </div>
                  {this.state.eelCode === "unknown" ? (
                    <div className="row section-row" id="row-dependent-3">
                      <div className="col-12">
                        <div className="row section-row">
                          <div className="col-lg-8 col-xl-6">
                            <div className="form-group">
                              <div className="inline-label-wrapper">
                                <label
                                  className="is-required"
                                  htmlFor="aesManualEntry"
                                >
                                  AES exemption{" "}
                                  <span
                                    className="more-info-link modal-trigger"
                                    data-toggle="modal"
                                    data-target="#modal-option3"
                                  >
                                    <AesExemptionPopover targetName="returnAesExemptionPopover" />
                                  </span>
                                </label>
                              </div>
                              <input
                                name="aesManualEntry"
                                id="AESManualEntry"
                                className="form-control"
                                type="text"
                                placeholder="Type your AES Exemption"
                                value={this.state.aesManualEntry}
                                onChange={this.handleAesExemptions}
                              />
                            </div>
                            <div
                              className="more-info-content modal fade"
                              id="modal-option3"
                            >
                              <div
                                className="modal-dialog modal-lg"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <p>
                                      NOEEI 30.37(a)
                                      <br />
                                      Value of each class of goods is $2,500 or
                                      less, when an export license is not
                                      required.
                                    </p>
                                    <p>
                                      NOEEI 30.36
                                      <br />
                                      Shipments destined to Canada, when an
                                      export license is not required.
                                    </p>
                                    <p>
                                      NOEEI 30.37(h)
                                      <br />
                                      Authorized shipments of gift parcels or
                                      humanitarian donations
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row section-row">
                          <div className="col-12">
                            <p>
                              <strong>Need additional exemptions?</strong>
                              <br />
                              Call the U.S. Census Bureau at:{" "}
                              <a
                                href="tel:1-800-549-0595"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <strong>1-800-549-0595</strong>
                              </a>
                              .
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.eelCode === "I have an export license" ? (
                    <div className="row section-row" id="row-dependent-4">
                      <div className="col-12">
                        <div className="row section-row">
                          <div className="col-lg-8 col-xl-6">
                            <p>
                              If you already have an ITN#, you may go ahead and
                              enter it below:
                            </p>
                          </div>
                        </div>
                        <div className="row section-row">
                          <div className="col-lg-8 col-xl-6">
                            <div className="form-group">
                              <div className="inline-label-wrapper">
                                <label className="is-required" htmlFor="aesItn">
                                  AES / ITN#{" "}
                                  <span
                                    className="more-info-link modal-trigger"
                                    data-toggle="modal"
                                    data-target="#modal-option4"
                                  >
                                    <span>
                                      <AesExemptionITNPopover targetName="returnAesExemptionITNPopover" />
                                    </span>
                                  </span>
                                </label>
                              </div>
                              <input
                                name="aesItn"
                                id="AES_ITN"
                                className="form-control"
                                type="text"
                                placeholder="Enter your ITN#"
                                value={this.state.aesItn}
                                onChange={this.handleAesExemptions}
                              />
                            </div>
                            <div
                              className="more-info-content modal fade"
                              id="modal-option4"
                            >
                              <div
                                className="modal-dialog modal-lg"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <p>
                                      An Internal Transaction Number (ITN) or
                                      AES Downtime Citation is a number provided
                                      by the US Census Bureau upon successfully
                                      filing electronic export information.
                                      Electronic filing of export information is
                                      required when either of the following
                                      applies:
                                    </p>
                                    <p>
                                      <strong>A.</strong> A type of goods (per
                                      schedule B Export Codes at
                                      www.census.gov/foreign-trade/schedules/b/)
                                      is valued at more than $2500
                                    </p>
                                    <p>
                                      <strong>B.</strong> An item requires an
                                      export license under U.S. Law to determine
                                      if an export license is required, go to:
                                      www.export.gov/regulation/exp_reg_licenses.asp
                                      or call: 1-800-USA-TRADE
                                    </p>
                                    <p>
                                      <strong>Note:</strong> Customers who fail
                                      to electronically file their export
                                      information may be subject to civil and
                                      criminal penalties.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="row section-row">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                              <div className="form-button-container">
                                <button
                                  className="previous btn btn-outline-primary"
                                  type="button"
                                  onClick={this.handleBackButton}
                                >
                                  Back
                                </button>
                              </div>
                              {this.displayContinueButton() ? (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary force-show"
                                    id="submit"
                                    onClick={this.handleContinueButton}
                                  >
                                    Continue
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.eelCode === "I have an export license" ? (
                    <div
                      className="row section-row"
                      id="row-dependent-need-help"
                    >
                      <div className="col-12">
                        <p>
                          <strong>Need Help?</strong>
                          <br />
                          Call{" "}
                          <a
                            href="tel:1-800-923-8282"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>1-800-923-8282</strong>
                          </a>
                          .
                          <br />
                          For more information about an export license, you may
                          also refer to the{" "}
                          <a
                            href="https://pe.usps.com/cpim/ftp/manuals/imm/full/imm.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <strong>USPS International Mail Manual</strong>
                          </a>
                        </p>
                      </div>
                    </div>
                  ) : null}
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(AesExemptions);
