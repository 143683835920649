import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const PackageWeightPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Please keep in mind that if the package weighs more than the weight you
        entered, it will require additional postage when it is mailed or
        delivered.
      </p>
      <p>
        A convenient alternative to a scale is our Priority Mail Express
        <sup>&reg;</sup> Flat Rate Envelopes and Priority Mail Flat Rate
        <sup>&reg;</sup> Envelopes and Boxes. For one low price, you can mail
        any amount of material to a U.S. destination, of any weight up to 70 lbs
        that will easily fit in the flat rate envelope or box.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Package Weight Information Icon"
      header="Package Weight Information"
      content={content}
    />
  );
};

export default PackageWeightPopover;
