import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import caretRight from "../customs_forms/images/caret-right.svg";
import LoggedInNav from "./LoggedInNav";
import base64toPDF from "../utils/base64toPDF";

class ThankYou extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.stepNumberCallback(this.previousState.stepNumber + 1);
    this.props.setPageErrors([]);
    this.flowType = this.previousState.flowType;
    this.cartInfo = this.previousState.cartInfo;
    this.printOption = this.previousState.printOption;

    this.state = {
      hasLoaded: false,
      email: ""
    };
    // this.previousState.stepNumber = this.previousState.stepNumber + 1;
  }

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.printOption
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there is no print option
    window.location.replace("/index");
  };

  componentDidMount() {
    this.props.loginCheck();
    this.props.toggleLoadingBar(true);
    this.props.toggleLoadingBar(false);
    this.setState({
      hasLoaded: true
    });
  }

  getStartingFlowTypeOrReceipt = () => {
    return this.previousState.receiptCheck2
      ? this.previousState.receiptCheck2
      : this.flowType;
  };

  isMilitaryReceipt = () => {
    return (
      this.previousState.flowType === "militaryToUSA" &&
      this.previousState.receiptCheck2 === "domesticReceipt"
    );
  };

  printCustomsForm = () => {
    if (this.is_iOS12()) {
      // Open the PDF in a new tab
      window.open(this.iframeUrl, "_blank");
    } else {
      // Open the PDF in an iframe
      this.setState((state) => {
        return {
          hasLoaded: true,
          iframeSrc: !state.iframeSrc ? this.iframeUrl : ""
        };
      });
    }
  };

  // Test if user is running iOS 12.
  is_iOS12 = () => {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var version12 = !!navigator.userAgent.match(/OS\s*12_\d/);
    return iOS && version12;
  };

  getIframeTitle = () => {
    return !this.previousState.receiptCheck2 ? "Label" : "Receipt";
  };

  handleCreateNewButton = (event) => {
    event.preventDefault();
    window.location.assign("/index");
  };

  handleReprintButton = () => {
    this.printCustomsForm();
  };

  reprintCustomsForm = (e) => {
    e.preventDefault();
    if (
      this.previousState.receiptCheck2 === "domesticReceipt" ||
      this.previousState.receiptCheck2 === "internationalReceipt"
    ) {
      axios
        .get(
          "/CFOApplication/thankYouReceipt?cnsCartId=" +
            encodeURIComponent(this.cartInfo.encodedCartId) +
            "&&flowType=" +
            this.getStartingFlowTypeOrReceipt() +
            "&&startingFlowType=" +
            this.flowType + 
            "&&officialBusiness=" + (this.previousState.officialBusiness === "1" ? "true" : "false")
        )
        .then((response) => {
          base64toPDF(response.data.labelImage, response.data.barcodeNumber);
          this.setState({
            hasLoaded: true,
            barcodeNumber: response.data.barcodeNumber
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let isThermal = this.previousState.printOption === "N" ? false : true;
      axios
        .get(
          "/CFOApplication/thankYou?cnsCartId=" +
            encodeURIComponent(this.cartInfo.encodedCartId) +
            "&isThermal=" +
            isThermal + 
            "&&officialBusiness=" + (this.previousState.officialBusiness === "1" ? "true" : "false")
        )
        .then((response) => {
          base64toPDF(response.data.labelImage, response.data.barcodeNumber);
          this.setState({
            hasLoaded: true,
            barcodeNumber: response.data.barcodeNumber
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  changeEmail = (e) => {
    this.setState({
      email: e.target.value
    });
  };

  sendNewEmail = (e) => {
    e.preventDefault();
    this.props.toggleLoadingBar(true);
    axios
      .get(
        "/CFOApplication/sendReceiptEmail?cnsCartId=" +
          encodeURIComponent(this.cartInfo.encodedCartId) +
          "&&flowType=" +
          this.flowType +
          "&&newEmail=" +
          encodeURIComponent(this.state.email)
      )
      .then((response) => {
        base64toPDF(response.data.labelImage, response.data.barcodeNumber);
        this.setState({
          hasLoaded: true,
          barcodeNumber: response.data.barcodeNumber
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.props.toggleLoadingBar(false);
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.hasLoaded ? (
          <div id="cfo-wrapper">
            <div id="inner">
              <main className="section" id="customs-form-2019-main">
                <section className="section" id="app-form">
                  <div className="container">
                    <form
                      className="customs-form"
                      id="customs-form"
                      action="#"
                      method="post"
                    >
                      <div className="row section-row align-items-center">
                        <div className="col-lg-4">
                          <h1>Customs Form</h1>
                        </div>
                        <div className="col-lg-8">
                          {this.props.loggedIn ? (
                            <LoggedInNav />
                          ) : (
                            <ul className="title-links">
                              <li>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                                >
                                  FAQs{" "}
                                  <span>
                                    <img src={caretRight} alt="open link" />
                                  </span>
                                </a>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="row section-row">
                        <div
                          className={
                            this.props.loggedIn ? "col-lg-12" : "col-lg-7"
                          }
                        >
                          <div className="row section-row">
                            <div className="col-12">
                              {this.previousState.receiptCheck2 ? (
                                <h2>
                                  Your{" "}
                                  {this.isMilitaryReceipt() ? "military " : ""}
                                  receipt has been created!
                                </h2>
                              ) : (
                                <h2>Your customs form has been created!</h2>
                              )}
                            </div>
                          </div>

                          <div className="row section-row">
                            <div className="col-12">
                              <p>
                                <strong>
                                  Thank you for choosing the United States
                                  Postal Service<sup>&reg;</sup>.
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div className="row section-row">
                            <div className="col-12">
                              {this.previousState.receiptCheck2 ? (
                                <p>
                                  Please take the package and receipt to the{" "}
                                  {this.isMilitaryReceipt() ? "Military " : ""}
                                  Post Office to weigh the package, choose a
                                  shipping service, pay for postage, and print
                                  the customs form.
                                </p>
                              ) : (
                                <p>
                                  To ship this package and pay postage, please
                                  take the package and printed customs form to a{" "}
                                  <a
                                    href="https://tools.usps.com/find-location.htm"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Post Office
                                  </a>
                                  <sup>&trade;</sup> Retail Associate.
                                </p>
                              )}
                            </div>
                          </div>
                          <br />
                          {this.previousState.receiptCheck2 ? (
                            <div>
                              <div className="row section-row">
                                <div className="col-12">
                                  <p>
                                    <strong>
                                      Receive Your Customs Form Receipt via
                                      Email.
                                    </strong>
                                  </p>
                                </div>
                              </div>
                              <div className="row section-row">
                                <div className="col-12">
                                  <p>
                                    If you would like to have your customs form{" "}
                                    {this.isMilitaryReceipt()
                                      ? "military "
                                      : ""}
                                    receipt emailed to you so it is accessible
                                    via your mobile device and can be scanned at
                                    the{" "}
                                    {this.isMilitaryReceipt()
                                      ? "Military "
                                      : ""}
                                    Post Office, please enter your email address
                                    below:
                                  </p>
                                </div>
                              </div>
                              <div className="row section-row">
                                <div
                                  className={
                                    this.props.loggedIn
                                      ? "col-8 col-md-6 col-lg-4"
                                      : "col-8"
                                  }
                                >
                                  <input
                                    name="email"
                                    id="email"
                                    className="form-control"
                                    type="email"
                                    placeholder="Enter your email address"
                                    value={this.state.email}
                                    onChange={(e) => this.changeEmail(e)}
                                  />
                                </div>
                              </div>
                              {this.state.email.length > 0 ? (
                                <div className="row section-row">
                                  <div className="col-8">
                                    <button
                                      type="submit"
                                      className="btn btn-primary force-show"
                                      id="submit"
                                      onClick={(e) => this.sendNewEmail(e)}
                                    >
                                      Send
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <React.Fragment />
                              )}
                            </div>
                          ) : (
                            <React.Fragment />
                          )}

                          <div className="row section-row">
                            <div className="col-12">
                              <p>
                                In the future, consider using{" "}
                                <a
                                  href="https://cns.usps.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Click-N-Ship
                                </a>
                                <sup>&reg;</sup> to create customs forms and
                                purchase postage online for international
                                destinations, APO / FPO / DPO destinations, and
                                U.S. possessions, territories and Freely
                                Associated States.
                              </p>
                            </div>
                          </div>
                          {this.previousState.receiptCheck2 ? (
                            <div className="row section-row">
                              <div className="col-12">
                                <div className="subsection button-section">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="customs-form-buttons">
                                        <div className="form-button-container">
                                          <button
                                            type="submit"
                                            className="btn btn-primary force-show"
                                            id="submit"
                                            onClick={this.handleCreateNewButton}
                                          >
                                            Create a New{" "}
                                            {this.isMilitaryReceipt()
                                              ? "Military "
                                              : ""}
                                            Receipt
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row section-row">
                              <div className="col-12">
                                <div className="subsection button-section">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="customs-form-buttons">
                                        <div className="form-button-container">
                                          <button
                                            type="submit"
                                            className="btn btn-primary force-show"
                                            id="submit"
                                            onClick={this.handleCreateNewButton}
                                          >
                                            Create a New Customs Form
                                          </button>
                                        </div>
                                        <div className="form-button-container">
                                          <button
                                            className="previous btn btn-outline-primary"
                                            type="button"
                                            onClick={(e) =>
                                              this.reprintCustomsForm(e)
                                            }
                                          >
                                            Reprint Customs Form
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="row section-row">
                            <div className="col-12">
                              <p>
                                Note: You will receive a new label number if you
                                edit your customs form.
                                <br />
                                Please be sure to use the new label number when
                                tracking your package.
                              </p>
                            </div>
                          </div>
                        </div>
                        {!this.props.loggedIn && (
                          <div className="col-lg-5 mt-lg-0 mt-3">
                            <div className="sidebar-box">
                              <h3>
                                Need to save the customs form just created?
                              </h3>
                              <p className="note">
                                <strong>
                                  Register for a USPS.com Account today to save
                                  this customs form as well as gain access to
                                  other benefits such as:
                                </strong>
                              </p>
                              <ul className="list-group bullet-list">
                                <li>Set Preferences</li>
                                <li>Save Addresses</li>
                                <li>Reprint Created Customs Forms</li>
                              </ul>
                              <p>
                                <a
                                  href="https://reg.usps.com/entreg/LoginAction_input?app=CNS&appURL=https://cfo.usps.com/cfo-web/labelInformation.html"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn-outline-primary"
                                >
                                  Sign Up Now
                                </a>
                              </p>
                              <p>
                                Already have an account?{" "}
                                <a
                                  href="https://reg.usps.com/entreg/LoginAction_input?app=CNS&appURL=https://cfo.usps.com/cfo-web/labelInformation.html"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Sign In
                                </a>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </section>
              </main>
            </div>
            <iframe
              className="iframePdf"
              title={this.getIframeTitle()}
              src={this.state.iframeSrc}
            ></iframe>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(ThankYou);
