import React from "react";
import { withRouter } from "react-router-dom";
import caretRight from "../customs_forms/images/caret-right.svg";

class LoggedInNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname
    };
  }

  isOnCreateCustomsFormPage = () => {
    return (
      this.state.pathname !== "/preferences" &&
      this.state.pathname !== "/customs-history" &&
      this.state.pathname !== "/customs-details"
    );
  };

  goToCreateCustomsForm = (e) => {
    e.preventDefault();
    if (!this.isOnCreateCustomsFormPage()) {
      this.props.history.push("/index");
    } else {
      return false;
    }
  };

  goToHistory = (e) => {
    e.preventDefault();
    this.props.history.push("/customs-history");
  };

  goToPreferences = (e) => {
    e.preventDefault();
    this.props.history.push("/preferences");
  };

  render() {
    return (
      <div className="col-lg-12">
        <ul className="title-links">
          <li className={this.isOnCreateCustomsFormPage() ? "current" : ""}>
            <a href="/index" onClick={(e) => this.goToCreateCustomsForm(e)}>
              Create Customs Form
            </a>
          </li>
          <li
            className={this.state.pathname === "/preferences" ? "current" : ""}
          >
            <a href="/preferences" onClick={(e) => this.goToPreferences(e)}>
              Preferences
            </a>
          </li>
          <li
            className={
              this.state.pathname === "/customs-details" ||
              this.state.pathname === "/customs-history"
                ? "current"
                : ""
            }
          >
            <a href="/customs-history" onClick={(e) => this.goToHistory(e)}>
              Customs Form History
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="/addressBook">
              Address Book
            </a>
          </li>

          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
            >
              FAQs{" "}
              <span>
                <img src={caretRight} alt="open link" />
              </span>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(LoggedInNav);
