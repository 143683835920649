import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import axios from "axios";

import InputFields from "../Subcomponents/InputFields";
import hsTariffCodeUtils from "../../utils/hsTariffCodeUtils";

const LandedCostEstimate = (props) => {
  const {
    // Modal controls
    isOpen,
    toggle,
    // Props for making the Landed Cost API call
    callLandedCostApi,
    toggleCallToLandedCostApi,
    // Props relating to inputs/outputs of the Landed Cost API call
    items,
    isNewUneditedRow,
    returnAddressCountryCode,
    deliveryAddress,
    serviceInfo,
    landedCostInputs,
    setLandedCostInputs,
    tlcAmounts,
    setTlcAmounts,
    // Props for handling the email field and endpoint
    cartId,
    tlcEmail,
    handleTlcEmail,
    // Other required props
    toggleLoadingBar,
    get2LetterCountryCode
  } = props;

  // Flag that indicates if a landed cost lookup failed
  const [landedCostError, setLandedCostError] = useState(false);

  // Error message to display if the user does not enter an email address
  const [emailValidationError, setEmailValidationError] = useState("");

  // State that determines if an error message should be focused via the
  // keyboard
  const [focusOnError, setFocusOnError] = useState(false);

  // Flag that indicates if saving the entered email address was successful
  // - true = show success message
  // - false = show error message
  // - null = show no message
  const [emailSuccessful, setEmailSuccessful] = useState(null);

  // The number of items with all required fields entered and an HS Tariff Code
  const [numberOfItems, setNumberOfItems] = useState(0);

  // Update the landed cost inputs when appropriate
  useEffect(() => {
    // Mapping of our own product classes to the service levels obtained from Zonos
    const serviceLevelMapping = {
      AMB: "Airmail M-bags",
      EXM: "usps_priority_express",
      FCPSR: "usps_first_class",
      IEM: "Priority Mail Express International (PMEI)",
      IFC: "First-Class Package International Service (FCPIS)",
      IPM: "Priority Mail International (PMI)",
      PRI: "usps_priority"
    };

    // Format the items array of objects for the request JSON
    const getFormattedItems = () => {
      const itemsFormatted = [];
      for (let index in items) {
        const item = items[index];
        // Only add items with the appropriate fields filled out
        // as well as an HS Tariff Code
        if (
          (isNewUneditedRow === undefined || !isNewUneditedRow(item)) &&
          hsTariffCodeUtils.itemHasHsTariffCode(item)
        ) {
          itemsFormatted.push({
            id: index,
            amount: item.unitValue,
            brand: item.tlcBrand || null,
            category: item.tlcCategory,
            color: item.tlcItemColor || null,
            country_of_origin: get2LetterCountryCode(item.countryOfOrigin),
            description_customs: item.itemDesc,
            detail: item.tlcItemDetail || null,
            dimensions: {
              height: item.tlcProductHeight,
              length: item.tlcProductLength,
              unit: "inch",
              width: item.tlcProductWidth
            },
            hs_code: item.hsTariffNbr,
            quantity: item.itemQty,
            upc_code: item.tlcUpcCode || null,
            weight: Number(item.weightPounds) * 16 + Number(item.weightOunces),
            weight_unit: "ounce"
          });
        }
      }
      return itemsFormatted;
    };

    // Update the landed cost inputs
    setLandedCostInputs({
      currency: "USD",
      items: getFormattedItems(),
      ship_from_country: returnAddressCountryCode,
      ship_to: {
        city: deliveryAddress.city,
        country: deliveryAddress.countryCode,
        postal_code: deliveryAddress.zipCode,
        state: deliveryAddress.province
      },
      shipping: {
        amount: serviceInfo.postOfficePriceDisplay || 0,
        service_level: serviceLevelMapping[serviceInfo.productClass]
      }
    });
  }, [
    items,
    isNewUneditedRow,
    get2LetterCountryCode,
    returnAddressCountryCode,
    deliveryAddress.city,
    deliveryAddress.countryCode,
    deliveryAddress.zipCode,
    deliveryAddress.province,
    serviceInfo.postOfficePriceDisplay,
    serviceInfo.productClass,
    setLandedCostInputs
  ]);

  // Call the Landed Cost API
  useEffect(() => {
    if (callLandedCostApi) {
      toggleLoadingBar(true);
      toggleCallToLandedCostApi(false);
      axios
        .post("/CFOApplication/tlc/landed-cost", landedCostInputs)
        .then((response) => {
          const amountSubtotal = response?.data?.amount_subtotal;
          if (amountSubtotal) {
            setLandedCostError(false);
            setTlcAmounts({
              duties: amountSubtotal.duties,
              fees: amountSubtotal.fees,
              taxes: amountSubtotal.taxes
            });
          } else {
            setLandedCostError(true);
            setTlcAmounts(null);
          }
        })
        .catch((error) => {
          setLandedCostError(true);
          setTlcAmounts(null);
          console.log(error);
        })
        .finally(() => {
          toggleLoadingBar(false);
        });
    }
  }, [
    callLandedCostApi,
    toggleLoadingBar,
    toggleCallToLandedCostApi,
    landedCostInputs,
    setTlcAmounts
  ]);

  // Focus on an error, if present
  useEffect(() => {
    if (focusOnError) {
      if (emailValidationError) {
        document.getElementById("tlcEmail-a11y").focus();
      }
      setFocusOnError(false);
    }
  }, [focusOnError, emailValidationError]);

  // Update the number of items with all required fields and an HS Tariff Code
  // provided
  useEffect(() => {
    let numberOfItems = 0;
    if (items && items.length > 0) {
      numberOfItems = hsTariffCodeUtils.checkHsTariffCodeCount(
        items,
        isNewUneditedRow
      );
    }
    setNumberOfItems(numberOfItems);
  }, [items, isNewUneditedRow]);

  const handleTlcEmailSubmit = () => {
    setEmailSuccessful(null);
    if (tlcEmail) {
      setEmailValidationError("");
      toggleLoadingBar(true);
      axios
        .post("/CFOApplication/tlc/email", {
          tlcEmail: tlcEmail,
          encryptedCartId: cartId
        })
        .then((response) => {
          const result = response?.data?.result;
          if (result === "success") {
            setEmailSuccessful(true);
          } else {
            setEmailSuccessful(false);
          }
        })
        .catch((error) => {
          setEmailSuccessful(false);
          console.log(error);
        })
        .finally(() => {
          toggleLoadingBar(false);
        });
    } else {
      setEmailValidationError("Please enter an email address.");
      setFocusOnError(true);
    }
  };

  const closeModal = (e) => {
    setLandedCostError(false);
    setEmailSuccessful(null);
    toggle(e, false);
  };

  return (
    <Modal id="modal-tlc-estimate" isOpen={isOpen}>
      <div className="modal-content modal-container">
        <div className="modal-header">
          <button
            type="button"
            className="close button-link"
            id="close"
            data-dismiss="modal"
            tabIndex="0"
            onClick={(e) => closeModal(e)}
          >
            <span className="visuallyhidden">Close Modal</span>
          </button>
          <h3 className="modal-title">Landed Cost Estimate</h3>
        </div>
        <div className="modal-body">
          <div className="body-content">
            {!landedCostError ? (
              <React.Fragment>
                <p>
                  Landed cost includes the estimated calculation of duties,
                  taxes and other fees that are imposed for shipments crossing
                  international borders by the importing country's government.
                </p>
                <p>
                  These costs may require payment by the recipient upon arrival
                  based on the {numberOfItems} item
                  {numberOfItems === 1 ? "" : "s"} with an HS Tariff Code.
                </p>
                <div id="tlc-table" className="row">
                  <div className="col-12 col-sm-9">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="tlc-table-column">Duties</td>
                          {tlcAmounts?.duties && (
                            <td className="tlc-table-column tlc-table-column-cost">
                              ${tlcAmounts.duties}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td className="tlc-table-column">Fees</td>
                          {tlcAmounts?.fees && (
                            <td className="tlc-table-column tlc-table-column-cost">
                              ${tlcAmounts.fees}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td className="tlc-table-column">Taxes</td>
                          {tlcAmounts?.taxes && (
                            <td className="tlc-table-column tlc-table-column-cost">
                              ${tlcAmounts.taxes}
                            </td>
                          )}
                        </tr>
                        <tr id="tlc-summary-row">
                          <td
                            id="tlc-summary-text"
                            className="tlc-table-column"
                          >
                            <h4>Total Landed Cost</h4>
                          </td>
                          <td className="tlc-table-column tlc-table-column-cost">
                            {tlcAmounts?.sum && <h4>${tlcAmounts.sum}</h4>}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h4 id="tlc-email-header">
                  Email a copy of the Landed Cost Estimate
                </h4>
                <div className="row">
                  <InputFields
                    outerDivClassList="col-12 col-sm-8"
                    errorMessage={emailValidationError}
                    includeAssistiveError={true}
                    inputId="tlcEmail"
                    inputClassList="form-control"
                    inputType="text"
                    labelText="Enter Email"
                    inputValue={tlcEmail}
                    inputOnChange={(e) => {
                      setEmailSuccessful(null);
                      setEmailValidationError("");
                      handleTlcEmail(e);
                    }}
                  />
                  <div className="col-12 col-sm-4 submit-tlc-email-wrapper">
                    <button
                      className="previous btn btn-outline-primary"
                      id="submit-tlc-email-button"
                      type="submit"
                      onClick={() => handleTlcEmailSubmit()}
                    >
                      Send Estimate
                    </button>
                  </div>
                </div>
                {emailSuccessful ? (
                  <p id="tlc-email-confirmation-text">
                    Success: Email with the Landed Cost summary will be sent
                    after your Customs Form is complete.
                  </p>
                ) : emailSuccessful === false ? (
                  <p className="pre-form-error">
                    There was an error saving the email address.
                  </p>
                ) : (
                  <React.Fragment />
                )}
              </React.Fragment>
            ) : (
              <p className="pre-form-error">
                Error retrieving Total Landed Cost estimate.
              </p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LandedCostEstimate;
