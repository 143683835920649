import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Autocomplete from "./Subcomponents/Autocomplete";
import * as analytics from "../Components/Analytics/Analytics.js";
// Images
import caretRight from "../customs_forms/images/caret-right.svg";
import alert from "../customs_forms/images/alert.svg";
import LoggedInNav from "./LoggedInNav";
import InputFields from "./Subcomponents/InputFields";
var dataLayer = {
  event: "application",
  element: "",
  selection: "",
  page: "cfo-step-1a-select-destination-international"
};

class OfficialBusiness extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.setPageErrors([]);
    this.props.toggleLoadingBar(false);
    this.state = {
      flowType: this.previousState.flowType,
      officialBusiness: this.previousState.officialBusiness || -1
    };
  }

  handleOfficialBusinessAction = (event) => {
    const value = event.currentTarget.value;
    this.setState({
      officialBusiness: value
    });
  };

  componentDidMount = () => {
    this.props.loginCheck();
    if(!this.previousState.officialBusiness || 
      (this.previousState.officialBusiness && this.previousState.officialBusiness === -1)){
      this.previousState.stepNumber = this.previousState.stepNumber + 1
      this.props.stepNumberCallback(this.previousState.stepNumber);
    }
  };

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.flowType
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there is no flow type
    window.location.replace("/index");
  };


  handleBackButton = () => {
    this.previousState.officialBusiness = this.previousState.officialBusiness -1
    dataLayer.element = "Back";
    analytics.setDestinationPricePackageDataLayerInfo(dataLayer);
    this.props.history.push("/flow-type", {
      previousState: {
        ...this.previousState,
        flowType:
          this.previousState.flowType === "militaryToUSA"
            ? "usa"
            : this.previousState.flowType
      }
    });
  };

  handleContinueButton = (e) => {
    e.preventDefault();
    dataLayer.element = "Continue";
    analytics.setDestinationPricePackageDataLayerInfo(dataLayer);
    this.previousState.officialBusiness = this.state.officialBusiness
    this.props.history.push("/flow-type-inputs", {
      previousState: this.previousState
    });
  };

  render() {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                      <p className="note">* indicates a required field</p>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-12">
                      <h2>
                        Are you shipping Official Business?
                      </h2>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-8 col-xl-7">
                      <p>
                        *Official Business should only be selected if you are sending Official Government mail from one Government Agency to another, 
                        or between a Government Agency and an individual.This is not to be used for personal mailings.
                      </p>
                      <div className="radio-button">
                        <label>
                          <input
                            type="radio"
                            name="lightDocumentsOnly"
                            className="has-dependent"
                            data-dependent="1"
                            value="1"
                            checked={this.state.officialBusiness ==="1"}
                            onChange={this.handleOfficialBusinessAction}
                          />
                          <span> Yes</span>
                        </label>
                      </div>
                      <div className="radio-button">
                        <label>
                          <input
                            required="required"
                            type="radio"
                            name="lightDocumentsOnly"
                            className="has-dependent"
                            data-dependent="0"
                            value="0"
                            checked={this.state.officialBusiness ==="0"}
                            onChange={this.handleOfficialBusinessAction}
                          />
                          <span> No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row section-row" id="row-dependent-0">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                               
                                <div className="form-button-container">
                                  <button
                                    className="previous btn btn-outline-primary"
                                    type="button"
                                    onClick={this.handleBackButton}
                                  >
                                    Back
                                  </button>
                                </div>
                                {this.state.officialBusiness > -1 ? (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="submit"
                                    onClick={this.handleContinueButton}
                                  >
                                    Continue
                                  </button>
                                </div>
                                ):null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
   
  }
}

export default withRouter(OfficialBusiness);
