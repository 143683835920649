import React from "react";
import axios from "axios";

import { withRouter } from "react-router-dom";
import caretRight from "../customs_forms/images/caret-right.svg";
import LoggedInNav from "./LoggedInNav";
import * as analytics from "../Components/Analytics/Analytics.js";
var dataLayer = {
  event: "application",
  element: "",
  selection: "",
  page: "'cfo-step-1b-prohibited-items-restrictions-international"
};
class Prohibitions extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.setPageErrors([]);
    this.flowType = this.previousState.flowType;
    this.state = {
      hideContent: false,
      displayAccordion: "accordion-content-one",
      prohibitionsShow: "",
      countryName: "",
      restrictionShow: "",
      showExpandAll: false,
      showCigars: false,
      showCrematedRemains: false,
      showFirearms: false,
      showGlues: false,
      showLithiumBatteries: false,
      showLiveAnimals: false,
      showMatches: false,
      showMedicalDevices: false,
      showMedicinesPrescription: false,
      showDrugs: false,
      showPaint: false,
      showPerishableItems: false,
      showAerosols: false,
      showTobacco: false,
      showDryIce: false,
      showFragileItems: false,
      showHandSanitizer: false,
      showNailPolish: false,
      showPerfumes: false,
      showPoisons: false,
      showAlocholicBeverages: false
    };
  }
  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.flowTypeInputs
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there are no flow type inputs
    window.location.replace("/index");
  };

  handleBackButton = () => {
    this.previousState.stepNumber = this.previousState.stepNumber - 1;
    this.props.stepNumberCallback(this.previousState.stepNumber);
    this.previousState.prohibitionsStatus = 0;
    this.props.history.push("/flow-type-inputs", {
      previousState: this.previousState
    });
    dataLayer.element = "Back";
    analytics.setDataLayerInfo(dataLayer);
  };

  handleExpandAll = (e) => {
    e.preventDefault();
    if (this.state.showExpandAll) {
      this.setState({
        showExpandAll: false,
        showCigars: false,
        showCrematedRemains: false,
        showFirearms: false,
        showGlues: false,
        showLithiumBatteries: false,
        showLiveAnimals: false,
        showMatches: false,
        showMedicalDevices: false,
        showMedicinesPrescription: false,
        showDrugs: false,
        showPaint: false,
        showPerishableItems: false,
        showAerosols: false,
        showTobacco: false,
        showDryIce: false,
        showFragileItems: false,
        showHandSanitizer: false,
        showNailPolish: false,
        showPerfumes: false,
        showPoisons: false,
        showAlocholicBeverages: false
      });
    } else {
      this.setState({
        showExpandAll: true,
        showCigars: true,
        showCrematedRemains: true,
        showFirearms: true,
        showGlues: true,
        showLithiumBatteries: true,
        showLiveAnimals: true,
        showMatches: true,
        showMedicalDevices: true,
        showMedicinesPrescription: true,
        showDrugs: true,
        showPaint: true,
        showPrivacy: false,
        showPerishableItems: true,
        showAerosols: true,
        showTobacco: true,
        showDryIce: true,
        showFragileItems: true,
        showHandSanitizer: true,
        showNailPolish: true,
        showPerfumes: true,
        showPoisons: true,
        showAlocholicBeverages: true
      });
    }
  };

  handleCigars = (e) => {
    e.preventDefault();
    if (this.state.showCigars) {
      this.setState({ showCigars: false });
    } else {
      this.setState({ showCigars: true });
    }
  };

  handleCrematedRemains = (e) => {
    e.preventDefault();
    if (this.state.showCrematedRemains) {
      this.setState({ showCrematedRemains: false });
    } else {
      this.setState({ showCrematedRemains: true });
    }
  };

  handleFirearms = (e) => {
    e.preventDefault();
    if (this.state.showFirearms) {
      this.setState({ showFirearms: false });
    } else {
      this.setState({ showFirearms: true });
    }
  };

  handleGlues = (e) => {
    e.preventDefault();
    if (this.state.showGlues) {
      this.setState({ showGlues: false });
    } else {
      this.setState({ showGlues: true });
    }
  };
  handleLithiumBatteries = (e) => {
    e.preventDefault();
    if (this.state.showLithiumBatteries) {
      this.setState({ showLithiumBatteries: false });
    } else {
      this.setState({ showLithiumBatteries: true });
    }
  };

  handleLiveAnimals = (e) => {
    e.preventDefault();
    if (this.state.showLiveAnimals) {
      this.setState({ showLiveAnimals: false });
    } else {
      this.setState({ showLiveAnimals: true });
    }
  };
  handleMatches = (e) => {
    e.preventDefault();
    if (this.state.showMatches) {
      this.setState({ showMatches: false });
    } else {
      this.setState({ showMatches: true });
    }
  };

  handleMedicalDevices = (e) => {
    e.preventDefault();
    if (this.state.showMedicalDevices) {
      this.setState({ showMedicalDevices: false });
    } else {
      this.setState({ showMedicalDevices: true });
    }
  };
  handleMedicinesPrescription = (e) => {
    e.preventDefault();
    if (this.state.showMedicinesPrescription) {
      this.setState({ showMedicinesPrescription: false });
    } else {
      this.setState({ showMedicinesPrescription: true });
    }
  };

  handleDrugs = (e) => {
    e.preventDefault();
    if (this.state.showDrugs) {
      this.setState({ showDrugs: false });
    } else {
      this.setState({ showDrugs: true });
    }
  };
  handlePaint = (e) => {
    e.preventDefault();
    if (this.state.showPaint) {
      this.setState({ showPaint: false });
    } else {
      this.setState({ showPaint: true });
    }
  };

  handlePerishableItems = (e) => {
    e.preventDefault();
    if (this.state.showPerishableItems) {
      this.setState({ showPerishableItems: false });
    } else {
      this.setState({ showPerishableItems: true });
    }
  };

  handleAerosols = (e) => {
    e.preventDefault();
    if (this.state.showAerosols) {
      this.setState({ showAerosols: false });
    } else {
      this.setState({ showAerosols: true });
    }
  };

  handleAlcoholicBeverages = (e) => {
    e.preventDefault();
    if (this.state.showAlocholicBeverages) {
      this.setState({ showAlocholicBeverages: false });
    } else {
      this.setState({ showAlocholicBeverages: true });
    }
  };

  handleTobacco = (e) => {
    e.preventDefault();
    if (this.state.showTobacco) {
      this.setState({ showTobacco: false });
    } else {
      this.setState({ showTobacco: true });
    }
  };

  handleDryIce = (e) => {
    e.preventDefault();
    if (this.state.showDryIce) {
      this.setState({ showDryIce: false });
    } else {
      this.setState({ showDryIce: true });
    }
  };

  handleFragileItems = (e) => {
    e.preventDefault();
    if (this.state.showFragileItems) {
      this.setState({ showFragileItems: false });
    } else {
      this.setState({ showFragileItems: true });
    }
  };

  handleHandSanitizer = (e) => {
    e.preventDefault();
    if (this.state.showHandSanitizer) {
      this.setState({ showHandSanitizer: false });
    } else {
      this.setState({ showHandSanitizer: true });
    }
  };

  handleNailPolish = (e) => {
    e.preventDefault();
    if (this.state.showNailPolish) {
      this.setState({ showNailPolish: false });
    } else {
      this.setState({ showNailPolish: true });
    }
  };

  handlePerfumes = (e) => {
    e.preventDefault();
    if (this.state.showPerfumes) {
      this.setState({ showPerfumes: false });
    } else {
      this.setState({ showPerfumes: true });
    }
  };
  handlePoisons = (e) => {
    e.preventDefault();
    if (this.state.showPoisons) {
      this.setState({ showPoisons: false });
    } else {
      this.setState({ showPoisons: true });
    }
  };
  handleContinueButton = (e) => {
    e.preventDefault();
    analytics.prohibitions_InfoToDataLayer(this.previousState);
    this.previousState.prohibitionsStatus = 1;
    this.props.history.push("/package-info", {
      previousState: this.previousState
    });
    dataLayer.element = "Continue";
    analytics.setDataLayerInfo(dataLayer);
  };

  handlebackToItemsTable = () => {
    this.props.history.push("/item-entry", {
      previousState: this.previousState
    });
  };
  toggleOpen(accordionId) {
    this.setState({ displayAccordion: accordionId });
  }

  renderInternational = () => {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                    </div>

                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="row section-row">
                    <div className="col-12">
                      <h2>Internationally Prohibited Items and Restrictions</h2>
                    </div>
                  </div>

                  <div className="row section-row">
                    <div className="col-lg-12">
                      <div className="accordion">
                        <div className="accordion-items">
                          <div className="accordion-item">
                            <div className="row">
                              <div className="col-lg-4">
                                <div
                                  className="accordion-title"
                                  onClick={this.toggleOpen.bind(
                                    this,
                                    "accordion-content-one"
                                  )}
                                >
                                  <h2>Prohibitions: United States</h2>
                                  <p>
                                    These items may not be sent from the United
                                    States to any country.
                                  </p>
                                </div>
                                <div
                                  className="accordion-title"
                                  onClick={this.toggleOpen.bind(
                                    this,
                                    "accordion-content-two"
                                  )}
                                >
                                  <h2>
                                    Prohibitions: {this.state.countryName}
                                  </h2>
                                  <p>
                                    These items may not be shipped to{" "}
                                    {this.state.countryName}.
                                  </p>
                                </div>
                                <div
                                  className="accordion-title"
                                  onClick={this.toggleOpen.bind(
                                    this,
                                    "accordion-content-three"
                                  )}
                                >
                                  <h2>Restrictions: United States</h2>
                                  <p>
                                    These restrictions apply when shipping from
                                    the United States.
                                  </p>
                                </div>
                                <div
                                  className="accordion-title"
                                  onClick={this.toggleOpen.bind(
                                    this,
                                    "accordion-content-four"
                                  )}
                                >
                                  <h2>
                                    Restrictions: {this.state.countryName}
                                  </h2>
                                  <p>
                                    These restrictions apply when shipping items
                                    to {this.state.countryName}.
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-8">
                                <div
                                  className="accordion-content"
                                  style={{
                                    display:
                                      this.state.displayAccordion ===
                                      "accordion-content-one"
                                        ? "block"
                                        : "none"
                                  }}
                                  id="accordion-content-one"
                                >
                                  <div className="container-fluid">
                                    <div className="row">
                                      <div className="col-lg-14">
                                        <p>
                                          It's important that you avoid these
                                          items to ensure fast, safe and legal
                                          shipments and avoid delays in customs
                                          offices.
                                        </p>
                                        <ul className="list-group bullet-list">
                                          <li>Aerosols</li>
                                          <li>Air Bags</li>
                                          <li>Alcoholic Beverages</li>
                                          <li>Ammunition</li>
                                          <li>Cigarettes</li>
                                          <li>Dry Ice</li>
                                          <li>Explosives</li>
                                          <li>Fresh Fruits and Vegetables</li>
                                          <li>Gasoline</li>
                                          <li>
                                            Marijuana (medical or otherwise)
                                          </li>
                                          <li>Nail Polish</li>
                                          <li>Perfumes (containing alcohol)</li>
                                          <li>Poisons</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="accordion-content"
                                  style={{
                                    display:
                                      this.state.displayAccordion ===
                                      "accordion-content-two"
                                        ? "block"
                                        : "none"
                                  }}
                                  id="accordion-content-two"
                                >
                                  <div className="container-fluid">
                                    <div className="row">
                                      <div className="col-lg-14">
                                        {this.state.prohibitionsShow}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="accordion-content"
                                  style={{
                                    display:
                                      this.state.displayAccordion ===
                                      "accordion-content-three"
                                        ? "block"
                                        : "none"
                                  }}
                                  id="accordion-content-three"
                                >
                                  <div className="faq-section">
                                    <div className="row">
                                      <div className="col-md-12 col-sm-12 col-xs-12">
                                        <h3
                                          onClick={(e) => {
                                            this.handleExpandAll(e);
                                            this.setState((prevState) => {
                                              return {
                                                showPrivacy:
                                                  !prevState.showPrivacy
                                              };
                                            });
                                          }}
                                          className={
                                            "expand-collapse" +
                                            (this.state.showPrivacy
                                              ? " expanded"
                                              : " collapsed")
                                          }
                                        >
                                          <span tabIndex="0">
                                            <span className="visuallyhidden">
                                              Open or Close all content below{" "}
                                            </span>
                                            Expand All
                                          </span>
                                        </h3>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-6 col-md-12">
                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showCigars
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleCigars(e)
                                            }
                                          >
                                            <div>
                                              <span className="visuallyhidden">
                                                <p onClick={this.handleCigars}>
                                                  {" "}
                                                  Open or Close content below{" "}
                                                </p>
                                              </span>
                                              Cigars
                                            </div>
                                          </h3>
                                          {this.state.showCigars ? (
                                            <div className="">
                                              <p>
                                                Cigars may be only be mailed to
                                                countries that allow cigar
                                                shipments.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c4_024.htm"
                                                >
                                                  <strong>
                                                    Tobacco Restrictions
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showCrematedRemains
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleCrematedRemains(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Cremated Remains
                                            </a>
                                          </h3>
                                          {this.state.showCrematedRemains ? (
                                            <div className="">
                                              <p>
                                                USPS<sup>&reg;</sup> is the only
                                                shipping company that ships
                                                cremated remains (human or pet
                                                ashes). You may ship cremated
                                                remains internationally (if the
                                                destination country permits) but
                                                must use Priority Mail Express
                                                International
                                                <sup>&reg;</sup> and the
                                                appropriate customs form. You
                                                are required to use a properly
                                                sealed funeral urn as the inner
                                                primary container. For the outer
                                                shipping package, you must use
                                                one of our two Cremated Remains
                                                Kits or use your own strong,
                                                durable container properly
                                                packaged and labeled with
                                                Cremated Remains Label 139 on
                                                all four sides of the box, plus
                                                top and bottom. Order free
                                                cremated remains shipping
                                                supplies on{" "}
                                                <a href="https://store.usps.com/store/results?Ntt=cremated&amp;_requestid=171234">
                                                  The Postal Store
                                                  <sup>&reg;</sup>
                                                </a>
                                                .
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://about.usps.com/newsroom/cremated-remains.htm"
                                                >
                                                  <strong>
                                                    Watch How to Ship Cremated
                                                    Remains Safely Video{" "}
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://about.usps.com/publications/pub139/welcome.htm"
                                                >
                                                  <strong>
                                                    Publication 139 - How to
                                                    Package &amp; Ship Cremated
                                                    Remains
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c4_017.htm?q=cremated&amp;t=H&amp;s=R&amp;p=1&amp;c=Pub52"
                                                >
                                                  <strong>
                                                    Other Guidelines on Shipping
                                                    &amp; Packaging Cremated
                                                    Remains
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showFirearms
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleFirearms(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Firearms
                                            </a>
                                          </h3>
                                          {this.state.showFirearms ? (
                                            <div className="">
                                              <p>
                                                Only licensed manufacturers and
                                                dealers may mail or receive
                                                handguns. Many countries have
                                                distinct domestic laws impacting
                                                the shipping and receipt of
                                                firearms.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c4_009.htm"
                                                >
                                                  <strong>
                                                    Additional Information on
                                                    Domestic Regulations &amp;
                                                    Guidelines Applicable to
                                                    International Shipping
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/imm/immc1_016.htm"
                                                >
                                                  <strong>
                                                    Additional Information on
                                                    International Shipping
                                                    Regulations
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showGlues
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) => this.handleGlues(e)}
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Glues
                                            </a>
                                          </h3>
                                          {this.state.showGlues ? (
                                            <div className="">
                                              <p>
                                                Glues have a wide range of
                                                flashpoints. Most glues are
                                                flammable and combustible and
                                                may not be mailed
                                                internationally. Glues are
                                                categorized as “adhesives” in
                                                the below resources.{" "}
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_021.htm"
                                                >
                                                  <strong>
                                                    Shipping Flammable &amp;
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52apxc_011.htm"
                                                >
                                                  <strong>
                                                    Packaging Instructions for
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showLithiumBatteries
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleLithiumBatteries(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Lithium Batteries
                                            </a>
                                          </h3>
                                          {this.state.showLithiumBatteries ? (
                                            <div className="">
                                              <p>
                                                Only lithium cells and batteries
                                                that are properly installed in
                                                the equipment they are intended
                                                to operate may be mailed
                                                internationally or to
                                                APO/FPO/DPO locations if the
                                                destination country and
                                                APO/FPO/DPO permit their
                                                receipt.
                                              </p>
                                              <h4>
                                                Prohibited Lithium Battery
                                                Shipments
                                              </h4>
                                              <ul>
                                                <li>
                                                  Lithium batteries packed with,
                                                  but not installed in,
                                                  equipment
                                                </li>
                                                <li>
                                                  Lithium batteries sent
                                                  separately from equipment
                                                </li>
                                                <li>
                                                  Damaged or recalled batteries
                                                </li>
                                              </ul>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="http://pe.usps.gov/text/imm/immc1_014.htm#ep1059904"
                                                >
                                                  <strong>
                                                    Shipping Lithium Batteries{" "}
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showLiveAnimals
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleLiveAnimals(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Live Animals
                                            </a>{" "}
                                          </h3>
                                          {this.state.showLiveAnimals ? (
                                            <div className="">
                                              <p>
                                                Except in limited circumstances,
                                                pets and warm-blooded animals
                                                may not go in the mail.
                                                Shipments of live animals,
                                                including poultry, reptiles, and
                                                bees, have very specific
                                                packaging requirements. Although
                                                you can drop these animals at
                                                any Post Office
                                                <sup>&trade;</sup> location,
                                                they are only shipped when the
                                                right transportation is
                                                available.{" "}
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c5_008.htm"
                                                >
                                                  <strong>
                                                    Regulations on Shipping Live
                                                    Animals
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/imm/immc1_017.htm"
                                                >
                                                  <strong>
                                                    Additional Regulations on
                                                    Shipping Live Animals
                                                    Internationally
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      <div className="col-lg-6 col-md-12">
                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showMatches
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleMatches(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Matches
                                            </a>
                                          </h3>
                                          {this.state.showMatches ? (
                                            <div className="">
                                              <h4>Safety Matches</h4>
                                              <p>
                                                Safety matches have treated
                                                match heads that will only
                                                ignite when struck against a
                                                specially prepared surface like
                                                a match book, card, or box.
                                                Safety matches must be sent via
                                                ground transportation and cannot
                                                be mailed internationally.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_022.htm"
                                                >
                                                  <strong>
                                                    Additional Information on
                                                    Mailing Safety Matches
                                                  </strong>
                                                </a>{" "}
                                              </p>
                                              <h4>Strike-Anywhere Matches</h4>
                                              <p>
                                                Strike-anywhere matches will
                                                light from friction against any
                                                surface. Strike-anywhere matches
                                                may not be mailed domestically.
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showMedicalDevices
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleMedicalDevices(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Medical Devices
                                            </a>
                                          </h3>
                                          {this.state.showMedicalDevices ? (
                                            <div className="">
                                              <p>
                                                Equipment or personal care
                                                products used by consumers,
                                                medical professionals, or
                                                pharmaceutical providers may not
                                                be mailed in the United States
                                                or internationally unless they
                                                meet certain requirements.{" "}
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="http://pe.usps.gov/text/pub52/pub52c3_023.htm#ep925138"
                                                >
                                                  <strong>
                                                    Shipping Medical Devices{" "}
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state
                                                .showMedicinesPrescription
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleMedicinesPrescription(
                                                e
                                              )
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Medicines &amp; Prescription Drugs
                                            </a>
                                          </h3>
                                          {this.state
                                            .showMedicinesPrescription ? (
                                            <div className="">
                                              <p>
                                                When sending medicine or drugs
                                                through the mail, mailers and
                                                shippers must know the
                                                characteristics of the items
                                                they are sending and treat them
                                                correctly. Medicines are
                                                controlled substances and are
                                                subject to strict regulations.
                                                Prescription medications may
                                                only be mailed by Drug
                                                Enforcement Administration (DEA)
                                                registered distributors. Similar
                                                regulations apply to some
                                                over-the-counter medications.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c4_019.htm"
                                                >
                                                  <strong>
                                                    Shipping Controlled
                                                    Substances &amp; Drugs
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showPaint
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) => this.handlePaint(e)}
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Paint
                                            </a>
                                          </h3>
                                          {this.state.showPaint ? (
                                            <div className="">
                                              <p>
                                                Flammable or combustible paint
                                                and paint-related items may not
                                                be shipped internationally.
                                                Latex Paint or a similar
                                                water-based paint product that
                                                is not flammable or combustible
                                                is not regulated as a hazardous
                                                material, and therefore, is not
                                                restricted.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_021.htm#ep898970"
                                                >
                                                  <strong>
                                                    Additional Information on
                                                    Mailing Paint
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showPerishableItems
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handlePerishableItems(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Perishable Items{" "}
                                            </a>
                                          </h3>
                                          {this.state.showPerishableItems ? (
                                            <div className="">
                                              <p>
                                                Perishable items are materials
                                                that can deteriorate in the
                                                mail, such as live animals,
                                                food, and plants. Permissible
                                                perishable items are sent at the
                                                mailer’s own risk. These items
                                                must be specially packaged and
                                                mailed so that they arrive
                                                before they begin to
                                                deteriorate.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/imm/immc1_017.htm"
                                                >
                                                  <strong>
                                                    Shipping Perishable
                                                    Materials Guidelines
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="accordion-content"
                                  style={{
                                    display:
                                      this.state.displayAccordion ===
                                      "accordion-content-four"
                                        ? "block"
                                        : "none"
                                  }}
                                  id="accordion-content-four"
                                >
                                  <div className="container-fluid">
                                    <div className="row">
                                      <div className="col-12">
                                        {this.state.restrictionShow}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row section-row">
                                  <div className="col-lg-6">
                                    <div className="subsection button-section">
                                      <div className="row">
                                        <div className="col-12">
                                          {this.state.fromItemEntry ===
                                          false ? (
                                            <div className="customs-form-buttons">
                                              <div className="form-button-container">
                                                <button
                                                  className="previous btn btn-outline-primary dynamic-back-button"
                                                  type="button"
                                                  onClick={
                                                    this.handleBackButton
                                                  }
                                                >
                                                  Back
                                                </button>
                                              </div>

                                              <div className="form-button-container">
                                                <button
                                                  type="submit"
                                                  className="btn btn-primary force-show btn-submit "
                                                  id="submit"
                                                  onClick={
                                                    this.handleContinueButton
                                                  }
                                                >
                                                  Continue
                                                </button>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="customs-form-buttons">
                                              <div className="form-button-container">
                                                <button
                                                  className="previous btn btn-outline-primary dynamic-back-button"
                                                  type="button"
                                                  onClick={
                                                    this.handlebackToItemsTable
                                                  }
                                                >
                                                  Back to Items Table
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  };

  renderMilitary = () => {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                      <p className="note">* indicates a required field</p>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="row section-row">
                    <div className="col-lg-12">
                      <h2>Domestically Prohibited Items and Restrictions</h2>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-12">
                      <div className="accordion">
                        <div className="accordion-items">
                          <div className="accordion-item">
                            <div className="row">
                              <div className="col-lg-4">
                                <div
                                  className="accordion-title"
                                  onClick={this.toggleOpen.bind(
                                    this,
                                    "accordion-content-one"
                                  )}
                                >
                                  <h2>Prohibitions: United States</h2>
                                  <p>
                                    These items may not be sent to the United
                                    States.
                                  </p>
                                </div>
                                <div
                                  className="accordion-title"
                                  onClick={this.toggleOpen.bind(
                                    this,
                                    "accordion-content-two"
                                  )}
                                >
                                  <h2>
                                    Prohibitions &amp; Restrictions: Military
                                    ZIP{" "}
                                    {
                                      this.previousState.flowTypeInputs
                                        .recipientZipCode
                                    }
                                  </h2>
                                  <p>
                                    Combined list of items that cannot be
                                    shipped along with items that can be shipped
                                    with restrictions.
                                  </p>
                                </div>
                                <div
                                  className="accordion-title"
                                  onClick={this.toggleOpen.bind(
                                    this,
                                    "accordion-content-three"
                                  )}
                                >
                                  <h2>Restrictions: United States</h2>
                                  <p>
                                    These restrictions apply when shipping to
                                    the United States.
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-8">
                                <div
                                  className="accordion-content"
                                  style={{
                                    display:
                                      this.state.displayAccordion ===
                                      "accordion-content-one"
                                        ? "block"
                                        : "none"
                                  }}
                                  id="accordion-content-one"
                                >
                                  <div className="container-fluid">
                                    <div className="row">
                                      <div className="col-lg-14">
                                        <p>
                                          It's important that you avoid these
                                          items to ensure fast, safe and legal
                                          shipments and avoid delays in customs
                                          offices.
                                        </p>
                                        <ul className="list-group bullet-list">
                                          <li>Air Bags</li>
                                          <li>Ammunition</li>
                                          <li>Explosives</li>
                                          <li>Gasoline</li>
                                          <li>
                                            Marijuana (medical or otherwise)
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="accordion-content"
                                  style={{
                                    display:
                                      this.state.displayAccordion ===
                                      "accordion-content-two"
                                        ? "block"
                                        : "none"
                                  }}
                                  id="accordion-content-two"
                                >
                                  <div className="container-fluid">
                                    <div className="row">
                                      <div className="col-lg-14">
                                        <ul className="list-group bullet-list">
                                          <li>
                                            B. Other than for PMEMS and the
                                            exceptions listed below, a customs
                                            declaration PS Form 2976 or PS Form
                                            2976-A is required for all items
                                            weighing 16 ounces or more, and for
                                            all items (regardless of weight)
                                            containing potentially dutiable mail
                                            contents (e.g., merchandise or
                                            goods) addressed to or from this ZIP
                                            Code. Other than the exceptions
                                            listed below, all PMEMS mailpieces
                                            (regardless of mail contents or
                                            weight) addressed to or from this
                                            ZIP Code must bear a properly
                                            completed PS Form 2976-B. The
                                            surface area of the address side of
                                            the mailpiece must be large enough
                                            to contain the applicable customs
                                            declaration. The following
                                            exceptions apply to known mailers,
                                            who for this purpose are defined as
                                            follows:
                                            <ul className="list-group bullet-list">
                                              <li>
                                                Business mailers who enter
                                                volume mailings through business
                                                mail entry units or other bulk
                                                mail acceptance locations, pay
                                                postage through advance deposit
                                                accounts, use permit imprints
                                                for postage payment, and submit
                                                completed postage statements at
                                                the time of entry that certify
                                                that the mailpieces contain no
                                                dangerous materials that are
                                                prohibited by postal
                                                regulations. Such business
                                                mailers are exempt from
                                                providing customs documentation
                                                on non-dutiable letters and
                                                printed matter.
                                              </li>
                                              <li>
                                                All federal, state, and local
                                                government agencies whose
                                                mailings are regarded as
                                                "Official Mail." Such agencies
                                                are exempt from providing
                                                customs documentation, except
                                                for any items addressed to an
                                                MPO or DPO to which restriction
                                                "B2" applies.
                                              </li>
                                            </ul>
                                          </li>
                                          <li>
                                            M. Fruits, vegetables, live animals,
                                            and live plants are prohibited.
                                          </li>
                                          <li>
                                            W. Hermetically sealed packages of
                                            meat products bearing USDA
                                            certification, such as dried beef,
                                            salami, and sausage, may be mailed.
                                            200 grams of tobacco per parcel are
                                            permitted duty free.
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="accordion-content"
                                  style={{
                                    display:
                                      this.state.displayAccordion ===
                                      "accordion-content-three"
                                        ? "block"
                                        : "none"
                                  }}
                                  id="accordion-content-three"
                                >
                                  <div className="faq-section">
                                    <div className="row">
                                      <div className="col-md-12 col-sm-12 col-xs-12">
                                        <h3
                                          onClick={(e) => {
                                            this.handleExpandAll(e);
                                            this.setState((prevState) => {
                                              return {
                                                showPrivacy:
                                                  !prevState.showPrivacy
                                              };
                                            });
                                          }}
                                          className={
                                            "expand-collapse" +
                                            (this.state.showPrivacy
                                              ? " expanded"
                                              : " collapsed")
                                          }
                                        >
                                          <span className="visuallyhidden">
                                            Open or Close all content below{" "}
                                          </span>
                                          Expand All
                                        </h3>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-6 col-md-12">
                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showAerosols
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleAerosols(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Aerosols
                                            </a>
                                          </h3>
                                          {this.state.showAerosols ? (
                                            <div className="">
                                              <p>
                                                Examples of mailable gases and
                                                aerosols include propane,
                                                butane, and fire extinguishers.
                                                Make sure you read about the
                                                specific rules and restrictions
                                                for sending mailable gases.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_020.htm"
                                                >
                                                  <strong>
                                                    List of Mailable Gasses
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showAlocholicBeverages
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleAlcoholicBeverages(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Alcoholic Beverages
                                            </a>
                                          </h3>
                                          {this.state.showAlocholicBeverages ? (
                                            <div className="">
                                              <p>
                                                Beer, wine, and liquor may not
                                                be sent through the mail, except
                                                in limited circumstances.
                                              </p>
                                              <p>
                                                Please note, if you want to
                                                reuse a box that has alcoholic
                                                beverage labels on it, remove
                                                all logos and labels so your
                                                package will pass through the
                                                mail system.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c4_006.htm"
                                                >
                                                  <strong>
                                                    Exceptions for Mailing
                                                    Intoxicating Liquors
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showTobacco
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleTobacco(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Cigarettes, Cigars, & Tobacco
                                            </a>
                                          </h3>
                                          {this.state.showTobacco ? (
                                            <div className="">
                                              <p>
                                                Cigarettes and smokeless tobacco
                                                are restricted items and can
                                                only be mailed in limited
                                                circumstances. Cigars may be
                                                mailed domestically. Customers
                                                are advised to review the
                                                Cigarettes, Cigars, and Tobacco{" "}
                                                <a
                                                  href="https://pe.usps.com/text/pub52/pub52c4_024.htm"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Definitions
                                                </a>{" "}
                                                and{" "}
                                                <a
                                                  href="https://pe.usps.com/text/pub52/pub52c4_025.htm"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Mailability
                                                </a>
                                                .
                                              </p>
                                              <h4>
                                                Exceptions for Mailing Cigarette
                                                & Smokeless Tobacco
                                              </h4>
                                              <p>
                                                Cigarettes and smokeless tobacco
                                                are mailable only in the below
                                                circumstances:
                                              </p>
                                              <ul className="list-group bullet-list">
                                                <li>
                                                  Intra-Alaskan and
                                                  intra-Hawaiian shipments
                                                </li>
                                                <li>
                                                  Business/regulatory purposes
                                                </li>
                                                <li>
                                                  Small-quantity "gift"
                                                  shipments to individuals
                                                </li>
                                                <li>
                                                  Individual returns of an
                                                  unacceptable tobacco product
                                                  to a manufacturer
                                                </li>
                                              </ul>
                                              <h4>
                                                Other Shipping & Packaging
                                                Requirements
                                              </h4>
                                              <p>
                                                Allowable cigarette or smokeless
                                                tobacco shipments must be
                                                approved by a Postal employee at
                                                a Post Office
                                                <sup>&trade;</sup>. The Postal
                                                employee will verify that an
                                                individual recipient is of legal
                                                age to receive the shipment.
                                              </p>
                                              <h4>
                                                Tobacco Shipments to APO/FPO/DPO
                                              </h4>
                                              <p>
                                                When shipping to APO/FPO/DPO
                                                locations in foreign countries,
                                                USPS treats the mail as domestic
                                                mail, but shipping is still
                                                subject to those foreign
                                                countries' prohibitions and
                                                restrictions. Customers should
                                                be aware that size and weight
                                                standards may differ from USPS
                                                standards. See{" "}
                                                <a
                                                  href="https://pe.usps.com/text/imm/ab_toc.htm"
                                                  targe="_blank"
                                                >
                                                  International Country Listings
                                                </a>{" "}
                                                to find country-specific
                                                prohibitions and restrictions.
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showCrematedRemains
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleCrematedRemains(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Cremated Remains
                                            </a>
                                          </h3>
                                          {this.state.showCrematedRemains ? (
                                            <div className="">
                                              <p>
                                                USPS<sup>&reg;</sup> is the only
                                                shipping company that ships
                                                cremated remains (human or pet
                                                ashes). You may ship cremated
                                                remains internationally (if the
                                                destination country permits) but
                                                must use Priority Mail Express
                                                International
                                                <sup>&reg;</sup> and the
                                                appropriate customs form. You
                                                are required to use a properly
                                                sealed funeral urn as the inner
                                                primary container. For the outer
                                                shipping package, you must use
                                                one of our two Cremated Remains
                                                Kits or use your own strong,
                                                durable container properly
                                                packaged and labeled with
                                                Cremated Remains Label 139 on
                                                all four sides of the box, plus
                                                top and bottom. Order free
                                                cremated remains shipping
                                                supplies on{" "}
                                                <a href="https://store.usps.com/store/results?Ntt=cremated&amp;_requestid=171234">
                                                  The Postal Store
                                                  <sup>&reg;</sup>
                                                </a>
                                                .
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://about.usps.com/newsroom/cremated-remains.htm"
                                                >
                                                  <strong>
                                                    Watch How to Ship Cremated
                                                    Remains Safely Video{" "}
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://about.usps.com/publications/pub139/welcome.htm"
                                                >
                                                  <strong>
                                                    Publication 139 - How to
                                                    Package &amp; Ship Cremated
                                                    Remains
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c4_017.htm?q=cremated&amp;t=H&amp;s=R&amp;p=1&amp;c=Pub52"
                                                >
                                                  <strong>
                                                    Other Guidelines on Shipping
                                                    &amp; Packaging Cremated
                                                    Remains
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showDryIce
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleDryIce(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Dry Ice
                                            </a>
                                          </h3>
                                          {this.state.showDryIce ? (
                                            <div className="">
                                              <p>
                                                Dry ice is permitted to be
                                                mailed when it is used as a
                                                refrigerant to cool the content
                                                of a mailable hazardous or
                                                nonhazardous material. Packages
                                                containing dry ice must be
                                                packed in containers that permit
                                                the release of carbon dioxide
                                                gas.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_027.htm#ep900106"
                                                >
                                                  <strong>
                                                    Shipping Dry Ice Rules &
                                                    Restrictions
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showFirearms
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleFirearms(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Firearms
                                            </a>
                                          </h3>
                                          {this.state.showFirearms ? (
                                            <div className="">
                                              <p>
                                                Only licensed manufacturers and
                                                dealers may mail or receive
                                                handguns. Many countries have
                                                distinct domestic laws impacting
                                                the shipping and receipt of
                                                firearms.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c4_009.htm"
                                                >
                                                  <strong>
                                                    Additional Information on
                                                    Domestic Regulations &amp;
                                                    Guidelines Applicable to
                                                    International Shipping
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/imm/immc1_016.htm"
                                                >
                                                  <strong>
                                                    Additional Information on
                                                    International Shipping
                                                    Regulations
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showFragileItems
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleFragileItems(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Fragile Items
                                            </a>
                                          </h3>
                                          {this.state.showFragileItems ? (
                                            <div className="">
                                              <p>
                                                Glues have a wide range of
                                                flashpoints. You are responsible
                                                for knowing the flashpoint and
                                                toxicity of the glue you want to
                                                mail. Flammable glues may be
                                                mailed if they are a consumer
                                                commodity. Some glues may only
                                                be sent by ground
                                                transportation. Glues are
                                                categorized as “adhesives” in
                                                the below resources.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_021.htm"
                                                >
                                                  <strong>
                                                    Shipping Flammable &amp;
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52apxc_011.htm"
                                                >
                                                  <strong>
                                                    https://pe.usps.com/text/pub52/pub52apxc_011.htm
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showGlues
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) => this.handleGlues(e)}
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Glues
                                            </a>
                                          </h3>
                                          {this.state.showGlues ? (
                                            <div className="">
                                              <p>
                                                Glues have a wide range of
                                                flashpoints. Most glues are
                                                flammable and combustible and
                                                may not be mailed
                                                internationally. Glues are
                                                categorized as “adhesives” in
                                                the below resources.{" "}
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_021.htm"
                                                >
                                                  <strong>
                                                    Shipping Flammable &amp;
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52apxc_011.htm"
                                                >
                                                  <strong>
                                                    Packaging Instructions for
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showHandSanitizer
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleHandSanitizer(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Hand Sanitizer
                                            </a>{" "}
                                          </h3>
                                          {this.state.showHandSanitizer ? (
                                            <div className="">
                                              <p>
                                                To ship hand sanitizers
                                                including wipes, you must use
                                                USPS Retail Ground, Parcel
                                                Select, or Parcel Select
                                                Lightweight. These services are
                                                only available through your
                                                local Post Office
                                                <sup>&trade;</sup> branch.
                                              </p>
                                              <p>
                                                Most hand sanitizers, including
                                                wipes, contain alcohol and are
                                                flammable in nature and are
                                                therefore handled and shipped as
                                                hazardous mater (HAZMAT) in the
                                                US Mail<sup>&trade;</sup>. As
                                                flammable materials, these
                                                products are limited to surface
                                                transportation-only in domestic
                                                mail. It is prohibited to send
                                                these materials by International
                                                Mail including APO/FPO/DPO
                                                (military) destinations.
                                              </p>
                                              <p>
                                                If you are unsure whether or not
                                                the product that you are
                                                shipping is flammable, please
                                                check with the manufacturer of
                                                the product before mailing.
                                              </p>
                                              <h4>Additional Resources</h4>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52apxc_010.htm"
                                                >
                                                  <strong>
                                                    Publication 52-Hazardous,
                                                    Restricted and Perishable
                                                    Mail Packaging Instructions
                                                    3A
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      <div className="col-lg-6 col-md-12">
                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showLithiumBatteries
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleLithiumBatteries(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Lithium Batteries
                                            </a>
                                          </h3>
                                          {this.state.showLithiumBatteries ? (
                                            <div className="">
                                              <p>
                                                For domestic mailings only,
                                                small consumer-type primary
                                                lithium cells or batteries
                                                (lithium metal or lithium alloy)
                                                like those used to power cameras
                                                and flashlights are mailable
                                                domestically under certain
                                                conditions.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_027.htm?q=349.11&t=H&s=R&p=1&c=Pub52"
                                                >
                                                  <strong>
                                                    Shipping Lithium Batteries
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showLiveAnimals
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleLiveAnimals(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Live Animals
                                            </a>
                                          </h3>
                                          {this.state.showLiveAnimals ? (
                                            <div className="">
                                              <p>
                                                Except in limited circumstances,
                                                pets and warm-blooded animals
                                                may not go in the mail.
                                                Shipments of live animals,
                                                including poultry, reptiles, and
                                                bees, have very specific
                                                packaging requirements. Although
                                                you can drop these animals at
                                                any Post Office
                                                <sup>&trade;</sup> location,
                                                they are only shipped when the
                                                appropriate transportation is
                                                available.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c5_008.htm"
                                                >
                                                  <strong>
                                                    Regulations on Shipping Live
                                                    Animals
                                                  </strong>
                                                </a>{" "}
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showMatches
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleMatches(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Matches
                                            </a>
                                          </h3>
                                          {this.state.showMatches ? (
                                            <div className="">
                                              <h4>Safety Matches</h4>
                                              <p>
                                                Safety matches have treated
                                                match heads that will only
                                                ignite when struck against a
                                                specially prepared surface like
                                                a match book, card, or box.
                                                Safety matches must be sent via
                                                ground transportation and cannot
                                                be mailed internationally.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_022.htm"
                                                >
                                                  <strong>
                                                    Additional Information on
                                                    Mailing Safety Matches
                                                  </strong>
                                                </a>{" "}
                                              </p>
                                              <h4>Strike-Anywhere Matches</h4>
                                              <p>
                                                Strike-anywhere matches will
                                                light from friction against any
                                                surface. Strike-anywhere matches
                                                may not be mailed domestically.
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state
                                                .showMedicinesPrescription
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleMedicinesPrescription(
                                                e
                                              )
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Medicines &amp; Prescription Drugs
                                            </a>
                                          </h3>
                                          {this.state
                                            .showMedicinesPrescription ? (
                                            <div className="">
                                              <p>
                                                When sending medicine or drugs
                                                through the mail, mailers and
                                                shippers must know the
                                                characteristics of the items
                                                they are sending and treat them
                                                correctly. Medicines are
                                                controlled substances and are
                                                subject to strict regulations.
                                                Prescription medications may
                                                only be mailed by Drug
                                                Enforcement Administration (DEA)
                                                registered distributors. Similar
                                                regulations apply to some
                                                over-the-counter medications.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c4_019.htm"
                                                >
                                                  <strong>
                                                    Shipping Controlled
                                                    Substances &amp; Drugs
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showNailPolish
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleNailPolish(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Nail Polish
                                            </a>
                                          </h3>
                                          {this.state.showNailPolish ? (
                                            <div className="">
                                              <p>
                                                If the nail polish you want to
                                                mail is flammable, you are
                                                responsible for knowing its
                                                flashpoint and toxicity.
                                                Flammable nail polishes may be
                                                mailed domestically if they are
                                                a consumer commodity. Some nail
                                                polishes may only be sent by
                                                ground transportation.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_021.htm"
                                                >
                                                  <strong>
                                                    Shipping Flammable &
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52apxc_011.htm"
                                                >
                                                  <strong>
                                                    Packaging Instructions for
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showPaint
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) => this.handlePaint(e)}
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Paint
                                            </a>
                                          </h3>
                                          {this.state.showPaint ? (
                                            <div className="">
                                              <p>
                                                Flammable or combustible paint
                                                and paint-related items are
                                                generally accepted for mailing,
                                                provided the material can
                                                qualify as a consumer commodity
                                                material or ORM-D (for surface
                                                only), and is sent within the
                                                quantity limitations and
                                                packaging requirements. Latex
                                                Paint or a similar water-based
                                                paint product that is not
                                                flammable or combustible is not
                                                regulated as a hazardous
                                                material, and therefore, is not
                                                restricted.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_021.htm#ep898970"
                                                >
                                                  <strong>
                                                    Additional Information on
                                                    Mailing Paint
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showPerfumes
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handlePerfumes(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Perfumes
                                            </a>
                                          </h3>
                                          {this.state.showPerfumes ? (
                                            <div className="">
                                              <p>
                                                Perfume containing alcohol may
                                                not be shipped internationally
                                                or mailed domestically using air
                                                transportation. Perfume
                                                containing alcohol, however, may
                                                be shipped within the United
                                                States by ground transportation.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_021.htm"
                                                >
                                                  <strong>
                                                    Shipping Flammable &
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52apxc_011.htm"
                                                >
                                                  <strong>
                                                    Packaging Instructions for
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showPerishableItems
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handlePerishableItems(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Perishable Items
                                            </a>
                                          </h3>
                                          {this.state.showPerishableItems ? (
                                            <div className="">
                                              <p>
                                                Perishable items are materials
                                                that can deteriorate in the
                                                mail, such as live animals,
                                                food, and plants. Permissible
                                                perishable items are sent at the
                                                mailer’s own risk. These items
                                                must be specially packaged and
                                                mailed so that they arrive
                                                before they begin to
                                                deteriorate.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c5_001.htm"
                                                >
                                                  <strong>
                                                    Shipping Perishable
                                                    Materials Guidelines
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showPoisons
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handlePoisons(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Poisons
                                            </a>
                                          </h3>
                                          {this.state.showPoisons ? (
                                            <div className="">
                                              <p>
                                                Poisons are considered toxins.
                                                You are responsible for knowing
                                                the toxicity of the material you
                                                want to mail. Some poisons may
                                                be sent by air and ground
                                                transport, others only by
                                                ground.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_024.htm#ep925060"
                                                >
                                                  <strong>
                                                    Mailable Class 6 Materials
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52apxc_017.htm#ep1000127"
                                                >
                                                  <strong>
                                                    Packaging Instructions for
                                                    Toxic Substances
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row section-row">
                                  <div className="col-lg-6">
                                    <div className="subsection button-section">
                                      <div className="row">
                                        <div className="col-12">
                                          {this.state.fromItemEntry ===
                                          false ? (
                                            <div className="customs-form-buttons">
                                              <div className="form-button-container">
                                                <button
                                                  className="previous btn btn-outline-primary dynamic-back-button"
                                                  type="button"
                                                  onClick={
                                                    this.handleBackButton
                                                  }
                                                >
                                                  Back
                                                </button>
                                              </div>

                                              <div className="form-button-container">
                                                <button
                                                  type="submit"
                                                  className="btn btn-primary force-show btn-submit "
                                                  id="submit"
                                                  onClick={
                                                    this.handleContinueButton
                                                  }
                                                >
                                                  Continue
                                                </button>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="customs-form-buttons">
                                              <div className="form-button-container">
                                                <button
                                                  className="previous btn btn-outline-primary dynamic-back-button"
                                                  type="button"
                                                  onClick={
                                                    this.handlebackToItemsTable
                                                  }
                                                >
                                                  Back to Items Table
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  };

  renderOtherMilitary = () => {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                    </div>

                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="row section-row">
                    <div className="col-12">
                      <h2>Domestically Prohibited Items and Restrictions</h2>
                    </div>
                  </div>

                  <div className="row section-row">
                    <div className="col-lg-12">
                      <div className="accordion">
                        <div className="accordion-items">
                          <div className="accordion-item">
                            <div className="row">
                              <div className="col-lg-4">
                                <div
                                  className="accordion-title"
                                  onClick={this.toggleOpen.bind(
                                    this,
                                    "accordion-content-one"
                                  )}
                                >
                                  <h2>Prohibitions: United States</h2>
                                  <p>
                                    These items may not be sent to the United
                                    States.
                                  </p>
                                </div>
                                <div
                                  className="accordion-title"
                                  onClick={this.toggleOpen.bind(
                                    this,
                                    "accordion-content-two"
                                  )}
                                >
                                  <h2>Restrictions: United States</h2>
                                  <p>
                                    These restrictions apply when shipping to
                                    the United States.
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-8">
                                <div
                                  className="accordion-content"
                                  style={{
                                    display:
                                      this.state.displayAccordion ===
                                      "accordion-content-one"
                                        ? "block"
                                        : "none"
                                  }}
                                  id="accordion-content-one"
                                >
                                  <div className="container-fluid">
                                    <div className="row">
                                      <div className="col-lg-14">
                                        <p>
                                          It's important that you avoid these
                                          items to ensure fast, safe and legal
                                          shipments and avoid delays in customs
                                          offices.
                                        </p>
                                        <ul className="list-group bullet-list">
                                          <li>Air Bags</li>
                                          <li>Ammunition</li>
                                          <li>Explosives</li>
                                          <li>Gasoline</li>
                                          <li>
                                            Marijuana (medical or otherwise)
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="accordion-content"
                                  style={{
                                    display:
                                      this.state.displayAccordion ===
                                      "accordion-content-two"
                                        ? "block"
                                        : "none"
                                  }}
                                  id="accordion-content-two"
                                >
                                  <div className="faq-section">
                                    <div className="row">
                                      <div className="col-md-12 col-sm-12 col-xs-12">
                                        <h3
                                          onClick={(e) => {
                                            this.handleExpandAll(e);
                                            this.setState((prevState) => {
                                              return {
                                                showPrivacy:
                                                  !prevState.showPrivacy
                                              };
                                            });
                                          }}
                                          className={
                                            "expand-collapse" +
                                            (this.state.showPrivacy
                                              ? " expanded"
                                              : " collapsed")
                                          }
                                        >
                                          <span className="visuallyhidden">
                                            Open or Close all content below{" "}
                                          </span>
                                          Expand All
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-12">
                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showAerosols
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleAerosols(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Aerosols
                                            </a>
                                          </h3>
                                          {this.state.showAerosols ? (
                                            <div className="">
                                              <p>
                                                Examples of mailable gases and
                                                aerosols include propane,
                                                butane, and fire extinguishers.
                                                Make sure you read about the
                                                specific rules and restrictions
                                                for sending mailable gases.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_020.htm"
                                                >
                                                  <strong>
                                                    List of Mailable Gasses
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showAlocholicBeverages
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleAlcoholicBeverages(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Alcoholic Beverages
                                            </a>
                                          </h3>
                                          {this.state.showAlocholicBeverages ? (
                                            <div className="">
                                              <p>
                                                Beer, wine, and liquor may not
                                                be sent through the mail, except
                                                in limited circumstances.
                                              </p>
                                              <p>
                                                Please note, if you want to
                                                reuse a box that has alcoholic
                                                beverage labels on it, remove
                                                all logos and labels so your
                                                package will pass through the
                                                mail system.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c4_006.htm"
                                                >
                                                  <strong>
                                                    Exceptions for Mailing
                                                    Intoxicating Liquors
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showTobacco
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleTobacco(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Cigarettes, Cigars, & Tobacco
                                            </a>
                                          </h3>
                                          {this.state.showTobacco ? (
                                            <div className="">
                                              <p>
                                                Cigarettes and smokeless tobacco
                                                are restricted items and can
                                                only be mailed in limited
                                                circumstances. Cigars may be
                                                mailed domestically. Customers
                                                are advised to review the
                                                Cigarettes, Cigars, and Tobacco{" "}
                                                <a
                                                  href="https://pe.usps.com/text/pub52/pub52c4_024.htm"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Definitions
                                                </a>{" "}
                                                and{" "}
                                                <a
                                                  href="https://pe.usps.com/text/pub52/pub52c4_025.htm"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Mailability
                                                </a>
                                                .
                                              </p>
                                              <h4>
                                                Exceptions for Mailing Cigarette
                                                & Smokeless Tobacco
                                              </h4>
                                              <p>
                                                Cigarettes and smokeless tobacco
                                                are mailable only in the below
                                                circumstances:
                                              </p>
                                              <ul className="list-group bullet-list">
                                                <li>
                                                  Intra-Alaskan and
                                                  intra-Hawaiian shipments
                                                </li>
                                                <li>
                                                  Business/regulatory purposes
                                                </li>
                                                <li>
                                                  Small-quantity "gift"
                                                  shipments to individuals
                                                </li>
                                                <li>
                                                  Individual returns of an
                                                  unacceptable tobacco product
                                                  to a manufacturer
                                                </li>
                                              </ul>
                                              <h4>
                                                Other Shipping & Packaging
                                                Requirements
                                              </h4>
                                              <p>
                                                Allowable cigarette or smokeless
                                                tobacco shipments must be
                                                approved by a Postal employee at
                                                a Post Office
                                                <sup>&trade;</sup>. The Postal
                                                employee will verify that an
                                                individual recipient is of legal
                                                age to receive the shipment.
                                              </p>
                                              <h4>
                                                Tobacco Shipments to APO/FPO/DPO
                                              </h4>
                                              <p>
                                                When shipping to APO/FPO/DPO
                                                locations in foreign countries,
                                                USPS treats the mail as domestic
                                                mail, but shipping is still
                                                subject to those foreign
                                                countries' prohibitions and
                                                restrictions. Customers should
                                                be aware that size and weight
                                                standards may differ from USPS
                                                standards. See{" "}
                                                <a
                                                  href="https://pe.usps.com/text/imm/ab_toc.htm"
                                                  targe="_blank"
                                                >
                                                  International Country Listings
                                                </a>{" "}
                                                to find country-specific
                                                prohibitions and restrictions.
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showCrematedRemains
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleCrematedRemains(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Cremated Remains
                                            </a>
                                          </h3>
                                          {this.state.showCrematedRemains ? (
                                            <div className="">
                                              <p>
                                                USPS<sup>&reg;</sup> is the only
                                                shipping company that ships
                                                cremated remains (human or pet
                                                ashes). You may ship cremated
                                                remains internationally (if the
                                                destination country permits) but
                                                must use Priority Mail Express
                                                International
                                                <sup>&reg;</sup> and the
                                                appropriate customs form. You
                                                are required to use a properly
                                                sealed funeral urn as the inner
                                                primary container. For the outer
                                                shipping package, you must use
                                                one of our two Cremated Remains
                                                Kits or use your own strong,
                                                durable container properly
                                                packaged and labeled with
                                                Cremated Remains Label 139 on
                                                all four sides of the box, plus
                                                top and bottom. Order free
                                                cremated remains shipping
                                                supplies on{" "}
                                                <a href="https://store.usps.com/store/results?Ntt=cremated&amp;_requestid=171234">
                                                  The Postal Store
                                                  <sup>&reg;</sup>
                                                </a>
                                                .
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://about.usps.com/newsroom/cremated-remains.htm"
                                                >
                                                  <strong>
                                                    Watch How to Ship Cremated
                                                    Remains Safely Video{" "}
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://about.usps.com/publications/pub139/welcome.htm"
                                                >
                                                  <strong>
                                                    Publication 139 - How to
                                                    Package &amp; Ship Cremated
                                                    Remains
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c4_017.htm?q=cremated&amp;t=H&amp;s=R&amp;p=1&amp;c=Pub52"
                                                >
                                                  <strong>
                                                    Other Guidelines on Shipping
                                                    &amp; Packaging Cremated
                                                    Remains
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showDryIce
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleDryIce(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Dry Ice
                                            </a>
                                          </h3>
                                          {this.state.showDryIce ? (
                                            <div className="">
                                              <p>
                                                Dry ice is permitted to be
                                                mailed when it is used as a
                                                refrigerant to cool the content
                                                of a mailable hazardous or
                                                nonhazardous material. Packages
                                                containing dry ice must be
                                                packed in containers that permit
                                                the release of carbon dioxide
                                                gas.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_027.htm#ep900106"
                                                >
                                                  <strong>
                                                    Shipping Dry Ice Rules &
                                                    Restrictions
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showFirearms
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleFirearms(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Firearms
                                            </a>
                                          </h3>
                                          {this.state.showFirearms ? (
                                            <div className="">
                                              <p>
                                                Only licensed manufacturers and
                                                dealers may mail or receive
                                                handguns. Many countries have
                                                distinct domestic laws impacting
                                                the shipping and receipt of
                                                firearms.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c4_009.htm"
                                                >
                                                  <strong>
                                                    Additional Information on
                                                    Domestic Regulations &amp;
                                                    Guidelines Applicable to
                                                    International Shipping
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/imm/immc1_016.htm"
                                                >
                                                  <strong>
                                                    Additional Information on
                                                    International Shipping
                                                    Regulations
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showFragileItems
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleFragileItems(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Fragile Items
                                            </a>
                                          </h3>
                                          {this.state.showFragileItems ? (
                                            <div className="">
                                              <p>
                                                Glues have a wide range of
                                                flashpoints. You are responsible
                                                for knowing the flashpoint and
                                                toxicity of the glue you want to
                                                mail. Flammable glues may be
                                                mailed if they are a consumer
                                                commodity. Some glues may only
                                                be sent by ground
                                                transportation. Glues are
                                                categorized as “adhesives” in
                                                the below resources.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_021.htm"
                                                >
                                                  <strong>
                                                    Shipping Flammable &amp;
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52apxc_011.htm"
                                                >
                                                  <strong>
                                                    https://pe.usps.com/text/pub52/pub52apxc_011.htm
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showGlues
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) => this.handleGlues(e)}
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Glues
                                            </a>
                                          </h3>
                                          {this.state.showGlues ? (
                                            <div className="">
                                              <p>
                                                Glues have a wide range of
                                                flashpoints. Most glues are
                                                flammable and combustible and
                                                may not be mailed
                                                internationally. Glues are
                                                categorized as “adhesives” in
                                                the below resources.{" "}
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_021.htm"
                                                >
                                                  <strong>
                                                    Shipping Flammable &amp;
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52apxc_011.htm"
                                                >
                                                  <strong>
                                                    Packaging Instructions for
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showHandSanitizer
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleHandSanitizer(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Hand Sanitizer
                                            </a>{" "}
                                          </h3>
                                          {this.state.showHandSanitizer ? (
                                            <div className="">
                                              <p>
                                                To ship hand sanitizers
                                                including wipes, you must use
                                                USPS Retail Ground, Parcel
                                                Select, or Parcel Select
                                                Lightweight. These services are
                                                only available through your
                                                local Post Office
                                                <sup>&trade;</sup> branch.
                                              </p>
                                              <p>
                                                Most hand sanitizers, including
                                                wipes, contain alcohol and are
                                                flammable in nature and are
                                                therefore handled and shipped as
                                                hazardous mater (HAZMAT) in the
                                                US Mail<sup>&trade;</sup>. As
                                                flammable materials, these
                                                products are limited to surface
                                                transportation-only in domestic
                                                mail. It is prohibited to send
                                                these materials by International
                                                Mail including APO/FPO/DPO
                                                (military) destinations.
                                              </p>
                                              <p>
                                                If you are unsure whether or not
                                                the product that you are
                                                shipping is flammable, please
                                                check with the manufacturer of
                                                the product before mailing.
                                              </p>
                                              <h4>Additional Resources</h4>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52apxc_010.htm"
                                                >
                                                  <strong>
                                                    Publication 52-Hazardous,
                                                    Restricted and Perishable
                                                    Mail Packaging Instructions
                                                    3A
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      <div className="col-lg-6 col-md-12">
                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showLithiumBatteries
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleLithiumBatteries(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Lithium Batteries
                                            </a>
                                          </h3>
                                          {this.state.showLithiumBatteries ? (
                                            <div className="">
                                              <p>
                                                For domestic mailings only,
                                                small consumer-type primary
                                                lithium cells or batteries
                                                (lithium metal or lithium alloy)
                                                like those used to power cameras
                                                and flashlights are mailable
                                                domestically under certain
                                                conditions.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_027.htm?q=349.11&t=H&s=R&p=1&c=Pub52"
                                                >
                                                  <strong>
                                                    Shipping Lithium Batteries
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showLiveAnimals
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleLiveAnimals(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Live Animals
                                            </a>
                                          </h3>
                                          {this.state.showLiveAnimals ? (
                                            <div className="">
                                              <p>
                                                Except in limited circumstances,
                                                pets and warm-blooded animals
                                                may not go in the mail.
                                                Shipments of live animals,
                                                including poultry, reptiles, and
                                                bees, have very specific
                                                packaging requirements. Although
                                                you can drop these animals at
                                                any Post Office
                                                <sup>&trade;</sup> location,
                                                they are only shipped when the
                                                appropriate transportation is
                                                available.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c5_008.htm"
                                                >
                                                  <strong>
                                                    Regulations on Shipping Live
                                                    Animals
                                                  </strong>
                                                </a>{" "}
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showMatches
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleMatches(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Matches
                                            </a>
                                          </h3>
                                          {this.state.showMatches ? (
                                            <div className="">
                                              <h4>Safety Matches</h4>
                                              <p>
                                                Safety matches have treated
                                                match heads that will only
                                                ignite when struck against a
                                                specially prepared surface like
                                                a match book, card, or box.
                                                Safety matches must be sent via
                                                ground transportation and cannot
                                                be mailed internationally.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_022.htm"
                                                >
                                                  <strong>
                                                    Additional Information on
                                                    Mailing Safety Matches
                                                  </strong>
                                                </a>{" "}
                                              </p>
                                              <h4>Strike-Anywhere Matches</h4>
                                              <p>
                                                Strike-anywhere matches will
                                                light from friction against any
                                                surface. Strike-anywhere matches
                                                may not be mailed domestically.
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state
                                                .showMedicinesPrescription
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleMedicinesPrescription(
                                                e
                                              )
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Medicines &amp; Prescription Drugs
                                            </a>
                                          </h3>
                                          {this.state
                                            .showMedicinesPrescription ? (
                                            <div className="">
                                              <p>
                                                When sending medicine or drugs
                                                through the mail, mailers and
                                                shippers must know the
                                                characteristics of the items
                                                they are sending and treat them
                                                correctly. Medicines are
                                                controlled substances and are
                                                subject to strict regulations.
                                                Prescription medications may
                                                only be mailed by Drug
                                                Enforcement Administration (DEA)
                                                registered distributors. Similar
                                                regulations apply to some
                                                over-the-counter medications.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c4_019.htm"
                                                >
                                                  <strong>
                                                    Shipping Controlled
                                                    Substances &amp; Drugs
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showNailPolish
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handleNailPolish(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Nail Polish
                                            </a>
                                          </h3>
                                          {this.state.showNailPolish ? (
                                            <div className="">
                                              <p>
                                                If the nail polish you want to
                                                mail is flammable, you are
                                                responsible for knowing its
                                                flashpoint and toxicity.
                                                Flammable nail polishes may be
                                                mailed domestically if they are
                                                a consumer commodity. Some nail
                                                polishes may only be sent by
                                                ground transportation.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_021.htm"
                                                >
                                                  <strong>
                                                    Shipping Flammable &
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52apxc_011.htm"
                                                >
                                                  <strong>
                                                    Packaging Instructions for
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showPaint
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) => this.handlePaint(e)}
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Paint
                                            </a>
                                          </h3>
                                          {this.state.showPaint ? (
                                            <div className="">
                                              <p>
                                                Flammable or combustible paint
                                                and paint-related items are
                                                generally accepted for mailing,
                                                provided the material can
                                                qualify as a consumer commodity
                                                material or ORM-D (for surface
                                                only), and is sent within the
                                                quantity limitations and
                                                packaging requirements. Latex
                                                Paint or a similar water-based
                                                paint product that is not
                                                flammable or combustible is not
                                                regulated as a hazardous
                                                material, and therefore, is not
                                                restricted.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_021.htm#ep898970"
                                                >
                                                  <strong>
                                                    Additional Information on
                                                    Mailing Paint
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showPerfumes
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handlePerfumes(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Perfumes
                                            </a>
                                          </h3>
                                          {this.state.showPerfumes ? (
                                            <div className="">
                                              <p>
                                                Perfume containing alcohol may
                                                not be shipped internationally
                                                or mailed domestically using air
                                                transportation. Perfume
                                                containing alcohol, however, may
                                                be shipped within the United
                                                States by ground transportation.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_021.htm"
                                                >
                                                  <strong>
                                                    Shipping Flammable &
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52apxc_011.htm"
                                                >
                                                  <strong>
                                                    Packaging Instructions for
                                                    Combustible Liquids
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showPerishableItems
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handlePerishableItems(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Perishable Items
                                            </a>
                                          </h3>
                                          {this.state.showPerishableItems ? (
                                            <div className="">
                                              <p>
                                                Perishable items are materials
                                                that can deteriorate in the
                                                mail, such as live animals,
                                                food, and plants. Permissible
                                                perishable items are sent at the
                                                mailer’s own risk. These items
                                                must be specially packaged and
                                                mailed so that they arrive
                                                before they begin to
                                                deteriorate.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c5_001.htm"
                                                >
                                                  <strong>
                                                    Shipping Perishable
                                                    Materials Guidelines
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                        </div>

                                        <div className="faq-unit">
                                          <h3
                                            className={
                                              "accordion-header expand-collapse" +
                                              (this.state.showPoisons
                                                ? " expanded"
                                                : " collapsed")
                                            }
                                            onClick={(e) =>
                                              this.handlePoisons(e)
                                            }
                                          >
                                            <a href="/#">
                                              <span className="visuallyhidden">
                                                Open or Close content below{" "}
                                              </span>
                                              Poisons
                                            </a>
                                          </h3>
                                          {this.state.showPoisons ? (
                                            <div className="">
                                              <p>
                                                Poisons are considered toxins.
                                                You are responsible for knowing
                                                the toxicity of the material you
                                                want to mail. Some poisons may
                                                be sent by air and ground
                                                transport, others only by
                                                ground.
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52c3_024.htm#ep925060"
                                                >
                                                  <strong>
                                                    Mailable Class 6 Materials
                                                  </strong>
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://pe.usps.com/text/pub52/pub52apxc_017.htm#ep1000127"
                                                >
                                                  <strong>
                                                    Packaging Instructions for
                                                    Toxic Substances
                                                  </strong>
                                                </a>
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row section-row">
                                  <div className="col-lg-6">
                                    <div className="subsection button-section">
                                      <div className="row">
                                        <div className="col-12">
                                          {this.state.fromItemEntry ===
                                          false ? (
                                            <div className="customs-form-buttons">
                                              <div className="form-button-container">
                                                <button
                                                  className="previous btn btn-outline-primary dynamic-back-button"
                                                  type="button"
                                                  onClick={
                                                    this.handleBackButton
                                                  }
                                                >
                                                  Back
                                                </button>
                                              </div>

                                              <div className="form-button-container">
                                                <button
                                                  type="submit"
                                                  className="btn btn-primary force-show btn-submit "
                                                  id="submit"
                                                  onClick={
                                                    this.handleContinueButton
                                                  }
                                                >
                                                  Continue
                                                </button>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="customs-form-buttons">
                                              <div className="form-button-container">
                                                <button
                                                  className="previous btn btn-outline-primary dynamic-back-button"
                                                  type="button"
                                                  onClick={
                                                    this.handlebackToItemsTable
                                                  }
                                                >
                                                  Back to Items Table
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  };

  componentDidMount() {
    this.props.loginCheck();
    if(!this.previousState.prohibitionsStatus || 
      (this.previousState.prohibitionsStatus && this.previousState.prohibitionsStatus === 0)){
      this.previousState.stepNumber = this.previousState.stepNumber + 1
      this.props.stepNumberCallback(this.previousState.stepNumber);
    }
    var cntNam = "";
    if (this.previousState.flowType === "other") {
      cntNam = this.previousState.flowTypeInputs.countryName;
    } else {
      cntNam = this.previousState.flowType;
    }
    this.setState({
      countryName: cntNam,
      fromItemEntry: window.location.search === "?itemEntry" ? true : false
    });
    if (
      this.previousState.flowType !== "military" &&
      this.previousState.flowType !== "territories" &&
      this.previousState.flowType !== "usa" &&
      this.previousState.flowType !== "militaryToUSA"
    ) {
      this.props.toggleLoadingBar(true);
      axios
        .get("/CFOApplication/fetchProhibitions?countryName=" + cntNam)
        .then((response) => {
          if (response.data) {
            this.setState({
              prohibitionsShow: response.data.prohibitions,
              restrictionShow: response.data.restrictions
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.setPageErrors([
            "We're sorry. An unexpected error has occurred."
          ]);
        })
        .finally(() => {
          this.setState({
            hasLoaded: true
          });
          this.props.toggleLoadingBar(false);
        });
    } else {
      this.setState({
        hasLoaded: true
      });
      this.props.toggleLoadingBar(false);
    }
  }
  render() {
    if (this.state.hasLoaded) {
      switch (this.flowType) {
        case "Canada":
        case "other":
          return <React.Fragment>{this.renderInternational()}</React.Fragment>;
        case "military":
          return <React.Fragment>{this.renderMilitary()}</React.Fragment>;
        default:
          return <React.Fragment>{this.renderOtherMilitary()}</React.Fragment>;
      }
    }
    return null;
  }
}

export default withRouter(Prohibitions);
