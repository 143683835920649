import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const PriorityMailInternationalPopover = (props) => {
  const content = (
    <React.Fragment>
      <div className="international-shipping-option-popover">
        <div className="popover-wrapper">
          <div className="popover-header"></div>
          <p>
            Use Priority Mail International<sup>®</sup> to send merchandise to
            over 190 countries worldwide. Your shipments are economical and
            receive priority handling to all destinations.
          </p>
          <p>
            Priority Mail International<sup>®</sup> features:
          </p>
          <ul>
            <li>
              <p>Online Tracking with Track & Confirm</p>
            </li>
            <li>
              <p>Free Package Pickup</p>
            </li>
            <li>
              <p>Buy insurance online</p>
            </li>
            <li>
              <p>Free Shipping Supplies</p>
            </li>
            <li>
              <p>USPS Customs Forms Envelope (2976-E) is required.</p>
            </li>
            <li>
              <p>
                Available online at The Postal Store<sup>&reg;</sup> or your
                local Post Office<sup>&trade;</sup>.
              </p>
            </li>
          </ul>
          <p>USPS Customs Forms Envelope (2976-E) is required.</p>
          <p>
            Available online at The Postal Store<sup>®</sup> or at your local
            Post Office<sup>™</sup>.
          </p>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Priority Mail International"
      header="Priority Mail International"
      content={content}
    />
  );
};

export default PriorityMailInternationalPopover;
