import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import caretRight from "../customs_forms/images/caret-right.svg";
import LoggedInNav from "./LoggedInNav";
import * as analytics from "../Components/Analytics/Analytics.js";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";
var dataLayer = {
  event: "vpvCFO",
  element: "",
  selection: "",
  page: "cfo-step-6-nondelivery-option"
};
class Hazmat extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.setPageErrors([]);
    this.props.toggleLoadingBar(false);

    let arrOfHazmatItemsInternational = [
      {
        esc: 0,
        description: "Select"
      },

      {
        esc: 813,
        description:
          "Class 7 – Radioactive Materials Package  (e.g., smoke detectors, minerals, gun sights, etc.)"
      },

      {
        esc: 820,
        description:
          "Class 9 – Lithium batteries, unmarked package - New electronic devices installed or packaged with lithium batteries (no marking)"
      },

      {
        esc: 826,
        description:
          "Division 6.2 Hazardous Materials - Biological Materials (e.g., lab test kits, authorized COVID test kit returns)"
      }
    ];

    this.state = {
      hazmatYesNo:
        this.previousState.hazmatType === undefined
          ? null
          : this.previousState.hazmatType.hazmatType === null
          ? "1"
          : "0",
      hazmatType:
        this.previousState.hazmatType !== undefined &&
        this.previousState.hazmatType.hazmatType !== null
          ? this.previousState.hazmatType.hazmatType
          : null,
      arrOfHazmatItemsInternational: arrOfHazmatItemsInternational
    };
  }

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.cartInfo &&
      this.props.history.location.state.previousState.packageInfo
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there is no cart or package info
    window.location.replace("/index");
  };

  handleHazmatOption = (event) => {
    const value = event.currentTarget.value;
    this.setState((prevState) => {
      return {
        hazmatYesNo: value,
        hazmatType: value === "0" ? prevState.hazmatType : null
      };
    });
  };

  handleBackButton = () => {
    this.previousState.stepNumber = this.previousState.stepNumber - 1;
    this.props.stepNumberCallback(this.previousState.stepNumber);
    this.previousState.hazmatStatus =0;
    this.props.history.push("/package-info", {
      previousState: this.previousState
    });
    dataLayer.element = "Back";
    analytics.setDataLayerInfo(dataLayer);
  };

  componentDidMount = () => {
    this.props.loginCheck();
    if(!this.previousState.hazmatStatus || 
      (this.previousState.hazmatStatus && this.previousState.hazmatStatus === 0)){
      this.previousState.stepNumber = this.previousState.stepNumber + 1
      this.props.stepNumberCallback(this.previousState.stepNumber);
    }
  };

  handleContinueButton = (e) => {
    e.preventDefault();
    this.previousState.hazmatStatus = 1;
    this.props.toggleLoadingBar(true);
    axios
      .get(
        "/CFOApplication/saveHazmatOption?cartId=" +
          encodeURIComponent(
            this.props.location.state.previousState.cartInfo.encodedCartId
          ) +
          "&&hazmatType=" +
          this.state.hazmatType
      )
      .then((response) => {
        this.updatePreviousState();
        this.props.history.push("/service-options", {
          previousState: this.previousState
        });
        dataLayer.element = "Continue";
        analytics.setDataLayerInfo(dataLayer);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  };

  updatePreviousState = () => {
    this.previousState.hazmatType = {
      hazmatType: this.state.hazmatType
    };
  };

  hazmatESCToDesc = (esc) => {
    for (let i in this.state.arrOfHazmatItemsInternational) {
      if (this.state.arrOfHazmatItemsInternational[i].esc == esc) {
        return this.state.arrOfHazmatItemsInternational[i].description;
      }
    }
    return "Select";
  };

  toggle = () => {
    this.setState((prevState) => {
      return {
        dropdownOpen: !prevState.dropdownOpen
      };
    });
  };

  setHazmatType = (e) => {
    this.setState({
      hazmatType: e.target.value,
      hazmatDescription: e.target.innerHTML
    });
  };

  render() {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-9">
                      <p className="note"></p>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-12">
                      <h2>
                        Does this parcel contain anything potentially hazardous?
                      </h2>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-12">
                      <p>
                        Identify whether your shipment contains dangerous goods.
                        It will be noted on the shipping label.
                      </p>

                      <p>
                        This category includes items such as batteries, dry ice,
                        flammable liquids, aerosol sprays, air bags, ammunition,
                        fireworks, gasoline, lighters, lithium batteries,
                        matches, nail polish, nail polish remover,
                        nitrigen-refrigerated liquid, paint, perfume, aerosols,
                        camping stoves, radioactive materials, solvents, and
                        more.
                      </p>

                      <p>These items must ship in seperate packages.</p>

                      <div className="row hazardQuestion">
                        <div className="col-6">
                          <p>
                            Are you shipping dangerous or hazardous materials?
                          </p>
                        </div>
                        <div className="col-3 linkUnderline">
                          <a
                            href="https://www.usps.com/ship/shipping-restrictions.htm"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn more
                          </a>
                        </div>
                        <div className="col-3 linkUnderline">
                          <a
                            href="https://www.uspsdelivers.com/hazmat-shipping-safety/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            See Examples
                          </a>
                        </div>
                      </div>
                      <div className="radio-button">
                        <label>
                          <input
                            type="radio"
                            name="nondelivery"
                            value="0"
                            checked={this.state.hazmatYesNo === "0"}
                            onChange={this.handleHazmatOption}
                          />
                          <span> Yes</span>
                        </label>
                      </div>
                      <div className="radio-button">
                        <label>
                          <input
                            type="radio"
                            name="nondelivery"
                            value="1"
                            checked={this.state.hazmatYesNo === "1"}
                            onChange={this.handleHazmatOption}
                          />
                          <span> No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {this.state.hazmatYesNo === "0" ? (
                    <div className="hazmatCategory">
                      <br />
                      <label htmlFor="hazmatDropDown">Select a category</label>

                      <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggle}
                        value={this.state.hazmatType}
                        className={"dropdown-selection hazmatChevron"}
                        id="hazmat"
                      >
                        <DropdownToggle
                          id="hazmatDropDown"
                          className="btn btn-default dropdown-items-wrapper "
                        >
                          {this.hazmatESCToDesc(this.state.hazmatType)}
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.arrOfHazmatItemsInternational.map(
                            (item, keys) => (
                              <li key={item.esc}>
                                <DropdownItem
                                  onClick={(e) => this.setHazmatType(e)}
                                  value={item.esc}
                                >
                                  {item.description}
                                </DropdownItem>
                              </li>
                            )
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  <div className="row section-row">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                              <div className="form-button-container">
                                <button
                                  className="previous btn btn-outline-primary"
                                  type="button"
                                  onClick={this.handleBackButton}
                                >
                                  Back
                                </button>
                              </div>
                              {this.state.hazmatYesNo === "1" ||
                              (this.state.hazmatYesNo === "0" &&
                                this.state.hazmatType !== null &&
                                this.state.hazmatDescription !== "Select") ? (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="submit"
                                    onClick={(e) =>
                                      this.handleContinueButton(e)
                                    }
                                  >
                                    Continue
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(Hazmat);
