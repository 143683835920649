import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const TotalItemWeightPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>Total weight of all of the items you have added here.</p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Total Items Weight Icon"
      header="Total Items Weight"
      content={content}
    />
  );
};

export default TotalItemWeightPopover;
