import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const ChooseAESExemptionPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Exemption or Exclusions Legend is a notation entered on the Customs
        Declaration (PS2976-A) that indicates the basis for not filing
        electronic export information. The most common exclusion legends are
        provided in the drop down list. Any mail pieces containing any type of
        goods (per Schedule B Export Codes at
        http://www.census.gov/foreign-trade/schedules/b/ valued at more than
        $2,500 or requiring an export license under U.S. law must have a Proof
        of Filing Citation (PFC) issued by the U.S. Census Bureau, subject to
        certain exceptions.
      </p>
      <p>
        NOEEI30.37(a): Each type of goods in the shipment (according to Schedule
        B Export Codes available at
        www.census.gov/foreign-trade/schedules/index.html) is valued at $2,500
        or less
      </p>
      <p>
        Note: Customers who fail to electronically file their export information
        may be subject to civil and criminal penalties.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="AES Exemption Information Icon"
      header="Choose an AES Exemption"
      content={content}
    />
  );
};

export default ChooseAESExemptionPopover;
