import React, { useState, useEffect, useRef } from "react";
import { Popover, Modal } from "reactstrap";
import "../../customs_forms/css/popoverStyles.css";
import info from "../images/info.svg";

// Component for info icon content that displays in popovers in desktop
// mode and modals on smaller screens
const InfoPopover = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(null);

  const { targetName, ariaLabel, header, content } = props;

  const popoverTextRef = useRef(null);

  const infoIconRef = useRef(null);

  useEffect(() => {
    if (popoverOpen) {
      // Set the focus to inside the popover
      if (popoverTextRef.current) {
        popoverTextRef.current.focus({
          preventScroll: true
        });
      }
    } else if (popoverOpen === false /* Not null */) {
      // Return focus to the info icon when closing the popover
      infoIconRef.current.focus({
        preventScroll: true
      });
    }
  }, [popoverOpen]);

  const toggle = () => {
    setPopoverOpen(!popoverOpen);
  };

  return (
    <React.Fragment>
      <button
        style={{ border: "none", backgroundColor: "white" }}
        type="button"
        className="info-icon button-link"
        data-trigger="focus"
        data-toggle="popover"
        data-backdrop="static"
        tabIndex="0"
        data-original-title=""
        title=""
        aria-label={ariaLabel}
        id={targetName}
        onClick={toggle}
        ref={infoIconRef}
      >
        <img
          className="tooltip-icon"
          src={info}
          alt="More info"
          width="18"
          height="18"
        />
      </button>

      {window.innerWidth > 760 ? (
        <Popover
          placement="auto"
          trigger="legacy"
          isOpen={popoverOpen || false}
          target={targetName}
          toggle={toggle}
        >
          <div
            className="standardize-address-popover"
            style={{ margin: "7px" }}
          >
            <div className="popover-wrapper">
              <div className="popover-header">
                <button
                  type="button"
                  className="close button-link"
                  data-dismiss="popover"
                  tabIndex="0"
                  onClick={toggle}
                  ref={popoverTextRef}
                >
                  <span className="visuallyhidden">Close Popover</span>
                </button>
                <h4>{header}</h4>
              </div>
              {content}
            </div>
          </div>
        </Popover>
      ) : (
        <React.Fragment>
          <Modal isOpen={popoverOpen} toggle={toggle}>
            <div className="modal-content modal-container">
              <div className="modal-header">
                <button
                  type="button"
                  className="close button-link"
                  data-dismiss="modal"
                  tabIndex="0"
                  onClick={toggle}
                >
                  <span className="visuallyhidden">Close Modal</span>
                </button>
                <h3 className="modal-title">{header}</h3>
              </div>
              <div className="modal-body">{content}</div>
            </div>
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default InfoPopover;
