import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const AirmailMBagPopover = (props) => {
  const content = (
    <React.Fragment>
      <div className="international-shipping-option-popover">
        <div className="popover-wrapper">
          <div className="popover-header"></div>
          <p>
            Airmail M-Bags&reg; allows customers to ship a sack of printed
            matter such as books or advertising material sent to a single
            foreign addressee at a single address.
          </p>
          <p>
            Shipments below 11 pounds are subject to the 11 pound rate. Maximum
            weight is 66 pounds.
          </p>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Airmail M-Bag"
      header="Airmail M-Bag"
      content={content}
    />
  );
};

export default AirmailMBagPopover;
