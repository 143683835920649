const isDomesticDestination = (flowType) => {
  return (
    flowType === "military" ||
    flowType === "territories" ||
    flowType === "militaryToUSA" ||
    flowType === "usa"
  );
};

export default isDomesticDestination;
