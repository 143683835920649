// Get all fields needed to fully populate all pages
const getAllCartInfo = (body) => {
  let totalWeightOz = body.cnsCart.labelPkg.weightOzQty;
  if (!totalWeightOz) {
    totalWeightOz = 0;
  }
  const convertedPounds = Math.floor(totalWeightOz / 16);
  const convertedOunces = totalWeightOz - convertedPounds * 16;

  if (body.labelItems) {
    for (var i = 0; i < body.labelItems.length; i++) {
      body.labelItems[i].labelItemId = "";
      let totalWeightOzItem = body.labelItems[i].unitWeightOz;
      if (!totalWeightOzItem) {
        totalWeightOzItem = 0;
      }
      const convertedPoundsItem = Math.floor(totalWeightOzItem / 16);
      const convertedOuncesItem = totalWeightOzItem - convertedPoundsItem * 16;
      body.labelItems[i].weightPounds = convertedPoundsItem;
      body.labelItems[i].weightOunces = convertedOuncesItem;
    }
  }

  let previousState = {
    // Step 1: Flow type (and receipt checks that are added later on)
    flowType: body.cnsCart.flowType,
    hazmatType: {
      hazmatType:
        body.cnsCart.hazmatType === "null" ? null : body.cnsCart.hazmatType
    },
    receiptCheck1:
      (body.cnsCart.toNote === "domesticReceipt" ||
        body.cnsCart.toNote === "internationalReceipt") &&
      totalWeightOz === 0
        ? body.cnsCart.toNote
        : undefined,
    receiptCheck2:
      body.cnsCart.toNote === "domesticReceipt" ||
      body.cnsCart.toNote === "internationalReceipt"
        ? body.cnsCart.toNote
        : undefined,
    // Step 2: Flow type inputs
    flowTypeInputs: {
      senderZipCode: body.cnsCart.returnAddress.zip5,
      recipientZipCode: body.cnsCart.deliveryAddress.zip5,
      countryName: body.cnsCart.deliveryAddress.countryName,
      countryId: body.cnsCart.deliveryAddress.countryId,
      lightDocumentsOnly: "",
      displayZipCodes: false
    },
    // Step 3: Prohibitions (nothing needed from here)
    // Step 4: Package info
    cartInfo: {
      encodedCartId: body.cnsCart.strCnsCartId,
      batchNumber: 0
    },
    packageInfo: {
      weightPounds: convertedPounds,
      weightOunces: convertedOunces,
      totalPackageValue: body.cnsCart.labelPkg.pkgValueAmt,
      length: body.cnsCart.labelPkg.pkgLengthQty,
      width: body.cnsCart.labelPkg.pkgWidthQty,
      height: body.cnsCart.labelPkg.pkgHeightQty,
      packageNotRectangular: body.cnsCart.labelPkg.pkgGirthQty > 0,
      girth: body.cnsCart.labelPkg.pkgGirthQty
    },
    // Step 5: Service options
    serviceOptions: {
      label: body.cnsCart.productId.productClassDesc,
      serviceDesc: body.cnsCart.productId.productDesc || "",
      serviceDimensions: body.cnsCart.productId.labelDimensions,
      postOfficePriceDisplay: body.cnsCart.labelCost.ttlShipCostAmt,
      productCode: body.cnsCart.productId.productCode
    },
    // Step 6: Sender info
    senderInfo: {
      firstName: body.cnsCart.returnAddress.firstName || "",
      mi: body.cnsCart.returnAddress.middleInit || "",
      lastName: body.cnsCart.returnAddress.lastName || "",
      companyName: body.cnsCart.returnAddress.companyName || "",
      streetAddress: body.cnsCart.returnAddress.line1Addr || "",
      aptSuiteOther: body.cnsCart.returnAddress.line2Addr || "",
      city: body.cnsCart.returnAddress.cityName || "",
      state: body.cnsCart.returnAddress.stateCode,
      stateId: body.cnsCart.returnAddress.stateId,
      zipCode: body.cnsCart.returnAddress.zip5,
      phone: body.cnsCart.returnAddress.phoneNbr,
      email: body.cnsCart.returnAddress.emailAddress || "",
      referenceNumber: body.cnsCart.returnAddress.refNbr || ""
    },
    // Step 7: Recipient info
    recipientInfo: {
      firstName: body.cnsCart.deliveryAddress.firstName || "",
      mi: body.cnsCart.deliveryAddress.middleInit || "",
      lastName: body.cnsCart.deliveryAddress.lastName || "",
      companyName: body.cnsCart.deliveryAddress.companyName || "",
      shipToPOBox: false, // always false?
      streetAddress: body.cnsCart.deliveryAddress.line1Addr || "",
      address2: body.cnsCart.deliveryAddress.line2Addr || "",
      address3: body.cnsCart.deliveryAddress.line3Addr || "",
      city: body.cnsCart.deliveryAddress.cityName || "",
      state: body.cnsCart.deliveryAddress.stateCode,
      zipCode:
        body.cnsCart.deliveryAddress.postalCode ||
        body.cnsCart.deliveryAddress.zip5,
      phone: body.cnsCart.deliveryAddress.phoneNbr,
      email: body.cnsCart.deliveryAddress.emailAddress || ""
    },
    // Step 8: Non-Delivery option
    nonDeliveryOption: {
      nonDeliveryOption: body.cnsCart.labelInd.nonDelvOption
    },
    // Step 10: Package contents
    packageContents: {
      category: body.labelCustDec.contents,
      otherDescription: body.labelCustDec.contentsDesc || "",
      additionalComments: body.labelCustDec.comments || ""
    },
    // Step 11: Commercial sender
    commercialSender: {
      isCommercialSender:
        body.cnsCart.labelInd.commercialInvoiceInd === "Y" ? "1" : "0",
      referenceNumber: body.labelCustDec.importersRefNbr || "",
      commercialLicenseNumber: body.labelCustDec.licenseNbr || "",
      commercialCertificateNumber: body.labelCustDec.certificateNbr || "",
      commercialInvoiceNumber: body.labelCustDec.invoiceNbr || ""
    },
    // Step 12: Item entry
    itemEntry: {
      globalKey: 1, // may need to change this
      itemsList: body.labelItems
    },
    // Step 13: AES exemptions
    aesExemptions: {
      eelCode: isInAesList(body.labelCustDec.eelCode, body.cnsCart.flowType)
        ? body.labelCustDec.eelCode
        : body.labelCustDec.pfcCode
        ? "I have an export license"
        : "unknown",
      aesManualEntry: !isInAesList(
        body.labelCustDec.eelCode,
        body.cnsCart.flowType
      )
        ? body.labelCustDec.eelCode || ""
        : "",
      aesItn: body.labelCustDec.pfcCode || ""
    }
  };
  return previousState;
};

const isInAesList = (eelCode, flowType) => {
  let allowedAesOptions = [];
  switch (flowType) {
    case "Canada":
      allowedAesOptions.push("NOEEI 30.36");
      break;
    case "military":
      allowedAesOptions.push("NOEEI 30.37(a)");
      allowedAesOptions.push("NOEEI 30.37(h)");
      allowedAesOptions.push("NOEEI 30.37(w)");
      break;
    case "other":
    case "territories":
    case "usa":
    case "militaryToUSA":
      allowedAesOptions.push("NOEEI 30.37(a)");
      allowedAesOptions.push("NOEEI 30.37(h)");
      break;
    default:
      break;
  }
  return allowedAesOptions.includes(eelCode);
};

export default getAllCartInfo;
