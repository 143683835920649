import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const PriorityMailPopover = (props) => {
  const content = (
    <React.Fragment>
      <div className="domestic-shipping-option-popover">
        <div className="popover-wrapper">
          <div className="popover-header"></div>
          <p>
            Use Priority Mail<sup>®</sup> to send merchandise to over 190
            countries worldwide. Your shipments are economical and receive
            priority handling to all destinations.
          </p>
          <p>
            Priority Mail<sup>®</sup> features:
          </p>
          <ul>
            <li>
              <p>USPS Tracking</p>
            </li>
            <li>
              <p>Free Package Pickup</p>
            </li>
            <li>
              <p>Insurance Included</p>
            </li>
            <li>
              <p>Free Shipping Supplies</p>
            </li>
          </ul>
          <p>USPS Customs Forms Envelope (2976-E) is required.</p>
          <p>
            Available online at The Postal Store<sup>®</sup> or at your local
            Post Office<sup>™</sup>.
          </p>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Priority Mail"
      header="Priority Mail"
      content={content}
    />
  );
};

export default PriorityMailPopover;
