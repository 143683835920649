import React, { useState } from "react";
import { Modal } from "reactstrap";
import info from "../images/info.svg";

// Component for info icon content that displays in modals in both
// desktop mode and on smaller screens
const InfoPopover = (props) => {
  const [modal, setModal] = useState(false);

  const { targetName, ariaLabel, header, content } = props;

  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  };

  return (
    <React.Fragment>
      <button
        style={{ border: "none", backgroundColor: "white" }}
        type="button"
        className="info-icon button-link"
        data-trigger="focus"
        data-toggle="modal"
        data-backdrop="static"
        tabIndex="0"
        data-original-title=""
        title=""
        aria-label={ariaLabel}
        id={targetName}
        onClick={toggle}
      >
        <img
          className="tooltip-icon"
          src={info}
          alt="More info"
          width="18"
          height="18"
        />
      </button>

      <Modal isOpen={modal} toggle={toggle}>
        <div className="modal-content modal-container">
          <div className="modal-header">
            <button
              type="button"
              className="close button-link"
              data-dismiss="modal"
              tabIndex="0"
              onClick={toggle}
            >
              <span className="visuallyhidden">Close Modal</span>
            </button>
            <h3 className="modal-title">{header}</h3>
          </div>
          <div className="modal-body">{content}</div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default InfoPopover;
