import React from "react";
import InfoPopover from "./InfoPopover";

const DescriptionPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>Required if "Other" is selected</p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Description for Other Information Icon"
      header="Description for Other"
      content={content}
    />
  );
};

export default DescriptionPopover;
