import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import axios from "axios";

import Autocomplete from "../Subcomponents/Autocomplete";
import CategoryLists from "../Subcomponents/CategoryLists";
import InputFields from "../Subcomponents/InputFields";
import HsTariffNotFound from "./HsTariffNotFound";

const LookupHsTariffCode = (props) => {
  const {
    // Modal controls
    isOpen,
    toggle,
    // Props controlling input fields
    itemIndex,
    item,
    maxLengths,
    handleItemEntry,
    updateHsTariff,
    hsTariffMandatory,
    setCanOmitHsTariff,
    shipToCountryCode,
    handleValueModal,
    handleWeightModal,
    // Other required props
    toggleLoadingBar,
    countryList,
    lookupCodes,
    flowType,
    flowTypeOrReceipt,
    checkForProhibitions
  } = props;

  // State that contains all errors for TLC fields
  const [tlcErrors, setTlcErrors] = useState({
    itemDesc: "",
    itemQty: "",
    unitValue: "",
    weightPounds: ""
  });

  // State that determines if an error message should be focused via the
  // keyboard
  const [focusOnError, setFocusOnError] = useState(false);

  // Flags that determine if the item value or weight is required
  const [valueRequired, setValueRequired] = useState(true);
  const [weightRequired, setWeightRequired] = useState(true);

  // State for displaying the HS tariff not found modal
  const [displayHsTariffNotFoundModal, setDisplayHsTariffNotFoundModal] =
    useState(false);

  // Focus on the first error present
  useEffect(() => {
    if (focusOnError) {
      if (tlcErrors.itemDesc) {
        document.getElementById("itemDescTlc-a11y").focus();
      } else if (tlcErrors.itemQty) {
        document.getElementById("itemQtyTlc-a11y").focus();
      } else if (tlcErrors.unitValue) {
        document.getElementById("unitValueTlc-a11y").focus();
      } else if (tlcErrors.weightPounds) {
        document.getElementById("weightPoundsTlc-a11y").focus();
      }
      setFocusOnError(false);
    }
  }, [focusOnError, tlcErrors]);

  // Determine if the item value or weight is required
  // (depends on the flow type and whether this is a receipt)
  useEffect(() => {
    setValueRequired(
      flowTypeOrReceipt === "Canada" ||
        flowTypeOrReceipt === "other" ||
        flowTypeOrReceipt === "internationalReceipt"
    );
    setWeightRequired(
      flowTypeOrReceipt === "military" ||
        flowTypeOrReceipt === "territories" ||
        flowTypeOrReceipt === "usa" ||
        flowTypeOrReceipt === "militaryToUSA" ||
        flowTypeOrReceipt === "Canada" ||
        flowTypeOrReceipt === "other"
    );
  }, [flowTypeOrReceipt]);

  // Event handler for all fields present both within and outside this modal
  const handleFields = (event) => {
    let name = event.currentTarget.name;
    const value = event.currentTarget.value;
    // Remove the "Tlc" from the input field name (the last three characters)
    // to find the name of the associated error field, if present,
    // and the equivalent name of the input outside this modal
    name = name.substring(0, name.length - 3);
    let errorName = name;
    // The weight in pounds and weight in ounces fields both use the same error
    // (weightPounds)
    if (name === "weightOunces") {
      errorName = "weightPounds";
    }
    // Remove the error message for this input field, if present
    if (Object.hasOwn(tlcErrors, errorName)) {
      setTlcErrors((prevState) => {
        return {
          ...prevState,
          [errorName]: ""
        };
      });
    }
    // Since the user modified a required input field, reset the
    // flag that determines if an HS tariff is required
    setCanOmitHsTariff(false, itemIndex);
    // Update the value of the field
    handleItemEntry(name, value, itemIndex);
    // Reset the HS tariff field since changing any of these inputs may change
    // the HS tariff
    updateHsTariff(itemIndex, "");
  };

  // Event handler for all TLC fields exclusive to this modal
  const handleTlcFields = (event) => {
    const name = event.currentTarget.name;
    let value = event.currentTarget.value;
    // Remove any non-numeric characters from the length, width, and height fields
    if (
      name === "tlcProductLength" ||
      name === "tlcProductWidth" ||
      name === "tlcProductHeight"
    ) {
      value = value.replaceAll(/\D/g, "");
    }
    // If the category is changed, the subcategory should be reset
    if (name === "tlcCategory" && value !== item.tlcCategory) {
      handleItemEntry("tlcSubcategory", "", itemIndex);
    }
    handleItemEntry(name, value, itemIndex);
    // Reset the HS tariff field since changing any of these inputs may change
    // the HS tariff
    updateHsTariff(itemIndex, "");
  };

  const classifyItem = () => {
    if (areInputsValid()) {
      toggleLoadingBar(true);
      axios
        .post("/CFOApplication/tlc/classify", {
          item: {
            id: "1",
            amount: item.unitValue,
            brand: item.tlcBrand,
            category: item.tlcCategory,
            description: item.itemDesc,
            detail: item.tlcItemDetail,
            language_code: "EN",
            subcategory: item.tlcSubcategory,
            upc_code: item.tlcUpcCode
          },
          ship_to_country: flowType !== "military" ? shipToCountryCode : null
        })
        .then((response) => {
          toggleLoadingBar(false);
          if (
            response?.data?.classified_code?.length >= 6 &&
            !response.data.error_message
          ) {
            updateHsTariff(itemIndex, response.data.classified_code);
            toggle(null, null);
            checkForProhibitions(itemIndex);
          } else {
            setDisplayHsTariffNotFoundModal(true);
          }
        })
        .catch((error) => {
          console.log(error);
          toggleLoadingBar(false);
          setDisplayHsTariffNotFoundModal(true);
        });
    } else {
      setFocusOnError(true);
    }
  };

  const areInputsValid = () => {
    let errorFound = false;
    if (!item.itemDesc || item.itemDesc.length < 2) {
      errorFound = true;
      setTlcErrors((prevState) => {
        return {
          ...prevState,
          itemDesc: "Please enter a description of this item."
        };
      });
    }
    if (item.itemQty < 1) {
      errorFound = true;
      setTlcErrors((prevState) => {
        return {
          ...prevState,
          itemQty: "Please enter the quantity of this item."
        };
      });
    }
    if (valueRequired && !(item.unitValue > 0)) {
      errorFound = true;
      setTlcErrors((prevState) => {
        return {
          ...prevState,
          unitValue: "Please enter the total value of this item."
        };
      });
    }
    if (weightRequired && item.weightPounds < 1 && item.weightOunces < 1) {
      errorFound = true;
      setTlcErrors((prevState) => {
        return {
          ...prevState,
          weightPounds: "Please enter the total weight of this item."
        };
      });
    }
    return !errorFound;
  };

  return (
    <Modal id="modal-tlc" isOpen={isOpen}>
      <div className="modal-content modal-container">
        <div className="modal-header">
          <button
            type="button"
            className="close button-link"
            id="close"
            data-dismiss="modal"
            tabIndex="0"
            onClick={(e) => {
              setTlcErrors({
                itemDesc: "",
                itemQty: "",
                unitValue: "",
                weightPounds: ""
              });
              toggle(e, null);
            }}
          >
            <span className="visuallyhidden">Close Modal</span>
          </button>
          <h3 className="modal-title">
            Do you know the HS Tariff Code for this item?
          </h3>
        </div>
        <div className="modal-body">
          <div className="body-content">
            <p id="tlc-lookup-paragraph">
              HS code is short for Harmonized Commodity Description and Coding
              System. It refers to the single, universal and global
              classification system for most traded goods and products. It is
              used to classify products upon export and to calculate applicable
              taxes and duties.
            </p>
            <h4>Enter information</h4>
            <p id="tlc-lookup-add-more-details" className="pre-form-error">
              Please add more detail about your item. Consider including what it
              is, the brand, color, material
            </p>
            <div className="row">
              <InputFields
                outerDivClassList="col-12"
                isRequired={true}
                errorMessage={tlcErrors.itemDesc}
                includeAssistiveError={true}
                inputId="itemDescTlc"
                inputClassList="form-control"
                inputType="text"
                labelText="Item Description"
                inputMaxLength={maxLengths.itemDesc}
                inputShowLength={true}
                inputValue={item.itemDesc}
                inputOnChange={handleFields}
              />
            </div>
            <div className="row">
              <InputFields
                outerDivClassList="col-8"
                inputId="tlcCategory"
                inputClassList="form-control dropdown"
                labelText="Select a Category"
                inputValue={item.tlcCategory}
                inputOnChange={handleTlcFields}
                elementType="select"
                optionsList={CategoryLists.getCategories}
              />
            </div>
            {item.tlcCategory && (
              <div className="row">
                <InputFields
                  outerDivClassList="col-8"
                  inputId="tlcSubcategory"
                  inputClassList="form-control dropdown"
                  labelText="Select a sub-category"
                  inputValue={item.tlcSubcategory}
                  inputOnChange={handleTlcFields}
                  elementType="select"
                  optionsList={CategoryLists.getSubcategories(item.tlcCategory)}
                />
              </div>
            )}
            <h4 id="tlc-lookup-tell-us-more">Tell us more about this item</h4>
            <div className="row">
              <InputFields
                outerDivClassList="col-3 tlc-quantity-input"
                isRequired={true}
                errorMessage={tlcErrors.itemQty}
                includeAssistiveError={true}
                inputId="itemQtyTlc"
                inputClassList="form-control"
                inputType="text"
                labelText="Total Quantity"
                inputMaxLength={maxLengths.itemQty}
                inputValue={item.itemQty}
                inputOnChange={handleFields}
              />
              <div className="tlc-quantity-btn-wrapper">
                <button
                  className="btn btn-outline-primary tlc-quantity-btn"
                  onClick={() => {
                    setTlcErrors((prevState) => {
                      return {
                        ...prevState,
                        itemQty: ""
                      };
                    });
                    const newItemQty = Number(item.itemQty) + 1;
                    handleItemEntry(
                      "itemQty",
                      String(newItemQty).length <= Number(maxLengths.itemQty)
                        ? String(newItemQty)
                        : item.itemQty,
                      itemIndex
                    );
                  }}
                >
                  <strong>+</strong>
                </button>
              </div>
              <div className="tlc-quantity-btn-wrapper">
                <button
                  className="btn btn-outline-primary tlc-quantity-btn"
                  onClick={() => {
                    setTlcErrors((prevState) => {
                      return {
                        ...prevState,
                        itemQty: ""
                      };
                    });
                    const itemQty = Number(item.itemQty);
                    handleItemEntry(
                      "itemQty",
                      String(itemQty >= 1 ? itemQty - 1 : 0),
                      itemIndex
                    );
                  }}
                >
                  <strong>-</strong>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div
                  id="tlc-lookup-country-of-origin-wrapper"
                  className="form-group-alt"
                >
                  <div className="inline-label-wrapper">
                    <label>Country of Origin</label>
                  </div>
                  <Autocomplete
                    userInput={item.countryOfOrigin}
                    suggestionList={countryList}
                    parentCallback={(countryName) => {
                      handleItemEntry(
                        "countryOfOrigin",
                        countryName,
                        itemIndex
                      );
                    }}
                    type="text"
                    name="countryOfOriginTlc"
                    className="form-control country-select"
                    id="countryOfOriginTlc"
                    hidePlaceholder={true}
                    source="itemEntry"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <InputFields
                outerDivClassList="col-6"
                isRequired={valueRequired}
                errorMessage={tlcErrors.unitValue}
                includeAssistiveError={true}
                inputId="unitValueTlc"
                inputDivClassList="field-wrapper with-prefix prefix-is-1-chars"
                inputClassList="form-control"
                inputType="text"
                labelText="Total Item Value"
                inputMaxLength={maxLengths.unitValue}
                inputPlaceholder="00.00 USD"
                inputValue={item.unitValue}
                inputOnChange={handleFields}
                inputOnBlur={handleValueModal}
                fieldPrefix="$"
                fieldPrefixClassList="tlc-units"
              />
              <InputFields
                outerDivClassList="col-6"
                inputId="tlcItemDetail"
                inputClassList="form-control"
                inputType="text"
                labelText="Item Detail"
                inputMaxLength={maxLengths.tlcItemDetail}
                inputValue={item.tlcItemDetail}
                inputOnChange={handleTlcFields}
              />
            </div>
            <div className="row">
              <InputFields
                outerDivClassList="col-6"
                inputId="tlcItemColor"
                inputClassList="form-control"
                inputType="text"
                labelText="Item Color"
                inputMaxLength={maxLengths.tlcItemColor}
                inputValue={item.tlcItemColor}
                inputOnChange={handleTlcFields}
              />
              <InputFields
                outerDivClassList="col-6"
                inputId="tlcBrand"
                inputClassList="form-control"
                inputType="text"
                labelText="Brand"
                inputMaxLength={maxLengths.tlcBrand}
                inputValue={item.tlcBrand}
                inputOnChange={handleTlcFields}
              />
            </div>
            <div className="row">
              <InputFields
                outerDivClassList="col-6"
                inputId="tlcUpcCode"
                inputClassList="form-control"
                inputType="text"
                labelText="UPC Code"
                inputMaxLength={maxLengths.tlcUpcCode}
                inputValue={item.tlcUpcCode}
                inputOnChange={handleTlcFields}
              />
            </div>
            <div className="row">
              <InputFields
                outerDivClassList="col-4"
                isRequired={weightRequired}
                errorMessage={tlcErrors.weightPounds}
                includeAssistiveError={true}
                inputId="weightPoundsTlc"
                inputDivClassList="field-wrapper with-suffix suffix-is-3-chars"
                inputClassList="form-control"
                inputType="text"
                labelText="Total Weight"
                inputMaxLength={maxLengths.weightPounds}
                inputValue={item.weightPounds}
                inputOnChange={handleFields}
                inputOnBlur={handleWeightModal}
                fieldSuffix="lbs"
                fieldSuffixClassList="tlc-units"
              />
              <InputFields
                outerDivClassList="col-4"
                errorMessage={tlcErrors.weightPounds}
                includeAssistiveError={true}
                inputId="weightOuncesTlc"
                inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                inputClassList="form-control"
                inputType="text"
                labelText=""
                inputMaxLength={maxLengths.weightOunces}
                inputValue={item.weightOunces}
                inputOnChange={handleFields}
                inputOnBlur={handleWeightModal}
                fieldSuffix="oz"
                fieldSuffixClassList="tlc-units"
              />
            </div>
            <p id="tlc-lookup-dimensions-text">
              Dimensions of Product in Inches
            </p>
            <div className="row">
              <InputFields
                outerDivClassList="col-4"
                inputId="tlcProductLength"
                inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                inputClassList="form-control"
                inputType="text"
                labelText="Length"
                inputMaxLength={maxLengths.tlcProductLength}
                inputValue={item.tlcProductLength}
                inputOnChange={handleTlcFields}
                fieldSuffix="in"
                fieldSuffixClassList="tlc-units"
              />
              <InputFields
                outerDivClassList="col-4"
                inputId="tlcProductWidth"
                inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                inputClassList="form-control"
                inputType="text"
                labelText="Width"
                inputMaxLength={maxLengths.tlcProductWidth}
                inputValue={item.tlcProductWidth}
                inputOnChange={handleTlcFields}
                fieldSuffix="in"
                fieldSuffixClassList="tlc-units"
              />
              <InputFields
                outerDivClassList="col-4"
                inputId="tlcProductHeight"
                inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                inputClassList="form-control"
                inputType="text"
                labelText="Height"
                inputMaxLength={maxLengths.tlcProductHeight}
                inputValue={item.tlcProductHeight}
                inputOnChange={handleTlcFields}
                fieldSuffix="in"
                fieldSuffixClassList="tlc-units"
              />
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-button-container">
                  <button
                    className="btn btn-primary"
                    id="submit-hs-tariff-info-button"
                    type="submit"
                    onClick={classifyItem}
                  >
                    <strong id="submit-hs-tariff-info-text">Continue</strong>
                  </button>
                </div>
              </div>
            </div>
            <HsTariffNotFound
              isOpen={displayHsTariffNotFoundModal}
              toggle={setDisplayHsTariffNotFoundModal}
              updateHsTariff={updateHsTariff}
              hsTariffMandatory={hsTariffMandatory}
              setCanOmitHsTariff={setCanOmitHsTariff}
              checkForProhibitions={checkForProhibitions}
              toggleParentModal={toggle}
              itemIndex={itemIndex}
              lookupCodes={lookupCodes}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LookupHsTariffCode;
