import React from "react";
import { withRouter } from "react-router-dom";
// Images
import caretRight from "../customs_forms/images/caret-right.svg";
import getStarted1 from "../customs_forms/images/get-started1.svg";
import getStarted2 from "../customs_forms/images/get-started2.svg";
import getStarted3 from "../customs_forms/images/icon_eu_no_gray.png";
import getStarted4 from "../customs_forms/images/get-started4.svg";
import LoggedInNav from "./LoggedInNav";
class Index extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.props.stepNumberCallback(0);
    this.props.setPageErrors([]);
    this.state = {
      IsLoggedIn: false
    };
  }

  handleCreateButton = () => {
    this.props.history.push("/flow-type");
  };

  componentDidMount = () => {
    this.props.loginCheck();
  };

  render() {
    return (
      <React.Fragment>
        <div id="cfo-wrapper">
          <div id="inner">
            <main className="section" id="customs-form-2019-main">
              <section className="section" id="app-form">
                <div className="container">
                  <form>
                    <div className="row section-row align-items-center">
                      <div className="col-lg-4">
                        <h1>Need a Customs Form?</h1>
                      </div>
                      <div className="col-lg-8">
                        {" "}
                        {this.props.loggedIn ? (
                          <LoggedInNav />
                        ) : (
                          <ul className="title-links">
                            <li>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                              >
                                FAQs{" "}
                                <span>
                                  <img src={caretRight} alt="open link" />
                                </span>
                              </a>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="row section-row">
                      <div className="col-12">
                        <p>
                          The form you need depends on the service you're using
                          and the value of your shipment.
                          <br />
                          Let us guide you through the process. It's quick and
                          easy!
                        </p>
                        <p className="note">
                          <strong>Note:</strong> A customs form is not required
                          if you are shipping within the United States to
                          locations other than a U.S. Territory or a military
                          base.
                        </p>
                        <h2>Here is what you need to get started</h2>
                      </div>
                    </div>
                    <div className="row section-row">
                      <div className="col-lg-7">
                        <div className="row">
                          <div className="col-lg-6 py-3 text-center">
                            <div className="guide-image">
                              <span className="gi-outer">
                                <span className="gi-inner">
                                  <img
                                    src={getStarted1}
                                    alt="Total Package Weight"
                                  />
                                </span>
                              </span>
                            </div>
                            <h3>Total Package Weight</h3>
                            <p>
                              The total weight of your package so we can provide
                              you with accurate pricing.
                            </p>
                          </div>
                          <div className="col-lg-6 py-3 text-center">
                            <div className="guide-image">
                              <span className="gi-outer">
                                <span className="gi-inner">
                                  <img
                                    src={getStarted2}
                                    alt="Package Dimensions"
                                  />
                                </span>
                              </span>
                            </div>
                            <h3>
                              Package Dimensions{" "}
                              <span className="note">(optional)</span>
                            </h3>
                            <p>
                              If you know the measurements of your package
                              (length, width, height), we can provide you with
                              lowest cost and fastest delivery shipping
                              recommendations.
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 py-3 text-center">
                            <div className="guide-image">
                              <span className="gi-outer">
                                <span className="gi-inner">
                                  <img
                                    id="european-union-image"
                                    src={getStarted3}
                                    alt="Value of Each Item in Package"
                                  />
                                </span>
                              </span>
                            </div>
                            <h3>Item Information</h3>
                            <p>
                              Shipments to the EU* will require an HS Tariff
                              Code based on item description. If you do not have
                              one, you will be able to look it up as you add
                              items to your shipment.
                            </p>
                          </div>
                          <div className="col-lg-6 py-3 text-center">
                            <div className="guide-image">
                              <span className="gi-outer">
                                <span className="gi-inner">
                                  <img
                                    src={getStarted4}
                                    alt="Recipient Address"
                                  />
                                </span>
                              </span>
                            </div>
                            <h3>Sender &amp; Recipient Address</h3>
                            <p>
                              Your full name and address along with the full
                              name and address for the person or business to
                              whom the shipment will be sent.
                            </p>
                          </div>
                        </div>
                      </div>
                      {!this.props.loggedIn ? (
                        <div className="col-lg-5 mt-lg-0 mt-3">
                          <div className="sidebar-box">
                            <h3>Want to save time creating customs forms?</h3>
                            <p className="note">
                              <strong>Create a USPS.com Account to...</strong>
                            </p>
                            <ul className="list-group bullet-list">
                              <li>View Customs Form History</li>
                              <li>Set Preferences</li>
                              <li>Save Addresses</li>
                              <li>Auto-Populate Information</li>
                              <li>Reprint Created Customs Forms</li>
                            </ul>
                            <p>
                              <a
                                href={
                                  this.props.lookupCodes &&
                                  this.props.lookupCodes["reg.signInUrl"]
                                    ? this.props.lookupCodes["reg.signInUrl"]
                                    : "https://reg.usps.com/entreg/LoginAction_input?app=CNS&appURL=https://cfo.usps.com/cfo-web/labelInformation.html"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-outline-primary"
                              >
                                Sign Up Now
                              </a>
                            </p>
                            <p>
                              Already have an account?{" "}
                              <a
                                href={
                                  this.props.lookupCodes &&
                                  this.props.lookupCodes["reg.signInUrl"]
                                    ? this.props.lookupCodes["reg.signInUrl"]
                                    : "https://reg.usps.com/entreg/LoginAction_input?app=CNS&appURL=https://cfo.usps.com/cfo-web/labelInformation.html"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Sign In
                              </a>
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="row section-row">
                      <div className="col-12">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={this.handleCreateButton}
                        >
                          Create a Customs Form
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="col-lg-7">
                    <p className="european-union-text">
                      *The list of EU countries and countries that follow EU
                      regulations are: (Austria, Belgium, Bulgaria, Croatia,
                      Republic of Cyprus, Czech Republic, Denmark, Estonia,
                      Finland, France, Germany, Greece, Hungary, Ireland, Italy,
                      Latvia, Lithuania, Luxembourg, Malta, Netherlands, Norway,
                      Poland, Portugal, Romania, Slovakia, Slovenia, Spain,
                      Sweden, and Switzerland)
                    </p>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Index);
