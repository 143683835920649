import axios from "axios";
import ReactGA from "react-ga";

export function getAlert() {
  alert("getAlert from Child");
}

export function setUserPreferencePODataLayer(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "application",
    page: "cfo-preferences",
    application: {
      element: "Save - Package Options",
      flatRate: data.flatRate,
      packageWeight: data.packageWeight,
      packageDimensions: data.packageDimensions,
      serviceType: data.serviceType,
      packageType: data.packageType,
      userAction: "interaction"
    }
  });
}
export function setUserPreferenceSNDataLayer(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "application",
    page: "cfo-preferences",
    application: {
      element: "Save - Shipment Notifications",
      notify: data.iWantTrackingNote,
      updates: [
        {
          email: data.expectedDeliveryUpdates,
          text: data.expectedDeliveryUpdates,
          update: "Expected Delivery Updates"
        },
        {
          email: data.dayOfDeliveryUpdates,
          text: data.dayOfDeliveryUpdates,
          update: "Day of Delivery Updates"
        },
        {
          email: data.packageDelivered,
          text: data.packageDelivered,
          update: "Package Delivered"
        },
        {
          email: data.availableForPickup,
          text: data.availableForPickup,
          update: "Available for Pickup"
        },
        {
          email: data.deliveryExceptionUpdates,
          text: data.deliveryExceptionUpdates,
          update: "Delivery Exception Update"
        },
        {
          email: data.allUpdates,
          text: data.allUpdates,
          update: "Package In-Transit Updates"
        }
      ],
      userAction: "interaction"
    }
  });
}

export function setSearchShippingHistoryDataLayer() {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );

  window.dataLayer.push({
    event: "application",
    page: "cfo-history",
    application: {
      element: "Label Link",
      userAction: "interaction"
    }
  });
}

export function setUserPreferencePrintDataLayer(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "application",
    page: "cfo-preferences",
    application: {
      element: "Save - Print",
      printSettings: data.print,
      userAction: "interaction"
    }
  });
}

export function setUserPreferenceRNDataLayer() {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "application",
    page: "cfo-preferences",
    application: {
      element: "Save - Reference Number",
      userAction: "interaction"
    }
  });
}

export function setUserPreferenceRADataLayer(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "application",
    page: "cfo-preferences",
    application: {
      element: "Save - Return Address",
      returnZIPCode: data.zipcode,
      saveToAddressBook: data.updateAddress,
      userAction: "interaction"
    }
  });
}
export function setUserInformation(callback, eventType, data) {
  axios
    .get("/go/cnsrest/security")
    .then((data) => {})
    .catch((error) => {})
    .finally(function () {
      if (typeof callback === "function") {
        callback(eventType)(data);
      }
    });
}

export function setDataLayerInfo(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));

  window.dataLayer.push({
    event: data.event,
    page: data.page,
    loginStatus: accountInfo.loggedIn,
    accountType: accountInfo.accountType,
    accountZip: accountInfo.accountZip,
    customerID: accountInfo.customerID,
    accountStartDate: accountInfo.accountStartDate,
    application: {
      event: "Application",
      element: data.element,
      userAction: "interaction"
    }
  });
}

export function setDestinationPricePackageDataLayerInfo(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));

  window.dataLayer.push({
    event: data.event,
    page: data.page,
    loginStatus: accountInfo.loggedIn,
    accountType: accountInfo.accountType,
    accountZip: accountInfo.accountZip,
    customerID: accountInfo.customerID,
    accountStartDate: accountInfo.accountStartDate,
    application: {
      event: "Application",
      element: data.element,
      selection: data.selection,
      userAction: "interaction"
    }
  });
}
export function setSummaryToDataLayer(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );

  window.dataLayer.push({
    event: "application",
    page: data.page,
    application: {
      element: data.element,
      userAction: "interaction",
      package: {
        weight: data.weight,
        value: data.value
      },
      shipping: {
        service: data.service,
        option: data.option,
        size: data.size,
        price: data.price
      },
      senderAddress: data.senderAddress,
      recipientAddress: data.recipientAddress,
      nondeliverOption: data.nondeliverOption,
      contents: data.contentsPkg[0],
      exportInfo: data.exportInfo
    }
  });
}
// NOT USING UNTIL LunaMetrics CLARIFIES
export function createLabelDataLayer(location) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "CNSlabel",
    attribute: {
      category: "Click-N-Ship Interactions",
      action: "Create a Label",
      label: location
    }
  });
}
export function shippingHistoryAdvanceSearchDataLayer(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "application",
    page: "cfo-history",
    application: {
      element: data.element,
      columns: data.columns,
      userAction: "interaction"
    }
  });
}

export function shipingHistorySelectedLableDataLayer(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "application",
    page: "cfo-history",
    application: {
      element: data.element,
      numSelections: data.numberOfSelectedLabels,
      userAction: "interaction"
    }
  });
}
export function flowType_InfoToDataLayer(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));
  window.dataLayer.push({
    event: "vpvCFO",
    page: "cfo-step-1-select-destination-where-shipping",

    loginStatus: accountInfo.loggedIn,
    accountType: accountInfo.accountType,
    accountZip: accountInfo.accountZip,
    customerID: accountInfo.customerID,
    accountStartDate: accountInfo.accountStartDate,
    application: {
      element: data.element,
      selection: data.selection,
      userAction: "interaction"
    }
  });
}
export function prohibitions_InfoToDataLayer(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));

  window.dataLayer.push({
    event: "vpvCFO",
    page: data.prohibitions,
    loginStatus: accountInfo.loggedIn,
    accountType: accountInfo.accountType,
    accountZip: accountInfo.accountZip,
    customerID: accountInfo.customerID,
    accountStartDate: accountInfo.accountStartDate
  });
}

export function packageInfo_InfoToDataLayer(data) {
  const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );

  window.dataLayer.push({
    event: "vpvCFO",
    page: data.packageInfo,
    loginStatus: accountInfo.loggedIn,
    accountType: accountInfo.accountType,
    accountZip: accountInfo.accountZip,
    customerID: accountInfo.customerID,
    accountStartDate: accountInfo.accountStartDate
  });
}
export function serviceOptions_InfoToDataLayer(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));

  window.dataLayer.push({
    event: "vpvCFO",
    page: data.serviceOptions,
    loginStatus: accountInfo.loggedIn,
    accountType: accountInfo.accountType,
    accountZip: accountInfo.accountZip,
    customerID: accountInfo.customerID,
    accountStartDate: accountInfo.accountStartDate
  });
}
export function senderInfo_InfoToDataLayer(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));

  window.dataLayer.push({
    event: "vpvCFO",
    page: data.senderInfo,
    loginStatus: accountInfo.loggedIn,
    accountType: accountInfo.accountType,
    accountZip: accountInfo.accountZip,
    customerID: accountInfo.customerID,
    accountStartDate: accountInfo.accountStartDate
  });
}
export function recipientInfo_InfoToDataLayer(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));

  window.dataLayer.push({
    event: "vpvCFO",
    page: data.recipientInfo,
    loginStatus: accountInfo.loggedIn,
    accountType: accountInfo.accountType,
    accountZip: accountInfo.accountZip,
    customerID: accountInfo.customerID,
    accountStartDate: accountInfo.accountStartDate
  });
}
export function shippingHistory_Search(searchFilterSelection) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "CustomsFormsReact",
    category: "CustomsFormsReact Interactions",
    action: "Search",
    label: searchFilterSelection,
    attribute: {
      category: "CustomsFormsReact Interactions",
      action: "Search",
      label: searchFilterSelection
    }
  });
}
export function userInformation(data) {
  ReactGA.initialize("GTM-MVCC8H", { standardImplementation: true });
  ReactGA.pageview(
    window.location.pathname === "/" ||
      window.location.pathname === "/index.shtml"
      ? "labelInformation.shtml"
      : window.location.pathname + ".shtml" + window.location.search
  );
  window.dataLayer.push({
    event: "vpvCFO",
    page: "app",
    loginStatus: data.userData.loggedIn,
    accountType: data.userData.accountType,
    accountZip: data.userData.accountZip,
    customerID: data.userData.customerID
  });
}
