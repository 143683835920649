import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const ReferenceNumberPopover = (props) => {
  const content = (
    <React.Fragment>
      {/* <div className="reference-number-popover">
        <div className="popover-wrapper">
          <div className="popover-header">
            <button
              type="button"
              data-dismiss="popover"
              tabIndex="0"
            >
              {" "}
              <span className="visuallyhidden">Close Popover</span>
            </button>
          </div> */}
      <p>
        This number is for your reference only. You may enter up to 10
        characters.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Sender's Custom Reference Number Icon"
      header="Sender's Custom Reference Number"
      content={content}
    />
  );
};

export default ReferenceNumberPopover;
