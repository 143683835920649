import React from "react";
import AssistiveError from "./AssistiveError";

const InputFields = (props) => {
  const {
    // Always required (won't get errors if missing,
    // but styling and accessibility may be affected)
    outerDivClassList,
    inputId,
    labelText,
    inputClassList,
    inputType,
    inputValue,
    inputOnChange,
    // Only needed when the input field is required and/or has error validation
    isRequired,
    errorMessage,
    includeAssistiveError,
    // Only needed for popovers/modals
    infoIcon,
    // Only needed for <select> and <textarea> elements
    elementType,
    // Only needed for <select> elements
    optionsList,
    // Only needed if an <input> element is directly wrapped by an outer <div>
    inputDivClassList,
    // Only needed when the field has units (ex. "$", "lbs")
    fieldPrefix,
    fieldPrefixClassList,
    fieldSuffix,
    fieldSuffixClassList,
    // Only needed for certain fields
    labelClassList,
    inputPlaceholder,
    inputMaxLength,
    inputShowLength,
    inputOnBlur,
    // Only needed when the input field cannot be edited
    inputReadOnly
  } = props;

  return (
    <div className={outerDivClassList}>
      <div className={"form-group" + (errorMessage ? " has-error" : "")}>
        <div className={inputShowLength ? "row" : ""}>
          <div
            className={
              "inline-label-wrapper" + (inputShowLength ? " col-8" : "")
            }
          >
            {includeAssistiveError ? (
              <AssistiveError inputId={inputId} errorMessage={errorMessage} />
            ) : null}
            <label
              className={
                (labelClassList ? labelClassList + " " : "") +
                ((isRequired ? "is-required " : "") +
                  (errorMessage ? "error" : ""))
              }
              htmlFor={inputId}
            >
              {labelText}
              {infoIcon ? (
                <React.Fragment> {infoIcon}</React.Fragment>
              ) : (
                <React.Fragment />
              )}
            </label>
          </div>
          {inputShowLength && (
            <div className="col-4 field-length">
              {inputValue.length} / {inputMaxLength}
            </div>
          )}
        </div>
        {elementType === "select" ? (
          <select
            name={inputId}
            id={inputId}
            className={inputClassList}
            value={inputValue}
            onChange={inputOnChange}
          >
            {optionsList()}
          </select>
        ) : (
          <div className={inputDivClassList}>
            {fieldPrefix ? (
              <span
                className={
                  "field-icon field-prefix" +
                  (fieldPrefixClassList ? " " + fieldPrefixClassList : "")
                }
              >
                {fieldPrefix}
              </span>
            ) : null}
            <input
              name={inputId}
              id={inputId}
              className={inputClassList}
              required={isRequired}
              type={inputType}
              placeholder={inputPlaceholder}
              maxLength={inputMaxLength}
              value={inputValue}
              onChange={inputOnChange}
              readOnly={inputReadOnly}
              onBlur={inputOnBlur}
            />
            {fieldSuffix ? (
              <span
                className={
                  "field-icon field-suffix" +
                  (fieldSuffixClassList ? " " + fieldSuffixClassList : "")
                }
              >
                {fieldSuffix}
              </span>
            ) : null}
          </div>
        )}
        <span className="pre-form-error">{errorMessage}</span>
      </div>
    </div>
  );
};

export default InputFields;
