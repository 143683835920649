import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import "../customs_forms/styles.css";
import caretRight from "../customs_forms/images/caret-right.svg";
import viewList from "../customs_forms/images/view-list.svg";
import viewGrid from "../customs_forms/images/view-grid.svg";
import LoggedInNav from "./LoggedInNav";
import sortIcon from "../customs_forms/images/sort-icon.svg";
import PriorityMailPopover from "./Popovers/PriorityMailPopover";
import PriorityMailInternationalPopover from "./Popovers/PriorityMailInternationalPopover";
import AirmailMBagPopover from "./Popovers/AirmailMBagPopover";
import isDomesticDestination from "../utils/isDomesticDestination";
import * as analytics from "../Components/Analytics/Analytics.js";

var dataLayer = {
  event: "application",
  element: "",
  selection: "",
  page: "cfo-step-3-shipping-pricing"
};

class ServiceOptions extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.setPageErrors([]);

    // Remove the receipt status that may have been applied on previous visits
    // to this page (this will be reapplied if the user proceeds through this
    // page and chooses the "Select Service at Post Office" option or
    // omitted a weight on the package info page again)
    delete this.previousState.receiptCheck2;
    this.officialBusiness = this.previousState.officialBusiness;
    this.flowType = this.previousState.flowType;
    this.receiptCheck1 = this.previousState.receiptCheck1;
    this.flowTypeInputs = this.previousState.flowTypeInputs;
    this.cartInfo = this.previousState.cartInfo;
    this.packageInfo = this.previousState.packageInfo;
    const serviceOptions = this.previousState.serviceOptions;
    if (serviceOptions) {
      // If the user is returning to this page
      this.state = {
        ...serviceOptions,
        webtoolsErrorMessage: ""
      };
    } else {
      // If the user is loading this page for the first time
      this.state = {
        // Fetched services
        servicesByServiceType: [],
        // Viewing services
        selectedServiceType: isDomesticDestination(this.flowType)
          ? "PRI"
          : "IPM",
        // Selected service
        label: "",
        serviceDesc: "",
        serviceDimensions: "",
        postOfficePriceDisplay: "",
        productCode: "",
        additionalServices: [],
        webtoolsErrorMessage: ""
      };
    }
    // Set state fields that apply regardless of whether the user has already
    // been to this page
    this.state = {
      ...this.state,
      hasLoaded: false,
      // Viewing services
      showList: true,
      showServices: !this.receiptCheck1,
      showPostOfficeServices: !!this.receiptCheck1,
      // Column sorting
      // - true: currently in ascending order
      // - false: currently in descending order
      // - undefined: currently not sorted
      sortPackageTypeAsc: undefined,
      sortPriceAsc: undefined,
      selectedAdditionalServiceCode: "",
      webtoolsErrorMessage: ""
    };
  }

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.cartInfo &&
      (this.props.history.location.state.previousState.packageInfo ||
        this.props.history.location.state.previousState.hazmatType)
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there is no cart or package info
    window.location.replace("/index");
  };

  componentDidMount() {
    this.props.loginCheck();
    if(this.previousState.renderServiceOption && this.previousState.renderServiceOption === 1){
      this.previousState.senderInfoStatus =0;
    }
    if(!this.previousState.serviceStatus || 
      (this.previousState.serviceStatus && this.previousState.serviceStatus === 0)){
      this.previousState.stepNumber = this.previousState.stepNumber + 1
      this.props.stepNumberCallback(this.previousState.stepNumber);
    }
    
  
    this.props.toggleLoadingBar(true);
    axios
      .get(
        "/CFOApplication/fetchRates?cartId=" +
          encodeURIComponent(this.cartInfo.encodedCartId) +
          "&flowType=" +
          this.getStartingFlowTypeOrReceipt(),
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .then((response) => {
        if (response.data) {
          let services = [];
          let filteredRates = [];

          if (
            this.props.history.location.state.previousState?.hazmatType
              ?.hazmatType == "813"
          ) {
            filteredRates = response.data.rates.filter(
              (obj) =>
                !obj.productClassDesc.includes("Priority Mail") &&
                !obj.productClassDesc.includes("Priority Mail Express") &&
                !obj.productClassDesc.includes("Ground Advantage")
            );
          } else {
            filteredRates = response.data.rates;
          }

          for (let i in filteredRates) {
            // Only include products that are eligible for CFO
            if (this.isValidProductClass(filteredRates[i].productClass)) {
              // Ensure each product's productDesc is not null/undefined
              if (!filteredRates[i].productDesc) {
                filteredRates[i].productDesc = "";
              }
              // Get the product image attributes and service dimensions
              if (filteredRates[i].productAttributesCollection) {
                let foundProductImage = false;
                let foundProductAlt = false;
                let foundServiceDimensions = false;
                for (
                  let j = 0;
                  j < filteredRates[i].productAttributesCollection.length &&
                  (!foundProductImage ||
                    !foundProductAlt ||
                    !foundServiceDimensions);
                  j++
                ) {
                  const attribute =
                  filteredRates[i].productAttributesCollection[j];
                  if (attribute.attributeName === "SO.PRODUCT.IMAGE") {
                    filteredRates[i].serviceProductImage =
                      attribute.attributeValue
                        .replace(
                          "/media/images/cns/service-options-1/",
                          process.env.PUBLIC_URL + "/images/service-options/"
                        )
                        .replace(
                          "/media/images/cns/service-options-2/",
                          process.env.PUBLIC_URL + "/images/service-options/"
                        );
                    foundProductImage = true;
                  } else if (
                    attribute.attributeName === "SO.PRODUCT.IMAGE.ALT"
                  ) {
                    filteredRates[i].serviceProductAlt =
                      attribute.attributeValue;
                    foundProductAlt = true;
                  } else if (
                    attribute.attributeName === "SO.PRODUCT.DIMENSIONS"
                  ) {
                    filteredRates[i].serviceDimensions =
                      attribute.attributeValue;
                    foundServiceDimensions = true;
                  }
                }
                services.push(filteredRates[i]);
              }
            }
          }
          let serviceTypes = [];
          let lowestPrice = null;
          let fastestDelivery = null;
          for (let i in services) {
            // Get the lowest price
            if (
              services[i].price !== null &&
              (lowestPrice === null || services[i].price < lowestPrice)
            ) {
              lowestPrice = services[i].price.toString();
            }
            // Get the fastest delivery
            if (
              services[i].scheduledDelivery !== null &&
              (fastestDelivery === null ||
                services[i].scheduledDelivery < fastestDelivery)
            ) {
              fastestDelivery = services[i].scheduledDelivery;
            }
            // Get all service types (PM, PME, etc.)
            if (!serviceTypes.includes(services[i].productClass)) {
              serviceTypes.push(services[i].productClass);
            }
          }
          // Get all services that share the same lowest price or fastest delivery
          let lowestPriceServices = [];
          let fastestDeliveryServices = [];
          for (let i in services) {
            // Services with the lowest price
            if (
              services[i].price !== null &&
              services[i].price.toString() === lowestPrice
            ) {
              lowestPriceServices.push(services[i]);
            }
            // Services with the fastest delivery
            if (services[i].scheduledDelivery === fastestDelivery) {
              fastestDeliveryServices.push(services[i]);
            }
          }
          // If there are multiple services at the lowest price,
          // find the one with the fastest delivery
          let lowestPriceServiceToDisplay = null;
          for (let i in lowestPriceServices) {
            if (
              lowestPriceServices[i].scheduledDelivery !== null &&
              (lowestPriceServiceToDisplay === null ||
                lowestPriceServices[i].scheduledDelivery <
                  lowestPriceServiceToDisplay.scheduledDelivery)
            ) {
              lowestPriceServiceToDisplay = lowestPriceServices[i];
            }
          }
          if (lowestPriceServiceToDisplay === null) {
            if (lowestPriceServices.length > 0) {
              lowestPriceServiceToDisplay = lowestPriceServices[0];
            } else {
              lowestPriceServiceToDisplay = services[0];
            }
          }
          // If there are multiple services at the fastest delivery,
          // find the one with the lowest price
          let fastestDeliveryServiceToDisplay = null;
          for (let i in fastestDeliveryServices) {
            if (
              fastestDeliveryServices[i].price !== null &&
              (fastestDeliveryServiceToDisplay === null ||
                fastestDeliveryServices[i].price.toString() <
                  fastestDeliveryServiceToDisplay.price.toString())
            ) {
              fastestDeliveryServiceToDisplay = fastestDeliveryServices[i];
            }
          }
          if (fastestDeliveryServiceToDisplay === null) {
            if (fastestDeliveryServices.length > 0) {
              fastestDeliveryServiceToDisplay = fastestDeliveryServices[0];
            } else {
              fastestDeliveryServiceToDisplay = services[0];
            }
          }
          // Organize services by service type
          let servicesByServiceType = [];
          for (let i in serviceTypes) {
            const serviceType = serviceTypes[i];
            servicesByServiceType[serviceType] = services.filter(function (
              service
            ) {
              return service.productClass === serviceType;
            });
          }
          // Prepare the services that will be shown for informational purposes
          // if the user chooses to select the service at the post office
          // (the cheapest option for each service type will be shown here)
          if (this.displaySapServiceType()) {
            let postOfficeServices = [];
            for (let serviceType in servicesByServiceType) {
              postOfficeServices.push({
                productId: 555,
                productCode:
                  "SAP-" + servicesByServiceType[serviceType][0].productCode,
                productDesc: "",
                productClass: "SAP",
                productType: "DOM",
                productClassDesc:
                  servicesByServiceType[serviceType][0].productClassDesc,
                maxPackageItems: 30,
                labelDimensions: "8 1/2 X 11",
                labelPageMin: 1,
                labelPageMax: 5,
                labelPageThreshold: 5,
                onlineDiscount: 0,
                productDescShortName: "SAP",
                trackableInd: "Y",
                maxPackageValue: 0,
                mailClassid: 101,
                webtoolsClassid: 3,
                productCategory: 3,
                productAttributesCollection: [],
                attributeList: null,
                price: Math.min.apply(
                  null,
                  servicesByServiceType[serviceType].map((item) => item.price)
                ),
                productGrouping: null,
                serviceProductImage: this.getImagePath(
                  servicesByServiceType[serviceType][0].productClass
                ),
                serviceProductAlt: this.getImagePath(
                  servicesByServiceType[serviceType][0].productClass
                )
              });
            }
            if (postOfficeServices.length > 0) {
              servicesByServiceType["SAP"] = postOfficeServices;
            }
          }
          // Update the services to display
          this.setState({
            // The original, unmodified collection of services
            // (useful if the original order is needed, ex. without sorting)
            servicesByServiceTypeUnsorted: servicesByServiceType,
            // The collection of services to be displayed (the order will
            // be altered depending on any sorting that is applied later)
            servicesByServiceType: servicesByServiceType,
            lowestPriceServiceToDisplay: lowestPriceServiceToDisplay,
            fastestDeliveryServiceToDisplay: fastestDeliveryServiceToDisplay
          });
          // If returning to this page, preselect the service type
          // that was selected last time.
          if (this.state.selectedServiceType && this.state.productCode) {
            this.setState((prevState) => {
              return {
                hasLoaded: true,
                selectedServiceType: prevState.selectedServiceType,
                showServices:
                  !this.receiptCheck1 && prevState.productCode !== "SAP",
                showPostOfficeServices:
                  !!this.receiptCheck1 || prevState.productCode === "SAP"
              };
            });
            this.props.toggleLoadingBar(false);
          } else {
            // Otherwise, attempt to fetch user preferences before preselecting
            // the service
            let selectedServiceType = "";
            let productCode = "";
            axios
              .get("/CFOApplication/fetchUserPreferences")
              .then((response) => {
                // Only pre-populate the fields if the user is logged in
                // and has preferences set
                if (
                  !response.data.loggedOut &&
                  response.data.preferencesData !== null &&
                  response.data.preferencesData.domesticProductId &&
                  response.data.preferencesData.domesticProductId
                    .productClass &&
                  response.data.preferencesData.domesticProductId.productCode
                ) {
                  selectedServiceType =
                    response.data.preferencesData.domesticProductId
                      .productClass;
                  productCode =
                    response.data.preferencesData.domesticProductId.productCode;
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                // Check to see if a preferred service has been entered by the
                // user and is found in the list of returned services
                let preferredServiceFound = false;
                let label = "";
                let serviceDesc = "";
                let serviceDimensions = "";
                let postOfficePriceDisplay = "";
                if (
                  selectedServiceType &&
                  serviceTypes.includes(selectedServiceType) &&
                  productCode
                ) {
                  for (let i in services) {
                    if (services[i].productCode === productCode) {
                      preferredServiceFound = true;
                      label = services[i].productClassDesc;
                      serviceDesc = services[i].productDesc;
                      serviceDimensions = services[i].serviceDimensions;
                      postOfficePriceDisplay = services[i].price;
                      break;
                    }
                  }
                }
                this.setState((prevState) => {
                  if (
                    preferredServiceFound &&
                    !this.receiptCheck1 &&
                    prevState.productCode !== "SAP"
                  ) {
                    // Preselect the preferred service
                    return {
                      label: label,
                      serviceDesc: serviceDesc,
                      serviceDimensions: serviceDimensions,
                      postOfficePriceDisplay: postOfficePriceDisplay,
                      productCode: productCode,
                      selectedServiceType: selectedServiceType
                    };
                  } else {
                    // Preselect the initial service based on the flow type
                    return {
                      selectedServiceType: this.getInitialServiceType(
                        serviceTypes,
                        this.getStartingFlowTypeOrReceipt()
                      )
                    };
                  }
                });
                // Display the page
                this.setState((prevState) => {
                  return {
                    hasLoaded: true,
                    showServices:
                      !this.receiptCheck1 && prevState.productCode !== "SAP",
                    showPostOfficeServices:
                      !!this.receiptCheck1 || prevState.productCode === "SAP"
                  };
                });
                this.props.toggleLoadingBar(false);
              });
          }
        } else if (response.data.errorMessage) {
          this.setState({
            webtoolsErrorMessage: response.data.errorMessage
          });
          this.props.toggleLoadingBar(false);
          window.scrollTo(0,0);
          return;
        } else {
          console.log(response.data);
          this.props.setPageErrors([
            "We're sorry. An unexpected error has occurred."
          ]);
          this.props.toggleLoadingBar(false);
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.setPageErrors([
          "We're sorry. An unexpected error has occurred."
        ]);
        this.props.toggleLoadingBar(false);
      });
  }

  getStartingFlowTypeOrReceipt = () => {
    return this.receiptCheck1 ? this.receiptCheck1 : this.flowType;
  };

  // Check if the provided product class is valid for CFO
  isValidProductClass = (productClass) => {
    const validProductClasses = [
      "PRI",
      "EXM",
      "GA",
      "FCPSR",
      "IPM",
      "IEM",
      "IFC",
      "AMB"
    ];
    return validProductClasses.includes(productClass);
  };

  getInitialServiceType = (serviceTypes, flowTypeOrReceipt) => {
    switch (flowTypeOrReceipt) {
      // For domestic locations, preselect PM if present;
      // otherwise preselect the first service type in the array
      case "military":
      case "territories":
      case "militaryToUSA":
      case "usa":
        if (serviceTypes.includes("PRI")) {
          return "PRI";
        } else {
          return serviceTypes[0];
        }
      // For international locations, preselect PMI if present;
      // otherwise preselect the first service type in the array
      case "Canada":
      case "other":
        if (serviceTypes.includes("IPM")) {
          return "IPM";
        } else {
          return serviceTypes[0];
        }
      // For receipt flows,
      // preselect "Select Service at Post Office"
      case "domesticReceipt":
      case "internationalReceipt":
        return "SAP";
      default:
        return serviceTypes[0];
    }
  };

  getImagePath = (productClass) => {
    const basePath = process.env.PUBLIC_URL + "/images/service-options/";
    switch (productClass) {
      case "PRI":
        return basePath + "PRI.png";
      case "EXM":
        return basePath + "EXM.png";
      case "GA":
        return basePath + "PP.png";
      case "FCPSR":
        return basePath + "PP.png";
      case "IPM":
        return basePath + "IPM.png";
      case "IEM":
        return basePath + "IEM.png";
      case "IFC":
        return basePath + "FCMI.png";
      case "AMB":
        return basePath + "PP.png";
      default:
        return basePath + "PP.png";
    }
  };

  handleServiceClass = (event) => {
    const target = event.currentTarget.dataset.target;
    this.setState({
      selectedServiceType: target,
      showServices: target !== "SAP",
      showPostOfficeServices: target === "SAP"
    });
    // If changing service types, reset the selected service, sorting etc.
    if (this.state.selectedServiceType !== target) {
      this.setState((prevState) => {
        return {
          sortPackageTypeAsc: undefined,
          sortPriceAsc: undefined,
          servicesByServiceType: prevState.servicesByServiceTypeUnsorted,
          productCode: prevState.selectedServiceType === "SAP" ? "SAP" : ""
        };
      });
    }
  };

  handleService = (service) => {
    this.setState({
      label: service.productClassDesc,
      serviceDesc: service.productDesc,
      serviceDimensions: service.serviceDimensions,
      postOfficePriceDisplay: service.price,
      productCode: service.productCode,
      additionalServices: service.additionalServices
    });
  };

  sortColumns = (columnName) => {
    this.setState((prevState) => {
      // Exit the function if there is nothing to sort
      if (!prevState.servicesByServiceType[prevState.selectedServiceType]) {
        return;
      }
      let sortedData = JSON.parse(
        JSON.stringify(
          prevState.servicesByServiceType[prevState.selectedServiceType]
        )
      );
      if (columnName === "packageType") {
        if (!prevState.sortPackageTypeAsc) {
          // Sort in ascending order
          sortedData = sortedData.sort((a, b) => {
            return a.productDesc.localeCompare(b.productDesc);
          });
        } else {
          // Sort in descending order
          sortedData = sortedData.sort((a, b) => {
            return b.productDesc.localeCompare(a.productDesc);
          });
        }
      } else if (columnName === "price") {
        if (!prevState.sortPriceAsc) {
          // Sort in ascending order
          sortedData = sortedData.sort((a, b) => {
            return parseFloat(a.price) - parseFloat(b.price);
          });
        } else {
          // Sort in descending order
          sortedData = sortedData.sort((a, b) => {
            return parseFloat(b.price) - parseFloat(a.price);
          });
        }
      }
      return {
        servicesByServiceType: {
          ...prevState.servicesByServiceType,
          [prevState.selectedServiceType]: sortedData
        },
        sortPackageTypeAsc:
          columnName === "packageType"
            ? !prevState.sortPackageTypeAsc
            : undefined,
        sortPriceAsc:
          columnName === "price" ? !prevState.sortPriceAsc : undefined
      };
    });
  };

  handleBackButton = () => {
    this.previousState.stepNumber = this.previousState.stepNumber - 1;
    this.props.stepNumberCallback(this.previousState.stepNumber);
    this.previousState.serviceStatus =0;
    if (
      this.flowType === "militaryToUSA" ||
      this.flowType === "usa" ||
      this.flowType === "military" ||
      this.flowType === "territories"
    ) {
      this.props.history.push("/hazmat", {
        previousState: this.previousState
      });
    } else {
      this.props.history.push("/package-Info", {
        previousState: this.previousState
      });
      dataLayer.element = "Back";
      analytics.setDestinationPricePackageDataLayerInfo(dataLayer);
    }
  };

  handleBestService = (dataLayerText, event, service) => {
    dataLayer.selection = dataLayerText;
    this.handleServiceClass(event);
    this.handleService(service);
  };

  handleContinueButton = (event) => {
    event.preventDefault();
    this.previousState.serviceStatus = 1;
    // If the chosen service type is "Select Service at Post Office",
    // user enters or stays in a "receipt" flow
    const receiptStatus = this.getNewFlowTypeOrReceipt();
    const isExtraServiceStepRequired = (this.officialBusiness === "1") && ((this.state.selectedServiceType == "PRI"));

    if (!isExtraServiceStepRequired) {
      this.previousState.selectedAdditionalServiceCode = "";
      this.previousState.totalPackageValue = "";
    }
    
    this.previousState.receiptCheck2 =
      receiptStatus === "domesticReceipt" ||
      receiptStatus === "internationalReceipt"
        ? receiptStatus
        : undefined;
    this.previousState.serviceOptions = {
      selectedServiceType: this.previousState.receiptCheck2
        ? "SAP"
        : this.state.selectedServiceType,
      label: this.state.label,
      serviceDesc: this.state.serviceDesc,
      serviceDimensions: this.state.serviceDimensions,
      additionalServices: this.state.additionalServices,
      postOfficePriceDisplay: this.state.postOfficePriceDisplay,
      productCode: this.previousState.receiptCheck2
        ? "SAP"
        : this.state.productCode
    };

    this.props.toggleLoadingBar(true);

    if (isExtraServiceStepRequired) {
      this.continueToExtraServicesPage();
    } else { 
      axios
      .get(
        "/CFOApplication/saveServiceOption?cartId=" +
          encodeURIComponent(this.cartInfo.encodedCartId) +
          "&productCode=" +
          (this.previousState.receiptCheck2 ? "SAP" : this.state.productCode) +
          "&flowType=" +
          receiptStatus,
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .then((response) => {
        analytics.serviceOptions_InfoToDataLayer(this.previousState);
      })
      .catch((error) => {
        console.log(error);
        this.props.setPageErrors([
          "We're sorry. An unexpected error has occurred."
        ]);
        this.props.toggleLoadingBar(false);
      });
      
      this.props.history.push("/sender-info", {
        previousState: this.previousState
      });

    dataLayer.element = "Continue";
    if (dataLayer.selection === "") {
      dataLayer.selection = "Other";
    }
    }

    analytics.setDestinationPricePackageDataLayerInfo(dataLayer);
  };

  // Branch point:
  // User stays in a "receipt" flow if no weight was entered in the previous
  // step (in other words, if receiptCheck1 is a nonempty string).
  //
  // Alternatively, user enters a "receipt" flow if
  // "Select Service at Post Office" is selected. The status of the lookup codes
  // that disable receipt flows is not checked here because the
  // "Select Service at Post Office" option should have been hidden if the
  // lookup codes are active
  getNewFlowTypeOrReceipt = () => {
    if (this.receiptCheck1) {
      return this.receiptCheck1;
    }
    if (this.state.selectedServiceType === "SAP") {
      if (
        this.flowType === "militaryToUSA" ||
        this.flowType === "usa" ||
        this.flowType === "military" ||
        this.flowType === "territories"
      ) {
        return "domesticReceipt";
      } else if (this.flowType === "Canada" || this.flowType === "other") {
        return "internationalReceipt";
      } else {
        return this.flowType;
      }
    } else {
      return this.flowType;
    }
  };

  weightEntered = () => {
    return this.poundsEntered() || this.ouncesEntered();
  };

  poundsEntered = () => {
    return this.packageInfo.weightPounds > 0;
  };

  ouncesEntered = () => {
    return this.packageInfo.weightOunces > 0;
  };

  packageValueEntered = () => {
    return this.packageInfo.totalPackageValue > 0;
  };

  dimensionsEntered = () => {
    return (
      this.packageInfo.length > 0 ||
      this.packageInfo.width > 0 ||
      this.packageInfo.height > 0
    );
  };

  displaySapServiceType = () => {
    switch (this.flowType) {
      case "Canada":
      case "other":
      case "military":
      case "territories":
      case "usa":
        if (
          this.props.lookupCodes["cfo.receipts.international.disabled"] ===
          "true"
        ) {
          return false;
        } else {
          return true;
        }
      case "militaryToUSA":
        if (
          this.props.lookupCodes["cfo.receipts.military.domestic.disabled"] ===
          "true"
        ) {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  };

  renderPackageInfo = () => {
    return (
      <React.Fragment>
        <p>
          {!this.weightEntered() ? (
            <span>You have not entered a weight for your</span>
          ) : (
            <React.Fragment>
              <span> Price based on shipping a </span>
              <span className="bold">
                {this.poundsEntered() && (
                  <React.Fragment>
                    <span className="passed-param" id="package-weight-lb">
                      {this.packageInfo.weightPounds}
                    </span>{" "}
                    lb.{" "}
                  </React.Fragment>
                )}
                {this.ouncesEntered() && (
                  <React.Fragment>
                    <span className="passed-param" id="package-weight-oz">
                      {this.packageInfo.weightOunces}
                    </span>{" "}
                    oz.{" "}
                  </React.Fragment>
                )}
              </span>
            </React.Fragment>
          )}{" "}
          package{" "}
          {this.packageValueEntered() && (
            <React.Fragment>
              (valued at{" "}
              <span className="bold passed-param" id="package-value">
                ${this.packageInfo.totalPackageValue}
              </span>
              ){" "}
            </React.Fragment>
          )}
          {this.dimensionsEntered() && (
            <React.Fragment>
              with dimensions in inches (
              <span className="bold passed-param" id="package-dimensions">
                {(this.packageInfo.length || "0") +
                  "L x " +
                  (this.packageInfo.width || "0") +
                  "W x " +
                  (this.packageInfo.height || "0") +
                  "H"}
              </span>
              ){" "}
            </React.Fragment>
          )}
          {this.flowType === "Canada" || this.flowType === "other" ? (
            <React.Fragment>
              to{" "}
              <span className="bold passed-param" id="package-destination">
                {this.flowType === "Canada"
                  ? this.flowType.toUpperCase()
                  : this.flowTypeInputs.countryName.toUpperCase()}
                .
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              from ZIP Code
              <span className="bold passed-param" id="package-destination">
                {" " + this.flowTypeInputs.senderZipCode + " "}
              </span>{" "}
              to{" "}
              <span className="bold passed-param" id="package-destination">
                {this.flowTypeInputs.recipientZipCode + "."}
              </span>
            </React.Fragment>
          )}
        </p>
        <p>
          Prices for each service are shown, but payment will not be collected
          until you complete your shipment at the Post Office&trade;.
        </p>
      </React.Fragment>
    );
  };

  gridView = () => {
    this.setState({
      showList: false
    });
  };

  listView = () => {
    this.setState({
      showList: true
    });
  };

  renderBestServices = (type) => {
    const serviceToDisplay =
      type === "price"
        ? this.state.lowestPriceServiceToDisplay
        : this.state.fastestDeliveryServiceToDisplay;
    return (
      <React.Fragment>
        {serviceToDisplay ? (
          <div className="col-md-6 col-lg-5 p-3 panel-item">
            <div className="method-selection">
              <label
                className={
                  "best-service" +
                  (this.state.productCode === serviceToDisplay.productCode
                    ? " selected"
                    : "")
                }
              >
                <input
                  type="radio"
                  name={
                    type === "price"
                      ? "lowestPriceService"
                      : "fastestDeliveryService"
                  }
                  className="grid-item visuallyhidden"
                  data-target={serviceToDisplay.productClass}
                  checked={
                    this.state.productCode === serviceToDisplay.productCode
                  }
                  onChange={(e) =>
                    this.handleBestService(
                      type === "price" ? "Lowest Price" : "Fastest Delivery",
                      e,
                      serviceToDisplay
                    )
                  }
                />
                <div className="method-selection-box">
                  <div className="row flex-row-reverse">
                    <div className="col-4 col-md-6 mb-2 mb-lg-0 method-image-container method-image-container method-image-container method-image-container method-image-container">
                      <div className="method-image">
                        <span>
                          <img
                            src={serviceToDisplay.serviceProductImage}
                            alt={serviceToDisplay.serviceProductAlt}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="col-8 col-md-6">
                      {type === "price" ? (
                        <h2 className="bg-green">Lowest Price</h2>
                      ) : (
                        <h2>Fastest Delivery</h2>
                      )}
                      <div className="method-col-1">
                        <div className="method-desc">
                          <div className="method-name">
                            {serviceToDisplay.productClassDesc + " "}
                            {isDomesticDestination(this.flowType) ? (
                              <PriorityMailPopover
                                targetName={
                                  type === "price"
                                    ? "lowestPriceService"
                                    : "fastestDeliveryService"
                                }
                              />
                            ) : serviceToDisplay.productClass === "AMB" ? (
                              <AirmailMBagPopover
                                targetName={
                                  type === "price"
                                    ? "lowestPriceService"
                                    : "fastestDeliveryService"
                                }
                              />
                            ) : (
                              <PriorityMailInternationalPopover
                                targetName={
                                  type === "price"
                                    ? "lowestPriceService"
                                    : "fastestDeliveryService"
                                }
                              />
                            )}
                          </div>
                          <div className="note method-note">
                            {serviceToDisplay.productDesc ? (
                              <React.Fragment>
                                {serviceToDisplay.productDesc}
                                <br />
                              </React.Fragment>
                            ) : (
                              <React.Fragment />
                            )}
                            {serviceToDisplay.serviceDimensions
                              .split(/<br \/>|<br\/>/)
                              .map((line, i) => {
                                return <div key={i}>{line}</div>;
                              })}
                          </div>
                        </div>
                        <div className="note method-note schedule-note">
                          {serviceToDisplay.scheduledDelivery &&
                            serviceToDisplay.scheduledDelivery.replace(
                              "to many major markets",
                              ""
                            )}
                        </div>
                      </div>
                      <div className="method-price">
                        ${serviceToDisplay.price.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  };

  renderServiceTypes = () => {
    return (
      <React.Fragment>
        <div className="text-label text-select-a-service">
          Filter by Service:
        </div>
        <div className="control-list select-a-service mobile-list-selector">
          <ul>
            <div>
              <div className="service-type-tabs">
                {(() => {
                  if (isDomesticDestination(this.flowType)) {
                    return (
                      <React.Fragment>
                        <li
                          className={
                            "service-type-tab active" +
                            (this.state.selectedServiceType === "PRI"
                              ? " selected"
                              : "")
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-chromeless button-link"
                            data-target="PRI"
                            onClick={this.handleServiceClass}
                          >
                            {this.state.selectedServiceType === "PRI" ? (
                              <span className="visuallyhidden">
                                Currently selected service type
                              </span>
                            ) : null}
                            <span>Priority Mail®</span>
                          </button>
                        </li>
                        <li
                          className={
                            "service-type-tab active" +
                            (this.state.selectedServiceType === "EXM"
                              ? " selected"
                              : "")
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-chromeless button-link"
                            data-target="EXM"
                            onClick={this.handleServiceClass}
                          >
                            {this.state.selectedServiceType === "EXM" ? (
                              <span className="visuallyhidden">
                                Currently selected service type
                              </span>
                            ) : null}
                            <span>Priority Mail Express®</span>
                          </button>
                        </li>
                        <li
                          className={
                            "service-type-tab active" +
                            (this.state.selectedServiceType === "GA"
                              ? " selected"
                              : "")
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-chromeless button-link"
                            data-target="GA"
                            onClick={this.handleServiceClass}
                          >
                            {this.state.selectedServiceType === "GA" ? (
                              <span className="visuallyhidden">
                                Currently selected service type
                              </span>
                            ) : null}
                            <span>
                              Ground Advantage<sup>™</sup>
                            </span>
                          </button>
                        </li>
                        {/* <li
                          className={
                            "service-type-tab active" +
                            (this.state.selectedServiceType === "FCPSR"
                              ? " selected"
                              : "")
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-chromeless button-link"
                            data-target="FCPSR"
                            onClick={this.handleServiceClass}
                          >
                            {this.state.selectedServiceType === "FCPSR" ? (
                              <span className="visuallyhidden">
                                Currently selected service type
                              </span>
                            ) : null}
                            <span>First-Class Package Service - Retail™</span>
                          </button>
                        </li> */}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment>
                        <li
                          className={
                            "service-type-tab active" +
                            (this.state.selectedServiceType === "IPM"
                              ? " selected"
                              : "")
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-chromeless button-link"
                            data-target="IPM"
                            onClick={this.handleServiceClass}
                          >
                            {this.state.selectedServiceType === "IPM" ? (
                              <span className="visuallyhidden">
                                Currently selected service type
                              </span>
                            ) : null}
                            <span>Priority Mail International®</span>
                          </button>
                        </li>
                        <li
                          className={
                            "service-type-tab active" +
                            (this.state.selectedServiceType === "IEM"
                              ? " selected"
                              : "")
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-chromeless button-link"
                            data-target="IEM"
                            onClick={this.handleServiceClass}
                          >
                            {this.state.selectedServiceType === "IEM" ? (
                              <span className="visuallyhidden">
                                Currently selected service type
                              </span>
                            ) : null}
                            <span>Priority Mail Express International®</span>
                          </button>
                        </li>
                        <li
                          className={
                            "service-type-tab active" +
                            (this.state.selectedServiceType === "IFC"
                              ? " selected"
                              : "")
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-chromeless button-link"
                            data-target="IFC"
                            onClick={this.handleServiceClass}
                          >
                            {this.state.selectedServiceType === "IFC" ? (
                              <span className="visuallyhidden">
                                Currently selected service type
                              </span>
                            ) : null}
                            <span>
                              First-Class Package International Service®
                            </span>
                          </button>
                        </li>
                        <li
                          className={
                            "service-type-tab active" +
                            (this.state.selectedServiceType === "AMB"
                              ? " selected"
                              : "")
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-chromeless button-link"
                            data-target="AMB"
                            onClick={this.handleServiceClass}
                          >
                            {this.state.selectedServiceType === "AMB" ? (
                              <span className="visuallyhidden">
                                Currently selected service type
                              </span>
                            ) : null}
                            <span>Airmail M-Bag</span>
                          </button>
                        </li>
                      </React.Fragment>
                    );
                  }
                })()}
                {this.displaySapServiceType() ? (
                  <li
                    className={
                      "service-type-tab active" +
                      (this.state.selectedServiceType === "SAP"
                        ? " selected"
                        : "")
                    }
                  >
                    <button
                      type="button"
                      className="btn btn-chromeless button-link"
                      data-target="SAP"
                      onClick={this.handleServiceClass}
                    >
                      {this.state.selectedServiceType === "SAP" ? (
                        <span className="visuallyhidden">
                          Currently selected service type
                        </span>
                      ) : null}
                      <span>Select Service at Post Office®</span>
                    </button>
                  </li>
                ) : (
                  <React.Fragment />
                )}
              </div>
            </div>
          </ul>
        </div>
      </React.Fragment>
    );
  };

  renderServicesTable = () => {
    return (
      <div className="panel-groups-container">
        <div className="panel-group active">
          <div className="panel-groups-container">
            {this.state.showList ? (
              <div className="row section-row panel-items panel-items-list-header">
                <div className="col-12">
                  <div className="method-col-1">
                    <div className="method-desc">
                      <div className="method-name">
                        <button
                          type="button"
                          className="button-link"
                          onClick={() => this.sortColumns("packageType")}
                        >
                          Package Type <img src={sortIcon} alt="Sort icon" />
                          {this.state.sortPackageTypeAsc !== undefined ? (
                            <span className="visuallyhidden">
                              Currently sorted in
                              {this.state.sortPackageTypeAsc === false
                                ? " reverse "
                                : " "}
                              alphabetical order
                            </span>
                          ) : null}
                        </button>
                      </div>
                    </div>
                    <div className="note method-note schedule-note">
                      Scheduled Delivery
                    </div>
                  </div>
                  <div className="method-price">
                    <button
                      type="button"
                      className="button-link"
                      onClick={() => this.sortColumns("price")}
                    >
                      Price <img src={sortIcon} alt="Sort icon" />
                      {this.state.sortPriceAsc !== undefined ? (
                        <span className="visuallyhidden">
                          Currently sorted from
                          {this.state.sortPriceAsc === false
                            ? " highest to lowest "
                            : " lowest to highest "}
                          price
                        </span>
                      ) : null}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment />
            )}
            <div
              className={
                "row section-row panel-items panel-items-body" +
                (!this.state.servicesByServiceType[
                  this.state.selectedServiceType
                ]
                  ? " services-empty-table-div"
                  : "")
              }
            >
              {this.state.servicesByServiceType[this.state.selectedServiceType]
                ? this.state.servicesByServiceType[
                    this.state.selectedServiceType
                  ].map(this.renderServices)
                : this.renderNoServices()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderServices = (service) => {
    return (
      <React.Fragment key={service.productCode}>
        <div className="col-lg-4 col-md-6 p-3 panel-item">
          <div className="method-selection">
            <label>
              {!this.receiptCheck1 ? (
                <input
                  type="radio"
                  name="method"
                  className={
                    !this.state.showList ? "grid-item visuallyhidden" : ""
                  }
                  checked={this.state.productCode === service.productCode}
                  onChange={() => this.handleService(service)}
                />
              ) : (
                <React.Fragment />
              )}
              <div className="method-selection-box">
                <div className="row flex-row-reverse">
                  <div className="col-4 mb-2 mb-lg-0 method-image-container">
                    {service.serviceProductImage ? (
                      <div className="method-image">
                        <span>
                          <img
                            src={service.serviceProductImage}
                            alt={service.serviceProductAlt}
                          />
                        </span>
                      </div>
                    ) : (
                      <React.Fragment />
                    )}
                  </div>
                  <div className="col-8 method-text-container">
                    <div className="method-col-1">
                      <div className="method-desc">
                        <div className="method-name">
                          {service.productClassDesc.trim() + " "}
                          <span>
                            {isDomesticDestination(this.flowType) ? (
                              <PriorityMailPopover
                                targetName={"return-" + service.productCode}
                              />
                            ) : service.productClass === "AMB" ? (
                              <AirmailMBagPopover
                                targetName={"return-" + service.productCode}
                              />
                            ) : (
                              <PriorityMailInternationalPopover
                                targetName={"return-" + service.productCode}
                              />
                            )}
                          </span>
                        </div>
                        <div className="note method-note">
                          {service.productDesc}
                        </div>
                        <div className="note method-note">
                          {service.serviceDimensions ? (
                            <React.Fragment>
                              {service.serviceDimensions
                                .split(/<br \/>|<br\/>/)
                                .map((line, i) => {
                                  return <div key={i}>{line}</div>;
                                })}
                            </React.Fragment>
                          ) : (
                            <React.Fragment />
                          )}
                        </div>
                      </div>
                      <div className="note method-note schedule-note">
                        {service.scheduledDelivery
                          ? service.scheduledDelivery.replace(
                              "to many major markets",
                              ""
                            )
                          : ""}
                      </div>
                    </div>
                    <div className="method-price">
                      ${service.price.toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderNoServices = () => {
    return (
      <p id="services-empty-table">
        There are no services for the Service Type you selected, please select
        another Service Type.
      </p>
    );
  };

  continueToExtraServicesPage = ()=>{
    this.props.onDestination(17);
    analytics.setDestinationPricePackageDataLayerInfo(dataLayer);
    this.props.history.push("/extra-services", {
      previousState: this.previousState
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.hasLoaded ? (
          <div id="cfo-wrapper">
            <div id="inner">
              <main className="section" id="customs-form-2019-main">
                <section className="section" id="app-form">
                  <div className="container">
                    <form
                      className="customs-form"
                      id="customs-form"
                      action="#"
                      method="post"
                    >
                      <div className="row section-row align-items-center">
                        <div className="col-lg-4">
                          <h1>Customs Form</h1>
                          <p className="note">* indicates a required field</p>
                        </div>
                        {this.state.webtoolsErrorMessage && (
                          <div style = {{color: "red", marginBottom: "20px"}}>
                            {this.state.webtoolsErrorMessage}
                          </div>
                        )}
                        <div className="col-lg-8">
                          {this.props.loggedIn ? (
                            <LoggedInNav />
                          ) : (
                            <ul className="title-links">
                              <li>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                                >
                                  FAQs{" "}
                                  <span>
                                    <img src={caretRight} alt="open link" />
                                  </span>
                                </a>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-lg-9">
                          <h2>How would you like to ship your package?</h2>
                          {this.renderPackageInfo()}
                        </div>
                      </div>
                      {!this.receiptCheck1 && (
                        <div className="row section-row">
                          {this.renderBestServices("price")}
                          {this.renderBestServices("delivery")}
                        </div>
                      )}
                      {this.receiptCheck1 && this.flowType === "militaryToUSA" && (
                        <div className="row section-row">
                          <label style={{ marginLeft: "16px" }}>
                            <li>
                              Service and package types shown below are for
                              informational purposes only.
                            </li>
                            <li>
                              {" "}
                              These options will be selected at the Military
                              Post Office&trade;.{" "}
                            </li>
                            <li>
                              If you would like to see shipping recommendations
                              for lowest price and fastest delivery, you must go
                              back and enter the weight of your package.
                            </li>
                            <li>Flat rate pricing applies up to 70 pounds.</li>
                          </label>
                        </div>
                      )}
                      <div
                        className={
                          "row section-row panel-container paneltype-view-" +
                          (this.state.showList ? "list" : "grid")
                        }
                      >
                        <div className="col-12">
                          <div className="subsection control-list select-a-view">
                            <ul>
                              <li id="text-view-as">
                                <div>
                                  <h3>View as: </h3>
                                </div>
                              </li>
                              <li
                                className={
                                  "is-classer" +
                                  (this.state.showList ? " active" : "")
                                }
                              >
                                <button
                                  type="button"
                                  onClick={this.listView}
                                  className="btn btn-chromeless button-link"
                                  data-target="view-list"
                                >
                                  {this.state.showList ? (
                                    <span className="visuallyhidden">
                                      Currently selected
                                    </span>
                                  ) : null}
                                  List <img src={viewList} alt="List" />
                                </button>
                              </li>
                              <li
                                className={
                                  "is-classer" +
                                  (!this.state.showList ? " active" : "")
                                }
                              >
                                <button
                                  type="button"
                                  onClick={this.gridView}
                                  className="btn btn-chromeless button-link"
                                  data-target="view-grid"
                                >
                                  {!this.state.showList ? (
                                    <span className="visuallyhidden">
                                      Currently selected
                                    </span>
                                  ) : null}
                                  Grid <img src={viewGrid} alt="Grid" />
                                </button>
                              </li>
                            </ul>
                            {this.renderServiceTypes()}
                          </div>
                          {this.state.showServices ? (
                            this.renderServicesTable()
                          ) : (
                            <React.Fragment />
                          )}
                          <div className="row">
                            {this.state.showPostOfficeServices &&
                            this.state.servicesByServiceType["SAP"] ? (
                              <React.Fragment>
                                {this.state.servicesByServiceType["SAP"].map(
                                  function (item) {
                                    return (
                                      <div
                                        key={item.productClassDesc}
                                        className="col-6 col-lg-3"
                                      >
                                        <ul className="list-group list-unstyled package">
                                          <li>
                                            <img
                                              src={item.serviceProductImage}
                                              alt={item.serviceProductAlt}
                                            />
                                          </li>
                                          <li>
                                            <a
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              href="https://www.usps.com/ship/mail-shipping-services.htm"
                                            >
                                              {item.productClassDesc}
                                              <span>
                                                <img
                                                  src={caretRight}
                                                  alt="open link"
                                                />
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            Starting From $
                                            {item.price.toFixed(2)}
                                          </li>
                                        </ul>
                                      </div>
                                    );
                                  }
                                )}
                              </React.Fragment>
                            ) : this.state.showPostOfficeServices ? (
                              <div
                                className="services-empty-table-div"
                                style={{ width: "100%" }}
                              >
                                {this.renderNoServices()}
                              </div>
                            ) : (
                              <React.Fragment />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-lg-6">
                          <div className="subsection button-section">
                            <div className="row">
                              <div className="col-12">
                                <div className="customs-form-buttons">
                                  <div className="form-button-container">
                                    <button
                                      className="previous btn btn-outline-primary"
                                      type="button"
                                      onClick={this.handleBackButton}
                                    >
                                      Back
                                    </button>
                                  </div>
                                  {this.state.productCode ||
                                  this.receiptCheck1 ? (
                                    <div className="form-button-container">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        id="submit"
                                        onClick={this.handleContinueButton}
                                      >
                                        Continue
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </main>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ServiceOptions);
