// Modelled after the example at https://alligator.io/react/react-autocomplete/

import React from "react";

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // The index of the currently highlighted suggestion
      highlightedSuggestion: 0,
      // The suggestion list that matches the current input
      filteredSuggestionList: [],
      //  Whether or not to display the filtered suggestion list
      showSuggestions: false,
      // The user's current input
      userInput: this.props.userInput || ""
    };
    // Do not execute the following when processing items on the item
    // entry page (was causing issues with items incorrectly marked as
    // requiring HS tariff #s)
    if (this.props.source !== "itemEntry") {
      this.updateParent(
        this.props.userInput,
        this.props.userInput ? true : false
      );
    }
  }

  // Handler for the user's input
  handleChange = (event) => {
    const value = event.currentTarget.value;
    this.updateParent(value, false);
    this.setState({
      highlightedSuggestion: 0,
      filteredSuggestionList: this.props.suggestionList.filter(
        (suggestion) =>
          suggestion.toUpperCase().indexOf(value.trim().toUpperCase()) > -1
      ),
      showSuggestions: true,
      userInput: value
    });
  };

  // Handler for navigating through suggestions using a keyboard
  handleKeyDown = (event) => {
    const highlightedSuggestion = this.state.highlightedSuggestion;
    const filteredSuggestionList = this.state.filteredSuggestionList;
    if (event.keyCode === 13) {
      // Enter key (selects the currently highlighted suggestion)
      event.preventDefault();
      if (filteredSuggestionList.length && this.state.userInput.trim()) {
        this.updateParent(filteredSuggestionList[highlightedSuggestion], true);
        this.setState({
          highlightedSuggestion: 0,
          filteredSuggestionList: [],
          showSuggestions: false,
          userInput: filteredSuggestionList[highlightedSuggestion]
        });
      }
    } else if (event.keyCode === 38) {
      // Up arrow key
      if (highlightedSuggestion > 0) {
        this.setState({
          highlightedSuggestion: highlightedSuggestion - 1
        });
      }
    } else if (event.keyCode === 40) {
      // Down arrow key
      if (highlightedSuggestion + 1 < filteredSuggestionList.length) {
        this.setState({
          highlightedSuggestion: highlightedSuggestion + 1
        });
      }
    }
  };

  // Handler for navigating through suggestions using a mouse
  handleMouseOver = (event) => {
    this.setState({
      highlightedSuggestion: Number(event.currentTarget.dataset.index)
    });
  };

  // Handler for selecting a suggestion with a mouse
  handleClick = (event) => {
    this.updateParent(event.currentTarget.innerText, true);
    this.setState({
      highlightedSuggestion: 0,
      filteredSuggestionList: [],
      showSuggestions: false,
      userInput: event.currentTarget.innerText
    });
  };

  // Update the parent component with the user's input
  // and whether or not the input is a valid suggestion
  updateParent = (userInput, isValid) => {
    this.props.parentCallback(userInput, isValid);
  };

  // Render the input field along with filtered suggestions as list items
  render() {
    let filteredSuggestionList;
    if (this.state.showSuggestions && this.state.userInput.trim()) {
      if (this.state.filteredSuggestionList.length) {
        filteredSuggestionList = (
          <ul className="autocomplete-suggestions">
            {this.state.filteredSuggestionList.map((suggestion, index) => {
              return (
                <li
                  className={
                    "autocomplete-suggestion" +
                    (index === this.state.highlightedSuggestion
                      ? " autocomplete-selected"
                      : "")
                  }
                  data-index={index}
                  key={suggestion}
                  onMouseOver={this.handleMouseOver}
                  onClick={this.handleClick}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        filteredSuggestionList = (
          <div className="autocomplete-no-suggestion">No results found.</div>
        );
      }
    }

    return (
      <React.Fragment>
        <input
          type={this.props.type}
          name={this.props.name}
          className={this.props.className}
          id={this.props.id}
          required={this.props.isRequired}
          readOnly={this.props.readOnly}
          placeholder={
            this.props.hidePlaceholder ? "" : "Type in your destination"
          }
          value={this.state.userInput}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          autoComplete="off"
        />
        {filteredSuggestionList}
      </React.Fragment>
    );
  }
}

export default Autocomplete;
