const base64toPDF = (data, barcodeNumber) => {
  var bufferArray = base64ToArrayBuffer(data);
  var blobStore = new Blob([bufferArray], { type: "application/pdf" });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blobStore);
    return;
  }
  data = window.URL.createObjectURL(blobStore);
  var link = document.createElement("a");
  document.body.appendChild(link);
  link.href = data;
  link.download = "CustomsForm" + barcodeNumber + ".pdf";
  link.click();
  window.URL.revokeObjectURL(data);
  link.remove();
};

const base64ToArrayBuffer = (data) => {
  // needed for IE11, remove after IE11 is end of life.
  // atob() does not like these characters and will fail for invalid character
  // in IE but its fine in every other browser
  data = data.replace(/^[^,]+,/, "");
  data = data.replace(/\s/g, "");
  //////////////////////////////////////

  var bString = window.atob(data);
  var bLength = bString.length;
  var bytes = new Uint8Array(bLength);
  for (var i = 0; i < bLength; i++) {
    var ascii = bString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export default base64toPDF;
