import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const TextUpdatePopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        The phone number must be a text enabled U.S. or Canadian number.
        Standard message and data rates may apply.
      </p>
      <p>
        <a href="https://www.usps.com/text-tracking/welcome.htm">
          <strong>Learn more about USPS Text Tracking</strong>
        </a>
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Text Message Information Icon"
      header="Text Updates"
      content={content}
    />
  );
};

export default TextUpdatePopover;
