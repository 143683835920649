import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const TotalItemValuePopover = (props) => {
  const content = (
    <React.Fragment>
      <p>Total value of all of the items you have added here.</p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Total Items Value Icon"
      header="Total Items Value"
      content={content}
    />
  );
};

export default TotalItemValuePopover;
