import React from "react";
import InfoPopover from "../Popovers/InfoPopover";

const AesExemptionPopover = (props) => {
  const content = (
    <React.Fragment>
      {window.innerWidth > 760 ? (
        <div className="aes-exemption-popover">
          <div className="popover-wrapper">
            <div className="popover-header">
              <span className="visuallyhidden">Close Popover</span>

              <h4>AES Exemption</h4>
            </div>
            <p>
              <strong>NOEEI 30.37(a)</strong>
              <br />
              Value of each class of goods is $2,500 or less, when an export
              license is not required.
            </p>
            <p>
              <strong>NOEEI 30.36</strong>
              <br />
              Shipments destined to Canada, when an export license is not
              required.
            </p>
            <p>
              <strong>NOEEI 30.37(h)</strong>
              <br />
              Authorized shipments of gift parcels or humanitarian donations.
            </p>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className="aes-exemption-popover">
            <div className="popover-wrapper">
              <div className="popover-header">
                <span className="visuallyhidden">Close Popover</span>

                <h4>AES Exemption</h4>
              </div>
              <p>
                <strong>NOEEI 30.37(a)</strong>
                <br />
                Value of each class of goods is $2,500 or less, when an export
                license is not required.
              </p>
              <p>
                <strong>NOEEI 30.36</strong>
                <br />
                Shipments destined to Canada, when an export license is not
                required.
              </p>
              <p>
                <strong>NOEEI 30.37(h)</strong>
                <br />
                Authorized shipments of gift parcels or humanitarian donations.
              </p>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="Sender's Custom Reference Number"
      content={content}
    />
  );
};

export default AesExemptionPopover;
