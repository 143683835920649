import React from "react";
import axios from "axios";

// CSS
import "./customs_forms/css/bootstrap.min.css";
import "./customs_forms/css/bootstrap.min.css.map";
import "./customs_forms/css/helvetica-neue.css";
import "./customs_forms/styles.css";
import * as analytics from "../src/Components/Analytics/Analytics.js";
//import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Pages
import Index from "./Components/Index";
import FlowType from "./Components/FlowType";
import FlowTypeInputs from "./Components/FlowTypeInputs";
import Prohibitions from "./Components/Prohibitions";
import PackageInfo from "./Components/PackageInfo";
import ServiceOptions from "./Components/ServiceOptions";
import SenderInfo from "./Components/SenderInfo";
import RecipientInfo from "./Components/RecipientInfo";
import NonDeliveryOption from "./Components/NonDeliveryOption";
import PackageContents from "./Components/PackageContents";
import CommercialSender from "./Components/CommercialSender";
import ItemEntry from "./Components/ItemEntry";
import AesExemptions from "./Components/AesExemptions";
import Summary from "./Components/Summary";
import ThankYou from "./Components/ThankYou";
import ShippingHistory from "./Components/ShippingHistory";
import LabelDetails from "./Components/LabelDetails";
import Preferences from "./Components/Preferences";
import Hazmat from "./Components/Hazmat";
import WhiteSpinner from "./Components/Modals/WhiteSpinner";
import AssistiveGlobalError from "./Components/Subcomponents/AssistiveGlobalError";

import ReactGA from "react-ga";
import OfficialBusiness from "./Components/OfficialBusiness.js";
import ExtraServices from "./Components/ExtraServices.js";

ReactGA.initialize("UA-xxxxxxxxx-x");
var dataLayer = {
  userData: {}
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.lookupCodes = [];
    this.countryList = [];
    this.customsFormNotRequiredZipCodes = [];
    this.state = {
      hasLoaded: false,
      showGlobalLoadingBar: false,
      showLoadingBar: false,
      stepNumber: 0,
      globalErrors: [],
      pageErrors: [],
      inputErrors: {},
      totalSteps: 15
    };
  }

  // This is triggered when a user first visits CFO or refreshes any CFO page.
  toggleGlobalLoadingBar = (bool) => {
    this.setState({
      showGlobalLoadingBar: bool
    });
  };

  // This is triggered when endpoint calls on individual pages are made.
  toggleLoadingBar = (bool) => {
    this.setState({
      showLoadingBar: bool
    });
  };

  stepNumberCallback = (stepNumber) => {
    this.setState({
      stepNumber: stepNumber
    });
  };

  // Move the United States to the top of the list
  reorderCountryList = (countryList) => {
    let unitedStates = [];
    let unitedStatesIndex = -1;
    for (let i in countryList) {
      if (countryList[i].countryDbId === 840) {
        unitedStates.push(countryList[i]);
        unitedStatesIndex = i;
        break;
      }
    }
    countryList.splice(unitedStatesIndex, 1);
    countryList = unitedStates.concat(countryList);
    return countryList;
  };

  componentDidMount() {
    if (this.lookupCodes == null || this.lookupCodes.length === 0) {
      this.toggleGlobalLoadingBar(true);
      Promise.all([
        axios.get("/CFOApplication/fetchFrontendLookupCodes"),
        axios.get("/CFOApplication/fetchCustomsConfiguration"),
        axios.get("/CFOApplication/fetchStatesRequiresZip")
      ])
        .then((response) => {
          this.lookupCodes = response[0].data;
          this.countryList = this.reorderCountryList(response[1].data);
          this.customsFormNotRequiredZipCodes = response[2].data;

          this.setState({
            hasLoaded: true,
            lookupCodes: this.lookupCodes
          });

          sessionStorage.removeItem("accountInfo");
          sessionStorage.setItem(
            "accountInfo",
            JSON.stringify(dataLayer.userData)
          );

          this.loginCheck();
        })
        .catch((error) => {
          console.log(error);
          this.setGlobalErrors([
            "We're sorry. An unexpected error has occurred."
          ]);
        })
        .finally(() => {
          this.toggleGlobalLoadingBar(false);
        });
    }
  }

  // Login check during the initial page load and
  // when switching pages
  loginCheck = () => {
    axios
      .get("/CFOApplication/loginCheck")
      .then((response) => {
        this.setState({
          loggedIn: response.data.loggedIn,
          custRegEmail: response.data.custRegEmail
        });
        dataLayer.userData = response.data;
        analytics.userInformation(dataLayer);
      })
      .catch((error) => {
        this.setState({
          loggedIn: false
        });
        console.log(error);
      });
  };

  // Set "global" error messages that often appear at the top of the page.
  // These typically occur when an API call from App.js fails
  setGlobalErrors = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);
    let combinedErrors = actionErrors
      ? flattenFieldErrors.concat(actionErrors)
      : flattenFieldErrors;
    this.setState({
      globalErrors: combinedErrors
    });
  };

  // Set error messages that often appear at the top of the page.
  // These typically occur when an API call fails on an individual page
  setPageErrors = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);
    let combinedErrors = actionErrors
      ? flattenFieldErrors.concat(actionErrors)
      : flattenFieldErrors;
    this.setState({
      pageErrors: combinedErrors
    });
  };

  // Set error messages for input fields when invalid inputs are provided
  setInputErrors = (errorObject) => {
    this.setState({
      inputErrors: errorObject
    });
  };

  onDestination =(value) =>{
    this.setState({
      totalSteps: value
    })
  }
  
  render() {
    return (
      <div id="cfo-top-of-page" tabIndex="-1">
        {this.state.stepNumber > 0 ? (
          <div className="container">
            <div id="progress-bar-background">
              <div
                id="progress-bar"
                style={{
                  width: (this.state.stepNumber / this.state.totalSteps) * 100 + "%"
                }}
              ></div>
            </div>
            <p id="progress-bar-text" className="text-center align-middle">
              Step {this.state.stepNumber} / {this.state.totalSteps}
            </p>
          </div>
        ) : null}
        <Router>
          <AssistiveGlobalError
            globalErrorArray={this.state.globalErrors.concat(
              this.state.pageErrors
            )}
            shouldFocus={true}
          />
          {this.state.hasLoaded ? (
            <Switch>
              <Route exact path={["/", "/index", "/index.shtml"]}>
                <Index
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  setPageErrors={this.setPageErrors}
                  lookupCodes={this.state.lookupCodes}
                />
              </Route>
              <Route path="/flow-type">
                <FlowType
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  setPageErrors={this.setPageErrors}
                  onDestination={this.onDestination}
                />
              </Route>
              <Route path="/official-business">
                <OfficialBusiness
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                  lookupCodes={this.state.lookupCodes}
                />
              </Route>
              <Route path="/flow-type-inputs">
                <FlowTypeInputs
                  countryList={this.countryList}
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                />
              </Route>
              <Route path="/prohibitions">
                <Prohibitions
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                />
              </Route>
              <Route path="/package-info">
                <PackageInfo
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                  lookupCodes={this.state.lookupCodes}
                />
              </Route>
              <Route path="/hazmat">
                <Hazmat
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                  lookupCodes={this.state.lookupCodes}
                />
              </Route>
              <Route path="/service-options">
                <ServiceOptions
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                  onDestination={this.onDestination}
                  lookupCodes={this.state.lookupCodes}
                />
              </Route>
              <Route path="/extra-services">
                <ExtraServices
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                  lookupCodes={this.state.lookupCodes}
                />
              </Route>
              <Route path="/sender-info">
                <SenderInfo
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  lookupCodes={this.state.lookupCodes}
                  setPageErrors={this.setPageErrors}
                />
              </Route>
              <Route path="/recipient-info">
                <RecipientInfo
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  lookupCodes={this.state.lookupCodes}
                  setPageErrors={this.setPageErrors}
                />
              </Route>
              <Route path="/non-delivery-option">
                <NonDeliveryOption
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                />
              </Route>
              <Route path="/package-contents">
                <PackageContents
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                />
              </Route>
              <Route path="/commercial-sender">
                <CommercialSender
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                />
              </Route>
              <Route path="/item-entry">
                <ItemEntry
                  countryList={this.countryList}
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                  lookupCodes={this.state.lookupCodes}
                  custRegEmail={this.state.custRegEmail}
                />
              </Route>
              <Route path="/aes-exemptions">
                <AesExemptions
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                />
              </Route>
              <Route path="/summary">
                <Summary
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                  custRegEmail={this.state.custRegEmail}
                  countryList={this.countryList}
                />
              </Route>
              <Route path="/thank-you">
                <ThankYou
                  loggedIn={this.state.loggedIn}
                  loginCheck={this.loginCheck}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  setPageErrors={this.setPageErrors}
                />
              </Route>
              <Route path="/customs-history">
                <ShippingHistory
                  loggedIn={this.state.loggedIn}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  lookupCodes={this.state.lookupCodes}
                  setPageErrors={this.setPageErrors}
                />
              </Route>

              <Route path="/customs-details">
                <LabelDetails
                  loggedIn={this.state.loggedIn}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  lookupCodes={this.state.lookupCodes}
                  setPageErrors={this.setPageErrors}
                />
              </Route>

              <Route path="/preferences">
                <Preferences
                  loggedIn={this.state.loggedIn}
                  stepNumberCallback={this.stepNumberCallback}
                  toggleLoadingBar={this.toggleLoadingBar}
                  lookupCodes={this.state.lookupCodes}
                  setPageErrors={this.setPageErrors}
                />
              </Route>
              <Route
                path="/addressBook"
                component={() => {
                  window.location.href =
                    this.state.lookupCodes["cfo.addressbook.link"] ||
                    "https://gab.usps.com/";
                  return null;
                }}
              />

              <Route
                component={() => {
                  window.location.href =
                    "https://www.usps.com/root/global/server_responses/anyapp_outage_apology.htm";
                  return null;
                }}
              />
            </Switch>
          ) : (
            <React.Fragment />
          )}
          {this.state.showGlobalLoadingBar || this.state.showLoadingBar ? (
            <WhiteSpinner />
          ) : null}
        </Router>
      </div>
    );
  }
}

export default App;
