import React from "react";

import axios from "axios";
import { withRouter } from "react-router-dom";
import PackageWeightPopover from "./Popovers/PackageWeightPopover";
import TextUpdatePopover from "./Popovers/TextUpdatePopover";
import AddressStandardizedPopover from "./Popovers/AddressStandardizedPopover";
import GirthModal from "./Modals/GirthModal";
//import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";
//import * as analytics from "../Analytics/Analytics.js";
import LoggedInNav from "./LoggedInNav";
import caretRight from "../customs_forms/images/caret-right.svg";
import InputFields from "./Subcomponents/InputFields";
import StatesTerritoriesList from "./Subcomponents/StatesTerritoriesList";

import AddressBook from "./Modals/AddressBook";

import * as analytics from "../Components/Analytics/Analytics.js";
var modalAddressBook = "";
var dataLayer = {
  flatRate: "",
  packageWeight: "",
  packageDimensions: "",
  serviceType: "",
  packageType: "",
  print: "",
  zipCode: "",
  iWantTrackingNote: "",
  expectedDeliveryUpdates: 0,
  packageInTransitUpdates: 0,
  dayOfDeliveryUpdates: 0,
  packageDelivered: 0,
  availableForPickup: 0,
  deliveryExceptionUpdates: 0,
  updateAddress: ""
};
class Preferences extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.props.stepNumberCallback(0);
    this.state = {
      isLoaded: false,
      // Package Options
      flatRate: null,
      pounds: "",
      ounces: "",
      length: "",
      returnAddressBookmodal: false,
      width: "",
      height: "",
      isNotRectangularChecked: false,
      girth: "",
      selectedServiceType: "IPM",
      selectedProductCode: null,
      // Shipment Notifications
      iWantTrackingNote: false,
      emailUpdates: false,
      emailAddress: "",
      textUpdates: false,
      phoneNumber: "",
      allUpdates: false,
      selectUpdates: false,
      expectedDeliveryUpdates: false,
      dayOfDeliveryUpdates: false,
      packageDelivered: false,
      availableForPickup: false,
      deliveryExceptionUpdates: false,
      packageInTransitUpdates: false,
      // Print
      printWithReceipt: null,
      // Reference Number
      referenceNumber: "",
      // Return Address
      firstName: "",
      middle: "",
      lastName: "",
      company: "",
      streetAddress: "",
      otherAddress: "",
      city: "",
      stateId: "-1",
      stateAbbr: "",
      state: "",
      zipCode: "",
      urbanization: "",
      updateAddress: false
    };
  }

  componentDidMount() {
    this.props.toggleLoadingBar(true);
    this.refreshPreferences();
    this.chooseOption("packageOptions");
  }

  refreshPreferences = () => {
    axios
      .get("/CFOApplication/fetchUserPreferences", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
      .then((response) => {
        if (
          null !== response.data.preferencesData &&
          !response.data.loggedOut
        ) {
          // Remove any products that should not be selectable on this page
          let selectableServiceTypes = {};
          let serviceTypes = response.data.availableProducts;
          for (const property in serviceTypes) {
            if (
              Array.isArray(serviceTypes[property]) &&
              this.isValidProductClass(property)
            ) {
              selectableServiceTypes[property] = serviceTypes[property];
            }
          }
          response.data.availableProducts = selectableServiceTypes;
          // Find the service type to preselect when fetching preferences
          let selectedServiceType = null;
          if (response.data.preferencesData.domesticProductId) {
            serviceTypes = response.data.availableProducts;
            for (const property in serviceTypes) {
              if (Array.isArray(serviceTypes[property])) {
                let serviceType = serviceTypes[property].find(
                  (element) =>
                    element.productId ===
                    response.data.preferencesData.domesticProductId.productId
                );
                if (serviceType !== undefined) {
                  selectedServiceType = serviceType.productClass;
                  break;
                }
              }
            }
          }

          this.setState({
            isLoaded: true,
            preferencesData: response.data,
            // Package Options
            flatRate: response.data.preferencesData.flatRateInd || null,
            pounds: response.data.preferencesData.pounds || "",
            ounces: response.data.preferencesData.ounces || "",
            length: response.data.preferencesData.pkgLengthQty || "",
            width: response.data.preferencesData.pkgWidthQty || "",
            height: response.data.preferencesData.pkgHeightQty || "",
            isNotRectangularChecked:
              response.data.preferencesData.pkgShape === "NR",
            girth: response.data.preferencesData.pkgGirthQty || "",
            selectedServiceType: selectedServiceType || "IPM",
            selectedProductCode:
              response.data.preferencesData.domesticProductId &&
              response.data.preferencesData.domesticProductId.productCode
                ? response.data.preferencesData.domesticProductId.productCode
                : null,
            // Shipment Notifications
            iWantTrackingNote:
              response.data.preferencesData.ptsNotifyInd === "Y" ? true : false,
            emailUpdates:
              response.data.preferencesData.emailUpdatesInd === "Y"
                ? true
                : false,
            emailAddress: response.data.preferencesData.ptsTrackingEmail || "",
            textUpdates:
              response.data.preferencesData.ptsTxtUpdatesInd === "Y"
                ? true
                : false,
            phoneNumber: response.data.preferencesData.ptsTxtPhoneNbr || "",
            allUpdates:
              response.data.preferencesData.ptsExpectedDelvInd === "Y" &&
              response.data.preferencesData.ptsDayOfDelvInd === "Y" &&
              response.data.preferencesData.ptsDeliveredToAddrInd === "Y" &&
              response.data.preferencesData.ptsPickupAvailableInd === "Y" &&
              response.data.preferencesData.ptsAlertsOtherInd === "Y" &&
              response.data.preferencesData.ptsPkgInTransitInd === "Y"
                ? true
                : false,
            selectUpdates:
              response.data.preferencesData.ptsExpectedDelvInd === "Y" ||
              response.data.preferencesData.ptsDayOfDelvInd === "Y" ||
              response.data.preferencesData.ptsDeliveredToAddrInd === "Y" ||
              response.data.preferencesData.ptsPickupAvailableInd === "Y" ||
              response.data.preferencesData.ptsAlertsOtherInd === "Y" ||
              response.data.preferencesData.ptsPkgInTransitInd === "Y"
                ? true
                : false,
            expectedDeliveryUpdates:
              response.data.preferencesData.ptsExpectedDelvInd === "Y"
                ? true
                : false,
            dayOfDeliveryUpdates:
              response.data.preferencesData.ptsDayOfDelvInd === "Y"
                ? true
                : false,
            packageDelivered:
              response.data.preferencesData.ptsDeliveredToAddrInd === "Y"
                ? true
                : false,
            availableForPickup:
              response.data.preferencesData.ptsPickupAvailableInd === "Y"
                ? true
                : false,
            deliveryExceptionUpdates:
              response.data.preferencesData.ptsAlertsOtherInd === "Y"
                ? true
                : false,
            packageInTransitUpdates:
              response.data.preferencesData.ptsPkgInTransitInd === "Y"
                ? true
                : false,
            // Print
            printWithReceipt:
              response.data.preferencesData.printOptions || null,
            // Reference Number
            referenceNumber: response.data.preferencesData.refNbr || "",
            // Return Address
            firstName:
              response.data.preferencesData.userReturnAddr &&
              response.data.preferencesData.userReturnAddr.firstName
                ? response.data.preferencesData.userReturnAddr.firstName
                : "",
            middle:
              response.data.preferencesData.userReturnAddr &&
              response.data.preferencesData.userReturnAddr.middleInt
                ? response.data.preferencesData.userReturnAddr.middleInt
                : "",
            lastName:
              response.data.preferencesData.userReturnAddr &&
              response.data.preferencesData.userReturnAddr.lastName
                ? response.data.preferencesData.userReturnAddr.lastName
                : "",
            company:
              response.data.preferencesData.userReturnAddr &&
              response.data.preferencesData.userReturnAddr.company
                ? response.data.preferencesData.userReturnAddr.company
                : "",
            streetAddress:
              response.data.preferencesData.userReturnAddr &&
              response.data.preferencesData.userReturnAddr.line1Addr
                ? response.data.preferencesData.userReturnAddr.line1Addr
                : "",
            otherAddress:
              response.data.preferencesData.userReturnAddr &&
              response.data.preferencesData.userReturnAddr.line2Addr
                ? response.data.preferencesData.userReturnAddr.line2Addr
                : "",
            city:
              response.data.preferencesData.userReturnAddr &&
              response.data.preferencesData.userReturnAddr.cityName
                ? response.data.preferencesData.userReturnAddr.cityName
                : "",
            stateId:
              response.data.preferencesData.userReturnAddr &&
              response.data.preferencesData.userReturnAddr.stateID
                ? String(response.data.preferencesData.userReturnAddr.stateID)
                : "-1",
            stateAbbr:
              response.data.preferencesData.userReturnAddr &&
              response.data.preferencesData.userReturnAddr.stateCode
                ? response.data.preferencesData.userReturnAddr.stateCode
                : "",
            zipCode:
              response.data.preferencesData.userReturnAddr &&
              response.data.preferencesData.userReturnAddr.postalCode
                ? response.data.preferencesData.userReturnAddr.postalCode
                : "",
            urbanization:
              response.data.preferencesData.userReturnAddr &&
              response.data.preferencesData.userReturnAddr.urbanization
                ? response.data.preferencesData.userReturnAddr.urbanization
                : ""
          });
        } else if (response.data.loggedOut) {
          // User is not logged in
          window.location.replace("/index");
        } else {
          this.setState({
            isLoaded: true,
            preferencesData: response.data
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.props.toggleLoadingBar(false);
      });
  };

  // Check if the provided product class is valid to display on this page
  isValidProductClass = (productClass) => {
    const validProductClasses = ["PRI", "EXM", "FCPSR", "IPM", "IEM", "IFC"];
    return validProductClasses.includes(productClass);
  };

  chooseOption = (optionClicked) => {
    this.setState((prevState) => {
      return {
        optionClicked:
          optionClicked !== prevState.optionClicked ? optionClicked : ""
      };
    });
  };

  setServiceType = (e) => {
    this.setState({
      selectedServiceTypeEdit: e.target.value,
      selectedProductCodeEdit: null
    });
  };

  setPackageType = (itemValue) => {
    this.setState({ selectedProductCodeEdit: itemValue });
  };

  savePackageOptions = (e) => {
    e.preventDefault();
    let dataToSend = {};
    dataToSend.flatRate = this.getInputFieldValue("flatRate");
    dataToSend.totalWeightOz =
      Number(this.getInputFieldValue("pounds")) * 16 +
      Number(this.getInputFieldValue("ounces"));
    dataToSend.length = this.getInputFieldValue("length");
    dataToSend.width = this.getInputFieldValue("width");
    dataToSend.height = this.getInputFieldValue("height");
    dataToSend.isNotRectangularChecked = this.getInputFieldValue(
      "isNotRectangularChecked"
    );
    dataToSend.girth = this.getInputFieldValue("girth");
    dataToSend.productCode = this.getInputFieldValue("selectedProductCode");
    this.props.toggleLoadingBar(true);
    axios
      .post("/CFOApplication/savePackageOptions", JSON.stringify(dataToSend), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
      .then((response) => {
        this.cancelEdit();
        this.refreshPreferences();

        if (dataToSend.pounds !== "" && dataToSend.ounces !== "") {
          dataLayer.packageWeight =
            dataToSend.pounds + "lbs. " + dataToSend.ounces + "oz.";
        } else {
          dataLayer.packageWeight = "Package Weight Not Entered ";
        }

        if (
          dataToSend.length !== "" &&
          dataToSend.width !== "" &&
          dataToSend.height !== ""
        ) {
          dataLayer.packageDimensions =
            dataToSend.length +
            " in x" +
            dataToSend.width +
            " in x " +
            dataToSend.height +
            " in";
        } else {
          dataLayer.packageDimensions = "Package Dimensions Not Entered ";
        }
        dataLayer.flatRate =
          dataToSend.flatRate === "Y"
            ? "Flat Rate Selected"
            : "Flat Rate Not Selected ";
        if (dataToSend.girth !== "") {
          dataLayer.packageDimensions = "Package Dimensions Not Standard ";
        }

        dataLayer.serviceType = this.state.selectedServiceType;
        dataLayer.packageType = this.state.selectedProductCode;
        analytics.setUserPreferencePODataLayer(dataLayer);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  };

  selectAllUpdates = () => {
    this.setState({
      allUpdatesEdit: true,
      selectUpdatesEdit: false,
      expectedDeliveryUpdatesEdit: true,
      dayOfDeliveryUpdatesEdit: true,
      packageDeliveredEdit: true,
      availableForPickupEdit: true,
      deliveryExceptionUpdatesEdit: true,
      packageInTransitUpdatesEdit: true
    });
  };

  selectSomeUpdates = () => {
    this.setState({
      allUpdatesEdit: false,
      selectUpdatesEdit: true,
      expectedDeliveryUpdatesEdit: false,
      dayOfDeliveryUpdatesEdit: false,
      packageDeliveredEdit: false,
      availableForPickupEdit: false,
      deliveryExceptionUpdatesEdit: false,
      packageInTransitUpdatesEdit: false
    });
  };

  saveShipmentNotifications = (e) => {
    e.preventDefault();
    let dataToSend = {};
    dataToSend.iWantTrackingNote = this.getInputFieldValue("iWantTrackingNote");
    dataToSend.emailUpdates = this.getInputFieldValue("emailUpdates");
    dataToSend.emailAddress = this.getInputFieldValue("emailAddress");
    dataToSend.textUpdates = this.getInputFieldValue("textUpdates");
    dataToSend.phoneNumber = this.getInputFieldValue("phoneNumber");
    dataToSend.expectedDeliveryUpdates = this.getInputFieldValue(
      "expectedDeliveryUpdates"
    );
    dataToSend.dayOfDeliveryUpdates = this.getInputFieldValue(
      "dayOfDeliveryUpdates"
    );
    dataToSend.packageDelivered = this.getInputFieldValue("packageDelivered");
    dataToSend.availableForPickup =
      this.getInputFieldValue("availableForPickup");
    dataToSend.deliveryExceptionUpdates = this.getInputFieldValue(
      "deliveryExceptionUpdates"
    );
    dataToSend.packageInTransitUpdates = this.getInputFieldValue(
      "packageInTransitUpdates"
    );
    this.props.toggleLoadingBar(true);
    axios
      .post(
        "/CFOApplication/saveShipmentNotifications",
        JSON.stringify(dataToSend),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      )
      .then((response) => {
        this.cancelEdit();
        this.refreshPreferences();
        if (this.state.iWantTrackingNote) {
          dataLayer.iWantTrackingNote = "Notify Me";
        } else {
          dataLayer.iWantTrackingNote = "Do Not Notify Me";
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  };

  savePrint = (e) => {
    e.preventDefault();
    let dataToSend = {};

    dataToSend.printOptions = this.getInputFieldValue("printWithReceipt");
    this.props.toggleLoadingBar(true);
    axios
      .post("/CFOApplication/savePrint", JSON.stringify(dataToSend), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
      .then((response) => {
        this.cancelEdit();
        this.refreshPreferences();
        if (dataToSend.printOptions === "N") {
          dataLayer.print = "Standard 8.5 x 11 ";
          analytics.setUserPreferencePrintDataLayer(dataLayer);
        } else if (dataToSend.printOptions === "Y") {
          dataLayer.print = "Label Printer Compatible 4 x 6 ";

          analytics.setUserPreferencePrintDataLayer(dataLayer);
        }

        if (this.state.allUpdates) {
          dataLayer.expectedDeliveryUpdates = 1;
          dataLayer.dayOfDeliveryUpdates = 1;
          dataLayer.packageDelivered = 1;
          dataLayer.availableForPickup = 1;
          dataLayer.packageInTransitUpdates = 1;
          dataLayer.deliveryExceptionUpdates = 1;
        } else {
          if (dataToSend.expectedDeliveryUpdates) {
            dataLayer.expectedDeliveryUpdates = 1;
          } else {
            dataLayer.expectedDeliveryUpdates = 0;
          }
          if (dataToSend.dayOfDeliveryUpdates) {
            dataLayer.dayOfDeliveryUpdates = 1;
          } else {
            dataLayer.dayOfDeliveryUpdates = 0;
          }
          if (dataToSend.packageDelivered) {
            dataLayer.packageDelivered = 1;
          } else {
            dataLayer.packageDelivered = 0;
          }
          if (dataToSend.availableForPickup) {
            dataLayer.availableForPickup = 1;
          } else {
            dataLayer.availableForPickup = 0;
          }
          if (dataToSend.deliveryExceptionUpdates) {
            dataLayer.deliveryExceptionUpdates = 1;
          } else {
            dataLayer.deliveryExceptionUpdates = 0;
          }
          if (dataToSend.packageInTransitUpdates) {
            dataLayer.packageInTransitUpdates = 1;
          } else {
            dataLayer.packageInTransitUpdates = 0;
          }
        }
        analytics.setUserPreferenceSNDataLayer(dataLayer);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  };

  saveRefNumber = (e) => {
    e.preventDefault();
    this.props.toggleLoadingBar(true);
    axios
      .post(
        "/CFOApplication/saveRefNumber?refNumber=" +
          this.getInputFieldValue("referenceNumber"),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      )
      .then((response) => {
        this.cancelEdit();
        this.refreshPreferences();
        analytics.setUserPreferenceRNDataLayer();
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  };

  saveReturnAddress = (e) => {
    e.preventDefault();
    let dataToSend = {};
    dataToSend.firstName = this.getInputFieldValue("firstName");
    dataToSend.middleIntial = this.getInputFieldValue("middle");
    dataToSend.lastName = this.getInputFieldValue("lastName");
    dataToSend.company = this.getInputFieldValue("company");
    dataToSend.lineOneAddr = this.getInputFieldValue("streetAddress");
    dataToSend.lineTwoAddr = this.getInputFieldValue("otherAddress");
    dataToSend.city = this.getInputFieldValue("city");
    dataToSend.stateId = this.getInputFieldValue("stateId");
    dataToSend.state = this.getInputFieldValue("stateAbbr");
    dataToSend.zipCode = this.getInputFieldValue("zipCode");
    dataToSend.urbanizationCode = this.getInputFieldValue("urbanization");
    this.props.toggleLoadingBar(true);

    if (this.state.updateAddress) {
      const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));
      let dataToSaveAddressBook = {};
      dataToSaveAddressBook.returnContact = "true";
      dataToSaveAddressBook.userId = accountInfo.customerID;
      dataToSaveAddressBook.contactId = "-1";
      dataToSaveAddressBook.firstName = this.getInputFieldValue("firstName");
      dataToSaveAddressBook.middleIntial = this.getInputFieldValue("middle");
      dataToSaveAddressBook.lastName = this.getInputFieldValue("lastName");
      dataToSaveAddressBook.companyName = this.getInputFieldValue("company");
      dataToSaveAddressBook.addressId = "-1";
      dataToSaveAddressBook.addressLine1 =
        this.getInputFieldValue("streetAddress");
      dataToSaveAddressBook.city = this.getInputFieldValue("city");
      dataToSaveAddressBook.stateCode = this.getInputFieldValue("stateAbbr");
      dataToSaveAddressBook.zipCode = this.getInputFieldValue("zipCode");
      dataToSaveAddressBook.urbanizationCode =
        this.getInputFieldValue("urbanization");
      axios
        .post(
          "/CFOApplication/savetoaddressbook",
          JSON.stringify(dataToSaveAddressBook),
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json"
            }
          }
        )
        .then((response) => {
          console.log("success" + response);
        });
    }

    axios
      .post("/CFOApplication/saveReturnAddress", JSON.stringify(dataToSend), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
      .then((response) => {
        if (
          response.data.preferencesData &&
          response.data.preferencesData.error
        ) {
          this.props.setPageErrors([response.data.preferencesData.error]);
          this.props.toggleLoadingBar(false);
        } else {
          this.cancelEdit();
          this.refreshPreferences();
          dataLayer.zipcode = dataToSend.zipCode;
          if (this.state.updateAddress) {
            dataLayer.updateAddress = "Save to Address Book ";
          } else {
            dataLayer.updateAddress = "Do Not Save to Address Book ";
          }
          analytics.setUserPreferenceRADataLayer(dataLayer);
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  };

  gabReturnAddress = (addressFieldType) => (info) => {
    if (info && info.address) {
      this.setState({
        firstNameEdit: info.firstName || "",
        middleEdit: info.middleName || "",
        lastNameEdit: info.lastName || "",
        companyEdit: info.companyName || "",
        streetAddressEdit: info.address.addressLine1 || "",
        otherAddressEdit: info.address.addressLine2 || "",
        cityEdit: info.address.city || "",
        stateIdEdit:
          info.address.state && info.address.state.stateDbId
            ? String(info.address.state.stateDbId)
            : "-1",
        stateAbbrEdit:
          info.address.state && info.address.state.stateCode
            ? info.address.state.stateCode
            : "",
        zipCodeEdit: info.address.zipCode || "",
        urbanizationEdit: info.address.urbanizationCode || ""
      });
    }
  };

  getAvailableServiceTypes = () => {
    if (this.state.preferencesData.availableProducts) {
      return (
        <React.Fragment>
          {this.state.preferencesData.availableProducts["PRI"] ? (
            <option value="PRI">Priority Mail®</option>
          ) : null}
          {this.state.preferencesData.availableProducts["EXM"] ? (
            <option value="EXM">Priority Mail Express®</option>
          ) : null}
          {this.state.preferencesData.availableProducts["FCPSR"] ? (
            <option value="FCPSR">First-Class Package Service - Retail™</option>
          ) : null}
          {this.state.preferencesData.availableProducts["IPM"] ? (
            <option value="IPM">Priority Mail International®</option>
          ) : null}
          {this.state.preferencesData.availableProducts["IEM"] ? (
            <option value="IEM">Priority Mail Express International®</option>
          ) : null}
          {this.state.preferencesData.availableProducts["IFC"] ? (
            <option value="IFC">
              First-Class Package International Service®
            </option>
          ) : null}
        </React.Fragment>
      );
    }
  };

  // Determine what value to show for the provided input field.
  // 1) If the user has explicitly edited the field, use that value (inputFieldEdit)
  // 2) Otherwise, use the original value from the endpoint call (inputField)
  getInputFieldValue = (inputField) => {
    const inputFieldEdit = inputField + "Edit";
    if (this.state[inputFieldEdit] !== undefined) {
      return this.state[inputFieldEdit];
    } else {
      return this.state[inputField];
    }
  };

  // When a user clicks "Cancel" (or "Save"),
  // we want to undo all changes to the fields
  // in that section and restore them back to what they were from the
  // original endpoint call.
  // To do this, set the corresponding "...Edit" variables to undefined.
  //
  // If the user clicked "Save", we still want to remove the changes in these
  // "...Edit" variables since we make a fresh call to fetch the updated
  // preferences right afterward.
  //
  // Collapse the section when finished.
  cancelEdit = () => {
    this.setState((prevState) => {
      switch (prevState.optionClicked) {
        case "packageOptions":
          return {
            flatRateEdit: undefined,
            poundsEdit: undefined,
            ouncesEdit: undefined,
            lengthEdit: undefined,
            widthEdit: undefined,
            heightEdit: undefined,
            isNotRectangularCheckedEdit: undefined,
            girthEdit: undefined,
            selectedServiceTypeEdit: undefined,
            selectedProductCodeEdit: undefined
          };
        case "shipmentNotification":
          return {
            iWantTrackingNoteEdit: undefined,
            emailUpdatesEdit: undefined,
            emailAddressEdit: undefined,
            textUpdatesEdit: undefined,
            phoneNumberEdit: undefined,
            allUpdatesEdit: undefined,
            selectUpdatesEdit: undefined,
            expectedDeliveryUpdatesEdit: undefined,
            dayOfDeliveryUpdatesEdit: undefined,
            packageDeliveredEdit: undefined,
            availableForPickupEdit: undefined,
            deliveryExceptionUpdatesEdit: undefined,
            packageInTransitUpdatesEdit: undefined
          };
        case "print":
          return {
            printWithReceiptEdit: undefined
          };
        case "referenceNumber":
          return {
            referenceNumberEdit: undefined
          };
        case "returnAddress":
          return {
            firstNameEdit: undefined,
            middleEdit: undefined,
            lastNameEdit: undefined,
            companyEdit: undefined,
            streetAddressEdit: undefined,
            otherAddressEdit: undefined,
            cityEdit: undefined,
            stateIdEdit: undefined,
            stateAbbrEdit: undefined,
            zipCodeEdit: undefined,
            urbanizationEdit: undefined,
            updateAddress: false
          };
        default:
          return;
      }
    });
    this.chooseOption(this.state.optionClicked);
  };

  // When a user clicks "Clear", we want to empty all inputs to the fields
  // in that section.
  // To do this, set the corresponding "...Edit" variables
  // to their "cleared" values ("", null, false, etc. depending on the field)
  clearInputs = () => {
    this.setState((prevState) => {
      switch (prevState.optionClicked) {
        case "packageOptions":
          return {
            flatRateEdit: null,
            poundsEdit: "",
            ouncesEdit: "",
            lengthEdit: "",
            widthEdit: "",
            heightEdit: "",
            isNotRectangularCheckedEdit: false,
            girthEdit: "",
            selectedServiceTypeEdit: "IPM",
            selectedProductCodeEdit: null
          };
        case "print":
          return {
            printWithReceiptEdit: null
          };
        case "returnAddress":
          return {
            firstNameEdit: "",
            middleEdit: "",
            lastNameEdit: "",
            companyEdit: "",
            streetAddressEdit: "",
            otherAddressEdit: "",
            cityEdit: "",
            stateIdEdit: "-1",
            stateAbbrEdit: "",
            zipCodeEdit: "",
            urbanizationEdit: "",
            updateAddress: false
          };
        default:
          return;
      }
    });
  };

  render() {
    var isChecked = this.state.optionClicked === "returnAddress" ? true : false;
    if (isChecked) {
      modalAddressBook = (
        <div className="headline-buttons">
          <ul>
            <li>
              <strong>Return address</strong>
            </li>

            <li>
              <AddressBook
                buttonLabel={"Address Book"}
                changeDeliveryAddress={this.gabReturnAddress}
                addressFieldType={"GABReturn"}
                showInternational={"false"}
                targetName="btn-open-contact-return"
                lookupCodes={this.props.lookupCodes}
              />
            </li>
          </ul>
        </div>
      );
    } else {
      modalAddressBook = (
        <div className="headline-buttons hideAddress">
          <ul>
            <li>
              <strong>Return address</strong>
            </li>

            <li>
              <AddressBook
                buttonLabel={"Address Book"}
                changeDeliveryAddress={this.gabReturnAddress}
                addressFieldType={"GABReturn"}
                showInternational={"false"}
                targetName="btn-open-contact-return"
                lookupCodes={this.props.lookupCodes}
              />
            </li>
          </ul>
        </div>
      );
    }
    return (
      <React.Fragment>
        {" "}
        {/* <AssistiveGlobalError
          globalErrorArray={this.state.errors.globalError}
          styling={{ paddingLeft: "15px" }}
        /> */}
        {this.state.isLoaded ? (
          <React.Fragment>
            <div id="cfo-wrapper">
              <div id="inner">
                <main className="section" id="customs-form-2019-main">
                  <section className="section" id="app-form">
                    <div className="container">
                      <div className="row section-row align-items-center">
                        <div className="col-lg-4">
                          <h1>Customs Form Preferences</h1>
                        </div>
                        <div className="col-lg-8">
                          {this.props.loggedIn ? (
                            <LoggedInNav />
                          ) : (
                            <ul className="title-links">
                              <li>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                                >
                                  FAQs{" "}
                                  <span>
                                    <img src={caretRight} alt="open link" />
                                  </span>
                                </a>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>

                      <div className="row section-row desktopView">
                        <div className="col-lg-12">
                          <div className="accordion">
                            <div className="accordion-items">
                              <div className="accordion-item">
                                <div className="row">
                                  <div className="col-lg-5">
                                    <div
                                      className="accordion-title"
                                      onClick={(e) =>
                                        this.chooseOption("packageOptions")
                                      }
                                    >
                                      <h2>Package Options</h2>

                                      <div className="package-option">
                                        <p>
                                          Set the package option you use the
                                          most to be selected when you create
                                          your international or domestic label.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className="accordion-title"
                                      onClick={(e) =>
                                        this.chooseOption(
                                          "shipmentNotification"
                                        )
                                      }
                                    >
                                      <h2>Shipment Notifications</h2>

                                      <div className="package-option">
                                        <p>
                                          Let us know how you would like to be
                                          contacted after a package is enroute
                                          to its destination.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className="accordion-title"
                                      onClick={(e) =>
                                        this.chooseOption("print")
                                      }
                                    >
                                      <h2>Print</h2>
                                      <div className="package-option">
                                        <p>
                                          Save print settings you would like to
                                          appear on the confirmation page.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className="accordion-title"
                                      onClick={(e) =>
                                        this.chooseOption("referenceNumber")
                                      }
                                    >
                                      <h2>Reference Number</h2>
                                      <div className="package-option">
                                        <p>
                                          This number is for your reference
                                          only. You may use up to 10 characters.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className="accordion-title"
                                      onClick={(e) =>
                                        this.chooseOption("returnAddress")
                                      }
                                    >
                                      <h2>Return Address</h2>
                                      <div className="package-option">
                                        <p>
                                          Save the return address we should use
                                          on your label.This does not change
                                          your customer registered address.
                                        </p>
                                      </div>
                                    </div>{" "}
                                  </div>
                                  <div className="col-lg-7">
                                    {this.state.optionClicked ===
                                    "packageOptions" ? (
                                      <div className="accordion-content">
                                        <form
                                          className="customs-form"
                                          id="preferences-default-package-options"
                                          action="#"
                                          method="post"
                                        >
                                          <div className="row section-row">
                                            <div className="col-lg-6 my-2 my-lg-0">
                                              <label>
                                                <input
                                                  type="radio"
                                                  name="default_shipping_method"
                                                  id="flat-rate-radio-id"
                                                  className="has-dependent"
                                                  data-dependent="0"
                                                  checked={
                                                    this.getInputFieldValue(
                                                      "flatRate"
                                                    ) === "Y"
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      flatRateEdit: "Y"
                                                    });
                                                  }}
                                                />
                                                <strong
                                                  style={{
                                                    marginLeft: "7px"
                                                  }}
                                                >
                                                  I am shipping Flat Rate
                                                </strong>
                                                <br />
                                              </label>

                                              <div className="note">
                                                If it fits, it ships
                                                <sup>&reg; </sup> anywhere in
                                                the U.S.up to 70 lbs.
                                              </div>
                                            </div>

                                            <div className="col-lg-6 my-2 my-lg-0">
                                              <label>
                                                <input
                                                  type="radio"
                                                  name="default_shipping_method"
                                                  id="package-weight-radio-id"
                                                  className="has-dependent"
                                                  data-dependent="1"
                                                  checked={
                                                    this.getInputFieldValue(
                                                      "flatRate"
                                                    ) === "N"
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      flatRateEdit: "N"
                                                    });
                                                  }}
                                                />
                                                <strong
                                                  style={{
                                                    marginLeft: "7px"
                                                  }}
                                                >
                                                  Enter Package Weight
                                                </strong>
                                                <br />
                                              </label>

                                              <div className="note">
                                                What if I don't know my package
                                                weight?{" "}
                                                <PackageWeightPopover targetName="returnPackageWeightPopover" />
                                              </div>
                                            </div>
                                          </div>

                                          {this.getInputFieldValue(
                                            "flatRate"
                                          ) === "N" ? (
                                            <div className="row">
                                              <div className="col-12">
                                                <div
                                                  className="row row-dependent"
                                                  id="row-dependent-1"
                                                >
                                                  <div className="col-12">
                                                    <div className="row section-row">
                                                      <div className="col-lg-12">
                                                        &nbsp;
                                                      </div>
                                                      <div className="col-12">
                                                        <div className="row">
                                                          <div className="col-6">
                                                            <div className="form-group">
                                                              <div className="inline-label-wrapper">
                                                                <label htmlFor="weightPounds">
                                                                  *Package
                                                                  Weight
                                                                </label>
                                                              </div>
                                                              <div className="field-wrapper with-suffix suffix-is-3-chars">
                                                                <input
                                                                  name="weightPounds"
                                                                  id="weightPounds"
                                                                  className="form-control numbersonly"
                                                                  type="number"
                                                                  data-field-suffix="lbs"
                                                                  value={this.getInputFieldValue(
                                                                    "pounds"
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    this.setState(
                                                                      {
                                                                        poundsEdit:
                                                                          e
                                                                            .target
                                                                            .value
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                                <span className="field-icon field-suffix">
                                                                  lbs
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="col-6">
                                                            <div className="form-group">
                                                              <div className="inline-label-wrapper">
                                                                <label htmlFor="weightOunces">
                                                                  &nbsp;
                                                                </label>
                                                              </div>
                                                              <div className="field-wrapper with-suffix suffix-is-2-chars">
                                                                <input
                                                                  name="weightOunces"
                                                                  id="weightOunces"
                                                                  className="form-control numbersonly"
                                                                  max="15"
                                                                  type="number"
                                                                  data-field-suffix="oz"
                                                                  value={this.getInputFieldValue(
                                                                    "ounces"
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    this.setState(
                                                                      {
                                                                        ouncesEdit:
                                                                          e
                                                                            .target
                                                                            .value
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                                <span className="field-icon field-suffix">
                                                                  oz
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="row section-row">
                                                          <div className="col-lg-12">
                                                            <p>
                                                              Enter package
                                                              dimensions to see
                                                              only package types
                                                              that fit your
                                                              shipment.
                                                            </p>
                                                          </div>
                                                        </div>

                                                        <div className="row section-row">
                                                          <InputFields
                                                            outerDivClassList="col-lg-4"
                                                            isRequired={false}
                                                            inputId="length"
                                                            labelText="Length"
                                                            inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                                                            inputClassList="form-control numbersonly has-dependent"
                                                            inputType="number"
                                                            inputValue={this.getInputFieldValue(
                                                              "length"
                                                            )}
                                                            inputOnChange={(
                                                              e
                                                            ) => {
                                                              this.setState({
                                                                lengthEdit:
                                                                  e.target.value
                                                              });
                                                            }}
                                                            fieldSuffix="in"
                                                          />

                                                          <InputFields
                                                            outerDivClassList="col-lg-4"
                                                            isRequired={false}
                                                            inputId="width"
                                                            labelText="Width"
                                                            inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                                                            inputClassList="form-control numbersonly has-dependent"
                                                            inputType="number"
                                                            inputValue={this.getInputFieldValue(
                                                              "width"
                                                            )}
                                                            inputOnChange={(
                                                              e
                                                            ) => {
                                                              this.setState({
                                                                widthEdit:
                                                                  e.target.value
                                                              });
                                                            }}
                                                            fieldSuffix="in"
                                                          />
                                                          <InputFields
                                                            outerDivClassList="col-lg-4"
                                                            isRequired={false}
                                                            inputId="height"
                                                            labelText="Height"
                                                            inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                                                            inputClassList="form-control numbersonly has-dependent"
                                                            inputType="number"
                                                            inputValue={this.getInputFieldValue(
                                                              "height"
                                                            )}
                                                            inputOnChange={(
                                                              e
                                                            ) => {
                                                              this.setState({
                                                                heightEdit:
                                                                  e.target.value
                                                              });
                                                            }}
                                                            fieldSuffix="in"
                                                          />
                                                        </div>

                                                        <div
                                                          className="row section-row row-dependent"
                                                          id="row-dependent-5"
                                                        >
                                                          <div className="col-12">
                                                            <div className="row section-row">
                                                              <div className="col-12">
                                                                <div className="usps-checkbox checkbox-field">
                                                                  <input
                                                                    type="checkbox"
                                                                    id="packageNotRectangular"
                                                                    name="packageNotRectangular"
                                                                    className="form-control checkbox-field has-dependent"
                                                                    data-dependent="6"
                                                                    checked={this.getInputFieldValue(
                                                                      "isNotRectangularChecked"
                                                                    )}
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      this.setState(
                                                                        {
                                                                          isNotRectangularCheckedEdit:
                                                                            e
                                                                              .target
                                                                              .checked
                                                                        }
                                                                      );
                                                                    }}
                                                                  />
                                                                  <label htmlFor="packageNotRectangular">
                                                                    This package
                                                                    isn't a
                                                                    standard
                                                                    rectangular
                                                                    box.
                                                                  </label>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {this.getInputFieldValue(
                                                              "isNotRectangularChecked"
                                                            ) ? (
                                                              <div
                                                                className="row section-row row-dependent"
                                                                id="row-dependent-6"
                                                              >
                                                                <div className="col-lg-12">
                                                                  <p>
                                                                    We have
                                                                    calculated
                                                                    the girth of
                                                                    your package
                                                                    for you
                                                                    based on
                                                                    your package
                                                                    dimensions.
                                                                  </p>
                                                                </div>
                                                                <InputFields
                                                                  outerDivClassList="col-lg-4 mt-4"
                                                                  isRequired={
                                                                    false
                                                                  }
                                                                  inputId="girth"
                                                                  labelText="Girth"
                                                                  infoIcon={
                                                                    <GirthModal targetName="girthWeightIcon" />
                                                                  }
                                                                  inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                                                                  inputClassList="form-control numbersonly"
                                                                  inputType="number"
                                                                  inputValue={this.getInputFieldValue(
                                                                    "girth"
                                                                  )}
                                                                  inputOnChange={(
                                                                    e
                                                                  ) => {
                                                                    this.setState(
                                                                      {
                                                                        girthEdit:
                                                                          e
                                                                            .target
                                                                            .value
                                                                      }
                                                                    );
                                                                  }}
                                                                  fieldSuffix="in"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <React.Fragment />
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}

                                          <div className="row section-row">
                                            <InputFields
                                              outerDivClassList="col-12"
                                              inputId="service-type-id"
                                              labelClassList="strong"
                                              labelText="Choose a Service Type"
                                              elementType="select"
                                              inputClassList="form-control"
                                              inputValue={this.getInputFieldValue(
                                                "selectedServiceType"
                                              )}
                                              inputOnChange={(e) =>
                                                this.setServiceType(e)
                                              }
                                              optionsList={
                                                this.getAvailableServiceTypes
                                              }
                                            />
                                          </div>
                                          <div className="row section-row">
                                            <div className="col-12">
                                              <div
                                                className="row section-row usps-table"
                                                id="preferences-package-type"
                                              >
                                                <div className="col-12">
                                                  <div className="row usps-table-row usps-table-header">
                                                    <div className="col-12 usps-table-col usps-table-col-full">
                                                      <label>
                                                        <div className="radio-input">
                                                          &nbsp;
                                                        </div>
                                                        <div className="radio-label">
                                                          Package Type
                                                        </div>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  {this.state.preferencesData
                                                    .availableProducts &&
                                                  this.state.preferencesData
                                                    .availableProducts[
                                                    this.getInputFieldValue(
                                                      "selectedServiceType"
                                                    )
                                                  ] ? (
                                                    <div className="row">
                                                      <div className="col-12">
                                                        <div className="usps-table-body">
                                                          <div className="row usps-table-row">
                                                            {this.state.preferencesData.availableProducts[
                                                              this.getInputFieldValue(
                                                                "selectedServiceType"
                                                              )
                                                            ].map((item) => (
                                                              <div
                                                                className="col-12 usps-table-col usps-table-col-full"
                                                                key={
                                                                  item.productCode
                                                                }
                                                              >
                                                                <label>
                                                                  <div className="radio-input">
                                                                    <input
                                                                      type="radio"
                                                                      name="preference_package_type"
                                                                      value="1"
                                                                      checked={
                                                                        this.getInputFieldValue(
                                                                          "selectedProductCode"
                                                                        ) ===
                                                                        item.productCode
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        this.setPackageType(
                                                                          item.productCode
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                  <div className="radio-label">
                                                                    <strong>
                                                                      {item.productClassDesc +
                                                                        " " +
                                                                        (item.productDesc
                                                                          ? item.productDesc
                                                                          : "")}
                                                                    </strong>
                                                                    <br />
                                                                    {item.serviceDimensions
                                                                      .split(
                                                                        "<br />"
                                                                      )
                                                                      .map(
                                                                        (
                                                                          line,
                                                                          i
                                                                        ) => {
                                                                          return (
                                                                            <div
                                                                              key={
                                                                                i
                                                                              }
                                                                            >
                                                                              {
                                                                                line
                                                                              }
                                                                            </div>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </div>
                                                                </label>
                                                              </div>
                                                            ))}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <React.Fragment />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row section-row">
                                            <div className="col-12">
                                              <div className="buttons-group">
                                                <ul>
                                                  <li>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-primary btn-save"
                                                      onClick={(e) =>
                                                        this.savePackageOptions(
                                                          e
                                                        )
                                                      }
                                                    >
                                                      Save
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="btn btn-outline-primary btn-cancel"
                                                      type="button"
                                                      onClick={this.cancelEdit}
                                                    >
                                                      Cancel
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="btn btn-outline-primary btn-cancel"
                                                      type="button"
                                                      onClick={this.clearInputs}
                                                    >
                                                      Clear
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    ) : (
                                      <React.Fragment />
                                    )}
                                    {this.state.optionClicked ===
                                    "shipmentNotification" ? (
                                      <div className="accordion-content">
                                        <form
                                          action="#"
                                          method="post"
                                          id="preferences-shipment-notifications"
                                        >
                                          <div className="row section-row">
                                            <div className="col-12">
                                              <div className="usps-checkbox checkbox-field">
                                                <input
                                                  type="checkbox"
                                                  id="receive_tracking_notifications"
                                                  name="receive_tracking_notifications"
                                                  className="form-control checkbox-field has-dependent"
                                                  data-dependent="2"
                                                  checked={this.getInputFieldValue(
                                                    "iWantTrackingNote"
                                                  )}
                                                  onChange={(e) => {
                                                    this.setState({
                                                      iWantTrackingNoteEdit:
                                                        e.currentTarget.checked
                                                    });
                                                  }}
                                                />
                                                <label htmlFor="receive_tracking_notifications">
                                                  <strong>
                                                    I would like to get tracking
                                                    notifications.
                                                  </strong>
                                                  <br />
                                                  <span className="note">
                                                    Get track and confirm
                                                    notifications via email
                                                    and/or text message.
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>

                                          {this.getInputFieldValue(
                                            "iWantTrackingNote"
                                          ) ? (
                                            <div
                                              className="row section-row row-dependent"
                                              id="row-dependent-2"
                                            >
                                              <div className="col-12">
                                                <div className="row section-row">
                                                  <div className="col-12">
                                                    <div className="row">
                                                      <div className="col-12">
                                                        <div className="usps-checkbox checkbox-field">
                                                          <input
                                                            type="checkbox"
                                                            id="receive_email_updates"
                                                            name="receive_email_updates"
                                                            className="form-control checkbox-field"
                                                            checked={this.getInputFieldValue(
                                                              "emailUpdates"
                                                            )}
                                                            onChange={(e) => {
                                                              this.setState({
                                                                emailUpdatesEdit:
                                                                  e.target
                                                                    .checked
                                                                    ? true
                                                                    : false
                                                              });
                                                            }}
                                                          />
                                                          <label htmlFor="receive_email_updates">
                                                            <strong>
                                                              Email Updates
                                                            </strong>
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {this.getInputFieldValue(
                                                      "emailUpdates"
                                                    ) ? (
                                                      <div className="row">
                                                        <div className="col-lg-6">
                                                          <div className="form-group">
                                                            <div className="inline-label-wrapper">
                                                              <label htmlFor="updates_input_email"></label>
                                                            </div>
                                                            <input
                                                              name="updates_input_email"
                                                              id="updates_input_email"
                                                              className="form-control"
                                                              type="email"
                                                              placeholder="Email"
                                                              value={this.getInputFieldValue(
                                                                "emailAddress"
                                                              )}
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  emailAddressEdit:
                                                                    e.target
                                                                      .value
                                                                });
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <React.Fragment />
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="row section-row">
                                                  <div className="col-12">
                                                    <div className="row">
                                                      <div className="col-12">
                                                        <div className="usps-checkbox checkbox-field">
                                                          <input
                                                            type="checkbox"
                                                            id="receive_text_updates"
                                                            name="receive_text_updates"
                                                            className="form-control checkbox-field"
                                                            checked={this.getInputFieldValue(
                                                              "textUpdates"
                                                            )}
                                                            onChange={(e) => {
                                                              this.setState({
                                                                textUpdatesEdit:
                                                                  e.target
                                                                    .checked
                                                                    ? true
                                                                    : false
                                                              });
                                                            }}
                                                          />
                                                          <label
                                                            className="label-ib"
                                                            htmlFor="receive_text_updates"
                                                          >
                                                            <strong>
                                                              Text Updates
                                                            </strong>
                                                          </label>
                                                          <span
                                                            className="more-info-link modal-trigger"
                                                            data-toggle="modal"
                                                            data-target="#modal-text-updates"
                                                          >
                                                            <span>
                                                              <TextUpdatePopover targetName="returnTextUpdatePopover" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {this.getInputFieldValue(
                                                      "textUpdates"
                                                    ) ? (
                                                      <div className="row">
                                                        <div className="col-lg-6">
                                                          <div className="form-group">
                                                            <div className="inline-label-wrapper">
                                                              <label htmlFor="updates_input_text"></label>
                                                            </div>
                                                            <input
                                                              name="updates_input_text"
                                                              id="updates_input_text"
                                                              className="form-control"
                                                              type="text"
                                                              value={this.getInputFieldValue(
                                                                "phoneNumber"
                                                              )}
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  phoneNumberEdit:
                                                                    e.target
                                                                      .value
                                                                });
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <React.Fragment />
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="row section-row">
                                                  <div className="col-12">
                                                    <div className="radio-button">
                                                      <label>
                                                        <div className="radio-input">
                                                          <input
                                                            type="radio"
                                                            name="subscribed_all_updates"
                                                            id="updates_all_radio"
                                                            className="has-dependent"
                                                            data-dependent="0"
                                                            checked={this.getInputFieldValue(
                                                              "allUpdates"
                                                            )}
                                                            value="1"
                                                            onChange={() =>
                                                              this.selectAllUpdates()
                                                            }
                                                          />
                                                        </div>
                                                        <div className="radio-label">
                                                          <strong>
                                                            All Updates
                                                          </strong>
                                                        </div>
                                                      </label>
                                                    </div>
                                                    <div className="radio-button">
                                                      <label>
                                                        <div className="radio-input">
                                                          <input
                                                            type="radio"
                                                            name="subscribed_all_updates"
                                                            id="updates_select_radio"
                                                            value="1"
                                                            className="has-dependent"
                                                            data-dependent="3"
                                                            checked={
                                                              !this.getInputFieldValue(
                                                                "allUpdates"
                                                              ) &&
                                                              this.getInputFieldValue(
                                                                "selectUpdates"
                                                              )
                                                            }
                                                            onChange={(e) =>
                                                              this.selectSomeUpdates()
                                                            }
                                                          />
                                                        </div>
                                                        <div className="radio-label">
                                                          <strong>
                                                            Select Updates
                                                          </strong>
                                                          <br />
                                                          Choose your desired
                                                          updates from a list.
                                                        </div>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  {!this.getInputFieldValue(
                                                    "allUpdates"
                                                  ) &&
                                                  this.getInputFieldValue(
                                                    "selectUpdates"
                                                  ) ? (
                                                    <div className="col-12">
                                                      <div
                                                        className="row section-row row-dependent"
                                                        id="row-dependent-3"
                                                      >
                                                        <div className="col-12">
                                                          <div className="radio-button radio-button-checkboxes">
                                                            <div className="radio-input radio-input-placeholder">
                                                              &nbsp;
                                                            </div>
                                                            <ul className="list-group list-unstyled radio-label radio-label-column mt-3">
                                                              <li className="usps-checkbox checkbox-field pt-0">
                                                                <input
                                                                  type="checkbox"
                                                                  id="update_1"
                                                                  name="updates[1]"
                                                                  className="form-control checkbox-field"
                                                                  value="1"
                                                                  checked={this.getInputFieldValue(
                                                                    "expectedDeliveryUpdates"
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    this.setState(
                                                                      {
                                                                        expectedDeliveryUpdatesEdit:
                                                                          e
                                                                            .target
                                                                            .checked
                                                                            ? true
                                                                            : false
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                                <label htmlFor="update_1">
                                                                  Expected
                                                                  Delivery
                                                                  Updates
                                                                </label>
                                                              </li>
                                                              <li className="usps-checkbox checkbox-field pt-0">
                                                                <input
                                                                  type="checkbox"
                                                                  id="update_2"
                                                                  name="updates[2]"
                                                                  className="form-control checkbox-field"
                                                                  value="1"
                                                                  checked={this.getInputFieldValue(
                                                                    "dayOfDeliveryUpdates"
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    this.setState(
                                                                      {
                                                                        dayOfDeliveryUpdatesEdit:
                                                                          e
                                                                            .target
                                                                            .checked
                                                                            ? true
                                                                            : false
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                                <label htmlFor="update_2">
                                                                  Day of
                                                                  Delivery
                                                                  Updates
                                                                </label>
                                                              </li>
                                                              <li className="usps-checkbox checkbox-field pt-0">
                                                                <input
                                                                  type="checkbox"
                                                                  id="update_3"
                                                                  name="updates[3]"
                                                                  className="form-control checkbox-field"
                                                                  value="1"
                                                                  checked={this.getInputFieldValue(
                                                                    "packageDelivered"
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    this.setState(
                                                                      {
                                                                        packageDeliveredEdit:
                                                                          e
                                                                            .target
                                                                            .checked
                                                                            ? true
                                                                            : false
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                                <label htmlFor="update_3">
                                                                  Package
                                                                  Delivered
                                                                </label>
                                                              </li>
                                                              <li className="usps-checkbox checkbox-field pt-0">
                                                                <input
                                                                  type="checkbox"
                                                                  id="update_4"
                                                                  name="updates[4]"
                                                                  className="form-control checkbox-field"
                                                                  value="1"
                                                                  checked={this.getInputFieldValue(
                                                                    "availableForPickup"
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    this.setState(
                                                                      {
                                                                        availableForPickupEdit:
                                                                          e
                                                                            .target
                                                                            .checked
                                                                            ? true
                                                                            : false
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                                <label htmlFor="update_4">
                                                                  Available for
                                                                  Pickup
                                                                </label>
                                                              </li>
                                                              <li className="usps-checkbox checkbox-field pt-0">
                                                                <input
                                                                  type="checkbox"
                                                                  id="update_5"
                                                                  name="updates[5]"
                                                                  className="form-control checkbox-field"
                                                                  value="1"
                                                                  checked={this.getInputFieldValue(
                                                                    "deliveryExceptionUpdates"
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    this.setState(
                                                                      {
                                                                        deliveryExceptionUpdatesEdit:
                                                                          e
                                                                            .target
                                                                            .checked
                                                                            ? true
                                                                            : false
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                                <label htmlFor="update_5">
                                                                  Delivery
                                                                  Exception
                                                                  Updates
                                                                </label>
                                                              </li>
                                                              <li className="usps-checkbox checkbox-field pt-0">
                                                                <input
                                                                  type="checkbox"
                                                                  id="update_6"
                                                                  name="updates[6]"
                                                                  className="form-control checkbox-field"
                                                                  value="1"
                                                                  checked={this.getInputFieldValue(
                                                                    "packageInTransitUpdates"
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    this.setState(
                                                                      {
                                                                        packageInTransitUpdatesEdit:
                                                                          e
                                                                            .target
                                                                            .checked
                                                                            ? true
                                                                            : false
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                                <label htmlFor="update_6">
                                                                  Package
                                                                  In-Transit
                                                                  Updates
                                                                </label>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <React.Fragment />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <React.Fragment />
                                          )}
                                          <div className="row section-row">
                                            <div className="col-12">
                                              <div className="buttons-group">
                                                <ul>
                                                  <li>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-primary btn-save"
                                                      onClick={(e) =>
                                                        this.saveShipmentNotifications(
                                                          e
                                                        )
                                                      }
                                                    >
                                                      Save
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="btn btn-outline-primary btn-cancel"
                                                      type="button"
                                                      onClick={this.cancelEdit}
                                                    >
                                                      Cancel
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    ) : (
                                      <React.Fragment />
                                    )}
                                    {this.state.optionClicked === "print" ? (
                                      <div className="accordion-content">
                                        <form
                                          action="#"
                                          method="post"
                                          id="preferences-print"
                                        >
                                          <div className="row section-row">
                                            <div className="col-12">
                                              <div className="radio-button">
                                                <label>
                                                  <div className="radio-input">
                                                    <input
                                                      type="radio"
                                                      name="print"
                                                      id="print-standard-id"
                                                      value="1"
                                                      checked={
                                                        this.getInputFieldValue(
                                                          "printWithReceipt"
                                                        ) === "N"
                                                      }
                                                      onChange={(e) => {
                                                        this.setState({
                                                          printWithReceiptEdit:
                                                            "N"
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="radio-label">
                                                    <strong>
                                                      Standard (8.5" X 11")
                                                    </strong>
                                                    <br />2 pages per sheet
                                                  </div>
                                                </label>
                                              </div>
                                              <div className="radio-button">
                                                <label>
                                                  <div className="radio-input">
                                                    <input
                                                      type="radio"
                                                      name="print"
                                                      id="print-compatible-id"
                                                      value="2"
                                                      checked={
                                                        this.getInputFieldValue(
                                                          "printWithReceipt"
                                                        ) === "Y"
                                                      }
                                                      onChange={(e) => {
                                                        this.setState({
                                                          printWithReceiptEdit:
                                                            "Y"
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="radio-label">
                                                    <strong>
                                                      Label Printer Compatible
                                                      (4" X 6")
                                                    </strong>
                                                    <br />1 page per sheet
                                                  </div>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row section-row">
                                            <div className="col-12">
                                              <div className="buttons-group">
                                                <ul>
                                                  <li>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-primary btn-save"
                                                      onClick={(e) =>
                                                        this.savePrint(e)
                                                      }
                                                    >
                                                      Save
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="btn btn-outline-primary btn-cancel"
                                                      type="button"
                                                      onClick={this.cancelEdit}
                                                    >
                                                      Cancel
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="btn btn-outline-primary btn-cancel"
                                                      type="button"
                                                      onClick={this.clearInputs}
                                                    >
                                                      Clear
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    ) : (
                                      <React.Fragment />
                                    )}
                                    {this.state.optionClicked ===
                                    "referenceNumber" ? (
                                      <div className="accordion-content">
                                        <form
                                          action="#"
                                          method="post"
                                          id="preferences-reference-number"
                                        >
                                          <div className="row section-row">
                                            <div className="col-12">
                                              <div className="form-group">
                                                <div className="inline-label-wrapper">
                                                  <label
                                                    className="bold"
                                                    htmlFor="referenceNumber"
                                                  >
                                                    Enter a reference number
                                                  </label>
                                                </div>
                                                <div className="row">
                                                  <div className="col-8">
                                                    <input
                                                      name="referenceNumber"
                                                      id="referenceNumber"
                                                      className="form-control"
                                                      maxLength="10"
                                                      type="text"
                                                      placeholder="Up to 10 characters."
                                                      value={this.getInputFieldValue(
                                                        "referenceNumber"
                                                      )}
                                                      onChange={(e) => {
                                                        this.setState({
                                                          referenceNumberEdit:
                                                            e.target.value
                                                        });
                                                      }}
                                                    />
                                                    <p className="color-black note">
                                                      This number will appear on
                                                      the printed label.
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row section-row">
                                            <div className="col-12">
                                              <div className="buttons-group">
                                                <ul>
                                                  <li>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-primary btn-save"
                                                      onClick={(e) =>
                                                        this.saveRefNumber(e)
                                                      }
                                                    >
                                                      Save
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="btn btn-outline-primary btn-cancel"
                                                      type="button"
                                                      onClick={this.cancelEdit}
                                                    >
                                                      Cancel
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    ) : (
                                      <React.Fragment />
                                    )}
                                    {modalAddressBook}
                                    {this.state.optionClicked ===
                                    "returnAddress" ? (
                                      <div className="accordion-content">
                                        <form
                                          action="#"
                                          method="post"
                                          id="preferences-return-address"
                                        >
                                          <div className="row section-row"></div>
                                          <div className="row section-row">
                                            <div className="col-12">
                                              <div className="row">
                                                <InputFields
                                                  outerDivClassList="col-sm-9"
                                                  isRequired={true}
                                                  inputId="firstName"
                                                  labelText="First Name"
                                                  inputClassList="form-control"
                                                  inputType="text"
                                                  inputPlaceholder="First Name"
                                                  inputValue={this.getInputFieldValue(
                                                    "firstName"
                                                  )}
                                                  inputOnChange={(e) => {
                                                    this.setState({
                                                      firstNameEdit:
                                                        e.target.value
                                                    });
                                                  }}
                                                />
                                                <InputFields
                                                  outerDivClassList="col-sm-3"
                                                  isRequired={false}
                                                  inputId="mi"
                                                  labelText="M.I."
                                                  inputClassList="form-control"
                                                  inputType="text"
                                                  inputPlaceholder="M.I."
                                                  inputMaxLength="1"
                                                  inputValue={this.getInputFieldValue(
                                                    "middle"
                                                  )}
                                                  inputOnChange={(e) => {
                                                    this.setState({
                                                      middleEdit: e.target.value
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className="row">
                                                <InputFields
                                                  outerDivClassList="col-12"
                                                  isRequired={true}
                                                  inputId="lastName"
                                                  labelText="Last Name"
                                                  inputClassList="form-control"
                                                  inputType="text"
                                                  inputPlaceholder="Last Name"
                                                  inputValue={this.getInputFieldValue(
                                                    "lastName"
                                                  )}
                                                  inputOnChange={(e) => {
                                                    this.setState({
                                                      lastNameEdit:
                                                        e.target.value
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className="row">
                                                <InputFields
                                                  outerDivClassList="col-12"
                                                  isRequired={false}
                                                  inputId="companyName"
                                                  labelText="Company (Only required if
                                                  first and last name are not
                                                  provided)"
                                                  inputClassList="form-control"
                                                  inputType="text"
                                                  inputPlaceholder="Company"
                                                  inputValue={this.getInputFieldValue(
                                                    "company"
                                                  )}
                                                  inputOnChange={(e) => {
                                                    this.setState({
                                                      companyEdit:
                                                        e.target.value
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className="row">
                                                <InputFields
                                                  outerDivClassList="col-12"
                                                  isRequired={true}
                                                  inputId="streetAddress"
                                                  labelText="Street Address"
                                                  inputClassList="form-control"
                                                  inputType="text"
                                                  inputPlaceholder="Street Address"
                                                  inputValue={this.getInputFieldValue(
                                                    "streetAddress"
                                                  )}
                                                  inputOnChange={(e) => {
                                                    this.setState({
                                                      streetAddressEdit:
                                                        e.target.value
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className="row">
                                                <InputFields
                                                  outerDivClassList="col-12"
                                                  isRequired={false}
                                                  inputId="aptSuiteOther"
                                                  labelText="Apt/Suite/Other"
                                                  inputClassList="form-control"
                                                  inputType="text"
                                                  inputPlaceholder="Apt/Suite/Other"
                                                  inputValue={this.getInputFieldValue(
                                                    "otherAddress"
                                                  )}
                                                  inputOnChange={(e) => {
                                                    this.setState({
                                                      otherAddressEdit:
                                                        e.target.value
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className="row">
                                                <InputFields
                                                  outerDivClassList="col-12"
                                                  isRequired={true}
                                                  inputId="city"
                                                  labelText="City"
                                                  inputClassList="form-control"
                                                  inputType="text"
                                                  inputPlaceholder="City"
                                                  inputValue={this.getInputFieldValue(
                                                    "city"
                                                  )}
                                                  inputOnChange={(e) => {
                                                    this.setState({
                                                      cityEdit: e.target.value
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className="row">
                                                <InputFields
                                                  outerDivClassList="col-sm-9"
                                                  isRequired={true}
                                                  inputId="stateId"
                                                  labelText="State"
                                                  elementType="select"
                                                  inputClassList="form-control"
                                                  inputValue={this.getInputFieldValue(
                                                    "stateId"
                                                  )}
                                                  inputOnChange={(e) => {
                                                    this.setState({
                                                      stateIdEdit:
                                                        e.target.value,
                                                      stateAbbrEdit:
                                                        e.target.options[
                                                          e.target.selectedIndex
                                                        ].innerText.substring(
                                                          0,
                                                          2
                                                        )
                                                    });
                                                  }}
                                                  optionsList={
                                                    StatesTerritoriesList
                                                  }
                                                />
                                                <InputFields
                                                  outerDivClassList="col-sm-3"
                                                  isRequired={true}
                                                  inputId="zipCode"
                                                  labelText="ZIP Code&trade;"
                                                  inputClassList="form-control"
                                                  inputType="text"
                                                  inputPlaceholder="ZIP Code"
                                                  inputValue={this.getInputFieldValue(
                                                    "zipCode"
                                                  )}
                                                  inputOnChange={(e) => {
                                                    this.setState({
                                                      zipCodeEdit:
                                                        e.target.value
                                                    });
                                                  }}
                                                />
                                              </div>
                                              {this.getInputFieldValue(
                                                "stateId"
                                              ) === "49" ? (
                                                <div className="row">
                                                  <InputFields
                                                    outerDivClassList="col-12"
                                                    isRequired={false}
                                                    inputId="urbanization"
                                                    labelText="Urbanization Code"
                                                    inputClassList="form-control"
                                                    inputType="text"
                                                    inputPlaceholder="Urbanization Code"
                                                    inputValue={this.getInputFieldValue(
                                                      "urbanization"
                                                    )}
                                                    inputOnChange={(e) => {
                                                      this.setState({
                                                        urbanizationEdit:
                                                          e.target.value
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              ) : null}
                                              <div className="row">
                                                <div className="col-12">
                                                  <span className="color-black">
                                                    Your address will be
                                                    standardized.
                                                  </span>
                                                  <span
                                                    className="more-info-link modal-trigger"
                                                    data-toggle="modal"
                                                    data-target="#modal-address-standardized"
                                                  >
                                                    <AddressStandardizedPopover targetName="returnAddressStandardizedPopover" />
                                                  </span>
                                                </div>
                                                <div className="col-12">
                                                  <div className="form-group mt-2">
                                                    <div className="usps-checkbox checkbox-field">
                                                      <input
                                                        type="checkbox"
                                                        id="updateAddressCheckbox"
                                                        name="updateAddressCheckbox"
                                                        className="form-control checkbox-field"
                                                        checked={
                                                          this.state
                                                            .updateAddress
                                                        }
                                                        onChange={(e) => {
                                                          this.setState({
                                                            updateAddress:
                                                              e.target.checked
                                                          });
                                                        }}
                                                      />
                                                      <label
                                                        className="color-black"
                                                        htmlFor="updateAddressCheckbox"
                                                      >
                                                        Update this address in
                                                        my address book
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row section-row">
                                                <div className="col-12">
                                                  <div className="buttons-group">
                                                    <ul>
                                                      <li>
                                                        <button
                                                          type="submit"
                                                          className="btn btn-primary btn-save"
                                                          onClick={(e) =>
                                                            this.saveReturnAddress(
                                                              e
                                                            )
                                                          }
                                                        >
                                                          Save
                                                        </button>
                                                      </li>
                                                      <li>
                                                        <button
                                                          className="btn btn-outline-primary btn-cancel"
                                                          type="button"
                                                          onClick={
                                                            this.cancelEdit
                                                          }
                                                        >
                                                          Cancel
                                                        </button>
                                                      </li>
                                                      <li>
                                                        <button
                                                          className="btn btn-outline-primary btn-cancel"
                                                          type="button"
                                                          onClick={
                                                            this.clearInputs
                                                          }
                                                        >
                                                          Clear
                                                        </button>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <React.Fragment />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row section-row mobileView">
                        <div className="col-12">
                          <div className="accordion drawers">
                            <div className="accordion-items">
                              <div className="accordion-item">
                                <div
                                  className="accordion-title"
                                  onClick={(e) =>
                                    this.chooseOption("packageOptions")
                                  }
                                >
                                  <h2>Package Options</h2>
                                  <div className="drawer-desc">
                                    <p>
                                      Set the package option you use the most to
                                      be selected when you create your
                                      international or domestic label.
                                    </p>
                                  </div>
                                </div>

                                {this.state.optionClicked ===
                                "packageOptions" ? (
                                  <div className="accordion-content">
                                    <form
                                      className="customs-form"
                                      id="preferences-default-package-options"
                                      action="#"
                                      method="post"
                                    >
                                      <div className="row section-row">
                                        <div className="col-lg-6 my-2 my-lg-0">
                                          <label>
                                            <input
                                              type="radio"
                                              name="default_shipping_method"
                                              id="flat-rate-radio-id"
                                              className="has-dependent"
                                              data-dependent="0"
                                              checked={
                                                this.getInputFieldValue(
                                                  "flatRate"
                                                ) === "Y"
                                              }
                                              onChange={(e) => {
                                                this.setState({
                                                  flatRateEdit: "Y"
                                                });
                                              }}
                                            />
                                            <strong
                                              style={{
                                                marginLeft: "7px"
                                              }}
                                            >
                                              I am shipping Flat Rate
                                            </strong>
                                            <br />
                                          </label>

                                          <div className="note">
                                            If it fits, it ships<sup>&reg;</sup>{" "}
                                            anywhere in the U.S. up to 70 lbs.
                                          </div>
                                        </div>

                                        <div className="col-lg-6 my-2 my-lg-0">
                                          <label>
                                            <input
                                              type="radio"
                                              name="default_shipping_method"
                                              id="package-weight-radio-id"
                                              className="has-dependent"
                                              data-dependent="1"
                                              checked={
                                                this.getInputFieldValue(
                                                  "flatRate"
                                                ) === "N"
                                              }
                                              onChange={(e) => {
                                                this.setState({
                                                  flatRateEdit: "N"
                                                });
                                              }}
                                            />
                                            <strong
                                              style={{
                                                marginLeft: "7px"
                                              }}
                                            >
                                              Enter Package Weight
                                            </strong>
                                            <br />
                                          </label>

                                          <div className="note">
                                            What if I don't know my package
                                            weight?{" "}
                                            <PackageWeightPopover targetName="returnPackageWeightPopover" />
                                          </div>
                                        </div>
                                      </div>

                                      {this.getInputFieldValue("flatRate") ===
                                      "N" ? (
                                        <div className="row">
                                          <div className="col-12">
                                            <div
                                              className="row row-dependent"
                                              id="row-dependent-1"
                                            >
                                              <div className="col-12">
                                                <div className="row section-row">
                                                  <div className="col-lg-12">
                                                    &nbsp;
                                                  </div>
                                                  <div className="col-12">
                                                    <div className="row">
                                                      <div className="col-6">
                                                        <div className="form-group">
                                                          <div className="inline-label-wrapper">
                                                            <label htmlFor="weightPounds">
                                                              *Package Weight
                                                            </label>
                                                          </div>
                                                          <div className="field-wrapper with-suffix suffix-is-3-chars">
                                                            <input
                                                              name="weightPounds"
                                                              id="weightPounds"
                                                              className="form-control numbersonly"
                                                              type="number"
                                                              data-field-suffix="lbs"
                                                              value={this.getInputFieldValue(
                                                                "pounds"
                                                              )}
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  poundsEdit:
                                                                    e.target
                                                                      .value
                                                                });
                                                              }}
                                                            />
                                                            <span className="field-icon field-suffix">
                                                              lbs
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="col-6">
                                                        <div className="form-group">
                                                          <div className="inline-label-wrapper">
                                                            <label htmlFor="weightOunces">
                                                              &nbsp;
                                                            </label>
                                                          </div>
                                                          <div className="field-wrapper with-suffix suffix-is-2-chars">
                                                            <input
                                                              name="weightOunces"
                                                              id="weightOunces"
                                                              className="form-control numbersonly"
                                                              max="15"
                                                              type="number"
                                                              data-field-suffix="oz"
                                                              value={this.getInputFieldValue(
                                                                "ounces"
                                                              )}
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  ouncesEdit:
                                                                    e.target
                                                                      .value
                                                                });
                                                              }}
                                                            />
                                                            <span className="field-icon field-suffix">
                                                              oz
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className="row section-row">
                                                      <div className="col-lg-12">
                                                        <p>
                                                          Enter package
                                                          dimensions to see only
                                                          package types that fit
                                                          your shipment.
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div className="row section-row">
                                                      <InputFields
                                                        outerDivClassList="col-lg-4"
                                                        isRequired={false}
                                                        inputId="length"
                                                        labelText="Length"
                                                        inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                                                        inputClassList="form-control numbersonly has-dependent"
                                                        inputType="number"
                                                        inputValue={this.getInputFieldValue(
                                                          "length"
                                                        )}
                                                        inputOnChange={(e) => {
                                                          this.setState({
                                                            lengthEdit:
                                                              e.target.value
                                                          });
                                                        }}
                                                        fieldSuffix="in"
                                                      />

                                                      <InputFields
                                                        outerDivClassList="col-lg-4"
                                                        isRequired={false}
                                                        inputId="width"
                                                        labelText="Width"
                                                        inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                                                        inputClassList="form-control numbersonly has-dependent"
                                                        inputType="number"
                                                        inputValue={this.getInputFieldValue(
                                                          "width"
                                                        )}
                                                        inputOnChange={(e) => {
                                                          this.setState({
                                                            widthEdit:
                                                              e.target.value
                                                          });
                                                        }}
                                                        fieldSuffix="in"
                                                      />
                                                      <InputFields
                                                        outerDivClassList="col-lg-4"
                                                        isRequired={false}
                                                        inputId="height"
                                                        labelText="Height"
                                                        inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                                                        inputClassList="form-control numbersonly has-dependent"
                                                        inputType="number"
                                                        inputValue={this.getInputFieldValue(
                                                          "height"
                                                        )}
                                                        inputOnChange={(e) => {
                                                          this.setState({
                                                            heightEdit:
                                                              e.target.value
                                                          });
                                                        }}
                                                        fieldSuffix="in"
                                                      />
                                                    </div>

                                                    <div
                                                      className="row section-row row-dependent"
                                                      id="row-dependent-5"
                                                    >
                                                      <div className="col-12">
                                                        <div className="row section-row">
                                                          <div className="col-12">
                                                            <div className="usps-checkbox checkbox-field">
                                                              <input
                                                                type="checkbox"
                                                                id="packageNotRectangular"
                                                                name="packageNotRectangular"
                                                                className="form-control checkbox-field has-dependent"
                                                                data-dependent="6"
                                                                checked={this.getInputFieldValue(
                                                                  "isNotRectangularChecked"
                                                                )}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  this.setState(
                                                                    {
                                                                      isNotRectangularCheckedEdit:
                                                                        e.target
                                                                          .checked
                                                                    }
                                                                  );
                                                                }}
                                                              />
                                                              <label htmlFor="packageNotRectangular">
                                                                This package
                                                                isn't a standard
                                                                rectangular box.
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {this.getInputFieldValue(
                                                          "isNotRectangularChecked"
                                                        ) ? (
                                                          <div
                                                            className="row section-row row-dependent"
                                                            id="row-dependent-6"
                                                          >
                                                            <div className="col-lg-12">
                                                              <p>
                                                                We have
                                                                calculated the
                                                                girth of your
                                                                package for you
                                                                based on your
                                                                package
                                                                dimensions.
                                                              </p>
                                                            </div>
                                                            <InputFields
                                                              outerDivClassList="col-lg-4 mt-4"
                                                              isRequired={false}
                                                              inputId="girth"
                                                              labelText="Girth"
                                                              infoIcon={
                                                                <GirthModal targetName="girthWeightIcon" />
                                                              }
                                                              inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                                                              inputClassList="form-control numbersonly"
                                                              inputType="number"
                                                              inputValue={this.getInputFieldValue(
                                                                "girth"
                                                              )}
                                                              inputOnChange={(
                                                                e
                                                              ) => {
                                                                this.setState({
                                                                  girthEdit:
                                                                    e.target
                                                                      .value
                                                                });
                                                              }}
                                                              fieldSuffix="in"
                                                            />
                                                          </div>
                                                        ) : (
                                                          <React.Fragment />
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <React.Fragment />
                                      )}

                                      <div className="row section-row">
                                        <InputFields
                                          outerDivClassList="col-12"
                                          inputId="service-type-id"
                                          labelClassList="strong"
                                          labelText="Choose a Service Type"
                                          elementType="select"
                                          inputClassList="form-control"
                                          inputValue={this.getInputFieldValue(
                                            "selectedServiceType"
                                          )}
                                          inputOnChange={(e) =>
                                            this.setServiceType(e)
                                          }
                                          optionsList={
                                            this.getAvailableServiceTypes
                                          }
                                        />
                                      </div>

                                      <div className="row section-row">
                                        <div className="col-12">
                                          <div
                                            className="row section-row usps-table"
                                            id="preferences-package-type"
                                          >
                                            <div className="col-12">
                                              <div className="row usps-table-row usps-table-header">
                                                <div className="col-12 usps-table-col usps-table-col-full">
                                                  <label>
                                                    <div className="radio-input">
                                                      &nbsp;
                                                    </div>

                                                    <div className="radio-label">
                                                      Package Type
                                                    </div>
                                                  </label>
                                                </div>
                                              </div>

                                              {this.state.preferencesData
                                                .availableProducts &&
                                              this.state.preferencesData
                                                .availableProducts[
                                                this.getInputFieldValue(
                                                  "selectedServiceType"
                                                )
                                              ] ? (
                                                <div className="row">
                                                  <div className="col-12">
                                                    <div className="usps-table-body">
                                                      <div className="row usps-table-row">
                                                        {this.state.preferencesData.availableProducts[
                                                          this.getInputFieldValue(
                                                            "selectedServiceType"
                                                          )
                                                        ].map((item) => (
                                                          <div
                                                            className="col-12 usps-table-col usps-table-col-full"
                                                            key={
                                                              item.productCode
                                                            }
                                                          >
                                                            <label>
                                                              <div className="radio-input">
                                                                <input
                                                                  type="radio"
                                                                  name="preference_package_type"
                                                                  value="1"
                                                                  checked={
                                                                    this.getInputFieldValue(
                                                                      "selectedProductCode"
                                                                    ) ===
                                                                    item.productCode
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.setPackageType(
                                                                      item.productCode
                                                                    )
                                                                  }
                                                                />
                                                              </div>

                                                              <div className="radio-label">
                                                                <strong>
                                                                  {item.productClassDesc +
                                                                    " " +
                                                                    (item.productDesc
                                                                      ? item.productDesc
                                                                      : "")}
                                                                </strong>
                                                                <br />
                                                                {item.serviceDimensions
                                                                  .split(
                                                                    /<br \/>|<br\/>/
                                                                  )
                                                                  .map(
                                                                    (
                                                                      line,
                                                                      i
                                                                    ) => {
                                                                      return (
                                                                        <div
                                                                          key={
                                                                            i
                                                                          }
                                                                        >
                                                                          {line}
                                                                        </div>
                                                                      );
                                                                    }
                                                                  )}
                                                              </div>
                                                            </label>
                                                          </div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <React.Fragment />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row section-row">
                                        <div className="col-12">
                                          <div className="buttons-group">
                                            <ul>
                                              <li>
                                                <button
                                                  type="submit"
                                                  className="btn btn-primary btn-save"
                                                  onClick={(e) =>
                                                    this.savePackageOptions(e)
                                                  }
                                                >
                                                  Save
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  className="btn btn-outline-primary btn-cancel"
                                                  type="button"
                                                  onClick={this.cancelEdit}
                                                >
                                                  Cancel
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  className="btn btn-outline-primary btn-cancel"
                                                  type="button"
                                                  onClick={this.clearInputs}
                                                >
                                                  Clear
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  <React.Fragment />
                                )}
                              </div>

                              <div className="accordion-item">
                                <div
                                  className="accordion-title"
                                  onClick={(e) =>
                                    this.chooseOption("shipmentNotification")
                                  }
                                >
                                  <h2>Shipment Notifications</h2>

                                  <div className="drawer-desc">
                                    <p>
                                      Let us know how you would like to be
                                      contacted after a package is enroute to
                                      its destination.
                                    </p>
                                  </div>
                                </div>
                                {this.state.optionClicked ===
                                "shipmentNotification" ? (
                                  <div className="accordion-content">
                                    <form
                                      action="#"
                                      method="post"
                                      id="preferences-shipment-notifications"
                                    >
                                      <div className="row section-row">
                                        <div className="col-12">
                                          <div className="usps-checkbox checkbox-field">
                                            <input
                                              type="checkbox"
                                              id="receive_tracking_notifications"
                                              name="receive_tracking_notifications"
                                              className="form-control checkbox-field has-dependent"
                                              data-dependent="2"
                                              checked={this.getInputFieldValue(
                                                "iWantTrackingNote"
                                              )}
                                              onChange={(e) => {
                                                this.setState({
                                                  iWantTrackingNoteEdit:
                                                    e.currentTarget.checked
                                                });
                                              }}
                                            />
                                            <label htmlFor="receive_tracking_notifications">
                                              <strong>
                                                I would like to get tracking
                                                notifications.
                                              </strong>
                                              <br />
                                              <span className="note">
                                                Get track and confirm
                                                notifications via email and/or
                                                text message.
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>

                                      {this.getInputFieldValue(
                                        "iWantTrackingNote"
                                      ) ? (
                                        <div
                                          className="row section-row row-dependent"
                                          id="row-dependent-2"
                                        >
                                          <div className="col-12">
                                            <div className="row section-row">
                                              <div className="col-12">
                                                <div className="row">
                                                  <div className="col-12">
                                                    <div className="usps-checkbox checkbox-field">
                                                      <input
                                                        type="checkbox"
                                                        id="receive_email_updates"
                                                        name="receive_email_updates"
                                                        className="form-control checkbox-field"
                                                        checked={this.getInputFieldValue(
                                                          "emailUpdates"
                                                        )}
                                                        onChange={(e) => {
                                                          this.setState({
                                                            emailUpdatesEdit: e
                                                              .target.checked
                                                              ? true
                                                              : false
                                                          });
                                                        }}
                                                      />
                                                      <label htmlFor="receive_email_updates">
                                                        <strong>
                                                          Email Updates
                                                        </strong>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                {this.getInputFieldValue(
                                                  "emailUpdates"
                                                ) ? (
                                                  <div className="row">
                                                    <div className="col-lg-6">
                                                      <div className="form-group">
                                                        <div className="inline-label-wrapper">
                                                          <label htmlFor="updates_input_email"></label>
                                                        </div>
                                                        <input
                                                          name="updates_input_email"
                                                          id="updates_input_email"
                                                          className="form-control"
                                                          type="email"
                                                          placeholder="Email"
                                                          value={this.getInputFieldValue(
                                                            "emailAddress"
                                                          )}
                                                          onChange={(e) => {
                                                            this.setState({
                                                              emailAddressEdit:
                                                                e.target.value
                                                            });
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <React.Fragment />
                                                )}
                                              </div>
                                            </div>
                                            <div className="row section-row">
                                              <div className="col-12">
                                                <div className="row">
                                                  <div className="col-12">
                                                    <div className="usps-checkbox checkbox-field">
                                                      <input
                                                        type="checkbox"
                                                        id="receive_text_updates"
                                                        name="receive_text_updates"
                                                        className="form-control checkbox-field"
                                                        checked={this.getInputFieldValue(
                                                          "textUpdates"
                                                        )}
                                                        onChange={(e) => {
                                                          this.setState({
                                                            textUpdatesEdit: e
                                                              .target.checked
                                                              ? true
                                                              : false
                                                          });
                                                        }}
                                                      />
                                                      <label
                                                        className="label-ib"
                                                        htmlFor="receive_text_updates"
                                                      >
                                                        <strong>
                                                          Text Updates
                                                        </strong>
                                                      </label>
                                                      <span
                                                        className="more-info-link modal-trigger"
                                                        data-toggle="modal"
                                                        data-target="#modal-text-updates"
                                                      >
                                                        <span>
                                                          <TextUpdatePopover targetName="returnTextUpdatePopover" />
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                                {this.getInputFieldValue(
                                                  "textUpdates"
                                                ) ? (
                                                  <div className="row">
                                                    <div className="col-lg-6">
                                                      <div className="form-group">
                                                        <div className="inline-label-wrapper">
                                                          <label htmlFor="updates_input_text"></label>
                                                        </div>
                                                        <input
                                                          name="updates_input_text"
                                                          id="updates_input_text"
                                                          className="form-control"
                                                          type="text"
                                                          value={this.getInputFieldValue(
                                                            "phoneNumber"
                                                          )}
                                                          onChange={(e) => {
                                                            this.setState({
                                                              phoneNumberEdit:
                                                                e.target.value
                                                            });
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <React.Fragment />
                                                )}
                                              </div>
                                            </div>
                                            <div className="row section-row">
                                              <div className="col-12">
                                                <div className="radio-button">
                                                  <label>
                                                    <div className="radio-input">
                                                      <input
                                                        type="radio"
                                                        name="subscribed_all_updates"
                                                        id="updates_all_radio"
                                                        className="has-dependent"
                                                        data-dependent="0"
                                                        checked={this.getInputFieldValue(
                                                          "allUpdates"
                                                        )}
                                                        value="1"
                                                        onChange={() =>
                                                          this.selectAllUpdates()
                                                        }
                                                      />
                                                    </div>
                                                    <div className="radio-label">
                                                      <strong>
                                                        All Updates
                                                      </strong>
                                                    </div>
                                                  </label>
                                                </div>
                                                <div className="radio-button">
                                                  <label>
                                                    <div className="radio-input">
                                                      <input
                                                        type="radio"
                                                        name="subscribed_all_updates"
                                                        id="updates_select_radio"
                                                        value="1"
                                                        className="has-dependent"
                                                        data-dependent="3"
                                                        checked={
                                                          !this.getInputFieldValue(
                                                            "allUpdates"
                                                          ) &&
                                                          this.getInputFieldValue(
                                                            "selectUpdates"
                                                          )
                                                        }
                                                        onChange={(e) =>
                                                          this.selectSomeUpdates()
                                                        }
                                                      />
                                                    </div>
                                                    <div className="radio-label">
                                                      <strong>
                                                        Select Updates
                                                      </strong>
                                                      <br />
                                                      Choose your desired
                                                      updates from a list.
                                                    </div>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">
                                              {!this.getInputFieldValue(
                                                "allUpdates"
                                              ) &&
                                              this.getInputFieldValue(
                                                "selectUpdates"
                                              ) ? (
                                                <div className="col-12">
                                                  <div
                                                    className="row section-row row-dependent"
                                                    id="row-dependent-3"
                                                  >
                                                    <div className="col-12">
                                                      <div className="radio-button radio-button-checkboxes">
                                                        <div className="radio-input radio-input-placeholder">
                                                          &nbsp;
                                                        </div>
                                                        <ul className="list-group list-unstyled radio-label radio-label-column mt-3">
                                                          <li className="usps-checkbox checkbox-field pt-0">
                                                            <input
                                                              type="checkbox"
                                                              id="update_1"
                                                              name="updates[1]"
                                                              className="form-control checkbox-field"
                                                              value="1"
                                                              checked={this.getInputFieldValue(
                                                                "expectedDeliveryUpdates"
                                                              )}
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  expectedDeliveryUpdatesEdit:
                                                                    e.target
                                                                      .checked
                                                                      ? true
                                                                      : false
                                                                });
                                                              }}
                                                            />
                                                            <label htmlFor="update_1">
                                                              Expected Delivery
                                                              Updates
                                                            </label>
                                                          </li>
                                                          <li className="usps-checkbox checkbox-field pt-0">
                                                            <input
                                                              type="checkbox"
                                                              id="update_2"
                                                              name="updates[2]"
                                                              className="form-control checkbox-field"
                                                              value="1"
                                                              checked={this.getInputFieldValue(
                                                                "dayOfDeliveryUpdates"
                                                              )}
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  dayOfDeliveryUpdatesEdit:
                                                                    e.target
                                                                      .checked
                                                                      ? true
                                                                      : false
                                                                });
                                                              }}
                                                            />
                                                            <label htmlFor="update_2">
                                                              Day of Delivery
                                                              Updates
                                                            </label>
                                                          </li>
                                                          <li className="usps-checkbox checkbox-field pt-0">
                                                            <input
                                                              type="checkbox"
                                                              id="update_3"
                                                              name="updates[3]"
                                                              className="form-control checkbox-field"
                                                              value="1"
                                                              checked={this.getInputFieldValue(
                                                                "packageDelivered"
                                                              )}
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  packageDeliveredEdit:
                                                                    e.target
                                                                      .checked
                                                                      ? true
                                                                      : false
                                                                });
                                                              }}
                                                            />
                                                            <label htmlFor="update_3">
                                                              Package Delivered
                                                            </label>
                                                          </li>
                                                          <li className="usps-checkbox checkbox-field pt-0">
                                                            <input
                                                              type="checkbox"
                                                              id="update_4"
                                                              name="updates[4]"
                                                              className="form-control checkbox-field"
                                                              value="1"
                                                              checked={this.getInputFieldValue(
                                                                "availableForPickup"
                                                              )}
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  availableForPickupEdit:
                                                                    e.target
                                                                      .checked
                                                                      ? true
                                                                      : false
                                                                });
                                                              }}
                                                            />
                                                            <label htmlFor="update_4">
                                                              Available for
                                                              Pickup
                                                            </label>
                                                          </li>
                                                          <li className="usps-checkbox checkbox-field pt-0">
                                                            <input
                                                              type="checkbox"
                                                              id="update_5"
                                                              name="updates[5]"
                                                              className="form-control checkbox-field"
                                                              value="1"
                                                              checked={this.getInputFieldValue(
                                                                "deliveryExceptionUpdates"
                                                              )}
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  deliveryExceptionUpdatesEdit:
                                                                    e.target
                                                                      .checked
                                                                      ? true
                                                                      : false
                                                                });
                                                              }}
                                                            />
                                                            <label htmlFor="update_5">
                                                              Delivery Exception
                                                              Updates
                                                            </label>
                                                          </li>
                                                          <li className="usps-checkbox checkbox-field pt-0">
                                                            <input
                                                              type="checkbox"
                                                              id="update_6"
                                                              name="updates[6]"
                                                              className="form-control checkbox-field"
                                                              value="1"
                                                              checked={this.getInputFieldValue(
                                                                "packageInTransitUpdates"
                                                              )}
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  packageInTransitUpdatesEdit:
                                                                    e.target
                                                                      .checked
                                                                      ? true
                                                                      : false
                                                                });
                                                              }}
                                                            />
                                                            <label htmlFor="update_6">
                                                              Package In-Transit
                                                              Updates
                                                            </label>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <React.Fragment />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <React.Fragment />
                                      )}
                                      <div className="row section-row">
                                        <div className="col-12">
                                          <div className="buttons-group">
                                            <ul>
                                              <li>
                                                <button
                                                  type="submit"
                                                  className="btn btn-primary btn-save"
                                                  onClick={(e) =>
                                                    this.saveShipmentNotifications(
                                                      e
                                                    )
                                                  }
                                                >
                                                  Save
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  className="btn btn-outline-primary btn-cancel"
                                                  type="button"
                                                  onClick={this.cancelEdit}
                                                >
                                                  Cancel
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  <React.Fragment />
                                )}
                              </div>
                              <div className="accordion-item">
                                <div
                                  className="accordion-title"
                                  onClick={(e) => this.chooseOption("print")}
                                >
                                  <h2>Print</h2>
                                  <div className="drawer-desc">
                                    <p>
                                      Save print settings you would like to
                                      appear on the confirmation page.
                                    </p>
                                  </div>
                                </div>
                                {this.state.optionClicked === "print" ? (
                                  <div className="accordion-content">
                                    <form
                                      action="#"
                                      method="post"
                                      id="preferences-print"
                                    >
                                      <div className="row section-row">
                                        <div className="col-12">
                                          <div className="radio-button">
                                            <label>
                                              <div className="radio-input">
                                                <input
                                                  type="radio"
                                                  name="print"
                                                  id="print-standard-id"
                                                  value="1"
                                                  checked={
                                                    this.getInputFieldValue(
                                                      "printWithReceipt"
                                                    ) === "N"
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      printWithReceiptEdit: "N"
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className="radio-label">
                                                <strong>
                                                  Standard (8.5" X 11")
                                                </strong>
                                                <br />2 pages per sheet
                                              </div>
                                            </label>
                                          </div>
                                          <div className="radio-button">
                                            <label>
                                              <div className="radio-input">
                                                <input
                                                  type="radio"
                                                  name="print"
                                                  id="print-compatible-id"
                                                  value="2"
                                                  checked={
                                                    this.getInputFieldValue(
                                                      "printWithReceipt"
                                                    ) === "Y"
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      printWithReceiptEdit: "Y"
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className="radio-label">
                                                <strong>
                                                  Label Printer Compatible (4" X
                                                  6")
                                                </strong>
                                                <br />1 page per sheet
                                              </div>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row section-row">
                                        <div className="col-12">
                                          <div className="buttons-group">
                                            <ul>
                                              <li>
                                                <button
                                                  type="submit"
                                                  className="btn btn-primary btn-save"
                                                  onClick={(e) =>
                                                    this.savePrint(e)
                                                  }
                                                >
                                                  Save
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  className="btn btn-outline-primary btn-cancel"
                                                  type="button"
                                                  onClick={this.cancelEdit}
                                                >
                                                  Cancel
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  className="btn btn-outline-primary btn-cancel"
                                                  type="button"
                                                  onClick={this.clearInputs}
                                                >
                                                  Clear
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  <React.Fragment />
                                )}
                              </div>
                              <div className="accordion-item">
                                <div
                                  className="accordion-title"
                                  onClick={(e) =>
                                    this.chooseOption("referenceNumber")
                                  }
                                >
                                  <h2>Reference Number</h2>
                                  <div className="drawer-desc">
                                    <p>
                                      This number is for your reference only.
                                      You may use up to 10 characters.
                                    </p>
                                  </div>
                                </div>
                                {this.state.optionClicked ===
                                "referenceNumber" ? (
                                  <div className="accordion-content">
                                    <form
                                      action="#"
                                      method="post"
                                      id="preferences-reference-number"
                                    >
                                      <div className="row section-row">
                                        <div className="col-12">
                                          <div className="form-group">
                                            <div className="inline-label-wrapper">
                                              <label
                                                className="bold"
                                                htmlFor="referenceNumber"
                                              >
                                                Enter a reference number
                                              </label>
                                            </div>
                                            <div className="row">
                                              <div className="col-8">
                                                <input
                                                  name="referenceNumber"
                                                  id="referenceNumber"
                                                  className="form-control"
                                                  maxLength="10"
                                                  type="text"
                                                  placeholder="Up to 10 characters."
                                                  value={this.getInputFieldValue(
                                                    "referenceNumber"
                                                  )}
                                                  onChange={(e) => {
                                                    this.setState({
                                                      referenceNumberEdit:
                                                        e.target.value
                                                    });
                                                  }}
                                                />
                                                <p className="color-black note">
                                                  This number will appear on the
                                                  printed label.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row section-row">
                                        <div className="col-12">
                                          <div className="buttons-group">
                                            <ul>
                                              <li>
                                                <button
                                                  type="submit"
                                                  className="btn btn-primary btn-save"
                                                  onClick={(e) =>
                                                    this.saveRefNumber(e)
                                                  }
                                                >
                                                  Save
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  className="btn btn-outline-primary btn-cancel"
                                                  type="button"
                                                  onClick={this.cancelEdit}
                                                >
                                                  Cancel
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  <React.Fragment />
                                )}
                              </div>
                              <div className="accordion-item">
                                <div
                                  className="accordion-title"
                                  onClick={(e) =>
                                    this.chooseOption("returnAddress")
                                  }
                                >
                                  <h2>Return Address</h2>
                                  <div className="drawer-desc">
                                    <p>
                                      Save the return address we should use on
                                      your label. This does not change your
                                      customer registered address.
                                    </p>
                                  </div>
                                </div>
                                {this.state.optionClicked ===
                                "returnAddress" ? (
                                  <div className="accordion-content">
                                    <form
                                      action="#"
                                      method="post"
                                      id="preferences-return-address"
                                    >
                                      <div className="row section-row">
                                        <div className="col-12">
                                          <div className="headline-buttons">
                                            <ul>
                                              <li>
                                                <strong>Return address</strong>
                                              </li>

                                              <li>
                                                <AddressBook
                                                  buttonLabel={"Address Book"}
                                                  changeDeliveryAddress={
                                                    this.gabReturnAddress
                                                  }
                                                  addressFieldType={"GABReturn"}
                                                  showInternational={"false"}
                                                  targetName="btn-open-contact-return"
                                                  lookupCodes={
                                                    this.props.lookupCodes
                                                  }
                                                />
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row section-row">
                                        <div className="col-12">
                                          <div className="row">
                                            <InputFields
                                              outerDivClassList="col-sm-9"
                                              isRequired={true}
                                              inputId="firstName"
                                              labelText="First Name"
                                              inputClassList="form-control"
                                              inputType="text"
                                              inputPlaceholder="First Name"
                                              inputValue={this.getInputFieldValue(
                                                "firstName"
                                              )}
                                              inputOnChange={(e) => {
                                                this.setState({
                                                  firstNameEdit: e.target.value
                                                });
                                              }}
                                            />
                                            <InputFields
                                              outerDivClassList="col-sm-3"
                                              isRequired={false}
                                              inputId="mi"
                                              labelText="M.I."
                                              inputClassList="form-control"
                                              inputType="text"
                                              inputPlaceholder="M.I."
                                              inputMaxLength="1"
                                              inputValue={this.getInputFieldValue(
                                                "middle"
                                              )}
                                              inputOnChange={(e) => {
                                                this.setState({
                                                  middleEdit: e.target.value
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="row">
                                            <InputFields
                                              outerDivClassList="col-12"
                                              isRequired={true}
                                              inputId="lastName"
                                              labelText="Last Name"
                                              inputClassList="form-control"
                                              inputType="text"
                                              inputPlaceholder="Last Name"
                                              inputValue={this.getInputFieldValue(
                                                "lastName"
                                              )}
                                              inputOnChange={(e) => {
                                                this.setState({
                                                  lastNameEdit: e.target.value
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="row">
                                            <InputFields
                                              outerDivClassList="col-12"
                                              isRequired={false}
                                              inputId="companyName"
                                              labelText="Company (Only required if
                                                    first and last name are not
                                                    provided)"
                                              inputClassList="form-control"
                                              inputType="text"
                                              inputPlaceholder="Company"
                                              inputValue={this.getInputFieldValue(
                                                "company"
                                              )}
                                              inputOnChange={(e) => {
                                                this.setState({
                                                  companyEdit: e.target.value
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="row">
                                            <InputFields
                                              outerDivClassList="col-12"
                                              isRequired={true}
                                              inputId="streetAddress"
                                              labelText="Street Address"
                                              inputClassList="form-control"
                                              inputType="text"
                                              inputPlaceholder="Street Address"
                                              inputValue={this.getInputFieldValue(
                                                "streetAddress"
                                              )}
                                              inputOnChange={(e) => {
                                                this.setState({
                                                  streetAddressEdit:
                                                    e.target.value
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="row">
                                            <InputFields
                                              outerDivClassList="col-12"
                                              isRequired={false}
                                              inputId="aptSuiteOther"
                                              labelText="Apt/Suite/Other"
                                              inputClassList="form-control"
                                              inputType="text"
                                              inputPlaceholder="Apt/Suite/Other"
                                              inputValue={this.getInputFieldValue(
                                                "otherAddress"
                                              )}
                                              inputOnChange={(e) => {
                                                this.setState({
                                                  otherAddressEdit:
                                                    e.target.value
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="row">
                                            <InputFields
                                              outerDivClassList="col-12"
                                              isRequired={true}
                                              inputId="city"
                                              labelText="City"
                                              inputClassList="form-control"
                                              inputType="text"
                                              inputPlaceholder="City"
                                              inputValue={this.getInputFieldValue(
                                                "city"
                                              )}
                                              inputOnChange={(e) => {
                                                this.setState({
                                                  cityEdit: e.target.value
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="row">
                                            <InputFields
                                              outerDivClassList="col-sm-9"
                                              isRequired={true}
                                              inputId="stateId"
                                              labelText="State"
                                              elementType="select"
                                              inputClassList="form-control"
                                              inputValue={this.getInputFieldValue(
                                                "stateId"
                                              )}
                                              inputOnChange={(e) => {
                                                this.setState({
                                                  stateIdEdit: e.target.value,
                                                  stateAbbrEdit:
                                                    e.target.options[
                                                      e.target.selectedIndex
                                                    ].innerText.substring(0, 2)
                                                });
                                              }}
                                              optionsList={
                                                StatesTerritoriesList
                                              }
                                            />
                                            <InputFields
                                              outerDivClassList="col-sm-3"
                                              isRequired={true}
                                              inputId="zipCode"
                                              labelText="ZIP Code&trade;"
                                              inputClassList="form-control"
                                              inputType="text"
                                              inputPlaceholder="ZIP Code"
                                              inputValue={this.getInputFieldValue(
                                                "zipCode"
                                              )}
                                              inputOnChange={(e) => {
                                                this.setState({
                                                  zipCodeEdit: e.target.value
                                                });
                                              }}
                                            />
                                          </div>
                                          {this.getInputFieldValue(
                                            "stateId"
                                          ) === "49" ? (
                                            <div className="row">
                                              <InputFields
                                                outerDivClassList="col-12"
                                                isRequired={false}
                                                inputId="urbanization"
                                                labelText="Urbanization Code"
                                                inputClassList="form-control"
                                                inputType="text"
                                                inputPlaceholder="Urbanization Code"
                                                inputValue={this.getInputFieldValue(
                                                  "urbanization"
                                                )}
                                                inputOnChange={(e) => {
                                                  this.setState({
                                                    urbanizationEdit:
                                                      e.target.value
                                                  });
                                                }}
                                              />
                                            </div>
                                          ) : null}

                                          <div className="row">
                                            <div className="col-12">
                                              <span className="color-black">
                                                Your address will be
                                                standardized.
                                              </span>
                                              <span
                                                className="more-info-link modal-trigger"
                                                data-toggle="modal"
                                                data-target="#modal-address-standardized"
                                              >
                                                <AddressStandardizedPopover targetName="returnAddressStandardizedPopover" />
                                              </span>
                                            </div>

                                            <div className="col-12">
                                              <div className="form-group mt-2">
                                                <div className="usps-checkbox checkbox-field">
                                                  <input
                                                    type="checkbox"
                                                    id="updateAddressCheckbox"
                                                    name="updateAddressCheckbox"
                                                    className="form-control checkbox-field"
                                                    checked={
                                                      this.state.updateAddress
                                                    }
                                                    onChange={(e) => {
                                                      this.setState({
                                                        updateAddress:
                                                          e.target.checked
                                                      });
                                                    }}
                                                  />
                                                  <label
                                                    className="color-black"
                                                    htmlFor="updateAddressCheckbox"
                                                  >
                                                    Update this address in my
                                                    address book
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="row section-row">
                                            <div className="col-12">
                                              <div className="buttons-group">
                                                <ul>
                                                  <li>
                                                    <button
                                                      type="submit"
                                                      className="btn btn-primary btn-save"
                                                      onClick={(e) =>
                                                        this.saveReturnAddress(
                                                          e
                                                        )
                                                      }
                                                    >
                                                      Save
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="btn btn-outline-primary btn-cancel"
                                                      type="button"
                                                      onClick={this.cancelEdit}
                                                    >
                                                      Cancel
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="btn btn-outline-primary btn-cancel"
                                                      type="button"
                                                      onClick={this.clearInputs}
                                                    >
                                                      Clear
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                ) : (
                                  <React.Fragment />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </main>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}{" "}
      </React.Fragment>
    );
  }
}
export default withRouter(Preferences);
