import React from "react";
import { withRouter } from "react-router-dom";
import LoggedInNav from "./LoggedInNav";
import caretRight from "../customs_forms/images/caret-right.svg";
import axios from "axios";
import * as analytics from "../Components/Analytics/Analytics.js";
import DescriptionPopover from "./Popovers/DescriptionPopover";
var dataLayer = {
  event: "vpvCFO",
  element: "",
  selection: "",
  page: "cfo-step-7-package-contents-category"
};
class PackageContents extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();

    this.props.setPageErrors([]);
    this.props.toggleLoadingBar(false);

    this.hazmatType = this.previousState.hazmatType;
    this.nonDeliveryOption = this.previousState.nonDeliveryOption;
    this.flowType = this.previousState.flowType;
    this.officialBusiness = this.previousState.officialBusiness;
    const packageContents = this.previousState.packageContents;
    if (packageContents) {
      this.state = {
        ...packageContents
      };
    } else {
      this.state = {
        category:
          this.hazmatType && this.hazmatType.hazmatType
            ? "Dangerous Goods"
            : "",
        otherDescription: "",
        additionalComments: ""
      };
    }
  }

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.nonDeliveryOption
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there is no non-delivery option
    window.location.replace("/index");
  };

  handlePackageContents = (event) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    });
  };

  handleBackButton = () => {
    this.previousState.stepNumber = this.previousState.stepNumber - 1;
    this.props.stepNumberCallback(this.previousState.stepNumber);
    this.previousState.packageStatus = 0;
    this.props.history.push("/non-delivery-option", {
      previousState: this.previousState
    });
    dataLayer.element = "Back";
    analytics.setDestinationPricePackageDataLayerInfo(dataLayer);
  };

  displayContinueButton = () => {
    return (
      this.state.category
      // && (this.state.category !== "OTHER" || this.state.otherDescription)
    );
  };

  handleContinueButton = (e) => {
    if ((this.flowType !== "military" || this.flowType !== "usa") && this.officialBusiness !== "1") {
      e.preventDefault();
    }
    this.previousState.packageStatus = 1;
    let dataToSend = {
      cnsCartId: 0,
      strCnsCartId:
        this.props.location.state.previousState.cartInfo.encodedCartId,
      additionalComments: this.officialBusiness === "1" ? "OFFICIAL BUSINESS" : this.state.additionalComments,
      category: this.officialBusiness === "1" ? "OTHER" : this.state.category,
      otherDescription: this.officialBusiness === "1" ? "OFFICIAL BUSINESS" : this.state.otherDescription
    };

    this.props.toggleLoadingBar(true);
    axios
      .post("/CFOApplication/savePackageContents", JSON.stringify(dataToSend), {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        this.updatePreviousState();
        this.props.history.push("/commercial-sender", {
          previousState: this.previousState
        });
        dataLayer.element = "Continue";
        analytics.setDestinationPricePackageDataLayerInfo(dataLayer);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  };

  componentDidMount = () => {
    this.props.loginCheck();
    if (this.previousState.renderServiceOption && this.previousState.renderServiceOption === 1) {
      this.previousState.commercialStatus = 0;
    }
    if (!this.previousState.packageStatus ||
      (this.previousState.packageStatus && this.previousState.packageStatus === 0)) {
      this.previousState.stepNumber = this.previousState.stepNumber + 1
      this.props.stepNumberCallback(this.previousState.stepNumber);
    }
    if ((this.flowType === "military" || this.flowType === "militaryToUSA") && this.officialBusiness === "1") {
      this.handleContinueButton();
    }
  };

  updatePreviousState = () => {
    this.previousState.packageContents = {
      additionalComments: this.officialBusiness === "1" ? "OFFICIAL BUSINESS" : this.state.additionalComments,
      category: this.officialBusiness === "1" ? "OTHER" : this.state.category,
      otherDescription: this.officialBusiness === "1" ? "OFFICIAL BUSINESS" : this.state.otherDescription
    };
  };

  render() {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                      <p className="note">* indicates a required field</p>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="row section-row">
                    <div className="col-12">
                      <h2>What category is the contents of your package?</h2>
                    </div>
                  </div>
                  {!this.hazmatType || !this.hazmatType.hazmatType ? (
                    <React.Fragment>
                      <div className="row section-row">
                        <div className="col-lg-8 col-xl-6">
                          <p>*Package Contents:</p>
                          {this.officialBusiness === "1" ? <React.Fragment /> : (
                            <React.Fragment>
                              <div className="radio-button">
                                <label>
                                  <div className="radio-input">
                                    <input
                                      type="radio"
                                      name="category"
                                      className="has-dependent"
                                      data-dependent="0"
                                      value="Documents"
                                      checked={this.state.category === "Documents"}
                                      onChange={this.handlePackageContents}
                                    />
                                  </div>
                                  <div className="radio-label">Documents</div>
                                </label>
                              </div>
                              <div className="radio-button">
                                <label>
                                  <div className="radio-input">
                                    <input
                                      type="radio"
                                      name="category"
                                      className="has-dependent"
                                      data-dependent="0"
                                      value="Gifts"
                                      checked={this.state.category === "Gifts"}
                                      onChange={this.handlePackageContents}
                                    />
                                  </div>
                                  <div className="radio-label">Gifts</div>
                                </label>
                              </div>
                              <div className="radio-button">
                                <label>
                                  <div className="radio-input">
                                    <input
                                      type="radio"
                                      name="category"
                                      className="has-dependent"
                                      data-dependent="0"
                                      value="Merchandise"
                                      checked={
                                        this.state.category === "Merchandise"
                                      }
                                      onChange={this.handlePackageContents}
                                    />
                                  </div>
                                  <div className="radio-label">Merchandise</div>
                                </label>
                              </div>
                              {this.props.location.state.previousState
                                .serviceOptions.productCode !== "FCPIS" ? (
                                <div className="radio-button">
                                  <label>
                                    <div className="radio-input">
                                      <input
                                        type="radio"
                                        name="category"
                                        className="has-dependent"
                                        data-dependent="0"
                                        value="Returned Goods"
                                        checked={
                                          this.state.category === "Returned Goods"
                                        }
                                        onChange={this.handlePackageContents}
                                      />
                                    </div>
                                    <div className="radio-label">
                                      Returned Goods
                                    </div>
                                  </label>
                                </div>
                              ) : (
                                <React.Fragment />
                              )}
                              <div className="radio-button">
                                <label>
                                  <div className="radio-input">
                                    <input
                                      type="radio"
                                      name="category"
                                      className="has-dependent"
                                      data-dependent="0"
                                      value="Humanitarian Donation"
                                      checked={
                                        this.state.category ===
                                        "Humanitarian Donation"
                                      }
                                      onChange={this.handlePackageContents}
                                    />
                                  </div>
                                  <div className="radio-label">
                                    Humanitarian Donation
                                  </div>
                                </label>
                              </div>
                              <div className="radio-button">
                                <label>
                                  <div className="radio-input">
                                    <input
                                      type="radio"
                                      name="category"
                                      className="has-dependent"
                                      data-dependent="0"
                                      value="Commercial Sample"
                                      checked={
                                        this.state.category === "Commercial Sample"
                                      }
                                      onChange={this.handlePackageContents}
                                    />
                                  </div>
                                  <div className="radio-label">
                                    Commercial Sample
                                  </div>
                                </label>
                              </div>
                            </React.Fragment>
                          )}
                          {/* Remove "Dangerous Goods" as a content type. Dangerous Goods is no longer accepted by WebTools as an acceptable content type.  */}
                          {/* <div className="radio-button">
                            <label>
                              <div className="radio-input">
                                <input
                                  type="radio"
                                  name="category"
                                  className="has-dependent"
                                  data-dependent="0"
                                  value="Dangerous Goods"
                                  checked={
                                    this.state.category === "Dangerous Goods"
                                  }
                                  onChange={this.handlePackageContents}
                                />
                              </div>
                              <div className="radio-label">
                                Dangerous Goods <br />
                                (Select this option if you are shipping lithium
                                batteries)
                              </div>
                            </label>
                          </div> */}
                          <div className="radio-button">
                            <label>
                              <div className="radio-input">
                                <input
                                  type="radio"
                                  name="category"
                                  className="has-dependent"
                                  data-dependent="1"
                                  value="OTHER"
                                  checked={this.state.category === "OTHER"}
                                  onChange={this.handlePackageContents}
                                />
                              </div>
                              <div className="radio-label">Other</div>
                            </label>
                          </div>
                        </div>
                      </div>
                      {this.state.category === "OTHER" && this.officialBusiness !== "1" ? (
                        <div className="row section-row" id="row-dependent-1">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <div className="inline-label-wrapper">
                                <label htmlFor="otherDescription">
                                  * Description for Other
                                </label>{" "}
                                <span
                                  className="more-info-link modal-trigger"
                                  data-toggle="modal"
                                >
                                  <DescriptionPopover targetName="returnDescriptionPopover" />
                                </span>
                              </div>
                              <textarea
                                name="otherDescription"
                                maxLength="25"
                                id="otherDescription"
                                className="form-control two-line-textarea"
                                placeholder="max 25 characters"
                                value={this.state.otherDescription}
                                onChange={this.handlePackageContents}
                              ></textarea>
                              <div
                                className="more-info-content modal fade"
                                id="modal-other-description"
                              >
                                <div
                                  className="modal-dialog modal-lg"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-body">
                                      <p>Required if "Other" is selected</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="row section-row">
                        <div className="col-lg-8 col-xl-6">
                          You have previously indicated you are shipping
                          hazardous goods. You may not ship any other types of
                          items within this package.
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {this.officialBusiness === "1" ? <React.Fragment /> : (
                    <React.Fragment>
                      <div className="row section-row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="inline-label-wrapper">
                              <label htmlFor="additionalComments">
                                Additional Comments
                              </label>
                              <span
                                className="more-info-link modal-trigger"
                                data-toggle="modal"
                                data-target="#modal-additional-comments"
                              ></span>
                            </div>
                            <textarea
                              name="additionalComments"
                              maxLength="125"
                              id="additionalComments"
                              className="form-control two-line-textarea"
                              placeholder="max 125 characters"
                              value={this.state.additionalComments}
                              onChange={this.handlePackageContents}
                            ></textarea>
                            <div
                              className="more-info-content modal fade"
                              id="modal-additional-comments"
                            >
                              <div
                                className="modal-dialog modal-lg"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <p>
                                      e.g., goods subject to quarantine,
                                      sanitary/phytosanitary inspection, or other
                                      restrictions
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  <div className="row section-row">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                              <div className="form-button-container">
                                <button
                                  className="previous btn btn-outline-primary"
                                  type="button"
                                  onClick={this.handleBackButton}
                                >
                                  Back
                                </button>
                              </div>
                              {this.displayContinueButton() ? (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="submit"
                                    onClick={(e) =>
                                      this.handleContinueButton(e)
                                    }
                                  >
                                    Continue
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(PackageContents);
