import React from "react";

const PageNumbers = (props) => {
  const { currentPage, setCurrentPage, pageNumbersToDisplay, numOfPages } =
    props;

  return (
    <div className="col-xl-4 results-page-selector-wrapper">
      <div className="results-page-selector mt-3 mt-md-0">
        <div className="results-page-button results-page-prev">
          <button
            className={
              "btn btn-chromeless btn-prev" +
              (currentPage <= 1 ? " disabled" : "")
            }
            type="button"
            onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
              }
            }}
            aria-label="Previous Page"
          >
            <span className="navigation-text mobile">Previous</span>
            &lt;
          </button>
        </div>
        <div className="results-pages" id="ab-results-pages">
          <ol>
            {pageNumbersToDisplay.map((pageNumber) => {
              return (
                <li
                  key={pageNumber}
                  className={currentPage === pageNumber ? " active" : ""}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setCurrentPage(pageNumber);
                    }}
                  >
                    {currentPage === pageNumber ? (
                      <span className="visuallyhidden">
                        Currently selected page
                      </span>
                    ) : (
                      ""
                    )}
                    {pageNumber}
                  </button>
                </li>
              );
            })}
          </ol>
        </div>
        <div className="results-page-button results-page-next">
          <button
            className={
              "btn btn-chromeless btn-next" +
              (currentPage >= numOfPages ? " disabled" : "")
            }
            type="button"
            onClick={() => {
              if (currentPage < numOfPages) {
                setCurrentPage(currentPage + 1);
              }
            }}
            aria-label="Next Page"
          >
            <span className="navigation-text mobile">Next</span>
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageNumbers;
