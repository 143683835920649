import React from "react";
import InfoModal from "../Modals/InfoModal";

const GirthModal = (props) => {
  const content = (
    <React.Fragment>
      <p>
        Postage is charged by weight and distance. For lightweight large
        packages, a balloon rate may be charged instead.
      </p>
      <p>
        Parcels addressed to zones 1-4 (including local) that weigh less than 20
        pounds, but measure more than 84 inches (but not more than 108 inches)
        in combined length and girth are charged the applicable zone price for a
        20 pound parcel.
      </p>
      <p>
        Dimensional weight is applied to parcels addressed to zones 5-8 and that
        exceed 1 cubic foot (1,728 cubic inches) The dimensional weight
        represents the amount of space a package occupies in relation to its
        scale weight, therefore postage may be based on scale weight or
        dimensional weight, whichever is greater.
      </p>
      <p>
        These are the calculations we use to determine pricing based on the
        measurements you provide.
      </p>
      <p>
        <strong>Rectangular Packages</strong>
        <br />
        Balloon Rate - zones 1-4 (includes local)
        <br />
        (The maximum allowed for this rate is 108" and 70 pounds.)
      </p>
      <p>
        Length + Girth = X"
        <br />
        Length is always the longest measurement. Girth is the measurement
        around the thickest part of the parcel.
      </p>
      <p>
        Dimensional Weight - (zones 5-8)
        <br />
        Length x Width x Height = X / 194 = X pounds
        <br />
        If the result exceeds 1,728 cubic inches, divide the result by 194 and
        round up to the nearest whole inch to determine dimensional weight in
        pounds.*
      </p>
      <p>
        <strong>Non-rectangular Packages</strong>
        <br />
        Balloon Rate - zones 1-4 (includes local)
        <br />
        (The maximum allowed for this rate is 108"" and 70 pounds.)
      </p>
      <p>Length + Girth = X"</p>
      <p>Dimensional Weight</p>
      <p>
        Length x Width x Height = X(0.785) = X / 194 = X pounds
        <br />
        Before performing this calculation, round off length, width, and height.
        Multiply the result by 0.785 (*see above). If the dimensional weight
        exceeds 70 pounds, the mailer pays the 70 pound price.
      </p>
    </React.Fragment>
  );

  return (
    <InfoModal
      targetName={props.targetName}
      ariaLabel="Girth Information Icon"
      header="Girth Information"
      content={content}
    />
  );
};

export default GirthModal;
