const hsTariffCodeUtils = {
  // Determine whether the passed item has an HS Tariff Code
  itemHasHsTariffCode: (item) => {
    return !!item.hsTariffNbr;
  },

  // Determine how many items in the passed array have an HS Tariff Code
  checkHsTariffCodeCount: (itemsList, isNewUneditedRow) => {
    let hsTariffCodeCount = 0;
    for (let i in itemsList) {
      const lastIndex = itemsList.length - 1;
      if (itemsList[i].hsTariffNbr) {
        if (i < lastIndex) {
          hsTariffCodeCount++;
        } else if (
          isNewUneditedRow === undefined ||
          !isNewUneditedRow(itemsList[lastIndex])
        ) {
          hsTariffCodeCount++;
        }
      }
    }
    return hsTariffCodeCount;
  },

  // Determine whether the passed array has at least one item with
  // an HS Tariff Code
  hasAtLeastOne: (itemsList) => {
    for (let i in itemsList) {
      if (itemsList[i].hsTariffNbr) {
        return true;
      }
    }
    return false;
  }
};

export default hsTariffCodeUtils;
