import React from "react";

import axios from "axios";
import { withRouter } from "react-router-dom";
import LoggedInNav from "./LoggedInNav";
import caretRight from "../customs_forms/images/caret-right.svg";
import Moment from "moment";
import getAllCartInfo from "../utils/getAllCartInfo";

class LabelDetails extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    let barcodeFromURL = window.location.search.split("=")[1];
    this.state = {
      isLoaded: false,
      barCodeNbr: barcodeFromURL
    };
  }

  componentDidMount() {
    this.props.toggleLoadingBar(true);
    axios
      .post(
        "/CFOApplication/fetchCustomsDetails?barCodeNbr=" +
          this.state.barCodeNbr,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      )
      .then((response) => {
        if (response.data.loggedOut) {
          // User is not logged in
          window.location.replace("/index");
        }
        let totalWeightOz = response.data.customsDetails.labelPkg.weightOzQty;
        if (!totalWeightOz) {
          totalWeightOz = 0;
        }
        let pounds = Math.floor(totalWeightOz / 16);
        let ounces = totalWeightOz - pounds * 16;
        this.setState({
          isLoaded: true,
          customsDetailData: response.data.customsDetails,
          convertedPounds: pounds,
          convertedOz: ounces,
          itemCount: response.data.itemCount,
          accountId: response.data.accountId
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.props.toggleLoadingBar(false);
      });
  }

  shipSelectedLabelAgain = (e) => {
    e.preventDefault();
    this.props.toggleLoadingBar(true);
    axios
      .post("/CFOApplication/shipAgain?barCodeNbr=" + this.state.barCodeNbr)
      .then((response) => {
        this.props.history.push("/summary", {
          previousState: getAllCartInfo(response.data.body)
        });
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  };

  goToHistory = (e) => {
    e.preventDefault();
    this.props.history.push("/customs-history");
  };

  render() {
    return (
      <React.Fragment>
        {/* <AssistiveGlobalError
          globalErrorArray={this.state.errors.globalError}
          styling={{ paddingLeft: "15px" }}
        /> */}
        {this.state.isLoaded ? (
          <div id="cfo-wrapper">
            <div id="inner">
              <main className="section" id="customs-form-2019-main">
                <section className="section" id="app-form">
                  <div className="container">
                    <div className="row section-row align-items-center">
                      <div className="col-lg-4">
                        <h1>Customs Form Details</h1>
                      </div>
                      <div className="col-lg-8">
                        {this.props.loggedIn ? (
                          <LoggedInNav />
                        ) : (
                          <ul className="title-links">
                            <li>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                              >
                                FAQs{" "}
                                <span>
                                  <img src={caretRight} alt="open link" />
                                </span>
                              </a>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="row section-row">
                      <div className="col-lg-8">
                        <div className="row section-row">
                          <div className="col-12">
                            <h2>
                              Label #{" "}
                              <span id="tracking-label-number">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    this.props.lookupCodes[
                                      "cfo.webtools.tracking.link"
                                    ] +
                                    "?tLabels=" +
                                    this.state.barCodeNbr
                                  }
                                >
                                  {this.state.barCodeNbr}
                                </a>
                              </span>
                            </h2>
                          </div>
                        </div>
                        <div className="row section-row">
                          <div className="col-lg-6 mb-3 mb-lg-0">
                            <p>
                              <strong>Return Address</strong>
                              <br />
                              {
                                this.state.customsDetailData.returnAddress
                                  .fullOrCompanyName
                              }
                              {this.state.customsDetailData.returnAddress
                                .line1Addr ? (
                                <React.Fragment>
                                  <br />
                                  {
                                    this.state.customsDetailData.returnAddress
                                      .line1Addr
                                  }
                                </React.Fragment>
                              ) : (
                                <React.Fragment />
                              )}
                              {this.state.customsDetailData.returnAddress
                                .line2Addr ? (
                                <React.Fragment>
                                  <br />
                                  {
                                    this.state.customsDetailData.returnAddress
                                      .line2Addr
                                  }
                                </React.Fragment>
                              ) : (
                                <React.Fragment />
                              )}
                              {this.state.customsDetailData.returnAddress
                                .line3Addr ? (
                                <React.Fragment>
                                  <br />
                                  {
                                    this.state.customsDetailData.returnAddress
                                      .line3Addr
                                  }
                                </React.Fragment>
                              ) : (
                                <React.Fragment />
                              )}
                              <br />
                              {
                                this.state.customsDetailData.returnAddress
                                  .cityName
                              }
                              ,{" "}
                              {
                                this.state.customsDetailData.returnAddress
                                  .stateCode
                              }{" "}
                              {this.state.customsDetailData.returnAddress.zip5}
                              <br />
                              {this.state.customsDetailData.returnAddress
                                .emailAddress === null
                                ? ""
                                : this.state.customsDetailData.returnAddress
                                    .emailAddress}
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <p>
                              <strong>Delivery Address</strong>
                              <br />
                              {
                                this.state.customsDetailData.deliveryAddress
                                  .fullOrCompanyName
                              }
                              {this.state.customsDetailData.deliveryAddress
                                .line1Addr ? (
                                <React.Fragment>
                                  <br />
                                  {
                                    this.state.customsDetailData.deliveryAddress
                                      .line1Addr
                                  }
                                </React.Fragment>
                              ) : (
                                <React.Fragment />
                              )}
                              {this.state.customsDetailData.deliveryAddress
                                .line2Addr ? (
                                <React.Fragment>
                                  <br />
                                  {
                                    this.state.customsDetailData.deliveryAddress
                                      .line2Addr
                                  }
                                </React.Fragment>
                              ) : (
                                <React.Fragment />
                              )}
                              {this.state.customsDetailData.deliveryAddress
                                .line3Addr ? (
                                <React.Fragment>
                                  <br />
                                  {
                                    this.state.customsDetailData.deliveryAddress
                                      .line3Addr
                                  }
                                </React.Fragment>
                              ) : (
                                <React.Fragment />
                              )}
                              <br />
                              {this.state.customsDetailData.deliveryAddress
                                .addressCategory === "NA" ? (
                                <React.Fragment>
                                  {
                                    this.state.customsDetailData.deliveryAddress
                                      .countryName
                                  }
                                  ,{" "}
                                  {
                                    this.state.customsDetailData.deliveryAddress
                                      .cityName
                                  }{" "}
                                  {
                                    this.state.customsDetailData.returnAddress
                                      .zip5
                                  }
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  {
                                    this.state.customsDetailData.returnAddress
                                      .cityName
                                  }{" "}
                                  ,{" "}
                                  {
                                    this.state.customsDetailData.returnAddress
                                      .stateCode
                                  }{" "}
                                  {
                                    this.state.customsDetailData.returnAddress
                                      .zip5
                                  }
                                  <br />
                                  {this.state.customsDetailData.returnAddress
                                    .emailAddress === null
                                    ? ""
                                    : this.state.customsDetailData.returnAddress
                                        .emailAddress}
                                </React.Fragment>
                              )}
                              <br />
                              {this.state.customsDetailData.emailAddress ===
                              null
                                ? ""
                                : this.state.customsDetailData.emailAddress}
                            </p>
                          </div>
                        </div>
                        <div className="row section-row">
                          <div className="col-lg-6 mb-3 mb-lg-0">
                            <p>
                              <strong>Package</strong>
                              <br />
                              Ship Date:{" "}
                              {Moment(
                                this.state.customsDetailData.createDate
                              ).format("MM/DD/YYYY")}{" "}
                              <br />
                              Value: $
                              {
                                this.state.customsDetailData.labelPkg
                                  .pkgValueAmt
                              }{" "}
                              <br />
                              Weight: {this.state.convertedPounds} lbs{" "}
                              {this.state.convertedOz} oz <br />
                              From:{" "}
                              {
                                this.state.customsDetailData.returnAddress.zip5
                              }{" "}
                              <br />
                              Items: {this.state.itemCount} item(s)
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <p>
                              <strong>Service Type</strong>
                              <br />
                              {
                                this.state.customsDetailData.productId
                                  .productClassDesc
                              }
                              <br />
                              {this.state.customsDetailData.hazmatType !==
                              "null"
                                ? "HAZMAT"
                                : ""}
                            </p>
                            <p>
                              <strong>Account Number</strong>
                              <br />
                              {this.state.accountId}
                            </p>
                          </div>
                        </div>
                        <div className="row section-row usps-table">
                          <div className="col-12">
                            <div className="row usps-table-row usps-table-header">
                              <div className="col-4 usps-table-col usps-table-col-date-and-time">
                                Date and Time
                              </div>
                              <div className="col-8 usps-table-col usps-table-col-message">
                                Message
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="usps-table-body">
                                  <div className="row usps-table-row"></div>
                                  <div className="row usps-table-row">
                                    <div className="col-4 usps-table-col usps-table-col-date-and-time">
                                      {Moment(
                                        this.state.customsDetailData.createDate
                                      ).format("MM/DD/YYYY")}
                                      <br />
                                      {Moment(
                                        this.state.customsDetailData.createDate
                                      ).format("HH:mm:ss")}
                                    </div>
                                    <div className="col-8 usps-table-col usps-table-col-message">
                                      Customs Form Created
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 offset-lg-1 mt-3 mt-lg-0">
                        <div className="row section-row">
                          <div className="col-12">
                            <h2>Terms</h2>
                            <p>
                              <strong>Acceptance Cutoff</strong>
                              <br />
                              {Moment(
                                this.state.customsDetailData.createDate
                              ).format("MM/DD/YYYY")}
                            </p>
                            <p>
                              <strong>Acceptance Time</strong>
                              <br />
                              {this.state.customsDetailData.acceptanceTime ===
                              null
                                ? "No Data"
                                : this.state.customsDetailData.acceptanceTime}
                            </p>
                            <p>
                              <strong>Scheduled Date</strong>
                              <br />
                              {this.state.customsDetailData
                                .scheduledDeliveryDate === null
                                ? "No Data"
                                : this.state.customsDetailData
                                    .scheduledDeliveryDate}
                            </p>
                            <p>
                              <strong>Delivery Status</strong>
                              <br />
                              {this.state.customsDetailData.deliveryStatus ===
                              null
                                ? "No Data"
                                : this.state.customsDetailData.deliveryStatus}
                            </p>
                          </div>
                        </div>
                        <div className="row section-row">
                          <div className="col-12">
                            <h2>More Actions</h2>
                            <ul className="sidebar-links">
                              <li>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    this.props.lookupCodes[
                                      "cfo.webtools.tracking.link"
                                    ] +
                                    "?tLabels=" +
                                    this.state.barCodeNbr
                                  }
                                >
                                  USPS Tracking
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/summary"
                                  onClick={(e) =>
                                    this.shipSelectedLabelAgain(e)
                                  }
                                >
                                  Ship Again
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/customs-history"
                                  onClick={(e) => this.goToHistory(e)}
                                >
                                  Customs Form History
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row section-row">
                      <div className="col-12">
                        <a
                          className="btn btn-outline-primary btn-back"
                          href="/customs-history"
                          onClick={(e) => this.goToHistory(e)}
                        >
                          Back
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}{" "}
      </React.Fragment>
    );
  }
}

export default withRouter(LabelDetails);
