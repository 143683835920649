import React from "react";

import axios from "axios";
import { withRouter } from "react-router-dom";
import DateRangeSelector from "./Popovers/DateRangeSelector";
//import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";
//import * as analytics from "../Analytics/Analytics.js";
import LoggedInNav from "./LoggedInNav";
import gridViewIcon from "../customs_forms/images/grid-view-icon.svg";
import descendingIcon from "../customs_forms/images/descending-icon.svg";
import ascendingIcon from "../customs_forms/images/ascending-icon.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import caretRight from "../customs_forms/images/caret-right.svg";
import reverse from "../customs_forms/images/btn-reverse.svg";
import Moment from "moment";
import * as analytics from "../Components/Analytics/Analytics.js";
import InputFields from "./Subcomponents/InputFields";
import PageNumbers from "./Subcomponents/PageNumbers";
import base64toPDF from "../utils/base64toPDF";
import getAllCartInfo from "../utils/getAllCartInfo";

var dataLayer = {
  element: "",
  numberOfSelectedLabels: 0,
  columns: ""
};
class ShippingHistory extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.props.stepNumberCallback(0);
    this.state = {
      isLoaded: false,
      // Item info
      customsHistoryData: [],
      // Search fields
      searchBy: "1",
      labelNumber: "",
      firstName: "",
      lastName: "",
      companyName: "",
      shippingDate: "",
      lastSearchAction: {
        labelNumber: "",
        firstName: "",
        lastName: "",
        companyName: "",
        shippingDate: ""
      },
      // Page info
      resultsPerPage: 10,
      currentPage: 1,
      pageNumbersToDisplay: [1],
      numOfPages: 1,
      // Advanced search
      showAdvanced: false,
      // Columns to display ("S" = show, "H" = hide)
      dateColumn: "S",
      labelColumn: "S",
      serviceTypeColumn: "S",
      shippedColumn: "S",
      shippingAddressColumn: "S",
      // Sorting preferences
      sortColumnOptions: [
        "Date",
        "Service Type",
        "Shipping Address",
        "Label",
        "Shipped"
      ],
      sortColumnsAdded: [],
      // Table info
      selectAllLabelsChecked: false,
      selectedLabelList: [],
      labelSelectedDataLayer: 0,
      // Individual column sorting
      // - true: currently in ascending order
      // - false: currently in descending order
      // - undefined: currently not sorted
      datePrevState: undefined,
      serviceTypePrevState: undefined,
      deliveryaddressPrevState: undefined,
      labelPrevState: undefined,
      shippingPrevState: undefined,
      // Errors
      errors: {
        globalError: []
      }
    };

    // The limit for the number of labels that can be selected for any action
    this.MAX_SELECTED = 20;
    // The limit for the number of labels that can be selected for shipping again
    // (will probably stay at 1 unless a cart system is set up for CFO)
    this.MAX_SHIP_AGAIN = 1;
    // Counter to uniquely identify each custom sort column
    this.newestColumnId = -1;
  }

  componentDidMount() {
    this.fetchCustomsHistory();
  }

  // Fetch the user's customs history based on the provided parameters.
  // Should be called:
  //   1) Once upon first loading the page
  //   2) Whenever the "Search" button is clicked
  //   3) Whenever the results per page or current page changes
  //   4) Whenever the "Sort" or "Clear" buttons under multiple sorting are
  //      clicked
  fetchCustomsHistory = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.toggleLoadingBar(true);
    let dataToSend = {};
    // Search fields
    if (this.state.searchButtonClicked) {
      // If this call was initiated by clicking the Search button,
      // use the current values of the input fields
      dataToSend.labelNumber = this.state.labelNumber;
      dataToSend.firstName = this.state.firstName;
      dataToSend.lastName = this.state.lastName;
      dataToSend.companyName = this.state.companyName;
      dataToSend.dateRange = !this.state.shippingDate
        ? 0
        : Moment(this.state.shippingDate).unix();
    } else {
      // Otherwise (ex. if changing pages or sorting), use the values
      // that were last used when the Search button was clicked
      // (the values will be empty if the Search button was never clicked)
      dataToSend.labelNumber = this.state.lastSearchAction.labelNumber;
      dataToSend.firstName = this.state.lastSearchAction.firstName;
      dataToSend.lastName = this.state.lastSearchAction.lastName;
      dataToSend.companyName = this.state.lastSearchAction.companyName;
      dataToSend.dateRange = !this.state.lastSearchAction.shippingDate
        ? 0
        : Moment(this.state.lastSearchAction.shippingDate).unix();
    }
    // Page info
    dataToSend.resultsPerPage = this.state.resultsPerPage;
    dataToSend.currentPage = this.state.currentPage;
    // Call the endpoint
    axios
      .post(
        "/CFOApplication/fetchCustomsHistorySortedSearch",
        JSON.stringify(dataToSend),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      )
      .then((response) => {
        if (response.data.loggedOut) {
          // User is not logged in
          window.location.replace("/index");
        }
        // Get sorting preferences
        let savedSortData = [];
        if (response.data.sortData && response.data.sortData.length > 0) {
          for (let i = 0; i < response.data.sortData.length; i++) {
            let subArr = [];
            let splitData = response.data.sortData[i].split(":");
            subArr.push(splitData[0]);
            subArr.push(splitData[1]);
            subArr.push(i);
            savedSortData.push(subArr);
          }
        }
        // Get column display preferences
        if (response.data.filterData) {
          if (response.data.filterData.length > 0) {
            this.setState({
              dateColumn: response.data.filterData[0].split(":")[1],
              shippingAddressColumn: response.data.filterData[1].split(":")[1],
              shippedColumn: response.data.filterData[2].split(":")[1],
              serviceTypeColumn: response.data.filterData[3].split(":")[1],
              labelColumn: response.data.filterData[4].split(":")[1]
            });
          }
        }
        // Finish loading the page
        this.setState((prevState) => {
          return {
            isLoaded: true,
            customsHistoryData: response.data.customsHistory,
            sortColumnsAdded: savedSortData,
            selectAllLabelsChecked: false,
            selectedLabelList: [],
            // Store the search parameters that were provided
            // (these will be used if the user changes pages or does multiple
            // sorting, rather than whatever is currently in the input fields)
            lastSearchAction: {
              labelNumber: prevState.labelNumber,
              firstName: prevState.firstName,
              lastName: prevState.lastName,
              companyName: prevState.companyName,
              shippingDate: prevState.shippingDate
            }
          };
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({
          searchButtonClicked: false
        });
        this.props.toggleLoadingBar(false);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    // Get the total number of pages whenever the number of returned orders
    // or results per page change
    const newTotalNumberOfItems =
      this.state.customsHistoryData.length > 0
        ? this.state.customsHistoryData[0].totalRows
        : "0";
    const oldTotalNumberOfItems =
      prevState.customsHistoryData.length > 0
        ? prevState.customsHistoryData[0].totalRows
        : "0";
    if (
      newTotalNumberOfItems !== oldTotalNumberOfItems ||
      this.state.resultsPerPage !== prevState.resultsPerPage
    ) {
      let numOfPages;
      if (newTotalNumberOfItems > 0) {
        numOfPages = Math.ceil(
          newTotalNumberOfItems / this.state.resultsPerPage
        );
      } else {
        numOfPages = 1;
      }
      this.setState({
        numOfPages: numOfPages
      });
    }

    // Update the page numbers that should be displayed
    // whenever the current page or number of pages changes
    if (
      this.state.currentPage !== prevState.currentPage ||
      this.state.numOfPages !== prevState.numOfPages
    ) {
      let pageNumbersToDisplay = [];
      // Add the current page
      pageNumbersToDisplay.push(this.state.currentPage);
      // Add up to 2 pages before the current page, if available
      if (this.state.currentPage - 1 >= 1) {
        pageNumbersToDisplay.unshift(this.state.currentPage - 1);
        if (this.state.currentPage - 2 >= 1) {
          pageNumbersToDisplay.unshift(this.state.currentPage - 2);
        }
      }
      // Add pages after the current page until either the total number is 5 or
      // the last page has been reached
      for (
        let i = this.state.currentPage + 1;
        pageNumbersToDisplay.length < 5 && i <= this.state.numOfPages;
        i++
      ) {
        pageNumbersToDisplay.push(i);
      }
      // If the total number of pages is still less than 5 and the first page
      // has not been included yet, prepend pages to the array
      for (
        let j = this.state.currentPage - 3;
        pageNumbersToDisplay.length < 5 && j >= 1;
        j--
      ) {
        pageNumbersToDisplay.unshift(j);
      }
      // Update the state
      this.setState({
        pageNumbersToDisplay: pageNumbersToDisplay
      });
    }
    // Fetch the user's customs history whenever the search button is clicked
    // or the results per page or current page changes
    if (this.state.shouldFetchCustomsHistory) {
      this.setState({
        shouldFetchCustomsHistory: false
      });
      this.fetchCustomsHistory();
    }
  }

  changeSortPreferences = (old, changedTo, number) => {
    //////USE THIS FOR REMOVAL
    let newObjState = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.sortColumnsAdded))
    );

    for (let i = 0; i < newObjState.length; i++) {
      if (old === newObjState[i][0] && number === newObjState[i][2]) {
        newObjState[i][0] = changedTo;
      }
    }

    this.setState({
      sortColumnsAdded: newObjState
    });
  };

  advancedSearch = () => {
    this.setState((prevState) => {
      return { showAdvanced: !prevState.showAdvanced };
    });
  };

  renderSortColumnOptions = (index) => {
    // Get only the column names that have been added by the user
    let sortColumnNamesAdded = [];
    for (let i = 0; i < this.state.sortColumnsAdded.length; i++) {
      sortColumnNamesAdded.push(this.state.sortColumnsAdded[i][0]);
    }
    // Display the options
    return (
      <React.Fragment>
        <option value="Choose Column">Choose Column</option>
        {this.state.sortColumnOptions.map((item) => {
          return (
            <React.Fragment key={item}>
              {!sortColumnNamesAdded.includes(item) ||
              item === this.state.sortColumnsAdded[index][0] ? (
                <option value={item}>{item}</option>
              ) : (
                <React.Fragment />
              )}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  clickAscDesc = (e, id, sortProperty) => {
    let newObjState = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.sortColumnsAdded))
    );

    for (let i = 0; i < newObjState.length; i++) {
      if (id === String(newObjState[i][2])) {
        newObjState[i][1] = sortProperty;
      }
    }

    this.setState({ sortColumnsAdded: newObjState });
  };

  removeSortPreferences = (idKey) => {
    this.setState((state) => {
      const sortColumnsAdded = state.sortColumnsAdded.filter(
        (item, j) => idKey !== item[2]
      );

      return {
        sortColumnsAdded
      };
    });
  };

  sortColumn = (e) => {
    let sortFilterPrefs = [];
    //START HERE
    for (let i = 0; i < this.state.sortColumnsAdded.length; i++) {
      if (
        this.state.sortColumnsAdded[i] !== undefined &&
        this.state.sortColumnsAdded[i][0] !== "Choose Column"
      ) {
        let ascDescConvertToAD =
          this.state.sortColumnsAdded[i][1] === "asc" ? "A" : "D";
        sortFilterPrefs.push(
          String(this.state.sortColumnsAdded[i][0]) + ":" + ascDescConvertToAD
        );
      }
    }
    dataLayer.columns = sortFilterPrefs;
    dataLayer.element = "Advanced Search - Sort";
    analytics.shippingHistoryAdvanceSearchDataLayer(dataLayer);

    this.callSortEndpoint(sortFilterPrefs);
  };

  clearSortColumns = () => {
    this.setState({
      sortColumnsAdded: []
    });
    this.callSortEndpoint([]);
  };

  dragAndDropSort = (moved) => {
    if (this.state.sortColumnsAdded.length > 1) {
      const items = Array.from(this.state.sortColumnsAdded);
      const [reorderedItem] = items.splice(moved.source.index, 1);
      items.splice(moved.destination.index, 0, reorderedItem);

      this.setState({
        sortColumnsAdded: items
      });
    }
  };

  changeInvoiceDate = (e) => (input) => {
    this.setState({ [e]: input });
  };

  changeFirstName = (e) => {
    this.setState({ firstName: e.target.value });
  };

  changeLastName = (e) => {
    this.setState({ lastName: e.target.value });
  };

  changeCompanyName = (e) => {
    this.setState({ companyName: e.target.value });
  };

  changeSearchBy = (e) => {
    analytics.shippingHistory_Search(e.target.value);
    this.setState({
      searchBy: e.target.value,
      labelNumber: "",
      firstName: "",
      lastName: "",
      companyName: ""
    });
  };

  changeLabelNumber = (e) => {
    this.setState({ labelNumber: e.target.value });
  };

  handleSearchButton = (e) => {
    e.preventDefault();
    this.setState({
      currentPage: 1,
      shouldFetchCustomsHistory: true,
      searchButtonClicked: true
    });
  };

  changeResultsPerPage = (e) => {
    this.setState({
      resultsPerPage: parseInt(e.currentTarget.value, 10),
      currentPage: 1,
      shouldFetchCustomsHistory: true
    });
  };

  changeCurrentPage = (value) => {
    this.setState({
      currentPage: value,
      shouldFetchCustomsHistory: true
    });
  };

  sortByDeliveryAddress() {
    this.setState((prevState) => {
      let sortedData = JSON.parse(JSON.stringify(prevState.customsHistoryData));
      if (!prevState.deliveryaddressPrevState) {
        // Sort in ascending order
        sortedData = sortedData.sort((a, b) => {
          return a.deliveryAddress.displayAddress.localeCompare(
            b.deliveryAddress.displayAddress
          );
        });
      } else {
        // Sort in descending order
        sortedData = sortedData.sort((a, b) => {
          return b.deliveryAddress.displayAddress.localeCompare(
            a.deliveryAddress.displayAddress
          );
        });
      }
      return {
        customsHistoryData: sortedData,
        datePrevState: undefined,
        serviceTypePrevState: undefined,
        deliveryaddressPrevState: !prevState.deliveryaddressPrevState,
        labelPrevState: undefined,
        shippingPrevState: undefined
      };
    });
  }

  sortByServiceType() {
    this.setState((prevState) => {
      let sortedData = JSON.parse(JSON.stringify(prevState.customsHistoryData));
      if (!prevState.serviceTypePrevState) {
        // Sort in ascending order
        sortedData = sortedData.sort((a, b) => {
          return a.productId.productClassDesc.localeCompare(
            b.productId.productClassDesc
          );
        });
      } else {
        // Sort in descending order
        sortedData = sortedData.sort((a, b) => {
          return b.productId.productClassDesc.localeCompare(
            a.productId.productClassDesc
          );
        });
      }
      return {
        customsHistoryData: sortedData,
        datePrevState: undefined,
        serviceTypePrevState: !prevState.serviceTypePrevState,
        deliveryaddressPrevState: undefined,
        labelPrevState: undefined,
        shippingPrevState: undefined
      };
    });
  }

  sortByLabel() {
    this.setState((prevState) => {
      let sortedData = JSON.parse(JSON.stringify(prevState.customsHistoryData));
      if (!prevState.labelPrevState) {
        // Sort in ascending order
        sortedData = sortedData.sort((a, b) => {
          return a.barCodeNbr.localeCompare(b.barCodeNbr);
        });
      } else {
        // Sort in descending order
        sortedData = sortedData.sort((a, b) => {
          return b.barCodeNbr.localeCompare(a.barCodeNbr);
        });
      }
      return {
        customsHistoryData: sortedData,
        datePrevState: undefined,
        serviceTypePrevState: undefined,
        deliveryaddressPrevState: undefined,
        labelPrevState: !prevState.labelPrevState,
        shippingPrevState: undefined
      };
    });
  }

  sortByShipped() {
    this.setState((prevState) => {
      let sortedData = JSON.parse(JSON.stringify(prevState.customsHistoryData));
      if (!prevState.shippingPrevState) {
        // Sort in ascending order
        sortedData = sortedData.sort((a, b) => {
          return a.hasShipped - b.hasShipped;
        });
      } else {
        // Sort in descending order
        sortedData = sortedData.sort((a, b) => {
          return b.hasShipped - a.hasShipped;
        });
      }
      return {
        customsHistoryData: sortedData,
        datePrevState: undefined,
        serviceTypePrevState: undefined,
        deliveryaddressPrevState: undefined,
        labelPrevState: undefined,
        shippingPrevState: !prevState.shippingPrevState
      };
    });
  }

  sortByDate() {
    this.setState((prevState) => {
      let sortedData = JSON.parse(JSON.stringify(prevState.customsHistoryData));
      if (!prevState.datePrevState) {
        // Sort in ascending order
        sortedData = sortedData.sort((a, b) => {
          return (
            new Date(a.createDate).getDate() - new Date(b.createDate).getDate()
          );
        });
      } else {
        // Sort in descending order
        sortedData = sortedData.sort((a, b) => {
          return (
            new Date(b.createDate).getDate() - new Date(a.createDate).getDate()
          );
        });
      }
      return {
        customsHistoryData: sortedData,
        datePrevState: !prevState.datePrevState,
        serviceTypePrevState: undefined,
        deliveryaddressPrevState: undefined,
        labelPrevState: undefined,
        shippingPrevState: undefined
      };
    });
  }

  callSortEndpoint = (sortFilterPrefs) => {
    this.props.toggleLoadingBar(true);
    let dataToSend = { sortFilterPrefs };
    axios
      .post("/CFOApplication/saveCustomsHistorySort", dataToSend)
      .then((response) => {
        // Get sorting preferences
        let savedSortData = [];
        if (response.data.sortData && response.data.sortData.length > 0) {
          for (let i = 0; i < response.data.sortData.length; i++) {
            let subArr = [];
            let splitData = response.data.sortData[i].split(":");
            subArr.push(splitData[0]);
            subArr.push(splitData[1]);
            subArr.push(i);
            savedSortData.push(subArr);
          }
        }
        this.setState({
          sortColumnsAdded: savedSortData
        });
        // Change back to page 1 (thus triggering a new endpoint call to fetch
        // the order items with the new sorting order, after which the loading
        // bar will disappear)
        this.changeCurrentPage(1);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  };

  selectAllLabels = (isChecked) => {
    this.setState((prevState) => {
      let selectedLabelList = JSON.parse(
        JSON.stringify(prevState.selectedLabelList)
      );
      if (isChecked) {
        // Loop is restricted to the number of results per page or the maximum
        // number of items that can be selected, whichever is lower
        for (
          let i = 0;
          i < prevState.customsHistoryData.length &&
          selectedLabelList.length < this.MAX_SELECTED;
          i++
        ) {
          if (
            !selectedLabelList.includes(
              prevState.customsHistoryData[i].barCodeNbr
            )
          ) {
            selectedLabelList.push(prevState.customsHistoryData[i].barCodeNbr);
          }
        }
      } else {
        selectedLabelList = [];
      }
      return {
        selectedLabelList: selectedLabelList,
        labelSelectedDataLayer: selectedLabelList.length,
        selectAllLabelsChecked: isChecked
      };
    });
  };

  selectLabel = (isChecked, barCodeNbr) => {
    this.setState((prevState) => {
      let selectedLabelList = JSON.parse(
        JSON.stringify(prevState.selectedLabelList)
      );
      if (isChecked) {
        // Only check the box and add the item if the maximum number of items
        // that can be selected has not been exceeded
        if (selectedLabelList.length < this.MAX_SELECTED) {
          selectedLabelList.push(barCodeNbr);
        }
      } else {
        selectedLabelList = selectedLabelList.filter(
          (ele) => ele !== barCodeNbr
        );
      }
      return {
        selectedLabelList: selectedLabelList,
        labelSelectedDataLayer: selectedLabelList.length,
        selectAllLabelsChecked:
          selectedLabelList.length >= prevState.customsHistoryData.length ||
          selectedLabelList.length >= this.MAX_SELECTED
      };
    });
  };

  trackSelectedLabels = (e) => {
    e.preventDefault();
    if (this.state.selectedLabelList.length > 0) {
      dataLayer.element = "Track";
      dataLayer.numberOfSelectedLabels = this.state.labelSelectedDataLayer;
      analytics.shipingHistorySelectedLableDataLayer(dataLayer);
      window.open(
        this.props.lookupCodes["cfo.webtools.tracking.link"] +
          "?tLabels=" +
          this.state.selectedLabelList.join(",")
      );
    }
  };

  printSelectedLabels = () => {
    if (this.state.selectedLabelList.length > 0) {
      this.props.toggleLoadingBar(true);
      for (let i in this.state.customsHistoryData) {
        if (
          this.state.selectedLabelList.includes(
            this.state.customsHistoryData[i].barCodeNbr
          ) &&
          this.state.customsHistoryData[i].labelPdf
        ) {
          base64toPDF(
            this.state.customsHistoryData[i].labelPdf.page1Image,
            this.state.customsHistoryData[i].barCodeNbr
          );
        }
      }
      dataLayer.element = "Print";
      dataLayer.numberOfSelectedLabels = this.state.labelSelectedDataLayer;
      analytics.shipingHistorySelectedLableDataLayer(dataLayer);
      this.props.toggleLoadingBar(false);
    }
  };

  shipSelectedLabelAgain = (e) => {
    e.preventDefault();
    if (this.state.selectedLabelList.length > 0) {
      this.props.toggleLoadingBar(true);
      axios
        .post(
          "/CFOApplication/shipAgain?barCodeNbr=" +
            this.state.selectedLabelList[0]
        )
        .then((response) => {
          this.props.history.push("/summary", {
            previousState: getAllCartInfo(response.data.body)
          });
        })
        .catch((error) => {
          console.log(error);
          this.props.toggleLoadingBar(false);
        });

      dataLayer.element = "Ship Again";
      dataLayer.numberOfSelectedLabels = this.state.labelSelectedDataLayer;
      analytics.shipingHistorySelectedLableDataLayer(dataLayer);
    }
  };

  // Determine if a column should be shown
  showColumn = (columnName) => {
    // Show the column if either of the following are true
    // 1) the field is empty/null/etc.
    //    (indicating that the user never specified a preference)
    // 2) the checkbox is checked
    if (!this.state[columnName] || this.state[columnName] === "S") {
      return true;
    }
    // For the date column only, if all other columns are hidden,
    // show the date column since we must show something
    if (columnName === "dateColumn") {
      return (
        !this.showColumn("serviceTypeColumn") &&
        !this.showColumn("shippingAddressColumn") &&
        !this.showColumn("labelColumn") &&
        !this.showColumn("shippedColumn")
      );
    }
    // Otherwise, hide the column
    return false;
  };

  // Save the preferred columns to the database
  filterColumn = (e) => {
    e.preventDefault();
    this.props.toggleLoadingBar(true);
    let sortFilterPrefs = [
      "dateColumn:" + this.state["dateColumn"],
      "shippingAddressColumn:" + this.state["shippingAddressColumn"],
      "shippedColumn:" + this.state["shippedColumn"],
      "serviceTypeColumn:" + this.state["serviceTypeColumn"],
      "labelColumn:" + this.state["labelColumn"]
    ];
    let dataToSend = { sortFilterPrefs };

    axios
      .post("/CFOApplication/saveCustomsHistoryFilter", dataToSend)
      .then((response) => {
        if (response.data.filterData) {
          this.setState({
            dateColumn: response.data.filterData[0].split(":")[1],
            shippingAddressColumn: response.data.filterData[1].split(":")[1],
            shippedColumn: response.data.filterData[2].split(":")[1],
            serviceTypeColumn: response.data.filterData[3].split(":")[1],
            labelColumn: response.data.filterData[4].split(":")[1]
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.props.toggleLoadingBar(false);
      });
    var filterLabel = dataToSend.sortFilterPrefs;
    var columns = "";
    for (var i = 0; i < filterLabel.length; i++) {
      if (filterLabel[i].split(":")[1] === "S") {
        columns = columns + " | " + filterLabel[i].split(":")[0];
      }
    }
    dataLayer.columns = columns;
    dataLayer.element = "Advanced Search - Update Columns";
    analytics.shippingHistoryAdvanceSearchDataLayer(dataLayer);
  };

  goToLabelDetails = (e, barCodeNbr) => {
    e.preventDefault();
    this.props.history.push("/customs-details?labelBarcode=" + barCodeNbr);
  };

  render() {
    return (
      <React.Fragment>
        {" "}
        {/* <AssistiveGlobalError
          globalErrorArray={this.state.errors.globalError}
          styling={{ paddingLeft: "15px" }}
        /> */}
        {this.state.isLoaded ? (
          <div id="cfo-wrapper">
            <div id="inner">
              <main className="section" id="customs-form-2019-main">
                <section className="section" id="app-form">
                  <div className="container">
                    <div className="row section-row align-items-center">
                      <div className="col-lg-4">
                        <h1>Customs Form History</h1>
                      </div>

                      <div className="col-lg-8">
                        {this.props.loggedIn ? (
                          <LoggedInNav />
                        ) : (
                          <ul className="title-links">
                            <li>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                              >
                                FAQs{" "}
                                <span>
                                  <img src={caretRight} alt="open link" />
                                </span>
                              </a>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="row section-row">
                      <div className="col-12">
                        <div className="advanced-search-container">
                          <div className="row section-row">
                            <div className="col-12">
                              <form
                                id="customs-form-search-main"
                                action="#"
                                method="post"
                              >
                                <div className="row">
                                  <InputFields
                                    outerDivClassList="col-lg-2 col-md-6"
                                    inputId="search-by-dropdown-id"
                                    labelText="Search by"
                                    elementType="select"
                                    inputClassList="form-control search-by-dropdown dropdown"
                                    inputValue={this.state.searchBy}
                                    inputOnChange={(e) =>
                                      this.changeSearchBy(e)
                                    }
                                    optionsList={() => {
                                      return (
                                        <React.Fragment>
                                          <option value="1">All Labels</option>
                                          <option value="2">
                                            Name/Company
                                          </option>
                                        </React.Fragment>
                                      );
                                    }}
                                  />
                                  {this.state.searchBy === "2" ? (
                                    <React.Fragment>
                                      <InputFields
                                        outerDivClassList="col-lg-2 col-md-6"
                                        inputId="firstName"
                                        labelText="First Name"
                                        inputClassList="form-control"
                                        inputType="text"
                                        inputPlaceholder="First Name"
                                        inputValue={this.state.firstName}
                                        inputOnChange={(e) => {
                                          this.changeFirstName(e);
                                        }}
                                      />
                                      <InputFields
                                        outerDivClassList="col-lg-2 col-md-6"
                                        inputId="lastName"
                                        labelText="Last Name"
                                        inputClassList="form-control"
                                        inputType="text"
                                        inputPlaceholder="Last Name"
                                        inputValue={this.state.lastName}
                                        inputOnChange={(e) => {
                                          this.changeLastName(e);
                                        }}
                                      />
                                      <InputFields
                                        outerDivClassList="col-lg-2 col-md-6"
                                        inputId="companyName"
                                        labelText="Company"
                                        inputClassList="form-control"
                                        inputType="text"
                                        inputPlaceholder="Company"
                                        inputValue={this.state.companyName}
                                        inputOnChange={(e) => {
                                          this.changeCompanyName(e);
                                        }}
                                      />
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <InputFields
                                        outerDivClassList="col-lg-6 col-md-6"
                                        inputId="labelNumber"
                                        labelText="Label Number"
                                        inputClassList="form-control"
                                        inputType="text"
                                        inputPlaceholder="Label Number"
                                        inputValue={this.state.labelNumber}
                                        inputOnChange={(e) => {
                                          this.changeLabelNumber(e);
                                        }}
                                      />
                                    </React.Fragment>
                                  )}

                                  <div className="col-lg-2 col-md-6">
                                    <div className="form-group">
                                      <div className="inline-label-wrapper">
                                        <label htmlFor="date">Date</label>
                                      </div>
                                      <DateRangeSelector
                                        changeNonNestedFields={
                                          this.changeInvoiceDate
                                        }
                                        shippingDate={this.state.shippingDate}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-2 col-md-12">
                                    <div className="form-group">
                                      <div className="inline-label-wrapper d-none d-lg-block">
                                        <label>&nbsp;</label>
                                      </div>

                                      <button
                                        type="submit"
                                        className="btn btn-primary btn-search"
                                        onClick={this.handleSearchButton}
                                      >
                                        Search
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 advanced-search-button-container">
                              <button
                                type="button"
                                className="btn btn-chromeless btn-toggle"
                                data-target="#history-advanced-search"
                                onClick={(e) => this.advancedSearch(e)}
                              >
                                <strong>Advanced Search</strong>
                              </button>
                            </div>
                          </div>
                          {this.state.showAdvanced === true ? (
                            <div className="" id="history-advanced-search">
                              <div className="row section-row">&nbsp;</div>

                              <div className="row section-row">
                                <div className="col-lg-5 history-columns-col">
                                  <h4>Manage Table Columns</h4>
                                  <p>
                                    All options are selected by default.
                                    Deselect any checkbox and click the 'Update
                                    Columns' button to hide it in the table
                                    below.
                                  </p>

                                  <form
                                    id="customs-form-search-show"
                                    action="#"
                                    method="post"
                                  >
                                    <div className="row checkbox-group">
                                      <div className="col-lg-4 my-2">
                                        <div className="usps-checkbox checkbox-field">
                                          <input
                                            type="checkbox"
                                            id="as_date"
                                            name="as_date"
                                            data-column="usps-table-col-date-inline"
                                            className="form-control checkbox-field"
                                            onChange={(e) =>
                                              this.setState({
                                                dateColumn:
                                                  e.target.checked === true
                                                    ? "S"
                                                    : "H"
                                              })
                                            }
                                            checked={
                                              this.state["dateColumn"] === "S"
                                                ? true
                                                : false
                                            }
                                          />

                                          <label htmlFor="as_date">Date</label>
                                        </div>
                                      </div>

                                      <div className="col-lg-4 my-2">
                                        <div className="usps-checkbox checkbox-field">
                                          <input
                                            type="checkbox"
                                            id="as_shipping_address"
                                            name="as_shipping_address"
                                            data-column="usps-table-col-address"
                                            className="form-control checkbox-field"
                                            onChange={(e) =>
                                              this.setState({
                                                shippingAddressColumn:
                                                  e.target.checked === true
                                                    ? "S"
                                                    : "H"
                                              })
                                            }
                                            checked={
                                              this.state[
                                                "shippingAddressColumn"
                                              ] === "S"
                                                ? true
                                                : false
                                            }
                                          />

                                          <label htmlFor="as_shipping_address">
                                            Shipping Address
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-lg-4 my-2">
                                        <div className="usps-checkbox checkbox-field">
                                          <input
                                            type="checkbox"
                                            id="as_shipped"
                                            name="as_shipped"
                                            data-column="usps-table-col-shipped"
                                            className="form-control checkbox-field"
                                            onChange={(e) =>
                                              this.setState({
                                                shippedColumn:
                                                  e.target.checked === true
                                                    ? "S"
                                                    : "H"
                                              })
                                            }
                                            checked={
                                              this.state["shippedColumn"] ===
                                              "S"
                                                ? true
                                                : false
                                            }
                                          />

                                          <label htmlFor="as_shipped">
                                            Shipped
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-lg-4 my-2">
                                        <div className="usps-checkbox checkbox-field">
                                          <input
                                            type="checkbox"
                                            id="as_service_type"
                                            name="as_service_type"
                                            data-column="usps-table-col-service-type"
                                            className="form-control checkbox-field"
                                            onChange={(e) =>
                                              this.setState({
                                                serviceTypeColumn:
                                                  e.target.checked === true
                                                    ? "S"
                                                    : "H"
                                              })
                                            }
                                            checked={
                                              this.state[
                                                "serviceTypeColumn"
                                              ] === "S"
                                                ? true
                                                : false
                                            }
                                          />

                                          <label htmlFor="as_service_type">
                                            Service Type
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-lg-4 my-2">
                                        <div className="usps-checkbox checkbox-field">
                                          <input
                                            type="checkbox"
                                            id="as_label"
                                            name="as_label"
                                            data-column="usps-table-col-label"
                                            className="form-control checkbox-field"
                                            onChange={(e) =>
                                              this.setState({
                                                labelColumn:
                                                  e.target.checked === true
                                                    ? "S"
                                                    : "H"
                                              })
                                            }
                                            checked={
                                              this.state["labelColumn"] === "S"
                                                ? true
                                                : false
                                            }
                                          />

                                          <label htmlFor="as_label">
                                            Label
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row section-row">
                                      <div className="col-12">
                                        <button
                                          type="submit"
                                          className="btn btn-primary btn-update-history-cols"
                                          onClick={(e) => this.filterColumn(e)}
                                        >
                                          Update columns
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>

                                <div className="col-lg-7 history-sort-col">
                                  <h4>Multiple Sort</h4>
                                  <p>
                                    Use the options below to sort by multiple
                                    columns at once and change the order they
                                    are displayed in the table below.
                                  </p>
                                  <div id="sortable">
                                    <DragDropContext
                                      onDragEnd={(e) => this.dragAndDropSort(e)}
                                    >
                                      <Droppable droppableId="characters">
                                        {(provided) => (
                                          <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className="characters"
                                          >
                                            {Object.keys(
                                              this.state.sortColumnsAdded
                                            ).map((item, keys) => (
                                              <Draggable
                                                key={
                                                  this.state.sortColumnsAdded[
                                                    keys
                                                  ][2]
                                                }
                                                draggableId={String(
                                                  this.state.sortColumnsAdded[
                                                    keys
                                                  ][2]
                                                )}
                                                index={keys}
                                              >
                                                {(provided) => (
                                                  <div
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                  >
                                                    <div
                                                      className="row adv-sort-by characters-thumb"
                                                      key={
                                                        this.state
                                                          .sortColumnsAdded[
                                                          keys
                                                        ][2]
                                                      }
                                                    >
                                                      <img
                                                        src={gridViewIcon}
                                                        alt="Sort By handle"
                                                        className="sort-by-handle"
                                                      />

                                                      <div className="col-7 col-md-6 form-group">
                                                        <label
                                                          htmlFor={
                                                            "sort-by-dropdown" +
                                                            this.state
                                                              .sortColumnsAdded[
                                                              keys
                                                            ][2]
                                                          }
                                                        >
                                                          Sort by
                                                        </label>
                                                        <select
                                                          id={
                                                            "sort-by-dropdown" +
                                                            this.state
                                                              .sortColumnsAdded[
                                                              keys
                                                            ][2]
                                                          }
                                                          className="form-control dropdown"
                                                          value={
                                                            this.state
                                                              .sortColumnsAdded[
                                                              keys
                                                            ][0]
                                                          }
                                                          onChange={(e) =>
                                                            this.changeSortPreferences(
                                                              this.state
                                                                .sortColumnsAdded[
                                                                keys
                                                              ][0],
                                                              e.target.value,
                                                              this.state
                                                                .sortColumnsAdded[
                                                                keys
                                                              ][2]
                                                            )
                                                          }
                                                        >
                                                          {this.renderSortColumnOptions(
                                                            keys
                                                          )}
                                                        </select>
                                                      </div>

                                                      <div className="radio-container horizontal">
                                                        <input
                                                          id={
                                                            "adv-sort-desc-radio" +
                                                            this.state
                                                              .sortColumnsAdded[
                                                              keys
                                                            ][2]
                                                          }
                                                          type="radio"
                                                          className=""
                                                          name={
                                                            this.state
                                                              .sortColumnsAdded[
                                                              keys
                                                            ][2]
                                                          }
                                                          tabIndex="0"
                                                          checked={
                                                            this.state
                                                              .sortColumnsAdded[
                                                              keys
                                                            ][1] === "desc"
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            this.clickAscDesc(
                                                              e,
                                                              e.target.name,
                                                              "desc"
                                                            )
                                                          }
                                                        />
                                                        <label
                                                          htmlFor={
                                                            "adv-sort-desc-radio" +
                                                            this.state
                                                              .sortColumnsAdded[
                                                              keys
                                                            ][2]
                                                          }
                                                        >
                                                          <strong>
                                                            <img
                                                              src={
                                                                descendingIcon
                                                              }
                                                              alt="Descending icon"
                                                            />
                                                          </strong>
                                                        </label>
                                                      </div>
                                                      <div className="radio-container horizontal">
                                                        <input
                                                          id={
                                                            "adv-sort-asc-radio" +
                                                            this.state
                                                              .sortColumnsAdded[
                                                              keys
                                                            ][2]
                                                          }
                                                          type="radio"
                                                          className=""
                                                          name={
                                                            this.state
                                                              .sortColumnsAdded[
                                                              keys
                                                            ][2]
                                                          }
                                                          tabIndex="0"
                                                          checked={
                                                            this.state
                                                              .sortColumnsAdded[
                                                              keys
                                                            ][1] === "asc"
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            this.clickAscDesc(
                                                              e,
                                                              e.target.name,
                                                              "asc"
                                                            )
                                                          }
                                                        />
                                                        <label
                                                          htmlFor={
                                                            "adv-sort-asc-radio" +
                                                            this.state
                                                              .sortColumnsAdded[
                                                              keys
                                                            ][2]
                                                          }
                                                        >
                                                          <img
                                                            src={ascendingIcon}
                                                            alt="Ascending icon"
                                                          />
                                                        </label>
                                                      </div>
                                                      <button
                                                        type="button"
                                                        className="adv-remove-link button-link"
                                                        onClick={() =>
                                                          this.removeSortPreferences(
                                                            this.state
                                                              .sortColumnsAdded[
                                                              keys
                                                            ][2]
                                                          )
                                                        }
                                                        aria-label="Remove sort column"
                                                      >
                                                        <strong className="d-none d-md-inline">
                                                          Remove
                                                        </strong>
                                                      </button>
                                                      {provided.placeholder}
                                                    </div>
                                                    {provided.placeholder}
                                                  </div>
                                                )}
                                              </Draggable>
                                            ))}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                  </div>

                                  {this.state.sortColumnsAdded.length <
                                  this.state.sortColumnOptions.length ? (
                                    <div className="row">
                                      <div className="col-12 adv-add-column-link">
                                        <button
                                          type="button"
                                          className="inline-link button-link"
                                          onClick={(e) => {
                                            this.newestColumnId =
                                              this.newestColumnId + 1;
                                            this.setState((prevState) => {
                                              // Add the new row
                                              return {
                                                sortColumnsAdded:
                                                  prevState.sortColumnsAdded.concat(
                                                    [
                                                      [
                                                        "Choose Column",
                                                        "asc",
                                                        this.newestColumnId
                                                      ]
                                                    ]
                                                  ),
                                                focusOnRow: true
                                              };
                                            });
                                          }}
                                        >
                                          Add another sort column
                                        </button>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="row section-row">
                                    <div className="col-12">
                                      <div className="customs-form-buttons">
                                        <div className="form-button-container">
                                          <button
                                            type="submit"
                                            className="btn btn-primary btn-history-sort"
                                            onClick={(e) => this.sortColumn(e)}
                                          >
                                            Sort
                                          </button>
                                        </div>

                                        <div className="form-button-container">
                                          <button
                                            className="btn btn-outline-primary btn-history-clear"
                                            type="button"
                                            onClick={(e) =>
                                              this.clearSortColumns()
                                            }
                                          >
                                            Clear
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}
                        </div>
                      </div>
                    </div>

                    {this.state.customsHistoryData.length > 0 ? (
                      <div
                        className="row section-row usps-table"
                        id="history-output-table"
                      >
                        <div className="col-12">
                          <form method="post" action="#" id="cf-history-form">
                            <div className="row">
                              <div className="col-12">
                                <div className="table-responsive-lg usps-table-body">
                                  <table className="table">
                                    <thead>
                                      <tr className="usps-table-row usps-table-header">
                                        <th className="usps-table-col usps-table-col-date-and-row-check">
                                          <div className="form-group">
                                            <div className="usps-checkbox checkbox-field">
                                              <input
                                                type="checkbox"
                                                id="checkAll"
                                                name="checkAll"
                                                className="form-control checkbox-field"
                                                checked={
                                                  this.state
                                                    .selectAllLabelsChecked
                                                }
                                                onChange={(e) =>
                                                  this.selectAllLabels(
                                                    e.target.checked
                                                  )
                                                }
                                              />

                                              <label htmlFor="checkAll"></label>
                                            </div>
                                          </div>
                                        </th>

                                        {this.showColumn("dateColumn") ? (
                                          <th>
                                            Date
                                            <button
                                              type="button"
                                              className="btn btn-chromeless btn-reverse"
                                              data-direction="DESC"
                                              onClick={() => this.sortByDate()}
                                              data-column="date"
                                            >
                                              <img
                                                src={reverse}
                                                alt="reverse"
                                              />
                                              {this.state.datePrevState !==
                                              undefined ? (
                                                <span className="visuallyhidden">
                                                  Currently sorted by date in
                                                  {this.state.datePrevState
                                                    ? " ascending "
                                                    : " descending "}
                                                  order
                                                </span>
                                              ) : null}
                                            </button>
                                          </th>
                                        ) : (
                                          <React.Fragment />
                                        )}
                                        {this.showColumn(
                                          "serviceTypeColumn"
                                        ) ? (
                                          <th className="usps-table-col usps-table-col-service-type">
                                            Service Type
                                            <button
                                              type="button"
                                              className="btn btn-chromeless btn-reverse"
                                              data-direction="DESC"
                                              onClick={() =>
                                                this.sortByServiceType()
                                              }
                                              data-column="service_type"
                                            >
                                              <img
                                                src={reverse}
                                                alt="reverse"
                                              />
                                              {this.state
                                                .serviceTypePrevState !==
                                              undefined ? (
                                                <span className="visuallyhidden">
                                                  Currently sorted by service
                                                  type in
                                                  {this.state
                                                    .serviceTypePrevState
                                                    ? " ascending "
                                                    : " descending "}
                                                  order
                                                </span>
                                              ) : null}
                                            </button>
                                          </th>
                                        ) : (
                                          <React.Fragment />
                                        )}
                                        {this.showColumn(
                                          "shippingAddressColumn"
                                        ) ? (
                                          <th className="usps-table-col usps-table-col-address">
                                            Delivery Address
                                            <button
                                              type="button"
                                              className="btn btn-chromeless btn-reverse"
                                              onClick={() =>
                                                this.sortByDeliveryAddress()
                                              }
                                              data-direction="DESC"
                                              data-column="address"
                                            >
                                              <img
                                                src={reverse}
                                                alt="reverse"
                                              />
                                              {this.state
                                                .deliveryaddressPrevState !==
                                              undefined ? (
                                                <span className="visuallyhidden">
                                                  Currently sorted by delivery
                                                  address in
                                                  {this.state
                                                    .deliveryaddressPrevState
                                                    ? " ascending "
                                                    : " descending "}
                                                  order
                                                </span>
                                              ) : null}
                                            </button>
                                          </th>
                                        ) : (
                                          <React.Fragment />
                                        )}
                                        {this.showColumn("labelColumn") ? (
                                          <th className="usps-table-col usps-table-col-label">
                                            Label
                                            <button
                                              type="button"
                                              className="btn btn-chromeless btn-reverse"
                                              onClick={() => this.sortByLabel()}
                                              data-direction="DESC"
                                              data-column="label"
                                            >
                                              <img
                                                src={reverse}
                                                alt="reverse"
                                              />
                                              {this.state.labelPrevState !==
                                              undefined ? (
                                                <span className="visuallyhidden">
                                                  Currently sorted by label
                                                  number in
                                                  {this.state.labelPrevState
                                                    ? " ascending "
                                                    : " descending "}
                                                  order
                                                </span>
                                              ) : null}
                                            </button>
                                          </th>
                                        ) : (
                                          <React.Fragment />
                                        )}
                                        {this.showColumn("shippedColumn") ? (
                                          <th className="usps-table-col usps-table-col-shipped">
                                            Shipped
                                            <button
                                              type="button"
                                              className="btn btn-chromeless btn-reverse"
                                              onClick={() =>
                                                this.sortByShipped()
                                              }
                                              data-direction="DESC"
                                              data-column="shipped"
                                            >
                                              <img
                                                src={reverse}
                                                alt="reverse"
                                              />
                                              {this.state.shippingPrevState !==
                                              undefined ? (
                                                <span className="visuallyhidden">
                                                  Currently sorted by shipped
                                                  status in
                                                  {this.state.shippingPrevState
                                                    ? " ascending "
                                                    : " descending "}
                                                  order
                                                </span>
                                              ) : null}
                                            </button>
                                          </th>
                                        ) : (
                                          <React.Fragment />
                                        )}
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {this.state.customsHistoryData.map(
                                        (item) => (
                                          <tr
                                            className="usps-table-row"
                                            key={item.barCodeNbr}
                                          >
                                            <td className="usps-table-col usps-table-col-date-and-row-check">
                                              <div className="form-group">
                                                <div>
                                                  <label
                                                    htmlFor={
                                                      "label-list-" +
                                                      item.barCodeNbr
                                                    }
                                                  >
                                                    <input
                                                      id={
                                                        "label-list-" +
                                                        item.barCodeNbr
                                                      }
                                                      type="checkbox"
                                                      name="labels"
                                                      checked={this.state.selectedLabelList.includes(
                                                        item.barCodeNbr
                                                      )}
                                                      onChange={(e) =>
                                                        this.selectLabel(
                                                          e.target.checked,
                                                          item.barCodeNbr
                                                        )
                                                      }
                                                    />
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            {this.showColumn("dateColumn") ? (
                                              <td>
                                                {Moment(item.createDate).format(
                                                  "MM/DD/YYYY"
                                                )}
                                              </td>
                                            ) : (
                                              <React.Fragment />
                                            )}
                                            {this.showColumn(
                                              "serviceTypeColumn"
                                            ) ? (
                                              <td className="usps-table-col usps-table-col-service-type">
                                                {item.productId
                                                  ? item.productId
                                                      .productClassDesc
                                                  : ""}
                                                <br />
                                              </td>
                                            ) : (
                                              <React.Fragment />
                                            )}
                                            {this.showColumn(
                                              "shippingAddressColumn"
                                            ) ? (
                                              <td className="usps-table-col usps-table-col-address">
                                                {
                                                  item.deliveryAddress
                                                    .fullOrCompanyName
                                                }
                                                <br />
                                                {item.deliveryAddress.line1Addr}
                                                {item.deliveryAddress
                                                  .line2Addr != null ? (
                                                  <div>
                                                    {
                                                      item.deliveryAddress
                                                        .line2Addr
                                                    }
                                                  </div>
                                                ) : (
                                                  <React.Fragment />
                                                )}
                                                {item.deliveryAddress
                                                  .line3Addr != null ? (
                                                  <div>
                                                    {
                                                      item.deliveryAddress
                                                        .line3Addr
                                                    }
                                                  </div>
                                                ) : (
                                                  <React.Fragment />
                                                )}
                                                <div>
                                                  {
                                                    item.deliveryAddress
                                                      .countryName
                                                  }{" "}
                                                  {item.deliveryAddress
                                                    .postalCode ||
                                                    item.deliveryAddress.zip5}
                                                </div>
                                              </td>
                                            ) : (
                                              <React.Fragment />
                                            )}
                                            {this.showColumn("labelColumn") ? (
                                              <td className="usps-table-col usps-table-col-label">
                                                <a
                                                  href={
                                                    "/customs-details?labelBarcode=" +
                                                    item.barCodeNbr
                                                  }
                                                  onClick={(e) =>
                                                    this.goToLabelDetails(
                                                      e,
                                                      item.barCodeNbr
                                                    )
                                                  }
                                                >
                                                  {item.barCodeNbr}
                                                </a>
                                              </td>
                                            ) : (
                                              <React.Fragment />
                                            )}
                                            {this.showColumn(
                                              "shippedColumn"
                                            ) ? (
                                              <td className="usps-table-col usps-table-col-shipped">
                                                {item.hasShipped === null ||
                                                item.hasShipped === false
                                                  ? "No"
                                                  : "Yes"}
                                              </td>
                                            ) : (
                                              <React.Fragment />
                                            )}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div className="row usps-table-row usps-table-footer">
                              <div className="col-md-7">
                                <div className="text-results-per-page">
                                  Results per page:
                                </div>
                                <div className="results-per-page-selector">
                                  <div className="form-group">
                                    <select
                                      name="results_per_page"
                                      id="ab_results_per_page"
                                      className="form-control"
                                      value={this.state.resultsPerPage}
                                      onChange={this.changeResultsPerPage}
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="buttons-group">
                                  <ul>
                                    <li>
                                      <a
                                        className="btn btn-primary btn-history-track"
                                        type="button"
                                        href={
                                          this.props.lookupCodes[
                                            "cfo.webtools.tracking.link"
                                          ] +
                                          "?tLabels=" +
                                          this.state.selectedLabelList.join(",")
                                        }
                                        onClick={(e) =>
                                          this.trackSelectedLabels(e)
                                        }
                                      >
                                        Track
                                      </a>
                                    </li>
                                    <li>
                                      <button
                                        className="btn btn-primary btn-history-print"
                                        type="button"
                                        onClick={() =>
                                          this.printSelectedLabels()
                                        }
                                      >
                                        Print
                                      </button>
                                    </li>
                                    {this.state.selectedLabelList.length <=
                                    this.MAX_SHIP_AGAIN ? (
                                      <li>
                                        <a
                                          className="btn btn-primary btn-history-ship-again"
                                          type="button"
                                          href="/summary"
                                          onClick={(e) =>
                                            this.shipSelectedLabelAgain(e)
                                          }
                                        >
                                          Ship Again
                                        </a>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              </div>

                              <PageNumbers
                                divClassName="pagination-container"
                                currentPage={this.state.currentPage}
                                setCurrentPage={this.changeCurrentPage}
                                pageNumbersToDisplay={
                                  this.state.pageNumbersToDisplay
                                }
                                numOfPages={this.state.numOfPages}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="row text-center"
                        style={{ paddingTop: "25px" }}
                      >
                        <div className="col-4"></div>
                        <div className="col-4">
                          <p>No Results.</p>
                        </div>
                        <div className="col-4"></div>
                      </div>
                    )}
                  </div>
                </section>
              </main>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}{" "}
      </React.Fragment>
    );
  }
}

export default withRouter(ShippingHistory);
