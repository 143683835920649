import React from "react";
import InfoPopover from "./InfoPopover";

const LicenseNumberPopover = (props) => {
  const content = (
    <React.Fragment>
      <p>
        If your item is accompanied by a license or a certificate, enter the
        number. You should enclose an invoice for all commercial items.
      </p>
    </React.Fragment>
  );

  return (
    <InfoPopover
      targetName={props.targetName}
      ariaLabel="License Number Information Icon"
      header="License Number"
      content={content}
    />
  );
};

export default LicenseNumberPopover;
