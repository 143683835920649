import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";

const ProhibitedRestrictedItem = (props) => {
  const {
    // Modal controls
    isOpen,
    toggle,
    // List of prohibitions and/or restrictions for the item
    prohibitionsRestrictions,
    // Props for determining whether accepting the prohibitions/restrictions
    // causes the user to add the item or proceed to the next page
    handleAddButton,
    handleContinueButton,
    proceedIfAllowed,
    // Other required props
    itemIndex,
    lookupCodes
  } = props;

  const [prohibitions, setProhibitions] = useState([]);

  const [restrictions, setRestrictions] = useState([]);

  const [observations, setObservations] = useState([]);

  const [tlcCheckbox, setTlcCheckbox] = useState(false);

  const [tlcCheckboxText, setTlcCheckboxText] = useState("");

  // Determine whether the item has prohibitions and/or restrictions
  useEffect(() => {
    let prohibitions = [];
    let restrictions = [];
    let observations = [];
    for (let index in prohibitionsRestrictions) {
      const element = prohibitionsRestrictions[index];
      if (element.type === "PROHIBITION") {
        prohibitions.push(element);
      } else if (element.type === "RESTRICTION") {
        restrictions.push(element);
      } else {
        observations.push(element);
      }
    }
    setProhibitions(prohibitions);
    setRestrictions(restrictions);
    setObservations(observations);
  }, [prohibitionsRestrictions]);

  // Determine what text, if any, should be shown for the checkbox
  useEffect(() => {
    let text = "";
    if (prohibitions.length > 0) {
      if (lookupCodes?.tlc_prohibited_item_allow?.toUpperCase() === "TRUE") {
        text = "I have read and understand this item may be prohibited.";
      }
    } else if (restrictions.length > 0) {
      text = "I have read and understand this item is a restricted item.";
    } else {
      text = "I have read and understand this item is an observation item.";
    }
    setTlcCheckboxText(text);
  }, [prohibitions, restrictions, observations, lookupCodes]);

  const closeModal = (e) => {
    setTlcCheckbox(false);
    toggle(null, false, e);
  };

  const showProhibitionsRestrictions = () => {
    if (prohibitions.length > 0) {
      if (lookupCodes?.tlc_prohibited_item_allow?.toUpperCase() === "TRUE") {
        return renderProhibitedAllowedText();
      } else {
        return renderProhibitedText();
      }
    } else if (restrictions.length > 0) {
      return renderRestrictedText();
    } else if (observations.length > 0) {
      return renderObservationText();
    } else {
      return <React.Fragment />;
    }
  };

  const renderProhibitedText = () => {
    return (
      <React.Fragment>
        <h4>
          The item you added is flagged as a prohibited item and can not be
          shipped
        </h4>
        <p>
          Prohibited items are not available for export/import due to
          restrictions by the export country/government or the destination
          country's governments.
        </p>
        {/* Only show the first note */}
        <p>{prohibitions[0].note}</p>
        <h4>Please add a non-prohibited item.</h4>
      </React.Fragment>
    );
  };

  const renderProhibitedAllowedText = () => {
    return (
      <React.Fragment>
        <h4>
          This item you added is flagged as a prohibited item and should not be
          shipped.
        </h4>
        {/* Only show the first note */}
        <p>{prohibitions[0].note}</p>
        <h4>
          If you believe this to be incorrect you may still add it to your
          shipment.
        </h4>
        <p>Do you understand this may be a restricted item?</p>
        {renderCheckbox()}
      </React.Fragment>
    );
  };

  const renderRestrictedText = () => {
    return (
      <React.Fragment>
        <h4>
          This item is flagged as a restricted item.
          <br />A restricted item does not necessarily mean you cannot export
          the good.
        </h4>
        {/* Only show the first note */}
        <p>{restrictions[0].note}</p>
        <h4>Do you understand and accept these restrictions?</h4>
        <p>
          I understand this is a restricted item. It doesn't necessarily mean
          you can't export the good. You may just need to obtain the proper
          license or permission to export.
        </p>
        {renderCheckbox()}
      </React.Fragment>
    );
  };

  const renderObservationText = () => {
    return (
      <React.Fragment>
        <h4>
          This item is flagged as an observation item.
          <br />
          An observation item does not necessarily mean you cannot export the
          good.
        </h4>
        {/* Only show the first note */}
        <p>{observations[0].note}</p>
        <h4>Do you understand and accept these observations?</h4>
        <p>
          I understand this is an observation item. It doesn't necessarily mean
          you can't export the good. You may just need to obtain the proper
          license or permission to export.
        </p>
        {renderCheckbox()}
      </React.Fragment>
    );
  };

  const renderCheckbox = () => {
    return (
      <div className="row section-row">
        <div className="col-12">
          <div className="usps-checkbox checkbox-field">
            <input
              type="checkbox"
              id="tlc-checkbox"
              name="tlc-checkbox"
              className="form-control checkbox-field"
              checked={tlcCheckbox}
              onChange={(e) => {
                setTlcCheckbox(e.currentTarget.checked);
              }}
            />
            <label htmlFor="tlc-checkbox">{tlcCheckboxText}</label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal id="modal-tlc-prohibited" isOpen={isOpen}>
      <div className="modal-content modal-container">
        <div className="modal-header">
          <button
            type="button"
            className="close button-link"
            id="close"
            data-dismiss="modal"
            tabIndex="0"
            onClick={closeModal}
          >
            <span className="visuallyhidden">Close Modal</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="body-content">
            <div id="modal-tlc-text">{showProhibitionsRestrictions()}</div>
            <div className="row">
              <div class="col-12">
                <div className="form-button-container">
                  <button
                    className="previous btn btn-outline-primary"
                    id="tlc-prohibited-close-button"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
                {tlcCheckbox && (
                  <div className="form-button-container">
                    <button
                      className="btn btn-primary"
                      id="tlc-prohibited-continue-button"
                      onClick={(e) => {
                        if (proceedIfAllowed) {
                          handleContinueButton();
                        } else {
                          handleAddButton(itemIndex);
                        }
                        closeModal(e);
                      }}
                    >
                      Continue
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProhibitedRestrictedItem;
