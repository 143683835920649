import React, { useState, useEffect } from "react";

const AssistiveGlobalError = (props) => {
  const { globalErrorId, globalErrorArray, shouldFocus } = props;

  const [filteredArray, setFilteredArray] = useState([]);

  const [focusOnGlobalError, setFocusOnGlobalError] = useState(false);

  const [thisGlobalErrorId, setThisGlobalErrorId] = useState(
    globalErrorId || "global-error-section"
  );

  // Filter out all falsy elements
  useEffect(() => {
    if (globalErrorArray) {
      const newFilteredArray = globalErrorArray.filter((element) => {
        return element;
      });
      // Only update the array if something changed
      let changed = false;
      if (newFilteredArray.length === filteredArray.length) {
        for (let i in filteredArray) {
          if (!newFilteredArray.includes(filteredArray[i])) {
            changed = true;
            break;
          }
        }
      } else {
        changed = true;
      }
      if (changed) {
        setFilteredArray(newFilteredArray);
        setFocusOnGlobalError(true);
      }
    }
  }, [globalErrorArray, filteredArray]);

  // Focus on the global error(s)
  useEffect(() => {
    if (
      (shouldFocus === undefined || shouldFocus) &&
      focusOnGlobalError &&
      filteredArray.length > 0
    ) {
      document.getElementById(thisGlobalErrorId).focus();
      setFocusOnGlobalError(false);
    }
  }, [shouldFocus, focusOnGlobalError, filteredArray, thisGlobalErrorId]);

  // Update the ID to be focused
  useEffect(() => {
    setThisGlobalErrorId(globalErrorId || "global-error-section");
  }, [globalErrorId]);

  return (
    <div
      id={thisGlobalErrorId}
      className={
        "error-message" +
        (thisGlobalErrorId === "global-error-section" ? " container" : "")
      }
      tabIndex="-1"
      style={{ paddingTop: "15px" }}
    >
      {filteredArray.map((item) => (
        <p
          key={item}
          className="pre-form-error"
          dangerouslySetInnerHTML={{
            __html: item
          }}
          style={{ marginBottom: 0 }}
        />
      ))}
    </div>
  );
};

export default AssistiveGlobalError;
