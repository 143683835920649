import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Autocomplete from "./Subcomponents/Autocomplete";
import * as analytics from "../Components/Analytics/Analytics.js";
// Images
import caretRight from "../customs_forms/images/caret-right.svg";
import alert from "../customs_forms/images/alert.svg";
import LoggedInNav from "./LoggedInNav";
var dataLayer = {
  event: "application",
  element: "",
  selection: "",
  page: "cfo-step-1a-select-destination-international"
};

class ExtraServices extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.cartInfo = this.previousState.cartInfo;
    this.receiptCheck2 = this.previousState.serviceOptions.receiptCheck2;
    this.receiptCheck1 = this.previousState.serviceOptions.receiptCheck1;
    this.productCode = this.previousState.serviceOptions.productCode;

    this.props.stepNumberCallback(this.previousState.stepNumber + 1);
    this.props.setPageErrors([]);
    this.props.toggleLoadingBar(false);

    this.state = {
      flowType: this.previousState.flowType,
      totalPackageValue: "",
      errors: {},
      selectedAdditionalServiceCode: "",
    };
    this.previousState.stepNumber = this.previousState.stepNumber + 1
  }

  handleAdditionalServicesAction = (event) => {
    const selectedRadioButton = event.target.value;

    this.setState({
      selectedAdditionalServiceCode: selectedRadioButton,
      totalPackageValue: selectedRadioButton === "REG" ? this.state.totalPackageValue: '',
      errors: {}
    });
  }

  componentDidMount = () => {
    this.props.loginCheck();
  };

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.flowType
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there is no flow type
    window.location.replace("/index");
  };


  handleBackButton = () => {
    dataLayer.element = "Back";
    analytics.setDestinationPricePackageDataLayerInfo(dataLayer);
    this.props.history.push("/service-options", {
      previousState: this.previousState
    });
  };

  handlePackageInfo = (event) => {
    const value = event.target.value;
    
    this.setState ({
      totalPackageValue: value
    });
  
    console.log("Package value:", value);
  };

  handleContinueButton = (e) => {
    e.preventDefault();
    const receiptStatus = this.getNewFlowTypeOrReceipt();
    console.log(receiptStatus);
    const selectedAdditionalServiceCode = this.state.selectedAdditionalServiceCode;

    if (!this.validateInputs()) {
      dataLayer.element = "Continue";
      analytics.setDestinationPricePackageDataLayerInfo(dataLayer);

      //Proceed to the next step, saving state
      this.previousState.selectedAdditionalServiceCode = this.state.selectedAdditionalServiceCode
      this.previousState.totalPackageValue = this.state.totalPackageValue

      this.previousState.serviceOptions.receiptCheck2 =
      receiptStatus === "domesticReceipt" ||
      receiptStatus === "internationalReceipt"
        ? receiptStatus
        : undefined;
    

      //good one
      this.props.toggleLoadingBar(true);
      axios
      .get(
        "/CFOApplication/saveServiceOption?cartId=" +
          encodeURIComponent(this.cartInfo.encodedCartId) +
          "&productCode=" +
          (this.previousState.serviceOptions.receiptCheck2 ? "SAP" : this.previousState.serviceOptions.productCode) +
          (selectedAdditionalServiceCode === "None" ? "" : "&additionalService=" + selectedAdditionalServiceCode) +
          "&flowType=" +
          receiptStatus,
        {
          headers: { "Content-Type": "application/json" }
        } 
      )
      .then((response) => {
        analytics.serviceOptions_InfoToDataLayer(this.previousState);
      })
      .catch((error) => {
        console.log(error);
        this.props.setPageErrors([
          "We're sorry. An unexpected error has occurred."
        ]);
        this.props.toggleLoadingBar(false);
      });
      

      this.props.history.push("/sender-info", {
        previousState: this.previousState
      });
    }
  };

  validateInputs = () => {
    let errorObject = {};
    
    if ( this.state.selectedAdditionalServiceCode === "REG" && !(this.state.totalPackageValue > 0)) {
      errorObject["totalPackageValue"] = "Please enter a valid package value greater than 0.";
    }

    this.setState ({
      errors: errorObject
    });
    
    return Object.keys(errorObject).length > 0;
  };

  setInputErrors = (field, errorMessage) => {
    if (field === "errorObject") {
      this.setState({
        errors: errorMessage
      });
    } else {
      this.setState((prevState) => {
        return {
          errors: {
            ...prevState.errors,
            [field]: errorMessage
          }
        };
      });
    }
  };

  getNewFlowTypeOrReceipt = () => {
    if (this.previousState.receiptCheck1) {
      return this.previousState.receiptCheck1;
    }
    if (this.previousState.serviceOptions.selectedServiceType === "SAP") {
      if (
        this.previousState.flowType === "militaryToUSA" ||
        this.previousState.flowType === "usa" ||
        this.previousState.flowType === "military" ||
        this.previousState.flowType === "territories"
      ) {
        return "domesticReceipt";
      } else if (this.previousState.flowType === "Canada" || this.previousState.flowType === "other") {
        return "internationalReceipt";
      } else {
        return this.previousState.flowType;
      }
    } else {
      return this.previousState.flowType;
    }
  };

  render() {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                      <p className="note">* indicates a required field</p>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-12">
                      <h2>
                        Extra Services
                      </h2>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-8 col-xl-7">
                      <p>
                        *Are you adding an Extra Service to your shipment? 
                      </p>

                      <div>
                        <input
                          required
                          type="radio"
                          name="additionalService"
                          value= "None"
                          checked={this.state.selectedAdditionalServiceCode === "None"}
                          onChange={this.handleAdditionalServicesAction}
                        />
                        <span> None </span>
                        {this.previousState.serviceOptions.additionalServices.map((additionalService) => (
                          <div key={additionalService.serviceCode}>
                            <label>
                              <input
                                type="radio"
                                name="additionalService"
                                value={additionalService.serviceCode}
                                checked={this.state.selectedAdditionalServiceCode === additionalService.serviceCode}
                                onChange = {this.handleAdditionalServicesAction}
                              />
                              <span style={{marginLeft:'4px'}}>{additionalService.serviceName} - {additionalService.displayPrice}</span>                              
                            </label>
                          </div>
                        ))}
                        <br></br>
                      </div>  
                      
                    

                      {this.state.selectedAdditionalServiceCode === "REG" && (
                        <div className= {` form-group ${this.state.errors.totalPackageValue ? 'has-error' : ''}`}>
                          <label htmlFor="totalPackageValue">*Total Package Value</label>
                          <div className="input-group">
                            <span className="input-group-addon"></span> 
                            <input
                              type="number"
                              id="totalPackageValue"
                              className="form-control"
                              value={this.state.totalPackageValue}
                              onChange={this.handlePackageInfo}
                            />
                          </div>
                          {this.state.errors.totalPackageValue && (
                            <span className="error-message">{this.state.errors.totalPackageValue}</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row section-row" id="row-dependent-0">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                               
                                <div className="form-button-container">
                                  <button
                                    className="previous btn btn-outline-primary"
                                    type="button"
                                    onClick={this.handleBackButton}
                                  >
                                    Back
                                  </button>
                                </div>
                                {!(this.state.selectedAdditionalServiceCode === "") && (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="submit"
                                    onClick={this.handleContinueButton}
                                  >
                                    Continue
                                  </button>
                                </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
   
  }
}

export default withRouter(ExtraServices);
